import React from 'react';
import PropTypes from 'prop-types';

const DateTimeInput = ({ label, type, placeholder='', value, error, required, onChange }) => {

    return (
      <>
        {label &&
          <label className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">{label}
            {required && <span className="text-red-600 dark:text-red-400 ml-1">*</span>}
          </label>
        }
        <div className="mt-2.5">
          <input type={type} value={value} onChange={onChange} title={label} placeholder={placeholder}
        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-200 dark:bg-neutral-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 focus:outline-none" />
          {error && <span className="text-sm text-red-600 dark:text-red-400  font-semibold">{error}</span>}
        </div>
      </>
    );
  };

DateTimeInput.propTypes = {
  label: PropTypes.any,
  type: PropTypes.any,
  placeholder: PropTypes.any,
  error: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  required:PropTypes.any
}

  
  export default DateTimeInput;
  