import React from 'react';
import PropTypes from 'prop-types';

const CheckboxButton = ({ label, value, description, checked, onChange, disabled }) => {
    return (
        <div className="relative flex gap-x-3">
            <div className="flex h-6 items-center">
                <input
                    type="checkbox"
                    value={value}
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled} 
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
            </div>
            <div className="text-sm leading-6">
                <label htmlFor="comments" className="font-medium text-gray-900 dark:text-gray-200">{label}</label>
                {description && <p className="text-gray-600 dark:text-gray-400">{description}</p>}
            </div>
        </div>
    );
};

CheckboxButton.propTypes = {
    label: PropTypes.any,
    value: PropTypes.any,
    description: PropTypes.string,
    checked: PropTypes.any,
    onChange: PropTypes.any,
    disabled: PropTypes.bool, // Add the disabled prop type
};

export default CheckboxButton;