import React from 'react';
import PropTypes from 'prop-types';

const TableButtonSection = ({ children, searchQuery, onSearchChange, onClearSearch }) => {
    return (
        <div className="mt-6 md:flex md:items-center md:justify-between">
            <div className="flex flex-row items-center space-x-2">
                {children}
            </div>

            <div className="relative flex items-center mt-4 md:mt-0">
                <span className="absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mx-3 text-gray-400 dark:text-gray-400">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                </span>

                <input type="text" value={searchQuery} onChange={onSearchChange} placeholder="Search" className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 dark:placeholder-gray-300/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-neutral-900 dark:text-gray-200 dark:border-neutral-600 focus:border-indigo-600 dark:focus:border-indigo-600 focus:ring-2 focus:ring-inset" />

                {searchQuery && (
                    <button onClick={onClearSearch} className="absolute right-3 top-1/2 transform -translate-y-1/2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 text-gray-400 dark:text-gray-400">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
                )}
            </div>
        </div>
    );
};
TableButtonSection.propTypes = {
    children: PropTypes.any,
    searchQuery: PropTypes.any,
    onSearchChange: PropTypes.any,
    onClearSearch: PropTypes.any,
}

export default TableButtonSection;