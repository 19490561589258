import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { PencilIcon, TrashIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownShortWide, faArrowUpWideShort, faPlus, faDownload, faTrashCan, } from '@fortawesome/free-solid-svg-icons'
import PageWrapper from "../../../layouts/PageWrapper";
import Pagination from "../../../components/table/Pagination";
import TableWrapper from "../../../components/table/TableWrapper";
import TableContainer from "../../../components/table/TableContainer";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound";
import TableLoadingData from "../../../components/table/TableLoadingData";
import TableHeadingSection from "../../../components/table/TableHeadingSection";
import TableButtonSection from "../../../components/table/TableButtonSection";
import { useGetDocsMetadataForAdminQuery, useGetCurrentUserPermissionsQuery, useDeleteBulkFileMetadataByUrlMutation, useGetDocMetadataForAdminPagesMutation } from "../../../graphql/generated/graphql.ts";
import DialogModal from "../../../components/modal/DialogModal";
import Button from "../../../components/button/Button";
import { toast } from "react-toastify";
import axios from "axios";
import { formatTimestamp, formatSize, getFileExtension } from '../../../utils/commonFormatFunctions.js'
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import ActionButton from "../../../components/button/ActionButton.js";


const Index = () => {
    const navigate = useNavigate();
    const [docInfoMutation, { data: paginationData, loading, error }] = useGetDocMetadataForAdminPagesMutation();
    const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
    const [deleteDocInBulkByUrl, { loading: deleteLoder }] = useDeleteBulkFileMetadataByUrlMutation();
    const { fileMetadataList, pageInfo } = paginationData?.getDocMetadataForAdminPages || {};
    const [isOpen, setIsOpen] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [itemsData, setItemsData] = useState([]);
    const [deletedItem, setDeletedItem] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortedColumn, setSortedColumn] = useState('file_name');
    const [sortOrder, setSortOrder] = useState('ASC');
    const [downloadLoader, setDownloadLoader] = useState(false);
    const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
    const permissionAPIList = permissionData?.getAllAPIPermissions
    const createDocuments = permissionAPIList?.filter(item => item.accessType === "CreateDocuments")
    const deleteDocuments = permissionAPIList?.filter(item => item.accessType === "DeleteDocuments")
    const updateDocuments = permissionAPIList?.filter(item => item.accessType === "UpdateDocuments")
    // Helper function to check user permissions
    const hasUserPermission = (documentPermissions, permissionToCheck) =>
        documentPermissions?.length && documentPermissions[0].assignedPermissions.some(val => permissionToCheck.includes(val));
    // Refactored permission checks
    const editUserPermission = hasUserPermission(updateDocuments, addPermission);
    const addUserPermission = hasUserPermission(createDocuments, addPermission);
    const deleteUserPermission = hasUserPermission(deleteDocuments, addPermission);
    const maxPageNumber = 5;
    const defaultPageNumber = 1;
    const [recordsPerPageData, setRecordsPerPageData] = useState(pageInfo?.per_page)

    function handleNavigationError() {
        if (error) {
            navigate('/error', { state: { errorData: JSON.stringify(error) } });
        }
    }

    handleNavigationError();


    const { data } = useGetDocsMetadataForAdminQuery({
        fetchPolicy: "network-only",
        skip: !downloadLoader,
        onCompleted: Metadata => {
            if (Metadata?.getDocsMetadataForAdmin) {
                setItemsData(Metadata.getDocsMetadataForAdmin);
            }
        },
    })

    const getDocData = (page = defaultPageNumber, row = pageInfo?.per_page, search_term = searchQuery) => {
        setDeletedItem([])
        setSelectedItems([]); setSelectAll(false)
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term,
            search_column: "",
            order_by: sortOrder,
            order_by_column: sortedColumn,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        };

        docInfoMutation({ variables: { PageInfoInput } })
            .catch(er => {
                toast.error(er.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    };

    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getDocData(defaultPageNumber, rowValue);
    };


    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Documents`;
        document.title = pageTitle;
    }, [data]);

    useEffect(() => {
        if(sortOrder && sortedColumn) {
            getDocData(defaultPageNumber, pageInfo?.per_page);
        }
    }, [sortOrder, sortedColumn]);

    useEffect(() => {
        const delayTime = 1000;
        if (searchQuery) {
            const delayDebounceFn = setTimeout(() => {
                // Make API request with the current value
                getDocData(defaultPageNumber, pageInfo?.per_page, searchQuery);
            }, delayTime);
            return () => {
                clearTimeout(delayDebounceFn);
            };
        }
    }, [searchQuery]);

    const handleClearSearch = () => {
        setSearchQuery("");
        getDocData(defaultPageNumber, pageInfo?.per_page, "");
    };

    // Handle search input change
    const handleSearchChange = e => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm);
    };

    // Handle column header click for sorting
    const handleSort = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrder);
        setSortedColumn(column);
    };

    // Handle pagination link click
    const handlePageChange = pageNumber => {
        getDocData(pageNumber, pageInfo?.per_page);
    };

    //handle open modal.
    const openModal = () => {
        if (selectedItems.length) {
            setIsOpen(!isOpen)
        }
    };

    //handle close of delete modal
    const closeModal = () => {
        setIsOpen(!isOpen)
        setDeletedItem([])
        setSelectedItems([])
    };

    //handle checkbox
    const handleCheckboxChange = itemId => {
        if (selectedItems.includes(itemId)) {
            setSelectedItems(selectedItems.filter(id => id !== itemId));
        } else {
            setSelectedItems([...selectedItems, itemId]);
        }
    };

    //handle bulk doc delete. 
    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(fileMetadataList?.map(item => item.fileURL));
        }
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        if (itemsData?.length > 0) {
            let stringToDownload = "File Name, Support Document, Folder, Size(in bytes), Created On, Updated On, Sensitivity Label\n";
            itemsData?.forEach(dataValue => {
                const createdDate = new Date(dataValue.created_at);
                const updatedDate = new Date(dataValue.updated_at);
                // Convert timestamps to human-readable date formats and GMT time
                const createdDateGMT = formatTimestamp(createdDate);
                const updatedDateGMT = formatTimestamp(updatedDate);

                stringToDownload = `${stringToDownload}${dataValue.fileName},${(dataValue.is_support ? 'Yes' : 'No')},${dataValue.section},${dataValue.file_bytes},${createdDateGMT},${updatedDateGMT},${dataValue.sensitivity_label}\n`;
            });
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:attachment/text,' + encodeURI(stringToDownload);
            hiddenElement.target = '_blank';
            const date = new Date();
            hiddenElement.download = `documents_report ${date.toString()}.csv`;
            hiddenElement.click();
            setDownloadLoader(!downloadLoader)
        }
    }, [itemsData])

    //handle download csv file report for document details. 
    const handleDownloadFile = () => {
        setDownloadLoader(true)
    };

    //handle document deletion. 
    const onClickDelete = selectedItem => {
        const fileUrls = selectedItem;
        deleteDocInBulkByUrl({ variables: { fileUrls } })
            .then(() => {
                toast.success('Selected documents deleted successfully.')
                closeModal();
                getDocData();
            }).catch(err => {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            })
    }

    //handle individual delete. 
    const handleDelete = fileUrlData => {
        setDeletedItem([fileUrlData])
        setIsOpen(!isOpen)
    }

    //handle download doc.
    const handleDownloadDocument = async r => {
        try {
            const response = await axios.get(r.downloadURL, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = r.fileName;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (errData) {
            toast.error(`'Error downloading file:', ${errData}`, {
                autoClose: false,
            });
        }
    }

    return (
        <>
            <PageWrapper heading={'Manage Documents'}>
                <TableWrapper>
                    <TableHeadingSection heading={'Documents'} description={'All the active documents in the database.'} count={pageInfo?.total_records} />
                    <TableButtonSection searchQuery={searchQuery} onSearchChange={handleSearchChange} onClearSearch={handleClearSearch}>
                        {addUserPermission && !loading && <Link to='/manage/documents/new' className={"flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-indigo-500 rounded-lg shrink-0 gap-x-2 hover:bg-indigo-600 dark:bg-indigo-600"}>
                            <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faPlus} />
                            <span>Add</span>
                        </Link>}
                        <Button
                            type="button"
                            onClick={handleDownloadFile}
                            loader={downloadLoader}
                            className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                            icon={faDownload}
                            text="Download Report" />
                        {selectedItems?.length > 0 && deleteUserPermission &&
                            <Button type="button"
                                onClick={openModal}
                                className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-red-500 rounded-lg shrink-0 gap-x-2 hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600"
                                icon={faTrashCan}
                                text="Delete" />}
                    </TableButtonSection>
                    <TableContainer>
                        <thead className="bg-gray-50 dark:bg-neutral-800">
                            <tr>
                                <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold">
                                    <div className="flex h-6 items-center">
                                        <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    </div>
                                </th>
                                <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap"></th>
                                <th scope="col" onClick={() => handleSort('file_name')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Document Name</span>
                                        {sortedColumn === "file_name" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('section')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Folder</span>
                                        {sortedColumn === "section" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('is_support')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Support</span>
                                        {sortedColumn === "is_support" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('sensitivity_label')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Sensitivity Label</span>
                                        {sortedColumn === "sensitivity_labell" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>

                                <th scope="col" onClick={() => handleSort('file_bytes')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>File Size</span>
                                        {sortedColumn === "file_bytes" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('created_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Created On</span>
                                        {sortedColumn === "created_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('updated_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Updated On</span>
                                        {sortedColumn === "updated_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" className="px-4 py-3.5 text-sm text-center rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                            {fileMetadataList?.map(document => (
                                <tr key={document?.id}>
                                    <td className="px-4 py-3 text-sm font-medium">
                                        <div className="flex h-6 items-center">
                                            <input type="checkbox" checked={selectedItems.includes(document.fileURL)} onChange={() => handleCheckboxChange(document?.fileURL)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm">
                                        <div className="text-ellipsis overflow-hidden">
                                            <div className="text-2xl font-semibold text-gray-600 dark:text-gray-200">
                                                <FontAwesomeIcon icon={getFileExtension(document.fileURL)} /></div>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm font-medium">
                                        <div className="w-full">
                                            <p className="text-indigo-600 dark:text-indigo-400">
                                                {document.fileName}
                                            </p>
                                        </div>
                                    </td>

                                    <td className="px-4 py-3 text-sm font-medium whitespace-pre-line">
                                        <div className="w-48">
                                            <Link to={`/manage/folders/${document?.folder_id}`} className="text-indigo-600 dark:text-indigo-400 text-ellipsis">
                                                {document.section}</Link>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm font-medium">
                                        <div className=" text-ellipsis overflow-hidden">
                                            <h4 className=" text-gray-700 dark:text-gray-200">
                                                {document.is_support ? "Yes" : "No"}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm font-medium">
                                        <div className="text-ellipsis overflow-hidden ">
                                            <h4 className=" text-gray-700 dark:text-gray-200 capitalize">
                                                {document.sensitivity_label}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatSize(document.file_bytes)}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(document?.created_at)}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(document?.updated_at)}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="flex item-center justify-center text-gray-700 dark:text-gray-200">
                                            <ActionButton
                                                className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110 group-hover:relative"
                                                icon={ArrowDownTrayIcon} title="Download" onClick={() => { handleDownloadDocument(document) }} />
                                            {editUserPermission ? <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                onClick={() => { navigate('/manage/documents/edit', { state: { documentData: document } }) }} icon={PencilIcon} title="Edit" />
                                                : <ActionButton className="w-4 mr-2 transform text-gray-500" icon={PencilIcon} title="Edit" />
                                            }
                                            {deleteUserPermission ? <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" icon={TrashIcon} title="Delete"
                                                onClick={() => { handleDelete(document?.fileURL) }} /> : <ActionButton className="w-4 mr-2 transform text-gray-500"
                                                    icon={TrashIcon} title="Delete" />
                                            }
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {!fileMetadataList?.length && !loading && <TableNoRecordFound colSpan={10}></TableNoRecordFound>}
                            {loading && <TableLoadingData colSpan={10}></TableLoadingData>}
                        </tbody>
                    </TableContainer>
                </TableWrapper>
                <Pagination totalPages={loading ? 0 : pageInfo?.total_pages} totalRecords={loading ? 0 : pageInfo?.total_records}
                    maxPageNumbers={maxPageNumber}
                    recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0}
                    currentPage={loading ? 0 : pageInfo?.current_page} onPageChange={handlePageChange} onRowChanges={handleRowChanges} ></Pagination>
            </PageWrapper>
            <DialogModal
                isOpen={isOpen}
                onClose={closeModal}
                title="Confirm Deletion"
                closeOnClickOutside={false} // Set this prop as needed
                p pTagData={`Do you confirm to delete ${deletedItem?.length || selectedItems?.length} documents?`}
                btnOne="Confirm Delete"
                btnTwo="Cancel"
                loader={deleteLoder}
                onClickDeleteModal={() => { onClickDelete(deletedItem.length > 0 ? deletedItem : selectedItems) }}>
            </DialogModal>
        </>
    );
};

export default Index;
