import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UsersIcon, SwatchIcon, UserGroupIcon, FolderIcon, ClipboardDocumentListIcon, VideoCameraIcon, BellIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline'
import { useGetAllResourceCountsQuery, useGetCurrentUserPermissionsQuery } from "../graphql/generated/graphql.ts";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'


const Home = () => { 
    const [kpiCardsList, setKpiCardsList] = useState([]);
    const navigate = useNavigate();
    const { data: permissionData, error: permissionError, loading: permissionLoading, } = useGetCurrentUserPermissionsQuery({ variables: {}, });
    const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
    const permissionAPIList = permissionData?.getAllAPIPermissions
    const currentPermissionUser = permissionAPIList?.filter(item => item.accessType === "ReadAuditLogs")
    const accessAdminPortal = permissionAPIList?.filter(item => item.accessType === "AccessAdminPortal")
    const readUserInfo = permissionAPIList?.filter(item => item.accessType === "ReadUserInfos")
    const accessAdminPortalPermission = accessAdminPortal?.length && accessAdminPortal[0].assignedPermissions.some(val => addPermission.includes(val));
    const editUserPermission = currentPermissionUser?.length && currentPermissionUser[0].assignedPermissions.some(val => addPermission.includes(val));
    const readUserPermission = readUserInfo?.length && readUserInfo[0].assignedPermissions.some(val => addPermission.includes(val));

    useEffect(() =>{
        localStorage.setItem("uniqueId", permissionData?.getCurrentUserPermissions?.uniqueId);
    }, [permissionData])


    if (permissionError) {
        navigate('/error', { state: { errorData: JSON.stringify(permissionError) } });
    }

    useEffect(() => {
        // Handle errors from the first query
        if (editUserPermission && !accessAdminPortalPermission) {
            navigate('/audit/activity-logs', {
                state: "read_audit_log",
            })
        }
    }, [permissionData]);

    const { loading: resourceLoading, error: resourceError, data: resourceData } = useGetAllResourceCountsQuery({
        variables: {},
        skip: !readUserPermission,
    });

    if (resourceError) {
        navigate('/error', { state: { errorData: JSON.stringify(resourceError) } });
    }

    useEffect(()=>{
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Home`;
        document.title = pageTitle;


    },[])


    useEffect(() => {
        const kpiCardData = resourceData?.getAllResourceCounts;
        const kpiCardListData = [
            {
                title: 'Users', count: kpiCardData?.users, span_color: 'red', redirect_url: '/manage/users', Icon: UsersIcon
            },
            { title: 'Roles', count: kpiCardData?.roles, span_color: 'red', redirect_url: '/access-control/roles', Icon: SwatchIcon },
            { title: 'Groups', count: kpiCardData?.groups, span_color: 'red', redirect_url: '/access-control/groups', Icon: UserGroupIcon },
            { title: 'Folders', count: kpiCardData?.sections, span_color: 'red', redirect_url: '/manage/folders', Icon: FolderIcon },
            { title: 'Documents', count: kpiCardData?.documents, span_color: 'red', redirect_url: '/manage/documents', Icon: ClipboardDocumentListIcon },
            { title: 'Videos', count: kpiCardData?.videos, span_color: 'red', redirect_url: '/manage/videos', Icon: VideoCameraIcon },
            { title: 'Notifications', count: kpiCardData?.notifications, span_color: 'red', redirect_url: '/manage/notifications', Icon: BellIcon },
            { title: 'Feedbacks', count: kpiCardData?.feedbackAndRating, span_color: 'red', redirect_url: '/manage/feedbacks', Icon: ChatBubbleLeftRightIcon },
        ];
        setKpiCardsList(kpiCardListData);
    }, [resourceData]);


    return (
        <div className="mt-16 w-full max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mb-6">

                 <div>
                <div>
                    <div className="py-6">
                        <h2 className="text-2xl font-bold dark:text-gray-100 mb-4">Management Dashboard</h2>
                        <hr />
                    </div>
                </div>
                <div>
                    <div className="py-6">
                        <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                            <div className="col-span-12">
                                <div
                                    className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
                                    {kpiCardsList.map(cardItem => (
                                        <Link key={cardItem.title} to={cardItem.redirect_url} className="shadow bg-white hover:bg-gray-200 dark:bg-neutral-900 dark:text-gray-100 dark:hover:bg-neutral-800 cursor-pointer px-5 py-3">
                                            <div className="flex flex-row items-center">
                                                <div>
                                                    <cardItem.Icon className="w-6 h-6 mr-3" />
                                                </div>
                                                <div>
                                                    <h2 className="font-bold">{cardItem.title}</h2>
                                                    <div className="w-10 border-b-4 border-[#FFCD00] my-1"></div>
                                                </div>
                                            </div>
                                            {
                                                resourceLoading || permissionLoading ? 
                                                    <div className=" items-center">
                                                        <FontAwesomeIcon className="w-5 h-5 ml-28 mb-4 animate-spin" icon={faSpinner} />
                                                    </div>
                                                    : <p className="text-center text-4xl font-bold mb-4">{cardItem.count}</p>
                                            }
                                        </Link>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="py-6">
                        <h2 className="text-2xl font-bold dark:text-gray-100">User Activity</h2>
                        <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Fill in the user details and role based access privileges</p>
                    </div>
                </div>
                <div>
                    <div className="py-2">
                        <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                            <div className="col-span-12">
                                <div
                                    className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
                                    {kpiCardsList.map(cardItem=> (
                                        <div key={cardItem?.title} className="shadow bg-white hover:bg-gray-200 dark:bg-neutral-900 dark:text-gray-100 dark:hover:bg-neutral-800 cursor-pointer px-5 py-3">
                                            <div className="flex flex-row items-center">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                                        stroke="currentColor" className="w-6 h-6 mr-3">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                            d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                                    </svg>
                                                </div>
                                                <div>
                                                    <h2 className="font-bold">{cardItem.title}</h2>
                                                    <div className="w-10 border-b-4 border-[#DA291C] my-1"></div>
                                                </div>
                                            </div>
                                            {
                                                resourceLoading || permissionLoading ?
                                                    <div className=" items-center">
                                                        <FontAwesomeIcon className="w-5 h-5 ml-28 mb-4 animate-spin" icon={faSpinner} />
                                                    </div>
                                                    : <p className="text-center text-4xl font-bold mb-4">{cardItem.count}</p>
                                            }
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
