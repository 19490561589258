import InputField from "../../../components/form/input/InputField";
import TextAreaInput from "../../../components/form/input/TextAreaInput";
import PageWrapper from "../../../layouts/PageWrapper";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useFormik } from "formik";
import { formCreateScheduleNotificationSchema } from "../../../validations/FormValidationSchema"
import {
    useGetAllUserInfoListQuery,
    useNotificationScheduleListUpdateMutation, useIsPushNotificationEnabledQuery
} from '../../../graphql/generated/graphql.ts'
import { toast } from "react-toastify";
import {
    Link, useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import Button from "../../../components/button/Button";
import SelectMultipleInput from "../../../components/form/input/SelectMultipleInput.js";
import React, { useEffect, useState } from "react";
import '../../../styles/Draft.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import CheckboxButton from "../../../components/form/input/CheckboxButton.js";
import { formatTimestamp } from "../../../utils/commonFormatFunctions.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'

const EditScheduleNotification = () => {
    const { notificationId } = useParams();
    const { state } = useLocation();
    const { allNotification } = state || {};
    const navigate = useNavigate();
    const { data: getUserData } = useGetAllUserInfoListQuery();
    const [createNotificationSchedule, { loading, error }] = useNotificationScheduleListUpdateMutation();
    const { data: pushNotificationData, } = useIsPushNotificationEnabledQuery();


    useEffect(() => {
        if (allNotification) {
            const getDataList = allNotification
            const getGroupInfos = getUserData?.getAllGroups;
            const getUserInfo = getUserData?.getAllUserInfoList;
            const dataList = getDataList?.filter(item => Number(item.uniqueId) === Number(notificationId))

            if (dataList) {
                const group = dataList[0].groupIds
                const user = dataList[0].userIds

                const groupWithId = [];
                group.forEach(id => {
                    const groupLists = getGroupInfos?.filter(item => Number(item.id) === Number(id))
                    groupLists && groupWithId.push(...groupLists)
                })
                let groupData = []
                groupWithId?.map(item => groupData = [...groupData, { label: item.groupName, value: item.id }])

                const userWithId = [];
                user.forEach(id => {
                    const userLists = getUserInfo?.filter(item => Number(item.uniqueId) === Number(id))
                    userLists && userWithId.push(...userLists)
                })
                let userData = []
                userWithId?.map(item => userData = [...userData, { label: item.uniqueName, value: item.uniqueId }])

                const dataValue = dataList[0]
                setSelectedDate(formatTimestamp(dataValue.notifyAt))
                setInitialValues({
                    ...initialValues,
                    ...dataValue,
                    groupName: groupData,
                    userName: userData,
                })
            }
        }
    }, [getUserData, allNotification])


    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Notifications | Schedule`;
        document.title = pageTitle;
    }, [])
    const [initialValues, setInitialValues] = useState({
        groupName: [],
        userName: [],
        isChekedBox: false
    });
    const [userListFiltered, setUserListFiltered] = useState();
    const [userList, setUserList] = useState()
    const [selectedDate, setSelectedDate] = useState(false)
    const [isChekedBox, setIsChekedBox] = useState(false);


    if (error) {
        navigate('/error', { state: { errorData: JSON.stringify(error) } });
    }

    const groupList = [];
    const getGroupInfo = getUserData?.getAllGroups;
    if (getGroupInfo) {
        getGroupInfo.forEach(function (item) {
            groupList.push({ label: item.groupName, value: item.id })
        });
    }

    useEffect(() => {
        const userLists = [];
        const getUserInfo = getUserData?.getAllUserInfoList;
        const dataList = getUserInfo?.filter(val => !userListFiltered?.includes(val));
        if (dataList) {
            dataList.forEach(function (item) {
                userLists.push({ label: item.uniqueName, value: item.uniqueId })
            });
            setUserList(userLists)
        }
    }, [getUserData, userListFiltered])

    const handleGroupChange = e => {
        const groupFiltered = []
        const getUserInfo = getUserData?.getAllUserInfoList;
        e?.map(val => {
            const filteredData = getUserInfo.filter(item => item?.groupIds.includes(val.value))
            groupFiltered.push(...filteredData)
        })
        setUserListFiltered(groupFiltered)
    }

    const { values, errors, touched, setFieldValue, setFieldTouched, handleBlur, handleSubmit } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: formCreateScheduleNotificationSchema,
        onSubmit: value => {
            const groupIDs = value?.groupName?.map(item => item.value)
            const userListIds = value?.userName?.map(id => id.value)
            const unixTimestamp = new Date(selectedDate).getTime();
            const notificationScheduleUpdate = [{
                notificationId,
                title: values?.title,
                body: values?.body,
                description: values?.description, //NOSONAR
                groupIds: groupIDs,
                userIds: userListIds,
                notifyAt: unixTimestamp,
                isPushNotification: isChekedBox,
                notificationMetadata: "",
                notificationStatus: values?.notificationStatus,
                uniqueId: values?.uniqueId
            }]
            createNotificationSchedule({
                variables: {
                    notificationScheduleUpdate
                }
            }).then(() => {
                toast.success("Notification schedule created successfully.")
                navigate(`/manage/notifications/${notificationId}`)
            }).catch(err => {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            })
        }
    })

    const onClickConfirm = event => {
        const date = event.target.value
        setSelectedDate(date)
    }

    const onClickChecked = e => {
        setIsChekedBox(e.target.checked);
    };




    return (
        <PageWrapper heading={'Schedule New Notification'}>
            <div className="py-6">
                <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                    <div className="col-span-4">
                        <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">General Information
                        </h2>
                        <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Fill in the notification details</p>
                    </div>
                    <form method="POST" onSubmit={handleSubmit} className="col-span-8">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                            <div className="sm:col-span-2">
                                <InputField type={'text'} name={'title'} label={'Title'} value={values.title} disabled={true} onBlur={handleBlur} error={(errors.title && touched.title) ? errors.title : ''} />
                            </div>
                            <div className="sm:col-span-2">
                                <TextAreaInput name={'description'} label={'Description'} value={values.description} disabled={true} rowSpan={3} onBlur={handleBlur} error={(errors.description && touched.description) ? errors.description : ''} />
                            </div>
                            <div className="sm:col-span-2">
                                <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">Message Body</h3>
                                <div className="prose w-full sm:col-span-2" dangerouslySetInnerHTML={{ __html: values?.body }}>
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <SelectMultipleInput
                                    name="groupName"
                                    value={values?.groupName}
                                    options={groupList || []}
                                    onChange={selectedGroup => {
                                        setFieldValue('groupName', selectedGroup)
                                        handleGroupChange(selectedGroup)
                                    }}
                                    onBlur={() => setFieldTouched('groupName', true)}
                                    label="Select Group"
                                    error={(errors.groupName && touched.groupName) ? errors.groupName : ''}
                                />
                            </div>
                            <div className="sm:col-span-2">
                                <SelectMultipleInput
                                    name="userName"
                                    value={values?.userName}
                                    options={userList}
                                    required
                                    onChange={selectedRole => {
                                        setFieldValue('userName', selectedRole)
                                    }}
                                    onBlur={() => setFieldTouched('userName', true)}
                                    label="Select Name"
                                    error={(errors.userName && touched.userName) ? errors.userName : ''}
                                />
                            </div>
                            <div className="sm:col-span-2">
                                <CheckboxButton
                                    label="Send as Push Notification"
                                    value={isChekedBox}
                                    checked={isChekedBox}
                                    disabled={!pushNotificationData?.isPushNotificationEnabled}
                                    onChange={onClickChecked}
                                    description="Push notification on user device through Airship."
                                />
                            </div>
                            <div className="sm:col-span-2">
                                <input
                                    type="datetime-local"
                                    value={selectedDate || ""}
                                    onChange={onClickConfirm}
                                    className="border p-2 mb-4"
                                />
                            </div>
                        </div>
                        <div className="mt-10 flex justify-start">
                            {loading ? <Button type="button"
                                className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                text="Create" />
                                : <Button type="submit"
                                    className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    text="Create" />
                            }
                            <Link to="/manage/notifications"
                                className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                            {loading && <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                        </div>
                    </form>
                </div>
            </div>
        </PageWrapper>
    )
}

export default EditScheduleNotification;