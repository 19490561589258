import {  useLocation, useNavigate, } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSpinner, } from '@fortawesome/free-solid-svg-icons'
import Button from "../../../components/button/Button";
import { formatTimestamp } from "../../../utils/commonFormatFunctions";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetCronJobAuditLogMutation, useGetCronJobAuditLogDownloadReportMutation } from '../../../graphql/generated/graphql.ts'
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import TableWrapper from "../../../components/table/TableWrapper";
import TableButtonSection from "../../../components/table/TableButtonSection";
import TableContainer from "../../../components/table/TableContainer";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound";
import TableLoadingData from "../../../components/table/TableLoadingData";
import Pagination from "../../../components/table/Pagination";
import PageWrapper from "../../../layouts/PageWrapper";
import TableHeaderComponent from "../../../components/form/input/TableHeaderComponent.js";


const AuditCronJobDetails = () => {
    const defaultPageNumber = 1;
    const defaultPerPageData = 10;
    const maxPageNumber = 5;
    const navigate = useNavigate();
    const state = useLocation()?.state || {}
    const { cronJobData } = state;
    const [sortedColumn, setSortedColumn] = useState('timestamp');
    const [sortOrder, setSortOrder] = useState('DESC');
    const [searchQuery, setSearchQuery] = useState('');
    const [ downloadedLoader, setDownloadedLoader ] = useState(false);
    const [recordsPerPageData, setRecordsPerPageData] = useState(defaultPerPageData);
    const [auditLogMuation, { data: auditLogPaginationdata, loading }] = useGetCronJobAuditLogMutation(); 
    const [getDownloaData, { data: downloadData, loading: downloadLoder }] = useGetCronJobAuditLogDownloadReportMutation();
    const { cronAuditLogs, pageInfo } = auditLogPaginationdata?.getCronJobAuditLog || {};

    useEffect(() => {
        // getAuditCronJobDeatils()
    }, [])

    const getAuditCronJobDeatils = (page = defaultPageNumber, row = pageInfo?.per_page, searchTerm = searchQuery) => {
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term: searchTerm || "",
            search_column: "",
            order_by: sortOrder,
            order_by_column: sortedColumn,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        }
        auditLogMuation({ variables: { PageInfoInput, cronJob: cronJobData?.cronjobname } })
            .catch(err => {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    }

    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getAuditCronJobDeatils(defaultPageNumber, rowValue);
    }

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Cron Job | Deatils | ${cronJobData?.cronjobname}`;
        document.title = pageTitle;
    }, [])

    const handleSort = column => {
        const newSortOrderData = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrderData);
        setSortedColumn(column);
    };

    useEffect(() => {
        if (sortOrder && sortedColumn) {
            getAuditCronJobDeatils(defaultPageNumber, pageInfo?.per_page);
        }
    }, [sortOrder, sortedColumn]);

    const handlePageChange = pageNumber => {
        getAuditCronJobDeatils(pageNumber, pageInfo?.per_page,); //perpage data
    };

    const handleSearchChange = e => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm);
    };

    const handleClearSearch = () => {
        setSearchQuery("");
        getAuditCronJobDeatils(defaultPageNumber, pageInfo?.per_page, "");
    };
    useEffect(() => {
        const delayTime = 1000;
        if (searchQuery) {
            const delayDebounceFn = setTimeout(() => {
                // Make API request with the current value
                getAuditCronJobDeatils(defaultPageNumber, pageInfo?.per_page, searchQuery);
            }, delayTime);
            return () => {
                clearTimeout(delayDebounceFn);
            };
        }
    }, [searchQuery]);


    const onClickEdit = () =>{
        navigate(`/audit/cron-jobs/edit`, {
            state: cronJobData
        });
    }

    useEffect(() => {
        if (downloadData?.getCronJobAuditLogDownloadReport?.length > 0) {
            let stringToDownload = "Actor Email, Status, Records, Created At, Updated At \n";
            downloadData?.getCronJobAuditLogDownloadReport?.forEach(data => {
                stringToDownload += `${data.actorEmail},${data.status},${data.rowsAffected},${formatTimestamp(data.timestamp)},${formatTimestamp(data.endTimestamp)}\n`;
            });
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:attachment/text,' + encodeURI(stringToDownload);
            hiddenElement.target = '_blank';
            const date = new Date();
            hiddenElement.download = `audit_logs_report ${date.toString()}.csv`;
            hiddenElement.click();
            setDownloadedLoader(downloadLoder)
        }
    }, [downloadData])

    const handleDownloadUserReport = () =>{
        setDownloadedLoader(true)
        getDownloaData({ variables: { cronJob : cronJobData?.cronjobname}}) 
    }

    return (
        <div className="mt-16 container justify-center mx-auto px-2 sm:px-20 md:px-32 lg:px-40 xl:40">
            <div>
                <div className="py-6">
                    <h2 className="flex flex-row items-center text-2xl font-bold dark:text-gray-100 mb-4">Cron Job Details</h2>
                    <hr />
                </div>
            </div>
            {cronJobData ? <div>
                <div>
                    <div className="pb-6">
                        <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                            <div className="col-span-4 mt-10">
                                <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">General Information</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Cron job details and Status.</p>
                            </div>
                            <div className="col-span-8">
                                <div className="flex gap-2 mb-3 justify-end">
                                    <div>
                                        {loading ? <Button type="button" className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-gray-500 rounded-lg shrink-0 gap-x-2"
                                            text="Edit" /> : <Button type="button" className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-green-500 rounded-lg shrink-0 gap-x-2 hover:bg-green-600 dark:hover:bg-green-500 dark:bg-green-600"
                                                onClick={() => { onClickEdit()}}
                                                text="Edit" />}
                                    </div>
                                    <div>
                                        {cronJobData?.lastrunstatus ? <Button type="button" className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-gray-500 rounded-lg shrink-0 gap-x-2"
                                            text="Re Run" /> : <Button type="button" className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-green-500 rounded-lg shrink-0 gap-x-2 hover:bg-green-600 dark:hover:bg-green-500 dark:bg-green-600"
                                                text="Re Run" />}
                                    </div>
                                </div>
                                <dl className="md:px-8 divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                                    <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Title</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{cronJobData?.cronjobname}</dd>
                                    </div>
                                    <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Description</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{cronJobData?.description}</dd>
                                    </div>
                                    <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Status</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{cronJobData?.lastrunstatus}</dd>
                                    </div>
                                    <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Unique Id</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{cronJobData?.uniqueId}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="py-6">
                    <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                        <div className="col-span-4">
                            <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">Activity Timestamp</h3>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Created, Updated, Last and Next run Timestamp</p>
                        </div>
                        <div className="col-span-8">
                            <dl className="md:px-8 divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                                <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Created By</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{cronJobData?.created_by}</span><span className="text-xs font-semibold">{formatTimestamp(cronJobData?.created_at)}</span></dd>
                                </div>
                                <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Updated By</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{cronJobData?.updated_by}</span><span className="text-xs font-semibold">{formatTimestamp(cronJobData?.updated_at)}</span></dd>
                                </div>
                                <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Last Run</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{cronJobData?.lastrunstatus}</span><span className="text-xs font-semibold">{formatTimestamp(cronJobData?.lastrunat)}</span></dd>
                                </div>
                                <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Next Run</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span className="text-xs font-semibold">{formatTimestamp(cronJobData?.nextrunat)}</span></dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="flex flex-col xl:flex-row gap-4">
                    <PageWrapper className={" mt-16 mb-6 w-full"}>
                        <TableWrapper>
                            <div className="flex-1">
                                <div>
                                    <h2 className="text-2xl font-bold dark:text-gray-100 mb-4">{"Audit Logs"}</h2>
                                </div>
                                <hr />
                               <div className="flex flex-row-reverse justify-between ">
                                    <TableButtonSection
                                        searchQuery={searchQuery}
                                        onSearchChange={handleSearchChange}
                                        onClearSearch={handleClearSearch} />
                                    <Button
                                        type="button"
                                        onClick={handleDownloadUserReport}
                                        icon={faDownload}
                                        loader={downloadedLoader}
                                        text="Download Report"
                                        className="flex items-center justify-center mt-5  text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                                    />
                               </div>
                                <TableContainer>
                                    <thead className="bg-gray-50 dark:bg-neutral-800">
                                        <tr>
                                            <TableHeaderComponent columnKey="actorEmail" label="Actor Email" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                                            <TableHeaderComponent columnKey="status" label="Status" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                                            <TableHeaderComponent columnKey="rowsAffected" label="Records" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                                            <TableHeaderComponent columnKey="timestamp" label="Created At" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                                            <TableHeaderComponent columnKey="endTimestamp" label="Updated At" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                                            {/* <TableHeaderComponent columnKey="action" label="Action" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} /> */}

                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                                        {cronAuditLogs?.map(auditLogData => (
                                            <tr key={auditLogData?.id}>
                                                <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                    <div className="">
                                                        <h2 className="text-gray-700 dark:text-gray-200">{auditLogData.actorEmail}</h2>
                                                    </div>
                                                </td>
                                                <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                    <div>
                                                        <h4 className="text-gray-700 dark:text-gray-200">{auditLogData.status}</h4>
                                                    </div>
                                                </td>
                                                <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                    <div>
                                                        <h4 className="text-gray-700 dark:text-gray-200">{auditLogData.rowsAffected}</h4>
                                                    </div>
                                                </td>
                                                <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                    <div>
                                                        <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(auditLogData.timestamp)}</h4>
                                                    </div>
                                                </td>
                                                <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                    <div>
                                                        <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(auditLogData.endTimestamp)}</h4>
                                                    </div>
                                                </td>
                                                {/* <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                    <div>
                                                        <h4 className="text-gray-700 dark:text-gray-200">{(auditLogData.isActive)}</h4>
                                                    </div>
                                                </td> */}
                                            </tr>
                                        ))}
                                        {!cronAuditLogs?.length && !loading && <TableNoRecordFound colSpan={9}></TableNoRecordFound>}
                                        {loading && <TableLoadingData colSpan={9}></TableLoadingData>}
                                    </tbody>
                                </TableContainer>
                            </div>
                            <hr />
                        </TableWrapper>
                        <Pagination totalPages={pageInfo?.total_pages || 0}
                            totalRecords={pageInfo?.total_records || 0}
                            recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0}
                            maxPageNumbers={maxPageNumber} currentPage={pageInfo?.current_page || 0}
                            onPageChange={handlePageChange} onRowChanges={handleRowChanges}></Pagination>

                    </PageWrapper>
                </div>
            </div>
                :
                <div className="flex justify-center items-center h-screen">
                    <td colSpan={5} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
                        <div>
                            <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                            <span>Loading Data</span>
                        </div>
                    </td>
                </div>
            }
        </div>
    );
};

export default AuditCronJobDetails;
