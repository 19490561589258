import React from 'react';
import PropTypes from 'prop-types';

const TableWrapper = ({ children }) => {
    return (
        <div>
            {children}
        </div>
    );
};
TableWrapper.propTypes = {
    children: PropTypes.any,
}

export default TableWrapper;
