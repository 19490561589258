import React from 'react';
import PropTypes from 'prop-types';

const TableNoRecordFound = ({ colSpan }) => {
    return (
        <tr>
            <td colSpan={colSpan} className="p-4 bg-white dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">No records found</td>
        </tr>
    );
};
TableNoRecordFound.propTypes = {
    colSpan: PropTypes.any,
}
export default TableNoRecordFound;
