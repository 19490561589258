// apolloClient.js

import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQLHOST, // Replace this with your actual GraphQL API endpoint
});



const authMiddleware = new ApolloLink(async (operation, forward) => {
  const storedUser = await JSON.parse(localStorage.getItem('user'));
  const accessToken = storedUser ? storedUser.access_token : null;


  // Set the authorization header
  operation.setContext({
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  });

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log("message", message, locations, path) 
      if (message === "token expired"){
      localStorage.removeItem('uniqueId');
      localStorage.removeItem('user'); 
      window.location.href = "/login";
      }
    }); {/* NOSONAR */ }
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`); {/* NOSONAR */ }
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([authMiddleware, errorLink, httpLink]), // Combine the errorLink and customLink
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache', // Set the default fetchPolicy to "no-cache"
    },
    query: {
      fetchPolicy: 'no-cache', // Set the default fetchPolicy to "no-cache"
    },
  },
});

export default client;
