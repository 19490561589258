import React from 'react';
import PropTypes from 'prop-types';

const PageWrapper = ({ children, heading, style }) => {

    return (
        <div className={style || "mt-16 w-full max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mb-6"}>
            {heading && (
                <div className="cmp-page-heading">
                    <div className="py-6">
                        <h2 className="text-2xl font-bold dark:text-gray-100 mb-4">{heading}</h2>
                        <hr />
                    </div>
                </div>
            )}
            {/* Additional wrapper content */}
            <div className="cmp-page-content">
                {children}
            </div>
        </div>
    );
};
PageWrapper.propTypes = {
    children: PropTypes.any,
    heading: PropTypes.any,
    style: PropTypes.any,
}

export default PageWrapper;
