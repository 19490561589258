import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownShortWide, faArrowUpWideShort, faPlus, faTrashCan, faDownload, faCloudArrowUp, faChevronDown, faUser } from '@fortawesome/free-solid-svg-icons'
import PageWrapper from "../../../layouts/PageWrapper";
import Pagination from "../../../components/table/Pagination";
import TableWrapper from "../../../components/table/TableWrapper";
import TableContainer from "../../../components/table/TableContainer";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound";
import TableLoadingData from "../../../components/table/TableLoadingData";
import TableHeadingSection from "../../../components/table/TableHeadingSection";
import TableButtonSection from "../../../components/table/TableButtonSection";
import { useGetAllUserInfoListQuery, useDeleteUserInfosByIdMutation, useGetCurrentUserPermissionsQuery, useGetUserInfoPagesMutationMutation } from "../../../graphql/generated/graphql.ts";
import Button from '../../../components/button/Button';
import DialogModal from "../../../components/modal/DialogModal";
import { toast } from "react-toastify";
import { formatTimestamp } from '../../../utils/commonFormatFunctions.js'
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import ActionButton from "../../../components/button/ActionButton.js";



const Index = () => {
    const navigate = useNavigate();
    const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
    const [deleteMutation, { loading: deleteLoader }] = useDeleteUserInfosByIdMutation();
    const [userInfoMutation, { data: userData, error: errorData, loading }] = useGetUserInfoPagesMutationMutation();
    const { pageInfo } = userData?.getUserInfoPagesMutation || {};
    const [searchQuery, setSearchQuery] = useState('');
    const [sortedColumn, setSortedColumn] = useState('givenName');
    const [sortOrder, setSortOrder] = useState('ASC');
    const [isOpen, setIsOpen] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [deletedItem, setDeletedItem] = useState([]);
    const [showBulkDrop, setShowBulkDrop] = useState(false);
    const [userDataArray, setUserDataArray] = useState([]);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [recordsPerPageData, setRecordsPerPageData] = useState(pageInfo?.per_page);
    const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
    const permissionAPIList = permissionData?.getAllAPIPermissions
    const createUserInfo = permissionAPIList?.filter(item => item.accessType === "CreateUserInfo")
    const deleteUserInfo = permissionAPIList?.filter(item => item.accessType === "DeleteUserInfo")
    const updateUserInfo = permissionAPIList?.filter(item => item.accessType === "UpdateUserInfo")
    const editUserPermission = updateUserInfo?.length && updateUserInfo[0].assignedPermissions.some(val => addPermission.includes(val));
    const addUserPermission = createUserInfo?.length && createUserInfo[0].assignedPermissions.some(val => addPermission.includes(val));
    const deleteUserPermission = deleteUserInfo?.length && deleteUserInfo[0].assignedPermissions.some(val => addPermission.includes(val));
    
    const maxPageNumber = 5;
    const defaultPageNumber = 1;

    const bulkDropRef = useRef(null);

    const handleClickOutside = event => {
        if (bulkDropRef.current && !bulkDropRef.current.contains(event.target)) {
            setShowBulkDrop(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const { data } = useGetAllUserInfoListQuery({
        fetchPolicy: "network-only",
        skip: !downloadLoader,
        onCompleted: Userdata => {
            setUserDataArray(Userdata);
        },
    })

    function handleNavigationError() {
        if (errorData) {
            navigate('/error', { state: { errorData: JSON.stringify(errorData) } });
        }
    }
    // Call the function to handle navigation error
    handleNavigationError();

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Users`;
        document.title = pageTitle;
    }, []);

    useEffect(() => {
        if( sortOrder && sortedColumn) {
            getUserData(defaultPageNumber, userData?.getUserInfoPagesMutation?.pageInfo?.per_page);
        }
    }, [sortOrder, sortedColumn]);

    const getUserData = (page = defaultPageNumber, row = userData?.getUserInfoPagesMutation?.pageInfo?.per_page, search_term = searchQuery) => {
        setDeletedItem([])
        setSelectedItems([]); setSelectAll(false)
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term,
            search_column: "",
            order_by: sortOrder,
            order_by_column: sortedColumn,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        };

        userInfoMutation({ variables: { PageInfoInput } })
            .catch(error => {
                toast.error(error.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    };

    const handleSearchChange = e => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm);
    };

    useEffect(() => {
        const delayTime = 1000;
       if(searchQuery){
           const delayDebounceFn = setTimeout(() => {
               // Make API request with the current value
               getUserData(defaultPageNumber, pageInfo?.per_page, searchQuery);
           }, delayTime);
           return () => {
               clearTimeout(delayDebounceFn);
           };
       }
    }, [searchQuery]);

    const handleClearSearch = () => {
        setSearchQuery("");
        getUserData(defaultPageNumber, userData?.getUserInfoPagesMutation?.pageInfo?.per_page, "");
    };

    const handleSort = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrder);
        setSortedColumn(column);
    };

    const handlePageChange = pageNumber => {
        getUserData(pageNumber, userData?.getUserInfoPagesMutation?.pageInfo?.per_page);
    };

    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getUserData(defaultPageNumber, rowValue);
    };

    const openModal = () => {
        if (selectedItems.length) {
            setIsOpen(true);
        }
    };

    const closeModal = () => {
        setIsOpen(false);
        setDeletedItem([])
        setSelectedItems([])
    };

    const handleCheckboxChange = itemId => {
        setSelectedItems(prevItems => prevItems.includes(itemId) ? prevItems.filter(id => id !== itemId) : [...prevItems, itemId]);
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(userData?.getUserInfoPagesMutation?.userInfoForAdmin?.map(item => item.uniqueId) || []);
        }
        setSelectAll(prev => !prev);
    };

    useEffect(() => {
        if (userDataArray?.getAllUserInfoList?.length > 0) {
            let stringToDownload = "First Name, Last Name, Email Id, Role Names, Group Names, Line of Business, Job Title, Created On, Updated On, Last Active \n";
            userDataArray?.getAllUserInfoList?.forEach(user => {
                const roleNames_initial = user.roleNames.join('; ');
                const groupNames_initial = user.groupNames.join('; ');
                stringToDownload += `${user.givenName},${user.familyName},${user.uniqueName},${roleNames_initial},${groupNames_initial},"${user.lineOfBusiness}","${user.jobTitle}",${formatTimestamp(user.created_at)},${formatTimestamp(user.updated_at)},${formatTimestamp(user.last_active_at)} \n`;
            });
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:attachment/text,' + encodeURI(stringToDownload);
            hiddenElement.target = '_blank';
            const date = new Date();
            hiddenElement.download = `users_report_${date.toString()}.csv`;
            hiddenElement.click();
            setDownloadLoader(!downloadLoader)
        }
    }, [userDataArray])

    const handleDownloadButtonClick = () => {
        setDownloadLoader(true)
    }

    const onClickDelete = selectedItem => {
        deleteMutation({ variables: { uniqueId: selectedItem } })
            .then(() => {
                toast.success("User deleted successfully.");
                getUserData();
                closeModal();
                setSelectedItems([]);
            }).catch(error => {
                toast.error(error.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            })
    }

    const handleDelete = user => {
        const uniqueId = [user?.uniqueId];
        setDeletedItem(uniqueId);
        setIsOpen(true);
    }

    const handleBulkUpload = () => {
        setShowBulkDrop(prev => !prev);
    }

    const tableButtonSectionContent = () => {
        return (
            <TableButtonSection searchQuery={searchQuery} onSearchChange={handleSearchChange} onClearSearch={handleClearSearch}>
                {addUserPermission && <Link to='/manage/users/new' state={{ data }} className={loading ? "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2"
                    : "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-indigo-500 rounded-lg shrink-0 gap-x-2 hover:bg-indigo-600 dark:bg-indigo-600"}>
                    <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faPlus} />
                    <span>Add</span>
                </Link>}
                <div>
                    {!loading && addUserPermission &&
                        <div>
                            <Button
                                type="button"
                                onClick={handleBulkUpload}
                                icon={faCloudArrowUp}
                                secondIcon={faChevronDown}
                                text="Bulk Option"
                                className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                            />
                        </div>
                    }
                    {showBulkDrop &&
                        <div
                            ref={bulkDropRef}
                            className="absolute left-15  z-10 mt-2 w-40 origin-top-right rounded-md bg-white dark:bg-neutral-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                            <Button className="w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-neutral-700 font-semibold" role="menuitem" tabIndex="-1"
                                onClick={() => {
                                    navigate('/manage/users/bulkUpload', { state: { userData: data } });
                                }}
                                text="Create User" />
                            <Button className="w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-neutral-700 font-semibold" role="menuitem" tabIndex="-1"
                                onClick={() => {
                                    navigate('/manage/users/bulkUpdate', { state: { userData: data } });
                                }} text="Sync RBAC" />
                        </div>}
                </div>
                <div>
                    <div>
                        {addUserPermission && <Button
                            type="button"
                            onClick={() => { navigate('/manage/users/impersonate')}}
                            icon={faUser}
                            text="User Impersonate"
                            className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                        />}
                    </div>
                </div>
                <div>
                    <Button
                        type="button"
                        onClick={handleDownloadButtonClick}
                        icon={faDownload}
                        loader={downloadLoader}
                        text="Download Report"
                        className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                    />
                </div>
                {
                    selectedItems?.length > 0 && deleteUserPermission &&
                    <div>
                        <Button
                            type="button"
                            onClick={openModal}
                            className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-red-500 rounded-lg shrink-0 gap-x-2 hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600"
                            icon={faTrashCan}
                            text="Delete"
                        />
                    </div>
                }
            </TableButtonSection>
        )
    }

    return (
        <>
            <PageWrapper heading={'Manage Users'}>
                <TableWrapper>
                    <TableHeadingSection heading={'Users'} description={'All the active users in the database.'} count={pageInfo?.total_records} />
                    {tableButtonSectionContent()}
                    <TableContainer>
                        <thead className="bg-gray-50 dark:bg-neutral-800">
                            <tr>
                                <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold">
                                    <div className="flex h-6 items-center">
                                        <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('givenName')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>First Name</span>
                                        {sortedColumn === "givenName" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>

                                <th scope="col" onClick={() => handleSort('familyName')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Last Name</span>
                                        {sortedColumn === "familyName" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>

                                <th scope="col" onClick={() => handleSort('uniqueName')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Email</span>
                                        {sortedColumn === "uniqueName" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('lineOfBusiness')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Line of Business</span>
                                        {sortedColumn === "lineOfBusiness" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('jobTitle')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Job Title</span>
                                        {sortedColumn === "jobTitle" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>

                                <th scope="col" onClick={() => handleSort('created_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Created On</span>
                                        {sortedColumn === "created_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('updated_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Updated On</span>
                                        {sortedColumn === "updated_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('last_active_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Last Active On</span>
                                        {sortedColumn === "last_active_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>

                                <th scope="col" className="px-4 py-3.5 text-sm text-center rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                            {userData?.getUserInfoPagesMutation?.userInfoForAdmin?.map(user => (
                                <tr key={user?.uniqueId}>
                                    <td className="px-4 py-3 text-sm font-medium">
                                        <div className="flex h-6 items-center">
                                            <input type="checkbox" checked={selectedItems.includes(user.uniqueId)} onChange={() => handleCheckboxChange(user.uniqueId)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                        <div>
                                            <Link to={`/manage/users/${user.uniqueId}`} className="font-semibold text-indigo-600 dark:text-indigo-400 ">{user.givenName}</Link>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="w-40">
                                            <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{user.familyName}</h2>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="">
                                            <p className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{user.uniqueName}</p>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="">
                                            <p className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{user.lineOfBusiness}</p>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="">
                                            <p className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{user.jobTitle}</p>
                                        </div>
                                    </td>

                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(user?.created_at)}</h4>
                                        </div>
                                    </td>

                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(user.updated_at)}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(user.last_active_at)}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="flex item-center justify-center text-gray-700 dark:text-gray-200">
                                            <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" title="View" icon={EyeIcon}
                                                onClick={() => { navigate(`/manage/users/${user?.uniqueId}`) }} />
                                            {editUserPermission ? <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" icon={PencilIcon}
                                                onClick={() => { navigate('/manage/users/edit', { state: { user } }) }} title="Edit" />
                                                : <ActionButton className="w-4 mr-2 transform text-gray-500" icon={PencilIcon} title="Edit" />}
                                            {deleteUserPermission ? <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                icon={TrashIcon} title="Delete"
                                                onClick={() => { handleDelete(user) }} />
                                                : <ActionButton className="w-4 mr-2 transform text-gray-500"
                                                    icon={TrashIcon} title="Delete" />}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {!userData?.getUserInfoPagesMutation?.userInfoForAdmin?.length && !loading && <TableNoRecordFound colSpan={12}></TableNoRecordFound>}
                            {loading && <TableLoadingData colSpan={12}></TableLoadingData>}
                        </tbody>
                    </TableContainer>
                </TableWrapper>
                <Pagination totalPages={pageInfo?.total_pages ? pageInfo?.total_pages : 0} totalRecords={pageInfo?.total_records || 0}
                    maxPageNumbers={maxPageNumber}
                    recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0}
                    currentPage={pageInfo?.current_page ? pageInfo?.current_page : 0} onPageChange={handlePageChange} onRowChanges={handleRowChanges} ></Pagination>
            </PageWrapper>
            <DialogModal
                isOpen={isOpen}
                onClose={closeModal}
                title="Confirm Deletion"
                closeOnClickOutside={false} // Set this prop as needed
                pTagData={`Do you confirm to delete ${deletedItem?.length || selectedItems?.length} users?`}
                btnOne="Confirm Delete"
                btnTwo="Cancel"
                loader={deleteLoader}
                onClickDeleteModal={() => { onClickDelete(deletedItem.length > 0 ? deletedItem : selectedItems) }}>
            </DialogModal>
        </>
    );
};

export default Index;