import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetUserDetailsByIdMutation, useDeleteUserInfosByIdMutation, useGetAllUserInfoListQuery, useGetCurrentUserPermissionsQuery } from "../../../graphql/generated/graphql.ts";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons'
import Button from "../../../components/button/Button.js";
import { toast } from "react-toastify";
import DialogModal from "../../../components/modal/DialogModal.js";
import { formatTimestamp } from "../../../utils/commonFormatFunctions.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'

const UserDetails = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [getUserDetailsByIdMutation, { data, error, loading }] = useGetUserDetailsByIdMutation({
    variables: {
      uniqueId: userId
    },
  });
  const { data: userData } = useGetAllUserInfoListQuery({ variables: {}, });
  const [deleteMutation, { loading: loader }] = useDeleteUserInfosByIdMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [deletedItem, setDeletedItem] = useState([]);
  const user = data?.getUserDetailsById;
  const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
  const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
  const permissionAPIList = permissionData?.getAllAPIPermissions
  const deleteUserInfo = permissionAPIList?.filter(item => item.accessType === "DeleteUserInfo")
  const updateUserInfo = permissionAPIList?.filter(item => item.accessType === "UpdateUserInfo")
  const editUserPermission = updateUserInfo?.length && updateUserInfo[0].assignedPermissions.some(val => addPermission.includes(val));
  const deleteUserPermission = deleteUserInfo?.length && deleteUserInfo[0].assignedPermissions.some(val => addPermission.includes(val));

  useEffect(() => {
    const envVariable = process.env.REACT_APP_ENV;
    const prefix = 'SIERA';
    const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
    const pageTitle = `${prefix}${suffix} | Manage Users | ${user?.givenName} ${user?.familyName}`;
    document.title = pageTitle;
  }, [user]);

  useEffect(() => {
    if(userId){
      getUserDetailsByIdMutation();
    }
  }, [userId]);

  if (error) {
    navigate('/error', { state: { errorData: JSON.stringify(error) } });
  }


  const onClickDelete = selectedItem => {
    deleteMutation({ variables: { uniqueId: selectedItem } })
      .then(() => {
        toast.success("User deleted successfully.");
        closeModal();
        navigate('/manage/users')
      }).catch(er => {
        toast.error(er.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
          autoClose: false,
        });
      })
  }

  const handleDelete = () => {
    const id = [user?.uniqueId]
    setDeletedItem(id)
    setIsOpen(true)
  }
  const closeModal = () => {
    setIsOpen(false)
    setDeletedItem([])
  };

  const handleDeleteModal = () => {
    const itemsToDelete = deletedItem;
    onClickDelete(itemsToDelete);
  };



  return (
    <div className="mt-16 container justify-center mx-auto px-2 sm:px-20 md:px-32 lg:px-40 xl:40">
      <div>
        <div className="py-6">
          <h2 className="flex flex-row items-center text-2xl font-bold dark:text-gray-100 mb-4">User Details</h2>
          <hr />
        </div>
      </div>
      {user ? <div>
        <div>
          <div className="pb-6">
            <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
              <div className="col-span-4 mt-10">
                <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">General Information</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Personal details and application.</p>
              </div>
              <div className="col-span-8">
                <div className="flex gap-2 mb-3 justify-end">
                  {!editUserPermission || loading ? <div>
                    <Button className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2"
                      icon={faEdit}
                      text="Edit"
                      type="button"
                    />
                  </div> : <div>
                    <Link to='/manage/users/edit' state={{ user, data: userData }} className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-indigo-500 rounded-lg shrink-0 gap-x-2 hover:bg-indigo-600 dark:bg-indigo-600">
                      <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faEdit} />
                      <span>Edit</span>
                    </Link>
                  </div>}
                  {!deleteUserPermission ? <div>
                    <Button className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2"
                      icon={"faTrashCan"}
                      text={"Delete"}
                    />
                  </div> : <div>
                    <Button
                      type="button"
                      className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-red-500 rounded-lg shrink-0 gap-x-2 hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600"
                      onClick={handleDelete}
                      icon={"faTrashCan"}
                      text={"Delete"}
                    />
                  </div>}
                </div>
                <dl className="md:px-8 divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                  <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">First name</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{user.givenName}</dd>
                  </div>
                  <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Last name</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{user.familyName}</dd>
                  </div>
                  <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Email address</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{user.uniqueName}</dd>
                  </div>
                  <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Job Title</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{user.jobTitle}</dd>
                  </div>
                  <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Line of Business</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{user.lineOfBusiness}</dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="py-6">
          <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
            <div className="col-span-4">
              <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">Assigned Roles</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Each roles have set of persmissions.</p>
            </div>
            <div className="col-span-8">
              <dl className="divide-y divide-gray-200 dark:divide-gray-800 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                <div className=" py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dd className="mt-2 text-sm text-gray-900 dark:text-gray-100 dark:bg-neutral-800 sm:col-span-12 sm:mt-0">
                    <ul className="divide-y divide-gray-200 dark:divide-gray-700 rounded-md">
                      {user?.roleNames?.map(roleId => (
                        <li key={roleId?.id} className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
                          <div className="flex w-0 flex-1 items-center">
                            <div className="ml-4 flex min-w-0 flex-1 gap-2">
                              <span className="truncate font-medium">{roleId?.roleName}</span>
                            </div>
                            <dl>
                              {roleId?.updated_by ? <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100 ml-4">Updated By</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{(roleId.updated_by)}</span><span className="text-xs font-semibold">{formatTimestamp(roleId.updated_at)}</span></dd>
                              </div> : <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100 ml-6">Created By</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{(roleId.created_by)}</span><span className="text-xs font-semibold">{formatTimestamp(roleId.created_at)}</span></dd>
                              </div>}

                            </dl>
                          </div>
                        </li>))}
                    </ul>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <hr />
        <div className="py-6">
          <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
            <div className="col-span-4">
              <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">Assigned Groups</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Each groups control persmissions documents and videos.</p>
            </div>
            <div className="col-span-8">
              <dl className=" md:px-8 divide-y divide-gray-200 dark:divide-gray-800 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                  <div className=" py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dl className="mt-2 text-sm text-gray-900 dark:text-gray-100 dark:bg-neutral-800 sm:col-span-12 sm:mt-0">
                      <ul className="divide-y divide-gray-200 dark:divide-gray-700 rounded-md">
                        {user?.groupNames?.map(groupId => (
                          <li key={groupId?.id} className="flex items-center justify-between py-2 pl-4 pr-5 text-sm leading-6">
                            <div className="flex w-0 flex-1 items-center">
                              <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                <span className="truncate font-medium">{groupId?.groupName}</span>
                              </div>
                              <dl>
                                {groupId?.updated_by ? <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                  <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100 ml-4">Updated By</dt>
                                  <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{(groupId.updated_by)}</span><span className="text-xs font-semibold">{formatTimestamp(groupId.updated_at)}</span></dd>
                                </div> : <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                  <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100 ml-6">Created By</dt>
                                  <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{(groupId.created_by)}</span><span className="text-xs font-semibold">{formatTimestamp(groupId.created_at)}</span></dd>
                                </div>}
                              </dl>
                            </div>
                          </li>))}
                      </ul>
                    </dl>
                  </div>
                </dl>
            </div>
          </div>
        </div>
        <hr />
        <div className="py-6">
          <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
            <div className="col-span-4">
              <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">Activity Timestamp</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Created and Updated Timestamp</p>
            </div>
            <div className="col-span-8">
              <dl className="md:px-8 divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Last active On</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span className="text-xs font-semibold">{formatTimestamp(user.last_active_at)}</span></dd>
                </div>
                <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Created By</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{(user.created_by)}</span><span className="text-xs font-semibold">{formatTimestamp(user.created_at)}</span></dd>
                </div>
                <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Updated By</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{(user.updated_by)}</span><span className="text-xs font-semibold">{formatTimestamp(user.updated_at)}</span></dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <DialogModal
          isOpen={isOpen}
          onClose={closeModal}
          title="Confirm Deletion"
          closeOnClickOutside={false} // Set this prop as needed
          pTagData={`Do you confirm to delete user?`}
          btnOne="Confirm Delete"
          btnTwo="Cancel"
          loader={loader}
          onClickDeleteModal={handleDeleteModal}>
        </DialogModal>
      </div>
        :
        <div className="flex justify-center items-center h-screen">
          <td colSpan={5} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
            <div>
              <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
              <span>Loading Data</span>
            </div>
          </td>
        </div>
      }
    </div>
  );
};

export default UserDetails;
