import PageWrapper from "../../../layouts/PageWrapper";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useGetHomeScreenForAdminQuery, useGetCurrentUserPermissionsQuery } from '../../../graphql/generated/graphql.ts'
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState, } from 'draft-js';
import '../../../styles/Draft.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/button/Button.js";
import ContentEditor from "../../../components/form/editor/ContentEditor.js";



const ConfigurationMobileHomepage = () => {
  const navigate = useNavigate();
  const [htmlData, setHtmlData] = useState('');
  const [mobileHtmlData, setMobileHtmlData] = useState('');
  const { data, loading, error } = useGetHomeScreenForAdminQuery({ variables: {} });
  const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
  const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
  const permissionAPIList = permissionData?.getAllAPIPermissions
  const updateUserInfo = permissionAPIList?.filter(item => item.accessType === "UpdateSystemConfigs")
  const editUserPermission = updateUserInfo?.length && updateUserInfo[0].assignedPermissions.some(val => addPermission.includes(val));

  if (error) {
    navigate('/error', { state: { errorData: JSON.stringify(error) } });
  }

  useEffect(() => {
    const envVariable = process.env.REACT_APP_ENV;
    const prefix = 'SIERA';
    const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
    const pageTitle = `${prefix}${suffix} | Manage Mobile Home screen`;
    document.title = pageTitle;
  }, [])

  useEffect(() => {
    if (data?.getHomeScreenForAdmin?.adminContent) {
      convertHtmlToDraft(data?.getHomeScreenForAdmin?.adminContent)
      convertMobileHtmlToDraft(data?.getHomeScreenForAdmin?.mobileContent || "");
    }
  }, [data])

  function convertHtmlToDraft(html) {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorData = EditorState.createWithContent(contentState);
    setHtmlData(editorData)
  }

  function convertMobileHtmlToDraft(Mobilehtml) {
    const blocksFromMobileHtml = htmlToDraft(Mobilehtml);
    const { contentBlocks, entityMap } = blocksFromMobileHtml;
    const MobilecontentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const MobileeditorData = EditorState.createWithContent(MobilecontentState);
    setMobileHtmlData(MobileeditorData)
  }

  return (
    <PageWrapper heading={'Configure Mobile Homepage'}>
      {loading ? <div className="flex justify-center items-center h-screen">
        <td colSpan={3} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
          <div>
            <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
            <span>Loading Data</span>
          </div>
        </td>
      </div> :
        <div className="py-6">
          <div className="flex flex-col">
            <div className="relative">
              <div className="absolute top-0 right-0 mt-4 mr-4">
                {!editUserPermission ? <Button text="Edit"
                  icon={faEdit}
                  type="button"
                  className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2"
                /> : <Link
                  to='/configuration/mobile-homepage/edit'
                  className={loading ? "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2" : "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-indigo-500 rounded-lg shrink-0 gap-x-2 hover:bg-indigo-600 dark:bg-indigo-600"}>
                  <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faEdit} />
                  <span>Edit</span>
                </Link>}
              </div>
            </div>
            <div className="mb-4">
              <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200"> Content Label One
              </h2>
              <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">The content updated here will be rendered in the mobile homepage.</p>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex-grow bg-white dark:bg-neutral-700 shadow py-8 px-4 sm:px-8">
                <div className="w-full text-gray-900 dark:text-gray-200 justify-center items-center">
                  <ContentEditor editorState={htmlData} value={htmlData} name={'content'} toolbarHidden={true} />
                </div>
              </div>
            </div>
            <hr />
            {mobileHtmlData && <div>
              <div className="mb-4 mt-5">
                <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200"> Content Label Two
                </h2>
                <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">The content updated here will be rendered in the mobile homepage.</p>
              </div>
              <div className="flex flex-col items-center">
                <div className="flex-grow bg-white dark:bg-neutral-700 shadow py-8 px-4 sm:px-8">
                  <div className="w-full text-gray-900 dark:text-gray-200 justify-center items-center">
                    <ContentEditor editorState={mobileHtmlData} value={mobileHtmlData} name={'content'} toolbarHidden={true} />
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      }
    </PageWrapper>
  );
};

export default ConfigurationMobileHomepage;