import React, { useEffect, useState } from 'react';
import * as Auth from './AuthClient'; // NOSONAR
import UseContext from './UseContext';
import PropTypes from 'prop-types';
import qs from 'qs';
import { toast } from 'react-toastify';
import axios from 'axios';

const AuthenticationProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkAuth = async storedUser => {
    let newUser = null;
    let newIsAuthenticated = false;
    if (storedUser === null || storedUser === undefined) {
      localStorage.removeItem('user');
    }
    else if (storedUser?.refresh_token) {
      try {
        await Auth.requestRefreshTokenSimmas(storedUser.refresh_token);
        const newstoredUser = JSON.parse(localStorage.getItem('user'));
        newUser = { ...newstoredUser };
        newIsAuthenticated = true;
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 401) {
          alert('Token refresh failed. Please log in again.');
          localStorage.removeItem('user');
          window.location.href = "/login";
        }
      }
    } else {
      newUser = { ...storedUser };
      newIsAuthenticated = true;
    }
    setUser(newUser);
    setIsAuthenticated(newIsAuthenticated);
  };

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    checkAuth(storedUser);
  }, []);

  const logout = async () => {
    const data = qs.stringify({
      client_id: process.env.REACT_APP_CLIENTID,
      token: user.refresh_token,
    });
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_REVOKE}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data,
    };

    try {
      await axios(config);
    } catch (error) {
      toast.error(error.message, { autoClose: false });
    } finally {
      setIsAuthenticated(false);
      setUser(null);
      localStorage.removeItem('uniqueId');
      localStorage.removeItem('user');
      window.location.href = "/login";
    }
  };

  const setAuthData = (isAuth, userData) => {
    setIsAuthenticated(isAuth);
    setUser(userData);
    if (isAuth) {
      localStorage.setItem('user', JSON.stringify(userData));
    } else {
      localStorage.removeItem('user');
    }
  };

  return (
    <UseContext.Provider value={{ isAuthenticated, user, setAuthData, logout, checkAuth }}>
      {children}
    </UseContext.Provider>
  );
};

AuthenticationProvider.propTypes = {
  children: PropTypes.any,
};

export default AuthenticationProvider;
