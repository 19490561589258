import { gql } from '@apollo/client';

export const uploadDocumentsMute = `mutation ($req: [UploadFileWithMeta!]!) {
  multipleUploadWithMeta(req: $req) {
    fileSize,
    isSupport,
    isVideo,
    name,
    fileUrl,
    description,
    fileAction,
    notifyUser
    downloadURL
     }
}`;

export const ReplaceFile = `mutation ($req: ReplaceFile!) {
    replaceSingleFile(req: $req) {
      fileSize,
      isSupport,
      isVideo,
      name,
      fileUrl,
      description,
      fileAction,
      notifyUser,
      downloadURL
    }
  }`

export const getAllAuditLogs = gql`
query {
  getAllAuditLogs {
    action
    actorEmail
    recordPrimaryKey
    tableName
    timestamp
    __typename
  }
}`;