import Select from "react-tailwindcss-select";
import React from 'react';
import PropTypes from 'prop-types';

const SelectMultipleInput = ({ label, name, options, value, error, required, onChange, onBlur, disabled }) => {
  // Transform options to have 'value' and 'label' properties
  return (
    <>
      {label &&
        <label htmlFor={name} className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">{label}
          {required && <span className="text-red-600 dark:text-red-400 ml-1">*</span>}
        </label>
      }
      <div className="relative mt-2">
        <Select
          name={name}
          id={name}
          classNames={{
            menuButton: ({ isDisabled }) => (
              `flex text-sm text-gray-900 dark:text-gray-200 border border-gray-300 dark:border-neutral-600 rounded-md shadow-sm transition-all duration-300 focus:outline-none ${isDisabled
                ? "bg-gray-200"
                : "bg-white dark:bg-neutral-800 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 focus:outline-none"
              }`
            ),
            menu: "absolute z-50 w-full bg-white dark:bg-neutral-800 shadow-lg border border-gray-200 dark:border-gray-600 rounded py-1 mt-1.5 text-sm text-gray-700 h-96 overflow-y-auto", // Adjust max height and overflow
            listItem: ({ isSelected }) => (
              `block transition duration-200 px-3 py-2 cursor-pointer select-none truncate rounded font-medium${isSelected
                ? `text-white bg-blue-500`
                : `text-gray-500 dark:text-gray-200 hover:bg-indigo-100 hover:text-indigo-500 dark:hover:text-gray-900`
              }`
            ),
            tagItemText: 'text-gray-900 dark:text-gray-200 font-medium',
            tagItem: () => (`bg-gray-100 dark:bg-neutral-800 border border-gray-400 dark:border-gray-600 rounded-sm flex space-x-1 pl-2 mr-2`)
          }}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          options={options}
          isMultiple={true}
          isSearchable={true}
          isDisabled={disabled}
          loading={false}
        />
        {error && <span className="text-sm text-red-600 dark:text-red-400  font-semibold">{error}</span>}
      </div>
    </>
  );
};
SelectMultipleInput.propTypes = {
  label: PropTypes.any,
  name: PropTypes.any,
  options: PropTypes.any,
  onChange: PropTypes.any,
  value: PropTypes.any,
  error: PropTypes.any,
  required: PropTypes.any,
  onBlur: PropTypes.any,
  disabled: PropTypes.bool
}

export default SelectMultipleInput;
