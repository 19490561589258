import React, { useEffect, useState, useContext } from 'react';
import { Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom';
import UseContext from "./UseContext";

const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(
    () => JSON.parse(localStorage.getItem('user')) || null
  );
  const [isAuthenticated, setIsAuthenticated] = useState(user ? true : false);
  const { checkAuth } = useContext(UseContext);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    checkAuth(storedUser)
    if (!storedUser) {
      sessionStorage.setItem("redirectPath", location.pathname)
      navigate('/login');
      setIsAuthenticated(false);
      setUser(storedUser)
    }
    if (!storedUser?.refresh_token) {
      localStorage.removeItem('user');
      navigate('/login');
    }
  },[]);


  return (
    isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />

  );
};

export default ProtectedRoutes;
