import { Switch } from '@headlessui/react'
import React from 'react';
import PropTypes from 'prop-types';

const SwitchInput = ({ label, checked, error, required, onChange }) => {
    return (
        <>
            <div className='flex gap-x-4 '>
                <div className="flex h-6 items-center">
                    <Switch
                        checked={checked}
                        onChange={onChange}
                        className={`${checked ? 'bg-indigo-600' : 'bg-gray-200 dark:bg-neutral-600'
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                    >
                        <span className="sr-only">Enable notifications</span>
                        <span
                            className={`${checked ? 'translate-x-6' : 'translate-x-1'
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                    </Switch>
                </div>
                {label &&
                    <label className="text-sm leading-6 text-gray-600 dark:text-gray-200" id="switch-1-label">{label}
                        {required && <span className="text-red-600 dark:text-red-400 ml-1">*</span>}
                    </label>
                }
            </div>
            <div className="mt-1">
                {error && <span className="text-sm text-red-600 dark:text-red-400  font-semibold">{error}</span>}
            </div>
        </>
    );
};
SwitchInput.propTypes = {
    label: PropTypes.any,
    checked: PropTypes.any,
    onChange: PropTypes.any,
    error: PropTypes.any,
    required: PropTypes.any,
}

export default SwitchInput;
