import React from 'react';
import PropTypes from 'prop-types';

const TextAreaInput = ({ label, name, rowSpan, placeholder = '', value, error, required, onChange, onBlur, disabled }) => {
  return (
    <>
      {label &&
        <label htmlFor={name} className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">{label}
          {required && <span className="text-red-600 dark:text-red-400 ml-1">*</span>}
        </label>
      }
      <div className="mt-2.5">
        <textarea name={name} id={name} value={value} onChange={onChange} onBlur={onBlur} title={label} placeholder={placeholder} rows={rowSpan}
          className={disabled ? "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-200 bg-gray-200 dark:bg-neutral-700 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 focus:outline-none" : "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-200 dark:bg-neutral-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 focus:outline-none"}>
          </textarea>
        {error && <span className="text-sm text-red-600 dark:text-red-400  font-semibold">{error}</span>}
      </div>
    </>
  );
};
TextAreaInput.propTypes = {
  label: PropTypes.any,
  name: PropTypes.any,
  onChange: PropTypes.any,
  error: PropTypes.any,
  required: PropTypes.any,
  rowSpan: PropTypes.any,
  placeholder: PropTypes.any,
  value: PropTypes.any,
  onBlur: PropTypes.any,
  disabled: PropTypes.any,
}

export default TextAreaInput;
