import JWTDecode from "jwt-decode";
import qs from "qs";
import { toast } from "react-toastify";
import axios from "axios";

class AuthClient {
  constructor() {
    this.token = null;
  }

  isLoggedIn() {
    return !!this.token;
  }

  getAccessToken() {
    if (!this.isLoggedIn()) {
      return null;
    }

    return this.token?.access_token;
  }

  async requestAuthorisationURLSimmas() {
    const scope = "edit";
      const state = "p9B3vTYDU9";
      const responseType = "code";
    const URL = `${process.env.REACT_APP_HOST}${process.env.REACT_APP_AUTHORISE}?client_id=${process.env.REACT_APP_CLIENTID}&response_type=${responseType}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&code_challenge=${process.env.REACT_APP_CODE_CHALLENGE}&code_challenge_method=S256&scope=${scope}&state=${state}`; //NOSONAR

    return URL;
  }

  async requestAccessTokenSimmas(code) {
    const timer = 1000;
    const data = qs.stringify({
        grant_type: "authorization_code",
        client_id: process.env.REACT_APP_CLIENTID,
        code_verifier: process.env.REACT_APP_CODE_VERIFIER,
        code,
        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    });

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_TOKEN}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data,
    };

    const payload = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        toast.error(error, {
          autoClose: false,
        });
      });

    this.token = payload;
    payload['expiry_time'] = new Date(new Date().getTime() + payload?.expires_in * timer);
    payload["id_claims"] = this.getIdClaim();
    localStorage.setItem("user", JSON.stringify(payload));
  }

  async requestRefreshTokenSimmas(token) {
    const constantValue = 1000;
    const data = qs.stringify({
      grant_type: "refresh_token",
      refresh_token: token,
    });

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_HOST}${process.env.REACT_APP_TOKEN}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data,
    };

    const payload = await axios(config)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        toast.error(error, {
          autoClose: false,
        });
        localStorage.removeItem('user');
        return error;
      });
    if (payload?.response?.data?.error) {
      return payload?.response?.data?.error;
    } else {
      this.token = payload;
      payload['expiry_time'] = new Date(new Date().getTime() + payload?.expires_in * constantValue);
      payload["id_claims"] = this.getIdClaim();
      localStorage.setItem("user", JSON.stringify(payload));
      localStorage.setItem("darkMode", false);
      return false;
    }
  }

  getIdClaim() {
    const decodedJWT = this.decodeJWT();
    if (!decodedJWT) {
      return null;
    }
    return decodedJWT;
  }

  getUserID() {
    const decodedJWT = this.decodeJWT();
    if (!decodedJWT) {
      return null;
    }
    return decodedJWT.uid;
  }

  getUserEmail() {
    const decodedJWT = this.decodeJWT();
    if (!decodedJWT) {
      return null;
    }
    return decodedJWT.mail;
  }

  getUserName() {
    const decodedJWT = this.decodeJWT();
    if (!decodedJWT) {
      return null;
    }
    return `${decodedJWT.givenName} ${decodedJWT.sn}`;
  }

  getTokenExp() {
    const decodedJWT = this.decodeJWT();
    if (!decodedJWT) {
      return null;
    }
    return decodedJWT.exp;
  }

  decodeJWT() {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return null;
    }
    return JWTDecode(accessToken);
  }

  getToken() {
    return {
      access_token: this.token.access_token,
      expires_in: this.token.expires_in,
      refresh_token: this.token.refresh_token,
      token_type: this.token.token_type,
    };
  }

  removeToken() {
    this.token = null;
  }
}

const client = new AuthClient();

export const getAccessToken = () => client.getAccessToken();
export const isLoggedIn = () => client.isLoggedIn();
export const requestAccessTokenSimmas = code => client.requestAccessTokenSimmas(code);
export const requestRefreshTokenSimmas = token => client.requestRefreshTokenSimmas(token);
export const getUserID = () => client.getUserID();
export const requestAuthorisationURLSimmas = async () => client.requestAuthorisationURLSimmas();
export const getToken = () => client.getToken();
export const getuserEmail = () => client.getUserEmail();
export const getUserName = () => client.getUserName();
export const getTokenExp = () => client.getTokenExp();
export const removeToken = () => client.removeToken();
export const getIdClaim = () => client.getIdClaim();