import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useGetFileUploadSettingsQuery, useGetDocsMetadataForAdminQuery } from '../../../graphql/generated/graphql.ts'
import CheckboxButton from "../../../components/form/input/CheckboxButton.js";
import SelectSingleInput from "../../../components/form/input/SelectSingleInput";
import FileInputComponent from "../../../components/form/input/FileInputComponent";
import Button from "../../../components/button/Button";
import PageWrapper from "../../../layouts/PageWrapper";
import * as Auth from "../../../utils/AuthClient"; {/* NOSONAR */ }
import { uploadDocumentsMute } from '../../../graphql/graphql.queries';
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js';
import { formCreateNewDocumentSchema } from "../../../validations/FormValidationSchema";

const AddNewDocuments = () => {
  const navigate = useNavigate();
  const { folderId } = useParams();
  const { data: metaDataQuery, loading: loader } = useGetDocsMetadataForAdminQuery();
  const { getAllDocumentsFolders, getAllSenestivityLabel } = metaDataQuery || {};

  const formatFolderOptions = (folders = []) =>
    folders
      .filter(item => item.id !== process.env.REACT_APP_VIDEOSECTIONID)
      .map(option => ({
        value: option.id,
        label: option.display_name,
      }));

  // Helper function to format sensitivity label options
  const formatSensitivityOptions = (sensitivityLabels = []) =>
    sensitivityLabels.map(sensitivityOption => ({
      value: sensitivityOption?.key,
      label: capitalizeFirstLetter(sensitivityOption?.value),
    }));

  // Utility function to capitalize the first letter
  const capitalizeFirstLetter = (str = '') =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const folderSelectOptions = formatFolderOptions(getAllDocumentsFolders);
  const docSensitivityOptions = formatSensitivityOptions(getAllSenestivityLabel);

  const { data: maxUploadSizeData } = useGetFileUploadSettingsQuery();
  const { KB } = maxUploadSizeData?.getFileUploadSettings?.maxUploadSize || {};

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  // Helper function to get the selected folder option
  const getSelectedFolderOption = (idFolder, folderSelectOpt) =>
    idFolder ? folderSelectOpt.find(option => option.value === idFolder) : null;

  // Initial values setup
  const getInitialFormValues = (selectedFolderId, folderSelectData) => ({
    document_name: "",
    folder: getSelectedFolderOption(selectedFolderId, folderSelectData),
    sensitivityLabel: null,
    uploaded_doc: [],
    is_support: false,
    is_notify: false
  });

  // Use the helper function to set initial values in useState
  const [initialValues, setInitialValues] = useState(() =>
    getInitialFormValues(folderId, folderSelectOptions)
  );



  const { values, errors, touched, setFieldValue, setFieldTouched, handleSubmit } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formCreateNewDocumentSchema,
    onSubmit: async value => {
      try {
        const reqFile = [];
        const reqList = [];

        value?.uploaded_doc.forEach((item, i) => {
          const finalObj = {
            fileName: item.name,
            description: "This is a test file!!",
            roleIds: [1],
            isSupport: value?.is_support || false,
            isVideo: false,
            folderId: value?.folder.value,
            file: item,
            notifyUser: value?.is_notify,
            sensitivityLabel: value?.sensitivityLabel?.label || "internal",
            autoDownload: false
          };

          reqFile.push([`variables.req.${i}.file`]);
          reqList.push(finalObj);
        });

        const variables = { "req": [...reqList] };

        const data = new FormData();
        data.append("operations", JSON.stringify({ query: uploadDocumentsMute, variables }));
        data.append('map', JSON.stringify({ ...reqFile }));

        value?.uploaded_doc.forEach((item, i) => {
          data.append(i, item);
        });    

        const tokenData = JSON.parse(localStorage.getItem('user'));
        const token = tokenData.access_token;

        const tokenExpiration = tokenData.expiry_time;
        const dateNow = new Date();
        setLoading(true);

        const dateSecond = 1000;
        if (tokenExpiration < dateNow.getTime() / dateSecond) {
          await Auth.requestRefreshTokenSimmas(tokenData.refresh_token);
        } else {
          const response = await axios({
            method: "post",
            url: process.env.REACT_APP_GRAPHQLHOSTUPLOAD,
            data,
            headers: { 
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`
            },
          });

          const responseData = response?.data?.errors;
          if (!responseData) {
            toast.success(`${value?.uploaded_doc.length} - Document uploaded successfully.`);
            navigate('/manage/documents');
          } else {
            setLoading(false);
            toast.error(responseData[0]?.message, {
              autoClose: false,
            });
          }
        }

      } catch (err) {
        setLoading(false);
        toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
          autoClose: false,
        });
      }
    }
  });

  const handleFileChange = newFiles => {
    setFieldValue('uploaded_doc', [...values.uploaded_doc, ...newFiles])
    setSelectedFiles([...selectedFiles, ...newFiles]);
  };

  const handleRemoveFile = file => {
    setSelectedFiles(selectedFiles.filter(selectedFile => selectedFile !== file));
    setFieldValue('uploaded_doc', selectedFiles.filter(selectedFile => selectedFile !== file))
  };

  useEffect(() => {
    setInitialValues({
      document_name: "",
      folder: folderId ? folderSelectOptions.find(option => option.value === folderId) : null,
      sensitivityLabel: null,
      uploaded_doc: [],
      is_support: false,
      is_notify: false
    })
  }, [metaDataQuery])

  useEffect(() => {
    const envVariable = process.env.REACT_APP_ENV;
    const prefix = 'SIERA';
    const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
    const pageTitle = `${prefix}${suffix} | Manage Documents | Create`;
    document.title = pageTitle;
  }, [])

  return (
    <PageWrapper heading={'Create new document'}>
      {loader ? <div className="flex justify-center items-center h-screen">
        <td colSpan={5} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
          <div>
            <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
            <span>Loading Data</span>
          </div>
        </td>
      </div> :
        <div className="py-6">
          <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
            <div className="col-span-4">
              <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">General Information
              </h2>
              <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Fill in the document and folder details</p>
            </div>
            <form method="POST" onSubmit={handleSubmit} className="col-span-8">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                {folderId ?
                  <div className="sm:col-span-2">
                    <SelectSingleInput name={'folder'} value={values.folder} label={'Select folder'} options={folderSelectOptions}
                      onChange={selectedOption => setFieldValue('folder', selectedOption)} disabled
                    />
                  </div> : <div className="sm:col-span-2">
                    <SelectSingleInput name={'folder'} value={values.folder} options={folderSelectOptions} label={'Select folder'}
                      onChange={selectedOption => setFieldValue('folder', selectedOption)}
                      onBlur={() => setFieldTouched('folder', true)} disabled={loading}
                      required error={(errors.folder && touched.folder) ? errors.folder : ''} />
                  </div>}
                <div className="sm:col-span-2">
                  <SelectSingleInput name={'sensitivityLabel'} value={values.sensitivityLabel} options={docSensitivityOptions} label={'Sensitivity'}
                    onChange={selectedOption => setFieldValue('sensitivityLabel', selectedOption)} disabled={loading}
                    onBlur={() => setFieldTouched('sensitivityLabel', true)}
                    required error={(errors.sensitivityLabel && touched.sensitivityLabel) ? errors.sensitivityLabel : ''} />
                </div>
                <div className="sm:col-span-2">
                  <div className="mt-2.5">
                    <fieldset>
                      <div className="space-y-4">
                        <div className="relative flex gap-x-3">
                          <CheckboxButton
                            name="is_support"
                            label="Mark as Support Document"
                            value={values?.is_support}
                            checked={values?.is_support}
                            onChange={e => setFieldValue("is_support", e.target.checked)} 
                            disabled={loading}
                            description="The support documents will be visible in the support page of the mobile application."
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2 mt-5">
                        <CheckboxButton
                          name="is_notify"
                          label="Notify to users"
                          value={values?.is_notify}
                          checked={values?.is_notify}
                          onChange={e => setFieldValue("is_notify", e.target.checked)}
                          description="This will enable in-app notification to end users."
                          disabled={loading}
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <FileInputComponent
                    label={'Upload documents'}
                    name={'uploaded_doc'}
                    required={true}
                    multiple={true}
                    maxSize={KB}
                    selectedFiles={selectedFiles}
                    acceptedData=".txt, .doc, .docx, .pdf, .ppt, .pptx, .csv, .xls, .xlsx, .odt, .odg, .ods, .odp, image"
                    handleFileChange={handleFileChange}
                    handleRemoveFile={handleRemoveFile}
                    disabled={false}
                    error={(errors.uploaded_doc && touched.uploaded_doc) ? errors.uploaded_doc : ''}
                  />
                </div>
              </div>
              <div className="mt-10 flex justify-start">
                {loading ? <Button type="button"
                  className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  text="Create" />
                  : <Button type="submit"
                    className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    text="Create" />
                }
                <Link to="/manage/documents"
                  className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                {loading && <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
              </div>
            </form>
          </div>
        </div>}
    </PageWrapper>
  );
};

export default AddNewDocuments;