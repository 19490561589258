import React from 'react';
import PropTypes from 'prop-types';
const ActionButton = ({ onClick, title, icon: Icon, className, }) => {
    return (
        <button className={`w-4 mr-2 transform ${className}`} title={title} onClick={onClick}>
            {Icon && <Icon className="stroke-2" />}
        </button>

    );
};

ActionButton.propTypes = {
    onClick: PropTypes.any,
    icon: PropTypes.any,
    className: PropTypes.any,
    title: PropTypes.any,
}

export default ActionButton;
