import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import PageWrapper from "../../../layouts/PageWrapper";
import Pagination from "../../../components/table/Pagination";
import TableWrapper from "../../../components/table/TableWrapper";
import TableContainer from "../../../components/table/TableContainer";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound";
import TableLoadingData from "../../../components/table/TableLoadingData";
import TableHeadingSection from "../../../components/table/TableHeadingSection";
import TableButtonSection from "../../../components/table/TableButtonSection";
import { useDeleteRolePermissionsByRoleIdMutation, useGetAllRolePermissionsPagesMutation, useGetCurrentUserPermissionsQuery } from "../../../graphql/generated/graphql.ts";
import { toast } from "react-toastify";
import DialogModal from "../../../components/modal/DialogModal";
import Button from "../../../components/button/Button";
import InfoModal from "../../../components/modal/Infomodal.js";
import { formatTimestamp } from "../../../utils/commonFormatFunctions.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import ActionButton from "../../../components/button/ActionButton.js";
import TableHeaderComponent from "../../../components/form/input/TableHeaderComponent.js";


const AccessControlRoles = () => {
    const navigate = useNavigate();
    const [getRoleInfo, { data, error, loading }] = useGetAllRolePermissionsPagesMutation();
    const { rolePermissionList, pageInfo } = data?.getAllRolePermissionsPages || {};
    const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
    const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
    const permissionAPIList = permissionData?.getAllAPIPermissions
    const createRoles = permissionAPIList?.filter(item => item.accessType === "CreateRoles")
    const deleteRoles = permissionAPIList?.filter(item => item.accessType === "DeleteRoles")
    const updateRoles = permissionAPIList?.filter(item => item.accessType === "UpdateRoles")
    const editUserPermission = updateRoles?.length && updateRoles[0].assignedPermissions.some(val => addPermission.includes(val));
    const addUserPermission = createRoles?.length && createRoles[0].assignedPermissions.some(val => addPermission.includes(val));
    const deleteUserPermission = deleteRoles?.length && deleteRoles[0].assignedPermissions.some(val => addPermission.includes(val));
    const [searchQuery, setSearchQuery] = useState("");
    const [sortedColumn, setSortedColumn] = useState("roleName");
    const [deleteMutation, { loading: loader }] = useDeleteRolePermissionsByRoleIdMutation();
    const [sortOrder, setSortOrder] = useState("ASC");
    const [isOpen, setIsOpen] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [deletedItem, setDeletedItem] = useState([]);
    const [roleModal, setRoleModal] = useState(false);
    const [permissionList, setPermissionList] = useState([]);
    const [recordsPerPageData, setRecordsPerPageData] = useState(pageInfo?.per_page);
    const maxPageNumber = 5;
    const defaultPageNumber = 1;

    if (error) {
        navigate("/error", { state: { errorData: JSON.stringify(error) } });
    }

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Roles`;
        document.title = pageTitle;
    }, [])

    const getRoleData = (page = defaultPageNumber, row = pageInfo?.recordsPerPage, search_term = searchQuery) => {
        setDeletedItem([])
        setSelectedItems([]); setSelectAll(false)
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term,
            search_column: "",
            order_by: sortOrder,
            order_by_column: sortedColumn,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        };
        getRoleInfo({ variables: { PageInfoInput } })
            .catch(e => {
                toast.error(e.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    };

    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getRoleData(defaultPageNumber, rowValue);
    };


    const handleCheckboxChange = itemId => {
        if (selectedItems?.includes(itemId)) {
            setSelectedItems(selectedItems?.filter(id => id !== itemId));
        } else {
            setSelectedItems([...selectedItems, itemId]);
        }
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(rolePermissionList?.map(item => item.roleId));
        }
        setSelectAll(!selectAll);
    };

    // Handle search input change
    const handleSearchChange = e => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm);
    };

    // Handle column header click for sorting
    const handleSort = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrder);
        setSortedColumn(column);
    };

    // Handle pagination link click
    const handlePageChange = pageNumber => {
        getRoleData(pageNumber, pageInfo?.per_page, "");
    };

    const handleClearSearch = () => {
        setSearchQuery("");
        getRoleData(defaultPageNumber, pageInfo?.per_page, "");
    };

    useEffect(() => {
        if(sortOrder && sortedColumn) {
            getRoleData(defaultPageNumber, pageInfo?.per_page);
        }
    }, [sortOrder, sortedColumn]);

    useEffect(() => {
        const delayTime = 1000;
        if (searchQuery) {
            const delayDebounceFn = setTimeout(() => {
                // Make API request with the current value
                getRoleData(defaultPageNumber, pageInfo?.per_page, searchQuery);
            }, delayTime);
            return () => {
                clearTimeout(delayDebounceFn);
            };
        }
    }, [searchQuery]);

    const openModal = () => {
        if (selectedItems.length) {
            setIsOpen(true);
        }
    };

    const closeModal = () => {
        setIsOpen(false);
        setDeletedItem([])
        setSelectedItems([])
    };

    const onClickDelete = selectedItem => {
        deleteMutation({ variables: { uniqueId: selectedItem } })
            .then(() => {
                toast.success("Role deleted successfully.")
                closeModal()
                getRoleData();
                setSelectedItems([])
            }).catch(err => {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            })
    }

    const handleDelete = roles => {
        const uniqueId = [roles?.roleId]
        setDeletedItem(uniqueId)
        setIsOpen(true)
    }
    const openRoleModal = permissionsData => {
        const final = permissionsData?.map(item => item?.permissionName)
        setPermissionList(final)
        setRoleModal(true)
    }
    const closeRoleModal = () => {
        setPermissionList(null)
        setRoleModal(false)
    }

    return (
        <>
            <PageWrapper heading={"Access Control Roles"}>
                <TableWrapper>
                    <TableHeadingSection
                        heading={"Roles"}
                        description={"All the roles in the database."}
                        count={pageInfo?.total_records}
                    />
                    <TableButtonSection
                        searchQuery={searchQuery}
                        onSearchChange={handleSearchChange} onClearSearch={handleClearSearch}>
                        {addUserPermission && !loading && <Link to='/access-control/roles/new' state={{ permissions: rolePermissionList }} className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-indigo-500 rounded-lg shrink-0 gap-x-2 hover:bg-indigo-600 dark:hover:bg-indigo-500 dark:bg-indigo-600">
                            <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faPlus} />
                            <span>Add</span>
                        </Link>}
                        {
                            deleteUserPermission && selectedItems?.length > 0 &&
                            <div>
                                <Button
                                    type="button"
                                    onClick={openModal}
                                    className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-red-500 rounded-lg shrink-0 gap-x-2 hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600"
                                    icon={faTrashCan}
                                    text="Delete"
                                />
                            </div>
                        }
                    </TableButtonSection>
                    <TableContainer>
                        <thead className="bg-gray-50 dark:bg-neutral-800">
                            <tr>

                                <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold">
                                    <div className="flex h-6 items-center">
                                        <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    </div>
                                </th>
                                <TableHeaderComponent columnKey="roleName" label="Role Name" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                                <TableHeaderComponent columnKey="roleDescription" label="Role Description" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                                <TableHeaderComponent columnKey="permission" label="Permissions" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                                <TableHeaderComponent columnKey="created_at" label="Created On" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                                <TableHeaderComponent columnKey="updated_at" label="Updated On" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                                <th scope="col"
                                    className="px-4 py-3.5 text-sm text-center rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap"> Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                            {rolePermissionList?.map(roles => (
                                <tr key={roles?.roleId}>
                                    <td className="px-4 py-3 text-sm font-medium">
                                        <div className="flex h-6 items-center">
                                            <input type="checkbox" checked={selectedItems.includes(roles.roleId)} onChange={() => handleCheckboxChange(roles.roleId)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                        <div>
                                            <div className="font-semibold text-indigo-600 dark:text-indigo-400">
                                                {roles.roleName}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-pre-line" >
                                        <div className="" >
                                            <p className={`text-gray-700 dark:text-gray-400 overflow-hidden line-clamp-3`}>
                                                {roles.roleDescription}
                                            </p>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <button className="cursor-pointer" onClick={() => { openRoleModal(roles.permissions) }}>
                                            <div>
                                                <p className="text-black-700 font-semibold dark:text-gray-200">
                                                    {roles.permissions.length}
                                                </p>
                                            </div>
                                        </button>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">
                                                {formatTimestamp(roles.created_at)}
                                            </h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">
                                                {formatTimestamp(roles.updated_at)}
                                            </h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="flex item-center justify-center text-gray-700 dark:text-gray-200">
                                            {editUserPermission ? <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                icon={PencilIcon} title="Edit" onClick={() => { navigate('/access-control/roles/edit', { state: { roles, permissions: rolePermissionList } }) }} />
                                                : <ActionButton className="w-4 mr-2 transform text-gray-500" title="Edit" icon={PencilIcon} />
                                            }
                                            {deleteUserPermission ? <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" onClick={() => { handleDelete(roles) }}
                                                icon={TrashIcon} title="Delete" />
                                                : <ActionButton className="w-4 mr-2 transform text-gray-500" title="Delete" icon={TrashIcon} />
                                            }
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {!rolePermissionList?.length && !loading && (
                                <TableNoRecordFound colSpan={8}></TableNoRecordFound>
                            )}
                            {loading && <TableLoadingData colSpan={8}></TableLoadingData>}
                        </tbody>
                    </TableContainer>
                </TableWrapper>
                <Pagination totalPages={pageInfo?.total_pages || 0} totalRecords={pageInfo?.total_records || 0}
                    maxPageNumbers={maxPageNumber}
                    recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0}
                    currentPage={pageInfo?.current_page || 0} onPageChange={handlePageChange} onRowChanges={handleRowChanges} ></Pagination>
            </PageWrapper>
            <DialogModal
                isOpen={isOpen}
                onClose={closeModal}
                title="Confirm Deletion"
                closeOnClickOutside={false} // Set this prop as needed
                pTagData={`Do you confirm to delete ${deletedItem?.length || selectedItems?.length} roles?`}
                btnOne="Confirm Delete"
                btnTwo="Cancel"
                loader={loader}
                onClickDeleteModal={() => { onClickDelete(deletedItem.length > 0 ? deletedItem : selectedItems) }}>
            </DialogModal>
            <InfoModal
                isOpen={roleModal}
                onClose={closeRoleModal}
                title="Permissions List"
                closeOnClickOutside={false} // Set this prop as needed
                pTagData={permissionList}
                btnTwo="Cancel">
            </InfoModal>
        </>
    );
};

export default AccessControlRoles;
