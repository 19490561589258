import React, { useEffect, useState }  from 'react';
import PageWrapper from "../../../layouts/PageWrapper.js";
import { useNavigate, useParams } from 'react-router-dom';
import { useGetStaticPageByIdMutation } from '../../../graphql/generated/graphql.ts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { formatTimestamp } from '../../../utils/commonFormatFunctions.js';
import ContentEditor from '../../../components/form/editor/ContentEditor.js';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState, } from 'draft-js';

const StaticDetailsPage = () =>  {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [htmlData, setHtmlData] = useState('');
    const [getsStaticPageByIdMutation, { data, error, loading }] = useGetStaticPageByIdMutation({
        variables: {
            slug
        },
    });

    function handleNavigationError() {
        if (error) {
            navigate('/error', { state: { errorData: JSON.stringify(error) } });
        }
    }
    // Call the function to handle navigation error
    handleNavigationError();

    useEffect(() => {
        getsStaticPageByIdMutation();
    }, [])

    useEffect(() => {
        if (data?.getStaticPageById?.body) {
            convertHtmlToDraft(data?.getStaticPageById?.body)
        }
    }, [data])

    function convertHtmlToDraft(html) {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorData = EditorState.createWithContent(contentState);
        setHtmlData(editorData)
    }

    return(
        <PageWrapper heading={'Static Page Details'}>
            {loading ? <div className="flex justify-center items-center h-screen">
                <td colSpan={3} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
                    <div>
                        <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                        <span>Loading Data</span>
                    </div>
                </td>
            </div>: 
            <div>
                <div className="py-6">
                    <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                        <div className="col-span-4">
                                <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">Static Page Details</h3>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Page title and slug deatils</p>
                        </div>
                        <div className="col-span-8">
                            <dl className="md:px-8 divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                                <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Title</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{data?.getStaticPageById?.title}</dd>
                                </div>
                                <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Slug</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{data?.getStaticPageById?.slug}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
                <hr/>
                    <div className="py-6">
                        <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                            <div className="col-span-4">
                                <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">Message Body</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Customized Static page content</p>
                            </div>
                            <div className="col-span-8">
                                    <div className="w-full text-gray-900 dark:text-gray-200 justify-center items-center">
                                        <ContentEditor editorState={htmlData} value={htmlData} name={'content'} toolbarHidden={true} />
                                    </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="py-6">
                        <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                            <div className="col-span-4">
                                <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">Activity Timestamp</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Created and Updated Timestamp</p>
                            </div>
                            <div className="col-span-8">
                                <dl className="md:px-8 divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                                    <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Created By</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{data?.getStaticPageById?.created_by}</span><span className="text-xs font-semibold">{formatTimestamp(data?.getStaticPageById?.created_at)}</span></dd>
                                    </div>
                                    <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Updated By</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{data?.getStaticPageById?.updated_by}</span><span className="text-xs font-semibold">{formatTimestamp(data?.getStaticPageById?.updated_at)}</span></dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
                }
        </PageWrapper>
    )

};

export default StaticDetailsPage; 

