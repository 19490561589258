import PageWrapper from "../../../layouts/PageWrapper";
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faEdit, faSpinner, faArrowDownShortWide, faArrowUpWideShort, faTrashCan, faAdd, faCloudArrowUp, } from '@fortawesome/free-solid-svg-icons'
import {
    useGetGroupDetailsByIdMutation, useDeletegroupSectionsDocsByGroupIdMutation, useGetCurrentUserPermissionsQuery,
    useGetDocumentsByGroupIdPagesMutation, useGetFoldersByGroupIdPagesMutation, useGetUsersByGroupIdPagesMutation, useFileMetadataForAdminQuery,
    useRemoveUserFromGroupMutation
} from "../../../graphql/generated/graphql.ts";
import Button from "../../../components/button/Button";
import TableWrapper from "../../../components/table/TableWrapper";
import TableButtonSection from "../../../components/table/TableButtonSection";
import TableContainer from "../../../components/table/TableContainer";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound";
import TableLoadingData from "../../../components/table/TableLoadingData";
import Pagination from "../../../components/table/Pagination";
import DialogModal from "../../../components/modal/DialogModal";
import { toast } from "react-toastify";
import { formatTimestamp } from "../../../utils/commonFormatFunctions.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import ActionButton from "../../../components/button/ActionButton.js";
import { TrashIcon } from "@heroicons/react/24/outline";


const GroupDetails = () => {
    const { groupId } = useParams();
    const navigate = useNavigate();
    const defaultPageNumber = 1;
    const defaultPerPageData = 10;
    const [getGroupDetailsByIdMutation, { data, error, loading }] = useGetGroupDetailsByIdMutation({
        variables: {
            uniqueId: groupId
        },
    });
    const { data: folderAndDocData, loading: fileMetaDataLoader } = useFileMetadataForAdminQuery();
    const [groupDelete, { loading: loader }] = useDeletegroupSectionsDocsByGroupIdMutation();
    const [getDocByGroupId, { data: paginationDocData, loading: docLoader }] = useGetDocumentsByGroupIdPagesMutation();
    const [getFolderByGroupId, { data: paginationFolderData, loading: folderLoader }] = useGetFoldersByGroupIdPagesMutation();
    const [getUserByGroupId, { data: paginationUserData, loading: userLoader }] = useGetUsersByGroupIdPagesMutation();
    const [deleteMutation, { loading: deleteLoader }] = useRemoveUserFromGroupMutation();
    const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
    const [groupData, setGroupData] = useState([])
    const [sortedColumn, setSortedColumn] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [sortOrder, setSortOrder] = useState('');
    const [sortedColumnFolder, setSortedColumnFolder] = useState('');
    const [searchQueryFolder, setSearchQueryFolder] = useState("");
    const [sortOrderFolder, setSortOrderFolder] = useState('');
    const [deletedItem, setDeletedItem] = useState([]);
    const [deletedUser, setDeletedUser] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenUser, setIsOpenUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState([]);
    const [recordsPerPageData, setRecordsPerPageData] = useState(defaultPerPageData);
    const [recordPerPageDataFolder, setRecordPerPageDataFolder] = useState(defaultPerPageData);
    const [recordPerPageUserData, setRecordPerPageUserData] = useState(defaultPerPageData);
    const [searchQueryUser, setSearchQueryUser] = useState("")
    const [sortOrderUser, setSortOrderUser] = useState("ASC");
    const [sortedColumnUser, setSortedColumnUser] = useState("givenName");
    const maxPageNumber = 5;
    const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
    const permissionAPIList = permissionData?.getAllAPIPermissions
    const deleteGroups = permissionAPIList?.filter(item => item.accessType === "DeleteGroups")
    const updateGroups = permissionAPIList?.filter(item => item.accessType === "UpdateGroups")
    const createUserInfo = permissionAPIList?.filter(item => item.accessType === "CreateUserInfo")
    const editUserPermission = updateGroups?.length && updateGroups[0].assignedPermissions.some(val => addPermission.includes(val));
    const deleteUserPermission = deleteGroups?.length && deleteGroups[0].assignedPermissions.some(val => addPermission.includes(val));
    const addUserPermission = createUserInfo?.length && createUserInfo[0].assignedPermissions.some(val => addPermission.includes(val));
    const [searchQuery, setSearchQuery] = useState('');
    const { documentsByGroupIdList, pageInfo } = paginationDocData?.getDocumentsByGroupIdPages || {};
    const { foldersByGroupIdList, pageInfo: folderPgaeInfo } = paginationFolderData?.getFoldersByGroupIdPages || {};
    const { usersByGroupIdList, pageInfo: userPageInfo } = paginationUserData?.getUsersByGroupIdPages || {};
    const attachmentTextValidator = "data:attachment/text,"

    function handleNavigationError() {
        if (error) {
            navigate('/error', { state: { errorData: JSON.stringify(error) } });
        }
    }
    // Call the function to handle navigation error
    handleNavigationError();

    const getDocDataById = (page = defaultPageNumber, row = pageInfo?.per_page, search_term = searchQuery) => {
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term,
            search_column: "",
            order_by: sortOrder,
            order_by_column: sortedColumn,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        };
        getDocByGroupId({ variables: { PageInfoInput, uniqueId: groupId } })
            .catch(err => {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    };

    const getfolderDataById = (page = defaultPageNumber, row = folderPgaeInfo?.per_page, search_term = searchQueryFolder) => {
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term,
            search_column: "",
            order_by: sortOrderFolder,
            order_by_column: sortedColumnFolder,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        };

        getFolderByGroupId({ variables: { PageInfoInput, uniqueId: groupId } })
            .catch(er => {
                toast.error(er.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    };

    const getUserDataById = (page = defaultPageNumber, row = defaultPerPageData, search_term = searchQueryUser) => {
        setSelectAll(false)
        setSelectedUser([]); setDeletedUser([]);
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term,
            search_column: "",
            order_by: sortOrderUser,
            order_by_column: sortedColumnUser,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        };
        getUserByGroupId({ variables: { PageInfoInput, uniqueId: groupId } })
            .catch(errors => {
                toast.error(errors.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    }
    // Handle search input change
    const handleSearchChange = e => {
        setSearchQuery(e.target.value);
    };

    const handleSearchChangeFolder = e => {
        setSearchQueryFolder(e.target.value)
    }
    const handleSearchChangeUser = e => {
        setSearchQueryUser(e.target.value)
    }

    useEffect(() => {
        getDocDataById(defaultPageNumber, pageInfo?.per_page);
    }, [sortOrder, sortedColumn]);

    useEffect(() => {
        getfolderDataById(defaultPageNumber, folderPgaeInfo?.per_page);
    }, [sortOrderFolder, sortedColumnFolder]);

    useEffect(() => {
        getUserDataById(defaultPageNumber, userPageInfo?.per_page);
    }, [sortOrderUser, sortedColumnUser]);

    useEffect(() => {
        const delayTime = 1000;
        if (searchQuery) {
            const delayDebounceFn = setTimeout(() => {
                // Make API request with the current value
                getDocDataById(defaultPageNumber, pageInfo?.per_page, searchQuery);
            }, delayTime);
            return () => {
                clearTimeout(delayDebounceFn);
            };
        }

    }, [searchQuery]);

    useEffect(() => {
        const delayTime = 1000;
        if (searchQueryFolder) {
            const delayDebounceFn = setTimeout(() => {
                // Make API request with the current value
                getfolderDataById(defaultPageNumber, folderPgaeInfo?.per_page, searchQueryFolder);
            }, delayTime);
            return () => {
                clearTimeout(delayDebounceFn);
            };
        }

    }, [searchQueryFolder]);

    useEffect(() => {
        const delayTime = 1000;
        if (searchQueryUser) {
            const delayDebounceFn = setTimeout(() => {
                // Make API request with the current value
                getUserDataById(defaultPageNumber, userPageInfo?.per_page, searchQueryUser);
            }, delayTime);
            return () => {
                clearTimeout(delayDebounceFn);
            };
        }
    }, [searchQueryUser]);

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Groups | Details | ${groupData?.groupName}`;
        document.title = pageTitle;
    }, [groupData])

    useEffect(() => {
        getGroupDetailsByIdMutation()
    }, [groupId])

    useEffect(() => {
        if (data) {
            setGroupData(data?.getGroupDetailsById[0])
        }
    }, [data])

    const handleSort = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrder);
        setSortedColumn(column);
    };

    const handleSortFolder = column => {
        const newSortOrderData = column === sortedColumnFolder && sortOrderFolder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrderFolder(newSortOrderData);
        setSortedColumnFolder(column);
    };
    const handleSortUser = column => {
        const newSortOrderData = column === sortedColumnUser && sortOrderUser === 'ASC' ? 'DESC' : 'ASC';
        setSortOrderUser(newSortOrderData);
        setSortedColumnUser(column);
    };

    const handlePageChange = pageNumber => {
        getDocDataById(pageNumber, pageInfo?.per_page);
    };
    const handlePageUserChange = pageNumber => {
        getUserDataById(pageNumber, userPageInfo?.per_page);
    };

    const handlePageChangeFolder = pageNumber => {
        getfolderDataById(pageNumber, folderPgaeInfo?.per_page);
    };

    const handleDelete = () => {
        setDeletedItem(groupId);
        setIsOpen(true);
    }

    const handleDeleteUser = userData => {
        setDeletedUser(userData.uniqueId)
        setIsOpenUser(true)
    }

    const closeModal = () => {
        setIsOpen(false)
        setDeletedItem([])
    };

    const onClickDelete = selectedItem => {
        groupDelete({ variables: { uniqueId: selectedItem } })
            .then(() => {
                toast.success("Group deleted successfully.");
                closeModal();
                navigate("/access-control/groups")
            }).catch(e => {
                toast.error(e.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            })
    }
    const onClickDeleteUser = selectedUserDetails => {
        let removeUserData = null;
        if (selectedUserDetails.length > 0) {
            removeUserData = selectedUserDetails?.map(removedata => ({
                userId: removedata,
                groupId
            }));
        } else {
            removeUserData = {
                userId: selectedUserDetails,
                groupId
            }
        }

        deleteMutation({ variables: { addUserToGroupArgsList: removeUserData } })
            .then(() => {
                toast.success("User deleted successfully.");
                setSelectedItems([]);
                setIsOpenUser(false);
                getUserDataById();
            }).catch(errorData => {
                toast.error(errorData.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: true,
                });
            })
    }

    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getUserDataById(defaultPageNumber, rowValue);
    };

    const handleRowUserChanges = rowValue => {
        setRecordPerPageUserData(rowValue)
        getUserDataById(defaultPageNumber, rowValue);
    };
    const handleRowChangesFolder = rowValue => {
        setRecordPerPageDataFolder(rowValue)
        getfolderDataById(defaultPageNumber, rowValue);
    }

    const handleClearSearch = () => {
        setSearchQuery("");
        getDocDataById(defaultPageNumber, pageInfo?.per_page, "");
    };
    const handleClearSearchUser = () => {
        setSearchQueryUser("")
        getUserDataById(defaultPageNumber, userPageInfo?.per_page, "")
    }

    const handleClearSearchFolder = () => {
        setSearchQuery("");
        getfolderDataById(defaultPageNumber, folderPgaeInfo?.per_page, "");
    }
    const handleDownloadFileDoc = () => {
        let stringToDownload = "Document Name ,Document ID\n";
        groupData?.documents?.forEach(res => {
            stringToDownload = `${stringToDownload}${res.documentName},${res.id} \n`
        });
        const hiddenElement = document.createElement('a');
        hiddenElement.href = attachmentTextValidator + encodeURI(stringToDownload);
        hiddenElement.target = '_blank';
        const date = new Date();
        hiddenElement.download = `Document_report ${date.toString()} .csv`
        hiddenElement.click();
    };

    const handleDownloadFileUser = () =>{
        let stringToDownload = "First Name,Last Name, Email\n";
        groupData?.users?.forEach(res => {
            stringToDownload = `${stringToDownload}${res.givenName},${res.familyName},${res.uniqueName} \n`
        });
        const hiddenElement = document.createElement('a');
        hiddenElement.href = attachmentTextValidator + encodeURI(stringToDownload);
        hiddenElement.target = '_blank';
        const date = new Date();
        hiddenElement.download = `User_report ${date.toString()} .csv`
        hiddenElement.click();
    }
 
    const handleDownloadFileFolder = () => {
        let stringToDownload = "Folder Name, Folder ID \n";
        groupData?.folders?.forEach(res => {
            stringToDownload = `${stringToDownload}${res.folderName},${res.id} \n`
        });

        const hiddenElement = document.createElement('a');
        hiddenElement.href = attachmentTextValidator + encodeURI(stringToDownload);
        hiddenElement.target = '_blank';
        const date = new Date();
        hiddenElement.download = `Folder_report ${date.toString()} .csv`
        hiddenElement.click();
    };


    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(usersByGroupIdList?.map(item => item.uniqueId) || []);
        }
        setSelectAll(prev => !prev);
    };
    const handleCheckboxChange = itemId => {
        setSelectedItems(prevItems => prevItems.includes(itemId) ? prevItems.filter(id => id !== itemId) : [...prevItems, itemId]);
    };

    const renderLoaderOrContent = () => {
        if (loading || loader || fileMetaDataLoader) {
            return (
                <div className="flex justify-center items-center h-screen">
                    <td colSpan={8} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
                        <div>
                            <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                            <span>Loading Data</span>
                        </div>
                    </td>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="pb-6">
                        <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                            <div className="col-span-4 mt-10">
                                <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">General Information</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Personal details and application.</p>
                            </div>
                            <div className="col-span-8">
                                <div className="flex gap-2 mb-3 justify-end">
                                    <div>
                                        {!editUserPermission ? <Button text="Edit"
                                            icon={faEdit}
                                            type="button"
                                            className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2"
                                        /> : <Link
                                            to={`/access-control/groups/edit/${groupId}`} state={{ fileMetaData: folderAndDocData }}
                                            className={loading ? "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2" : "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-indigo-500 rounded-lg shrink-0 gap-x-2 hover:bg-indigo-600 dark:bg-indigo-600"}>
                                            <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faEdit} />
                                            <span>Edit</span>
                                        </Link>}
                                    </div>
                                    <div>
                                        {!deleteUserPermission ? <Button
                                            type="button"
                                            className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2"
                                            icon={faTrashCan} text={"Delete"} /> : <Button
                                            type="button"
                                            className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-red-500 rounded-lg shrink-0 gap-x-2 hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600"
                                            onClick={handleDelete}
                                            icon={faTrashCan}
                                            text={"Delete"}
                                        />}
                                    </div>
                                </div>
                                <dl className="md:px-8 divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                                    <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Group name</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{groupData?.groupName}</dd>
                                    </div>
                                    <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Group Id</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{groupData?.groupId}</dd>
                                    </div>
                                    <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Description</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{groupData?.groupDescription}</dd>
                                    </div>
                                </dl>
                            </div>

                        </div>
                    </div>
                    <hr />
                    {renderActivityTimestamp()}
                    <hr />
                    {renderFolderList()}
                    <hr />
                    {renderDocumentList()}
                    <hr />
                    {renderUserList()}
                </div>
            );
        }
    };

    const renderActivityTimestamp = () => {
        return (
            <div className="py-6">
                <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                    <div className="col-span-4">
                        <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">Activity Timestamp</h3>
                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Created and Updated Timestamp</p>
                    </div>
                    <div className="col-span-8">
                        <dl className="md:px-8 divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Created By</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{groupData?.created_by}</span><span className="text-xs font-semibold">{formatTimestamp(groupData?.created_at)}</span></dd>
                            </div>
                            <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Updated By</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{groupData?.updated_by}</span><span className="text-xs font-semibold">{formatTimestamp(groupData?.updated_at)}</span></dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        )
    };

    const renderFolderList = () => {
        return (
            <div className="flex flex-col xl:flex-row gap-4">
                <PageWrapper style={"mt-16 mb-6 w-full"}>   {/* NOSONAR */}
                    <TableWrapper>
                        <div className="flex-1">
                            <div>
                                <h2 className="text-2xl font-bold dark:text-gray-100 mb-4">{"Folder List"}</h2>
                            </div>
                            <hr />
                            <TableButtonSection
                                searchQuery={searchQueryFolder}
                                onSearchChange={handleSearchChangeFolder}
                                onClearSearch={handleClearSearchFolder}
                            >
                                <Button
                                    type="button"
                                    onClick={handleDownloadFileFolder}
                                    icon={faDownload}
                                    text="Download Report"
                                    className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                                />
                            </TableButtonSection>
                            <TableContainer>
                                <thead className="bg-gray-50 dark:bg-neutral-800">
                                    <tr>
                                        <th scope="col" onClick={() => handleSortFolder('display_name')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                            <div className="flex items-center gap-x-3 cursor-pointer">
                                                <span>Folder Name</span>
                                                {sortedColumnFolder === "display_name" && <FontAwesomeIcon className="h-3" icon={sortOrderFolder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                            </div>
                                        </th>
                                        <th scope="col" onClick={() => handleSortFolder('folder_id')} className="px-3.5 py-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                            <div className="flex items-center gap-x-4 cursor-pointer">
                                                <span>Folder Id</span>
                                                {sortedColumnFolder === "folder_id" && <FontAwesomeIcon className="h-3" icon={sortOrderFolder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                            </div>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                                    {foldersByGroupIdList?.map(folderData => (
                                        <tr key={folderData?.id}>
                                            <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                                <div className="w-50">
                                                    <h2 className="font-semibold text-indigo-600 dark:text-indigo-400 ">{folderData.folderName}</h2>
                                                </div>
                                            </td>
                                            <td className="px-4 py-4 text-sm font-medium whitespace-nowrap ">
                                                <div className="w-50">
                                                    <h2 className="font-semibold text-indigo-600 dark:text-indigo-400 ">{folderData.id}</h2>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    {!foldersByGroupIdList?.length && !folderLoader && <TableNoRecordFound colSpan={9}></TableNoRecordFound>}
                                    {folderLoader && <TableLoadingData colSpan={9}></TableLoadingData>}
                                </tbody>
                            </TableContainer>
                        </div>
                        <hr />
                    </TableWrapper>
                    <Pagination totalPages={folderPgaeInfo?.total_pages ? folderPgaeInfo?.total_pages : 0} totalRecords={folderPgaeInfo?.total_records || 0}
                        maxPageNumbers={maxPageNumber}
                        recordsPerPage={folderPgaeInfo?.per_page || recordPerPageDataFolder || 0}
                        currentPage={folderPgaeInfo?.current_page ? folderPgaeInfo?.current_page : 0} onPageChange={handlePageChangeFolder} onRowChanges={handleRowChangesFolder} ></Pagination>
                </PageWrapper>
            </div>
        )
    };

    const renderDocumentList = () => {
        return (
            <div className="flex flex-col xl:flex-row gap-4">
                <PageWrapper style={"mt-16 mb-6 w-full"}>   {/* NOSONAR */}
                    <TableWrapper>
                        <div className="flex-1">
                            <div>
                                <h2 className="text-2xl font-bold dark:text-gray-100 mb-4">{"Document List"}</h2>
                            </div>
                            <hr />
                            <TableButtonSection
                                searchQuery={searchQuery}
                                onSearchChange={handleSearchChange}
                                onClearSearch={handleClearSearch}
                            >
                                <Button
                                    type="button"
                                    onClick={handleDownloadFileDoc}
                                    icon={faDownload}
                                    text="Download Report"
                                    className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                                />
                            </TableButtonSection>
                            <TableContainer>
                                <thead className="bg-gray-50 dark:bg-neutral-800">
                                    <tr>
                                        <th scope="col" onClick={() => handleSort('file_name')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                            <div className="flex items-center gap-x-3 cursor-pointer">
                                                <span>Document Name</span>
                                                {sortedColumn === "file_name" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                            </div>
                                        </th>
                                        <th scope="col" onClick={() => handleSort('id')} className="px-3.5 py-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                            <div className="flex items-center gap-x-4 cursor-pointer">
                                                <span>Document Id</span>
                                                {sortedColumn === "id" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                            </div>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                                    {documentsByGroupIdList?.map(docData => (
                                        <tr key={docData?.id}>
                                            <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                                <div className="w-50">
                                                    <h2 className="font-semibold text-indigo-600 dark:text-indigo-400 ">{docData.documentName}</h2>
                                                </div>
                                            </td>
                                            <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                                <div className="w-50">
                                                    <h2 className="font-semibold text-gray-600 dark:text-gray-400 ">{docData.id}</h2>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    {!documentsByGroupIdList?.length && !docLoader && <TableNoRecordFound colSpan={9}></TableNoRecordFound>}
                                    {docLoader && <TableLoadingData colSpan={9}></TableLoadingData>}
                                </tbody>
                            </TableContainer>
                        </div>
                        <hr />
                    </TableWrapper>
                    <Pagination totalPages={pageInfo?.total_pages ? pageInfo?.total_pages : 0} totalRecords={pageInfo?.total_records || 0}
                        maxPageNumbers={maxPageNumber}
                        recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0}
                        currentPage={pageInfo?.current_page ? pageInfo?.current_page : 0} onPageChange={handlePageChange} onRowChanges={handleRowChanges} ></Pagination>
                </PageWrapper>
            </div>
        )
    };

    const renderUserList = () => {
        return (
            <div className="flex flex-col xl:flex-row gap-4">
                <PageWrapper style={"mt-16 mb-6 w-full"}>   {/* NOSONAR */}
                    <TableWrapper>
                        <div className="flex-1">
                            <div>
                                <h2 className="text-2xl font-bold dark:text-gray-100 mb-4">{"User List"}</h2>
                            </div>
                            <hr />
                            <TableButtonSection
                                searchQuery={searchQueryUser}
                                onSearchChange={handleSearchChangeUser}
                                onClearSearch={handleClearSearchUser}
                            >
                                {addUserPermission && <Button
                                    type="button"
                                    onClick={() => {
                                        navigate(`/access-control/groups/add/${groupId}`)

                                    }}
                                    icon={faAdd}
                                    text="Add"
                                    className="flex items-center justify-center px-5 py-2 text-sm font-medium text-white transition-colors duration-200 bg-indigo-500  border rounded-lg gap-x-2 dark:hover:bg-indigo-600 hover:bg-indigo-600 dark:text-gray-200 dark:bg-indigo-600"
                                />}
                                <Button
                                    type="button"
                                    onClick={handleDownloadFileUser}
                                    icon={faDownload}
                                    text="Download Report"
                                    className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                                />

                                {addUserPermission &&
                                    <Button
                                        type="button"
                                        onClick={() => { navigate(`/manage/groups/bulkUpload/${groupId}`) }}
                                        icon={faCloudArrowUp}
                                        text="Bulk Upload"
                                        className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                                    />
                                }
                                {
                                    selectedItems?.length > 0 && deleteUserPermission &&
                                    <Button
                                        type="button"
                                        onClick={() => { setIsOpenUser(!isOpenUser) }}
                                        icon={faTrashCan}
                                        text="Delete"
                                        className="flex items-center justify-center px-5 py-2 text-sm font-medium text-white transition-colors duration-200 bg-red-500  border rounded-lg gap-x-2 dark:hover:bg-red-600 hover:bg-red-600 dark:text-gray-200 dark:bg-red-600"
                                    />}

                                {
                                    selectedUser?.length > 0 && deleteUserPermission &&
                                    <div>
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                if (selectedUser.length) {
                                                    setIsOpenUser(true);
                                                }
                                            }}
                                            className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-red-500 rounded-lg shrink-0 gap-x-2 hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600"
                                            icon={faTrashCan}
                                            text="Delete"
                                        />
                                    </div>
                                }
                            </TableButtonSection>
                            <TableContainer>
                                <thead className="bg-gray-50 dark:bg-neutral-800">
                                    <tr>
                                        <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold">
                                            <div className="flex h-6 items-center">
                                                <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                            </div>
                                        </th>
                                        <th scope="col" onClick={() => handleSortUser('givenName')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                            <div className="flex items-center gap-x-3 cursor-pointer">
                                                <span>First Name</span>
                                                {sortedColumnUser === "givenName" && <FontAwesomeIcon className="h-3" icon={sortOrderUser === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                            </div>
                                        </th>
                                        <th scope="col" onClick={() => handleSortUser('familyName')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                            <div className="flex items-center gap-x-3 cursor-pointer">
                                                <span>Last Name</span>
                                                {sortedColumnUser === "familyName" && <FontAwesomeIcon className="h-3" icon={sortOrderUser === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                            </div>
                                        </th>
                                        <th scope="col" onClick={() => handleSortUser('uniqueName')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                            <div className="flex items-center gap-x-3 cursor-pointer">
                                                <span>Email</span>
                                                {sortedColumnUser === "uniqueName" && <FontAwesomeIcon className="h-3" icon={sortOrderUser === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                            </div>
                                        </th>
                                        <th scope="col" className="px-4 py-3.5 text-sm text-center rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                                    {usersByGroupIdList?.map(userData => (
                                        <tr key={userData?.id}>
                                            <td className="px-4 py-3 text-sm font-medium">
                                                <div className="flex h-6 items-center">
                                                    <input type="checkbox" checked={selectedItems.includes(userData.uniqueId)} onChange={() => handleCheckboxChange(userData.uniqueId)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                </div>
                                            </td>
                                            <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                                <div className="w-50">
                                                    <h2 className="font-semibold text-indigo-600 dark:text-indigo-400 ">{userData.givenName}</h2>
                                                </div>
                                            </td>
                                            <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                                <div className="w-50">
                                                    <h2 className="font-semibold text-indigo-600 dark:text-indigo-400 ">{userData.familyName}</h2>
                                                </div>
                                            </td>

                                            <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                                <div className="w-50">
                                                    <h2 className="font-semibold text-gray-600 dark:text-gray-400 ">{userData.uniqueName}</h2>
                                                </div>
                                            </td>
                                            <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                <div className="flex item-center justify-center text-gray-700 dark:text-gray-200">
                                                    {deleteUserPermission ? <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                        icon={TrashIcon} title="Delete"
                                                        onClick={() => { handleDeleteUser(userData) }} />
                                                        : <ActionButton className="w-4 mr-2 transform text-gray-500"
                                                            icon={TrashIcon} title="Delete" />}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    {!usersByGroupIdList?.length && !userLoader && <TableNoRecordFound colSpan={9}></TableNoRecordFound>}
                                    {userLoader && <TableLoadingData colSpan={9}></TableLoadingData>}
                                </tbody>
                            </TableContainer>
                        </div>
                        <hr />
                    </TableWrapper>
                    <Pagination totalPages={userPageInfo?.total_pages || 0} totalRecords={userPageInfo?.total_records || 0}
                        maxPageNumbers={maxPageNumber}
                        recordsPerPage={userPageInfo?.per_page || recordPerPageUserData || 0}
                        currentPage={userPageInfo?.current_page || 0} onPageChange={handlePageUserChange} onRowChanges={handleRowUserChanges} ></Pagination>
                </PageWrapper>
            </div>
        )
    };

    return (
        <PageWrapper heading={'Group Details'}>
            {renderLoaderOrContent()}
            <div>

                <DialogModal
                    isOpen={isOpen}
                    onClose={closeModal}
                    title="Confirm Deletion"
                    closeOnClickOutside={false} // Set this prop as needed
                    pTagData={`Do you confirm to delete group?`}
                    btnOne="Confirm Delete"
                    btnTwo="Cancel"
                    loader={loader}
                    onClickDeleteModal={() => { onClickDelete(deletedItem) }}>
                </DialogModal>
                <DialogModal
                    isOpen={isOpenUser}
                    onClose={() => { setIsOpenUser(false) }}
                    title="Confirm remove"
                    closeOnClickOutside={false} // Set this prop as needed
                    pTagData={`Do you confirm to remove ${selectedItems?.length ? selectedItems?.length : deletedUser ? "1" : selectedItems?.length} users?`}
                    btnOne="Confirm remove"
                    btnTwo="Cancel"
                    loader={deleteLoader}
                    onClickDeleteModal={() => { onClickDeleteUser(selectedItems.length > 0 ? selectedItems : deletedUser || null) }}>
                </DialogModal>
            </div>
        </PageWrapper>
    );
};

export default GroupDetails;
