import React, { useEffect } from "react";
import { useFormik } from "formik";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { faPlus, faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputField from "../../../components/form/input/InputField";
import TextAreaInput from "../../../components/form/input/TextAreaInput";
import PageWrapper from "../../../layouts/PageWrapper";
import ContentEditor from "../../../components/form/editor/ContentEditor";
import Button from "../../../components/button/Button";
import { formCreateNewNotificationSchema } from "../../../validations/FormValidationSchema";
import { useNotificationsListCreateMutation } from '../../../graphql/generated/graphql.ts';
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const AddNewNotifications = () => {
  const navigate = useNavigate();
  const [createNotification, { loading }] = useNotificationsListCreateMutation();

  const initialValues = {
    title: "",
    description: "",
    message: EditorState.createEmpty(),
    message_html: "",
    inputFields: [],
  };

  useEffect(() => {
    const envVariable = process.env.REACT_APP_ENV;
    const prefix = 'SIERA';
    const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
    const pageTitle = `${prefix}${suffix} | Manage Notifications | Create`;
    document.title = pageTitle;
  }, []);

  const { values, errors, touched, setFieldValue, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: formCreateNewNotificationSchema,
    onSubmit: value => {
      const dynamicOptionValue = value?.inputFields?.map(field => ({
        key: field.optionLabel !== "" && field?.optionLabel,
        value: field.optionValue !== "" && field?.optionValue
      }))

      const notificationData = {
        title: value.title.trim(),
        body: value.message_html.trim(),
        description: value.description.trim(),
        dynamicResponse: dynamicOptionValue?.length > 0 ? dynamicOptionValue :[]
      };
      createNotification({ variables: { notificationCreate: [notificationData] } })
        .then(() => {
          toast.success("Notification created successfully.");
          navigate('/manage/notifications');
        })
        .catch(err => {
          toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), { autoClose: false });
        });
    },
  });

  const handleAddInputField = () => {
    setFieldValue("inputFields", [...values.inputFields, { optionLabel: "", optionValue: "" }]);
  };

  const handleDeleteInputField = ( ) =>{
    const deleteVal = [...values.inputFields]
    deleteVal.splice(-1)
    setFieldValue("inputFields",deleteVal)
  }
 
  const handleInputChange = (e, i) => {
    const { name, value } = e.target;
    const updatedInputFields = [...values.inputFields];
    updatedInputFields[i][name] = value;
    setFieldValue("inputFields", updatedInputFields);
  };

  const handleEditorStateChange = editorState => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const contentHtml = draftToHtml(rawContent);
    setFieldValue('message', editorState);
    setFieldValue('message_html', contentHtml);
  };

  const deviceDefaultColor = "bg-indigo-500";
  const deviceHoverColor = "hover:bg-indigo-600 dark:bg-indigo-600";
  const deleteButtonColor = "bg-red-500  hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600";


  return (
    <PageWrapper heading={'Create New Notification'}>
      <div className="py-6">
        <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
          <div className="col-span-4">
            <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">General Information</h2>
            <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Fill in the notification details</p>
          </div>
          <form method="POST" onSubmit={handleSubmit} className="col-span-8">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
              <div className="sm:col-span-2">
                <InputField
                  type="text"
                  name="title"
                  label="Title"
                  value={values.title}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.title && touched.title ? errors.title : ""}
                />
              </div>
              <div className="sm:col-span-2">
                <TextAreaInput
                  name="description"
                  label="Description"
                  value={values.description}
                  rowSpan={3}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.description && touched.description ? errors.description : ""}
                />
              </div>
              <div className="sm:col-span-2">
                <ContentEditor
                  name="message"
                  label="Message"
                  editorState={values.message}
                  required
                  onEditorStateChange={handleEditorStateChange}
                  error={errors.message_html ? errors.message_html : ""}
                />
              </div>
              <div className="sm:col-span-2 flex flex-row justify-between items-center">
                <p className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">
                  Response Options
                </p>
                <div className="flex space-x-2">
                  <Button
                    type="button"
                    className={`flex items-center justify-center text-sm font-medium tracking-wide text-white transition-colors duration-200 ${deviceDefaultColor} rounded-lg shrink-0 gap-x-2 ${deviceHoverColor}`}
                    icon={faPlus}
                    onClick={handleAddInputField}
                    text="Add Responses"
                  />
                  <Button
                    type="button"
                    className={`flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 ${deleteButtonColor} rounded-lg shrink-0 gap-x-2`}
                    icon={faTrash}
                    onClick={handleDeleteInputField}
                    text="Delete"
                  />
                </div>
              </div>
              <div className="sm:col-span-2 justify-between">
                {values.inputFields.map((input, i) => (
                  <div key={input.key} className="mb-2 flex space-x-4">
                    <InputField
                      type="text"
                      name="optionLabel"
                      placeholder="Label"
                      value={input.optionLabel}
                      onChange={e => handleInputChange(e, i)}
                    />
                    <InputField
                      type="text"
                      name="optionValue"
                      placeholder="value"
                      value={input.optionValue}
                      onChange={e => handleInputChange(e, i)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-10 flex justify-start">
              {loading ? (
                <Button
                  type="button"
                  className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  text="Create"
                />
              ) : (
                <Button
                  type="submit"
                  className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  text="Create"
                />
              )}
              <Link
                to="/manage/notifications"
                className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              >
                <span>Cancel</span>
              </Link>
              {loading && <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
            </div>
          </form>
        </div>
      </div>
    </PageWrapper>
  );
};

export default AddNewNotifications;