import PageWrapper from "../../../layouts/PageWrapper";
import { Link } from 'react-router-dom';
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faPlay, faSpinner, faStar } from '@fortawesome/free-solid-svg-icons'
import {
    useGetAllUserInfoListQuery,
    useGetFilesByUserIdMutation
} from "../../../graphql/generated/graphql.ts";
import { toast } from "react-toastify";
import Button from "../../../components/button/Button.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import SelectSingleInput from "../../../components/form/input/SelectSingleInput.js";
import PropTypes from 'prop-types';
import { checkIsNewFile, convertMilliSecondToHrs, getFileExtension } from "../../../utils/commonFormatFunctions.js";
import axios from "axios";


const Card = ({ title, image, time, buttonLabel, critical, NewVideo, download_url }) => {
    const onClickDownloadVideo = async r => {
        try {
            const response = await axios.get(r, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = `${title}.mp4`;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (errData) {
            toast.error(`'Error downloading file:', ${errData}`, {
                autoClose: false,
            });
        }
    }
    return (
        <div className={`mb-2 md:mb-4 m-1 md:m-2 flex-1`}>
            <div className={'flex flex-row h-32'}>
                <div className={'flex flex-row items-start p-2 w-full'}>
                    <div className='relative flex-none h-24 w-32 bg-gray-600 md:h-32 md:w-40 flex justify-center items-center'>
                        {image ?
                            <img src={image} alt="Logo" />
                            : <FontAwesomeIcon className="w-20 h-20" icon={faPlay} />
                        }
                        <div className="absolute bottom-0 right-0 bg-black text-white text-xs px-1">
                            {time}
                        </div>
                    </div>

                    <div className={'flex-1 flex flex-col pl-2'}>
                        <div className={`flex-row mt-auto flex`}>
                            {critical && <div className="bg-red-600 rounded-lg mr-1-2 md:mr-1 items-center justify-center h-4 md:h-4 w-10">
                                <h2 style={{ fontSize: 9 }} className="text-xs text-white font-semibold text-center">{"Critical"}</h2>
                            </div>}
                            {NewVideo && <div className="bg-blue-600 rounded-lg items-center justify-center h-4 md:h-4 w-10">
                                <h2 style={{ fontSize: 9 }} className="text-xs text-white font-semibold text-center">{"New"}</h2>
                            </div>}
                        </div>
                        <div className="flex justify-between items-start">
                            <h2 className='md:text-md pr-2 font-bold text-siera-full-black dark:text-white' style={{ fontSize: 16 }}>
                                {title}
                            </h2>
                        </div>
                        <div className="flex mt-16 justify-end">
                            <button
                                type="button"
                                onClick={() => { onClickDownloadVideo(download_url,) }}
                                className="bg-green-600 px-2 rounded-lg ml-1 mt-2 md:ml-2 md:mt-4 items-center justify-center h-4 md:h-4"
                            >
                                <h2 style={{ fontSize: 9 }} className="text-xs text-white font-semibold text-center">{buttonLabel}</h2>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Card.propTypes = {
    title: PropTypes.any,
    image: PropTypes.any,
    time: PropTypes.any,
    buttonLabel: PropTypes.any,
    critical: PropTypes.any,
    NewVideo: PropTypes.bool,
    download_url: PropTypes.any
}

const UserImpersonate = () => {
    const noDataFoundError = "No Record Found"
    const { data, loading: loader } = useGetAllUserInfoListQuery({ variables: {}, });
    const [getDocVideoSupportMutation, { loading, data: docVideoSupportData }] = useGetFilesByUserIdMutation();
    const groupedByFolderId = docVideoSupportData && Object.values(docVideoSupportData?.getFilesByUserId?.docFiles?.reduce((acc, doc) => {
        if (!acc[doc.folder_id]) {
            acc[doc.folder_id] = [];
        }
        acc[doc.folder_id].push(doc);
        return acc;
    }, {}));

    const favouritesDocumenentData = docVideoSupportData?.getFilesByUserId?.docFiles?.filter(doc => doc.bookmarked_id !== 0);
    const groupedFavouritesByFolderId = favouritesDocumenentData && Object.values(favouritesDocumenentData?.reduce((acc, doc) => {
        if (!acc[doc.folder_id]) {
            acc[doc.folder_id] = [];
        }
        acc[doc.folder_id].push(doc);
        return acc;
    }, {}));

    const titleValue = [
        {
            value: 1,
            lable: "Documents",
            length: docVideoSupportData?.getFilesByUserId?.docFiles?.length || null
        },
        {
            value: 2,
            lable: "Videos",
            length: docVideoSupportData?.getFilesByUserId?.videoFiles?.length || null
        },
        {
            value: 3,
            lable: "Support Documents",
            length: docVideoSupportData?.getFilesByUserId?.supportFiles?.length || null
        }
    ]

    const documentTitle = [
        {
            value: 1,
            lable: "All Documents",
        },
        {
            value: 2,
            lable: "Favourites",
        },
    ]
    const [isActive, setIsActive] = useState(Array.from({ length: groupedFavouritesByFolderId?.length }, () => false));
    const [activeTab, setActiveTab] = useState(titleValue[0].value); // Default active top-level tab
    const [activeSubTab, setActiveSubTab] = useState(documentTitle[0].value);


    const handleTabClick = tabValue => {
        setActiveTab(tabValue);
        // Handle switching content or further logic based on the tab clicked
    };

    const handleSubTabClick = subTabValue => {
        setActiveSubTab(subTabValue);
        // Handle switching content or further logic based on the sub-tab clicked
    };

    // This will run when the page first loads and whenever the title changes
    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Impersonate Users | Deatils`;
        document.title = pageTitle;
    }, []);

    // Define default values for roles and groups
    const transformedOptionsUsers = data?.getAllUserInfoList?.map(userList => ({
        value: userList?.uniqueId,
        label: userList?.uniqueName
    }));


    // Initialize initial values with defaults
    const initialValues = {
        user: null,
    };

    const onClickDownload = async (downloadURL, fileName) => {
        try {
            const response = await axios.get(downloadURL, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (errData) {
            toast.error(`'Error downloading file:', ${errData}`, {
                autoClose: false,
            });
        }
    }


    const { values, errors, touched, setFieldValue, setFieldTouched, handleSubmit } = useFormik({
        initialValues,
        onSubmit: value => {
            try {
                // Handle data if needed (e.g., check result.data)
                getDocVideoSupportMutation({ variables: { userId: value?.user?.value } })
                    .catch(error => {
                        toast.error(error.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                            autoClose: false,
                        });
                    })
            } catch (err) {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
                // Handle error if needed
            }
        }
    })


    return (
        <PageWrapper heading={'User Impersonate'}>
            {loader ? <div className="flex justify-center items-center h-screen">
                <td colSpan={5} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
                    <div>
                        <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                        <span>Loading Data</span>
                    </div>
                </td>
            </div> :
                <div className="py-6">
                    <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                        <div className="col-span-4">
                            <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">Select User
                            </h2>
                            <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Select the the user to see the User Impersonate Details </p>
                        </div>
                        <form method="POST" onSubmit={handleSubmit} className="col-span-8">
                            <div
                                className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                                <div className="sm:col-span-2">
                                    <SelectSingleInput
                                        name="user"
                                        value={values.user}
                                        options={transformedOptionsUsers}
                                        required
                                        onChange={selectedRole => {
                                            setFieldValue('user', selectedRole)
                                        }}
                                        onBlur={() => setFieldTouched('user', true)}
                                        label="Select user"
                                        disabled={loader}
                                        error={(errors.user && touched.user) ? errors.user : ''}
                                    />
                                </div>
                            </div>
                            <div className="mt-10 flex justify-start">
                                {values.user === null ? <Button type="button" disabled={true}
                                    className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                    text="Impersonate" />
                                    : <Button type="submit"
                                        className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        text="Impersonate" />
                                }
                                <Link to='/manage/users'
                                    className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                                {loader && <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                            </div>
                        </form>
                    </div>
                    {loading ? <div className="flex justify-center items-center h-28">
                        <td colSpan={5} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
                            <div>
                                <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                                <span>Loading Data</span>
                            </div>
                        </td>
                    </div> :
                        docVideoSupportData?.getFilesByUserId &&
                        <div className="py-6">
                            <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                                <div className="col-span-4">
                                    <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">User Impersonate Data
                                    </h2>
                                    <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Select the the user to see the User Impersonate Details </p>
                                </div>
                                <div className="col-span-8">
                                    <div className="bg-gray-100 p-4 rounded-md dark:bg-neutral-900">
                                        <div className="flex items-center space-x-0 justify-center">
                                            {titleValue?.map(titleIndex => (
                                                <button key={titleIndex?.value} className={` text-gray-700 dark:bg-neutral-900 px-4 py-2 rounded-md font-bold border-2 border-gray-300 ${activeTab === titleIndex.value ? "bg-white  hover:bg-white-300 dark:bg-white" : "bg-gray-200 hover:bg-gray-300"}`}
                                                    onClick={() => handleTabClick(titleIndex.value)}>
                                                    {titleIndex?.lable} ({titleIndex?.length || 0})
                                                </button>
                                            ))}
                                        </div>
                                        {activeTab === 1 &&
                                            <div className="mt-4 grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 rounded-md bg-white dark:bg-neutral-900 shadow py-2 px-2 sm:px-4">
                                                <div className="flex items-center justify-evenly">
                                                    {documentTitle?.map(docIndex => (
                                                        <button key={docIndex?.id} className={` text-gray-700 dark:bg-neutral-900 px-4 py-2 rounded-md font-bold border-2 border-gray-300 ${activeSubTab === docIndex.value ? "bg-white  hover:bg-white-300 dark:bg-white" : "bg-gray-200 hover:bg-gray-300"}`}
                                                            onClick={() => handleSubTabClick(docIndex.value)}>
                                                            {docIndex?.lable}
                                                        </button>
                                                    ))}
                                                </div>
                                                {activeSubTab === 1 ?
                                                    groupedByFolderId?.length > 0 ? groupedByFolderId?.map((folder, docIndex) => (
                                                        <div key={folder?.id} className="mt-4 grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 rounded-md bg-white dark:bg-neutral-900 shadow py-2 px-2 sm:px-4">
                                                            <h2 className="text-lg font-bold flex justify-between dark:text-white text-black">
                                                                {folder?.[0]?.folder_name}
                                                                <button onClick={() => {
                                                                    const newActiveItems = [...isActive];
                                                                    newActiveItems[docIndex] = !newActiveItems[docIndex];
                                                                    setIsActive(newActiveItems);
                                                                }}>
                                                                    <FontAwesomeIcon className="w-5 h-5 pb-2 text-gray-400 dark:text-gray-600 pt-2" icon={isActive[docIndex] === true ? faChevronUp : faChevronDown} />
                                                                </button>
                                                            </h2>
                                                            <p className="text-gray-500 dark:bg-neutral-900 text-sm">{folder?.length} Documents</p>
                                                            {isActive[docIndex] === true &&
                                                                <div>
                                                                    {folder?.map(doc => (
                                                                        <div key={doc?.id} className="pt-5 pb-5 ">
                                                                            <div className="flex flex-row justify-between items-center">
                                                                                <div className="flex flex-row items-center">
                                                                                    <div className="w-10 justify-center items-center text-2xl font-semibold text-gray-600 dark:text-gray-200">
                                                                                        <FontAwesomeIcon height={22} width={22} icon={getFileExtension(doc.file_url)} />
                                                                                    </div>
                                                                                    <h2 className="md:text-lg text-black dark:text-white">{doc.file_name}</h2>
                                                                                    {checkIsNewFile(doc?.updated_at) && doc.notify_user && (
                                                                                        <div className="flex flex-row items-center">
                                                                                            <div className="bg-blue-600 px-2 rounded-lg ml-1 md:ml-2 items-center justify-center h-4 md:h-4 w-10 mb-2">
                                                                                                <h2 style={{ fontSize: 9 }} className="text-xs text-white font-semibold text-center">{"New"}</h2>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                                <div className="flex flex-row items-center">
                                                                                    {doc?.bookmarked_id !== 0 &&
                                                                                        <div className="h-3 md:h-3">
                                                                                            <FontAwesomeIcon icon={faStar} color="#FFD103" height={21} width={21} className="pb-4" />
                                                                                        </div>
                                                                                    }
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => { onClickDownload(doc?.download_url, doc?.file_name) }}
                                                                                        className="bg-green-600 px-2 rounded-lg ml-1 md:ml-2 items-center justify-center h-4 md:h-4"
                                                                                    >
                                                                                        <h2 style={{ fontSize: 9 }} className="text-xs text-white font-semibold text-center">{"Download"}</h2>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <hr className="border-gray-200 w-full mt-2" />
                                                                        </div>
                                                                    ))}
                                                                </div>

                                                            }
                                                        </div>)) :
                                                        <div className="flex flex-row pt-5 pb-5 justify-center items-center">
                                                            <h2 className="md:text-lg text-black dark:text-white">{noDataFoundError}</h2>
                                                        </div> :
                                                    <div>
                                                        {activeSubTab === 2 && groupedFavouritesByFolderId?.length > 0 ?
                                                            groupedFavouritesByFolderId?.map((item, index) => (
                                                                <div key={item?.id} className="mt-4 grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 rounded-md bg-white dark:bg-neutral-900 shadow py-2 px-2 sm:px-4">
                                                                    <h2 className="text-lg font-bold flex justify-between">
                                                                        {item?.[0]?.folder_name}
                                                                        <button onClick={() => {
                                                                            const newActiveItems = [...isActive];
                                                                            newActiveItems[index] = !newActiveItems[index];
                                                                            setIsActive(newActiveItems);
                                                                        }}>
                                                                            <FontAwesomeIcon className="w-5 h-5 pb-2 text-gray-400 dark:text-gray-600 pt-2" icon={isActive[index] === true ? faChevronUp : faChevronDown} />
                                                                        </button>
                                                                    </h2>
                                                                    <p className="text-gray-500 text-sm">{item?.length} Documents</p>
                                                                    {isActive[index] === true &&
                                                                        <div>
                                                                            {item?.map(doc => (
                                                                                <div key={doc?.id} className="flex flex-row pt-5 pb-5 justify-between items-center">
                                                                                    <div className="flex flex-row items-center">
                                                                                        <div className="w-10 justify-center items-center text-2xl font-semibold text-gray-600 dark:text-gray-200">
                                                                                            <FontAwesomeIcon height={22} width={22} icon={getFileExtension(doc.file_url)} />
                                                                                        </div>
                                                                                        <h2 className="md:text-lg text-black">{doc.file_name}</h2>
                                                                                    </div>
                                                                                    <div className="flex flex-row">
                                                                                        {doc?.bookmarked_id !== 0 &&
                                                                                            <div className="h-3 md:h-3">
                                                                                                <FontAwesomeIcon icon={faStar} color="#FFD103" height={21} width={21} className="pb-4" />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )) : <div className="flex flex-row pt-5 pb-5 justify-center items-center">
                                                                <h2 className="md:text-lg text-black dark:text-white">{noDataFoundError}</h2>
                                                            </div>}
                                                    </div>}
                                            </div>}
                                        {activeTab === 2 &&
                                            <div className="bg-gray-100  dark:bg-neutral-900">
                                                <div className="container mx-auto px-4 py-8">
                                                    {docVideoSupportData?.getFilesByUserId?.videoFiles.length > 0 ? docVideoSupportData?.getFilesByUserId?.videoFiles?.map(item => (
                                                        <div key={item?.id} className="mt-4 grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 rounded-md bg-white dark:bg-neutral-900 shadow py-2 px-2 sm:px-4">
                                                            <Card
                                                                title={item?.file_name}
                                                                image={item?.thumbnail}
                                                                time={convertMilliSecondToHrs(item?.duration)}
                                                                buttonLabel="Download"
                                                                critical={item?.auto_download}
                                                                download_url={item?.download_url}
                                                                NewVideo={checkIsNewFile(item?.updated_at) && (item.notify_user)}
                                                            />
                                                        </div>
                                                    )) :
                                                        <div className="flex flex-row pt-5 pb-5 justify-center items-center">
                                                            <h2 className="md:text-lg text-black dark:text-white">{noDataFoundError}</h2>
                                                        </div>}
                                                </div>
                                            </div>
                                        }
                                        {activeTab === 3 &&
                                            <div className="bg-gray-100 dark:bg-neutral-900">
                                                <div className="container mx-auto mt-4 grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 rounded-md bg-white dark:bg-neutral-900 shadow py-2 px-2 sm:px-4">
                                                    {docVideoSupportData?.getFilesByUserId?.supportFiles?.length > 0 ? docVideoSupportData?.getFilesByUserId?.supportFiles?.map(supportDoc => (
                                                        <div key={supportDoc?.id} className="mb-2">
                                                            <div className="flex flex-row justify-between items-center">
                                                                <div className="flex flex-row items-center">
                                                                    <div className="w-10 justify-center items-center">
                                                                        <FontAwesomeIcon height={22} width={22} icon={getFileExtension(supportDoc.file_url)} />
                                                                    </div>
                                                                    <h2 className="md:text-lg text-black dark:text-white">{supportDoc.file_name}</h2>
                                                                </div>
                                                                <div className="flex flex-row items-center">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => { onClickDownload(supportDoc?.download_url, supportDoc?.file_name) }}
                                                                        className="bg-green-600 px-2 rounded-lg ml-1 md:ml-2 items-center justify-center h-4 md:h-4"
                                                                    >
                                                                        <h2 style={{ fontSize: 9 }} className="text-xs text-white font-semibold text-center">{"Download"}</h2>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <hr /> </div>
                                                        </div>
                                                    )) : <div className="flex flex-row pt-5 pb-5 justify-center items-center">
                                                        <h2 className="md:text-lg text-black dark:text-white">{noDataFoundError}</h2>
                                                    </div>}
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>}
        </PageWrapper>
    );
};

export default UserImpersonate;