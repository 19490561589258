import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = ({ label, value, checked, onChange }) => {
    return (
      <div className="flex items-center gap-x-3">
        <input type="radio"
          value={value}
          checked={checked}
          onChange={onChange}
          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
        <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">{label}</label>
      </div>
    );
  };

RadioButton.propTypes = {
  label: PropTypes.any,
  checked: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
}
  
  export default RadioButton;