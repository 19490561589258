import React, { useEffect, useState } from "react";
import { useUserInfoListCreateMutation, useGetAllUserInfoListQuery } from "../../../graphql/generated/graphql.ts";
import Button from '../../../components/button/Button';
import { toast } from "react-toastify";
import PageWrapper from "../../../layouts/PageWrapper";
import { Link, useNavigate } from 'react-router-dom';
import {
    useCSVReader,
} from 'react-papaparse';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'

const BulkUploadUser = () => {
    const { CSVReader } = useCSVReader();
    const navigate = useNavigate();
    const [userFailedList, setUserFailedList] = useState([]);
    const [uploadedUser, setUploadedUser] = useState([]);
    const [bulkMutation, { loading }] = useUserInfoListCreateMutation();
    const { data: userData, loading:loader } = useGetAllUserInfoListQuery({ variables: {}, });

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Users | Bulk Upload User`;
        document.title = pageTitle;
    }, []);


    //download the sample file for upload csv. 
    const handleDownloadSampleFile = () => {
        let stringToDownload = "First Name,Last Name,Email Id,Role Names,Group Names,Line Of Business,Job Title\n";
        stringToDownload = `${stringToDownload}test,test,test@shell.com,testUser;testUser2,testGroup;testGroup2,testIRC2,TestSD2`
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:attachment/text,' + encodeURI(stringToDownload);
        hiddenElement.target = '_blank';
        hiddenElement.download = `Sample file.csv`
        hiddenElement.click();
    };

    //handle file file uploaded data.
    const handleFileUpload = fileData => {
        const indexValue = 2;
        try {
            const normalizedData = normalizeData(fileData);
            const columns = getColumns(normalizedData);
            const rows = getRows(normalizedData, columns);
            const existData = [];
            const mappingData = mapData(rows, existData, indexValue);
            setUserFailedList(mappingData);
        } catch (err) {
            toast.error("Invalid CSV file");
        }
    };

    const normalizeData = data => {
        return data?.data?.map(rowData => rowData?.map(cell => cell.replace(/\r$/, '')))
            .filter(rowItem => rowItem.length > 1);
    };

    const getColumns = normalizedData => {
        const HeaderNameChanges = {
            FirstName: "givenName",
            LastName: "familyName",
            EmailId: "uniqueName",
            RoleNames: "roleName",
            GroupNames: "groupNames",
            LineOfBusiness: "lineOfBusiness",
            JobTitle: "jobTitle"
        };

        return normalizedData?.[0]?.map(col => {
            let colValue = col.replace(/\s+/g, '');
            colValue = colValue.replace(/\b(?:FirstName|LastName|EmailId|RoleNames|GroupNames|LineOfBusiness|JobTitle)\b/gi, item => HeaderNameChanges[item]);
            return { Headers: colValue };
        });
    };

    const getRows = (normalizedData, columns) => {
        return normalizedData?.slice(1)?.map(rowData => {
            return rowData?.reduce((acc, curr, index) => {
                acc[columns[index].Headers] = curr;
                return acc;
            }, {});
        });
    };

    const mapData = (rows, existData, indexValue) => {
        return rows?.filter((element, index) => {
            const { allRoleNamesExist, allGroupNamesExist } = getUserData(element);
            const errorDescription = getErrorDescription(element, existData, allRoleNamesExist, allGroupNamesExist);

            if (errorDescription) {
                Object.assign(element, { description: `${index + indexValue} - ${errorDescription}` });
                return element;
            } else {
                setUploadedUser(addedUser => [...addedUser, element]);
                return false;
            }
        });
    };

    const getUserData = element => {
        const userRoleNames = element?.roleName?.split(/[;,]/)?.map(role => role?.trim()) || null;
        const userRoles = userData?.getAllRoles?.filter(role => userRoleNames?.includes(role.roleName));
        const userGroupNames = element?.groupNames?.split(/[;,]/).map(group => group.trim()) || null;
        const userGroup = userData?.getAllGroups?.filter(group => userGroupNames?.includes(group.groupName));
        const allRoleNamesExist = userRoleNames?.every(roleName => userRoles?.some(userRole => userRole?.roleName === roleName) || "");
        const allGroupNamesExist = userGroupNames?.every(groupName => userGroup?.some(groupData => groupData?.groupName === groupName) || "");

        return { userRoleNames, userGroupNames, allRoleNamesExist, allGroupNamesExist };
    };

    const getErrorDescription = (element, existData, allRoleNamesExist, allGroupNamesExist) => {
        let errorDescription = '';

        errorDescription += checkEmptyField(element.givenName, "FirstName can't be empty");
        errorDescription += checkEmptyField(element.familyName, "LastName can't be empty");
        errorDescription += checkEmail(element.uniqueName);
        errorDescription += checkExistence(element.uniqueName, existData, "Shell Email Id already exist");
        errorDescription += checkRoleName(element.roleName, allRoleNamesExist);
        errorDescription += checkGroupName(element.groupNames, allGroupNamesExist);

        if (!errorDescription) {
            existData.push(`${element.uniqueName}`);
        }

        return errorDescription;
    };

    const checkEmptyField = (field, message) =>  field === '' ? `${message} & ` : '';

    const checkEmail = email => !email?.toLowerCase().endsWith('@shell.com') ? "Please Provide Shell Email Id & " : '';

    const checkExistence = (uniqueName, existData, message) =>  existData.includes(`${uniqueName}`) ? `${message} & ` : '';

    const checkRoleName = (roleName, allRoleNamesExist) => {
        if (roleName === "") {
            return "Please Provide valid role name & ";
        } else if (!allRoleNamesExist) {
            return "Role name not found. & ";
        }
        return '';
    };

    const checkGroupName = (groupNames, allGroupNamesExist) => {
        if (groupNames === "") {
            return "Please Provide valid group name & ";
        } else if (!allGroupNamesExist) {
            return "Group name not found. & ";
        }
        return '';
    };


    //handle upload csv data. 
    const handleUploadCsv = () => { //try and catch.
        const userList = uploadedUser?.map(item => { //NOSONAR
            const userRoleNames = item?.roleName?.split(/[;,]/).map(role => role.trim());
            const userRoles = userData?.getAllRoles.filter(role => userRoleNames?.includes(role.roleName));
            const userGroupNames = item?.groupNames?.split(/[;,]/).map(group => group.trim());
            const userGroup = userData?.getAllGroups?.filter(group => userGroupNames?.includes(group.groupName));
            const roleIds = userRoles.map(role => role.id);
            const groupIds = userGroup.map(group => group.id);

            if (roleIds.length > 0 && groupIds.length > 0) {
                return {
                    familyName: item.familyName,
                    givenName: item.givenName,
                    uniqueName: item.uniqueName,
                    roleIds,
                    groupIds,
                    lineOfBusiness: item?.lineOfBusiness || "",
                    jobTitle: item?.jobTitle || ""
                };
            }
        }).filter(Boolean); // Filter out null values
        if (userList?.length === 0) {
            return; // No valid data to upload, handle accordingly
        }
        bulkMutation({ variables: { userInfoCreate: userList } })
            .then(() => {
                toast.success(`${userList.length} users added successfully.`)
                navigate('/manage/users');
            })
            .catch(error => {
                toast.error(error.message.replace(GRAPHQL_ERROR_PREFIX, ""));
            });
    };

    //handle remove csv file.
    const handleRemoveCsv = () => {
        setUserFailedList('');
        setUploadedUser([])
    };

    //handle download if data has failed to upload user. 
    const handleDownloadFailedUser = () => {
        let stringToDownload = "First Name,Last Name,Email Id,Role Names,Group Names,Line Of Business,Job Title,Descriptions \n";
        userFailedList.forEach(user => {
            const roleName = user?.roleName?.includes(',') ? `"${user.roleName}"` : user.roleName;
            const groupName = user?.groupNames?.includes(",") ? `"${user.groupNames}"` : user.groupNames;
            stringToDownload = `${stringToDownload}${user.givenName},${user.familyName},${user.uniqueName},${roleName},${groupName},${user.lineOfBusiness},${user.jobTitle},${user.description} \n`
        });

        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:attachment/text,' + encodeURI(stringToDownload);
        hiddenElement.target = '_blank';
        const date = new Date();
        hiddenElement.download = `failed_UserList_ ${date.toString()} .csv`
        hiddenElement.click();
    };

    //handle error for csv uploaded data. 
    const validateUser = val => {
        const { givenName, familyName, uniqueName, groupName, roleName } = val;
        return [givenName, familyName, uniqueName, groupName, roleName].some(value => !value);
    };

    const errorMsgValue = Array.isArray(userFailedList)
        ? userFailedList.filter(validateUser)
        : [];

    const errorMsgEmail = Array.isArray(userFailedList)
        ? userFailedList.filter(val => {
            const uniqueName = val?.uniqueName?.toLowerCase();
            return !uniqueName?.endsWith('@shell.com');
        })
        : [];

    const emailError = Array.isArray(userFailedList)
        ? userFailedList.filter(val => val?.emailError)
        : [];

    const errorMsgRole = Array.isArray(userFailedList)
        ? userFailedList.filter(val => !val?.roleName || val?.roleError)
        : [];

    const errorMsgGroup = Array.isArray(userFailedList)
        ? userFailedList.filter(val => !val?.groupName || val?.groupError)
        : [];

    return (
        <PageWrapper heading={'Upload bulk user'}>
            <div className="py-6">
                <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                    <div className="col-span-4">
                        <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">Create Multiple Users</h2>
                        <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Upload the CSV template to create users.</p>
                    </div>
                    <div className="col-span-8">
                        <CSVReader
                            onUploadAccepted={results => {
                                handleFileUpload(results);
                            }}
                        >
                            {({
                                getRootProps,
                                acceptedFile,
                                ProgressBar,
                                getRemoveFileProps,
                            }) => (
                                <>
                                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                                        <p className="text-gray-600 dark:text-gray-400 text-sm">You can quickly create new users by using a .csv file. You can upload the file with set of columns defined with right data points. There is no limit to how many users you can add in the file as long as it has 1 record per row.</p>
                                        <button className="cursor-pointer" onClick={handleDownloadSampleFile}>
                                            <p className="leading-8 text-blue-600 dark:text-blue-400 text-sm underline">Click here to download the .csv template</p>
                                        </button>
                                        {/* <p className="text-gray-600 dark:text-gray-400 text-sm underline">Download the csv template</p> */}
                                        <div className="flex">
                                            <div className="mr-2">
                                                <Button
                                                    type="button"
                                                    {...getRootProps()}
                                                    text="Upload CSV Template"
                                                    loader={loader}
                                                    disabled={loader}
                                                    className="flex items-center justify-center px-2 py-2 text-sm font-small text-gray-700 transition-colors duration-200 bg-white border rounded-lg dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            {acceptedFile?.name &&
                                                <div className="flex items-center justify-between p-2 bg-gray-100 dark:bg-neutral-700 rounded-md">
                                                    <span>{acceptedFile?.name}</span>
                                                    <Button
                                                        {...getRemoveFileProps()}
                                                        onClick={event => {
                                                            getRemoveFileProps().onClick(event);
                                                            handleRemoveCsv();
                                                        }}
                                                        text="Remove"
                                                        className="text-red-600 hover:text-red-400"
                                                    />
                                                </div>}
                                        </div>
                                    </div>
                                    <ProgressBar className="bg-red-500" />
                                </>
                            )}
                        </CSVReader>
                        {userFailedList?.length >= 1 &&
                            <>
                                <br />
                                <div className="p-3 mb-3 bg-red-100 border border-red-600 rounded text-base text-red-700" role="alert">
                                    <p><strong>Whoops!</strong> There were some problems with your input.</p>
                                    <ul>
                                        <li><strong>{userFailedList?.length}</strong> - User not uploaded.</li>
                                        {!errorMsgValue?.length ? '' : <li>All fields are required.</li>}
                                        {!emailError?.length ? '' : <li>Email does not found</li>}
                                        {!errorMsgEmail?.length ? '' : <li>Email should be a valid Shell email address.</li>}
                                        {!errorMsgRole?.length ? "" : <li>Please enter valid role name</li>}
                                        {!errorMsgGroup?.length ? "" : <li>Please enter valid group name</li>}

                                    </ul><div className="border-b border-red-500 mt-10 mb-14" />
                                    <Button type='button' onClick={handleDownloadFailedUser}
                                        text="Download">
                                    </Button>
                                </div>
                            </>
                        }
                        <div className="mt-10 flex justify-center">
                            < Button
                                type="button"
                                className={!uploadedUser?.length || loading ? "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2" : "inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"}
                                onClick={handleUploadCsv}
                                text="Upload"
                            />
                            <Link to='/manage/users'
                                className="ml-2 block rounded-md bg-gray-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                            {loading && <FontAwesomeIcon className="w-5 h-5 mr-4 ml-2 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                        </div>
                    </div>
                </div>
            </div>
        </PageWrapper>

    )
}

export default BulkUploadUser;