import { IS_NOT_EMPTY } from "../utils/constant";
import * as yup from "yup"; {/* NOSONAR */ }

const minValue = 2;
const maxValue = 50;
const userMinValue = 1;
const notificationMaxValue = 500;
const folderMessage = "Please select a folder";
const htmlBodyValidation = 'HTML body is required';
const notEmptyValidation = 'not-empty-p';
const emptyHtmlValidation = 'HTML body cannot be empty';
const EMAIL_DOMAIN = '@shell.com';

export const formCreateNewFolderSchema = yup.object().shape({
    folder_name: yup.string()
        .required("Please enter folder name")
        .matches(/^[a-zA-Z0-9\s&()_-]+$/, 'Folder name should only contain letters, numbers, &, _, (, ), and -')
        .min(minValue, 'Folder name must be at least 2 characters')
        .max(maxValue, 'Folder name must be at most 50 characters'),
    folder_path: yup.string()
        .required("Please enter folder path")
        .min(minValue, 'Folder path must be at least 2 characters')
        .max(maxValue, 'Folder path must be at most 50 characters'),
})

export const formCreateNewUserSchema = yup.object().shape({
    first_name: yup.string()
        .required("Please enter first name")
        .min(userMinValue, 'First name must be at least 1 character')
        .max(maxValue, 'First name must be at most 50 characters')
        .matches(/^[A-Za-z\d -]+(?:[A-Za-z\d -]+)*$/, 'First name must only contain string,- and numbers'),
    last_name: yup.string()
        .required("Please enter last name")
        .min(userMinValue, 'Last name must be at least 1 character')
        .max(maxValue, 'Last name must be at most 50 characters')
        .matches(/^[A-Za-z\d -]+(?:[A-Za-z\d -]+)*$/, 'Last name must only contain string,- and numbers'),
    email: yup.string().email()
        .required("Please enter email address")
        .test('is-shell', 'Email must end with @shell.com', value => { {/* NOSONAR */ }
            if (value) {
                return value.endsWith(EMAIL_DOMAIN);
            }
            return true; // Allow empty email
        }),
    roles: yup.array().test(IS_NOT_EMPTY, 'Roles must have at least one element', function (value) {
        if (value) {
            return value.length > 0;
        }
        return false;
    })
        .min(userMinValue, 'Please select at least one role').nullable(),
    groups: yup.array().test(IS_NOT_EMPTY, 'Groups must have at least one element', function (value) {
        if (value) {
            return value.length > 0;
        }
        return false;
    })
        .min(userMinValue, 'Please select at least one group').nullable(),
    is_agreed: yup.boolean().oneOf([true], 'Please accept the terms & conditions')
})


export const formCreateNewDocumentSchema = yup.object().shape({
    sensitivityLabel: yup.object().nullable().required('Please select a sensitivity'),
    folder: yup.object().nullable().required(folderMessage),
})
export const formCreateEditDocumentSchema = yup.object().shape({
    sensitivityLabel: yup.object().nullable().required('Please select a sensitivity'),
    folder: yup.object().nullable().required(folderMessage),
    document_name: yup.string().required("Please enter document name")
})

export const formCreateNewVideoSchema = yup.object().shape({
    video_name: yup.string().required("Please enter video name"),
    folder: yup.object().nullable().required(folderMessage),
})

export const formCreateNewGroupSchema = yup.object().shape({
    group_name: yup.string().required("Please enter valid group name"),
})

export const formCreateNeUserGroupSchema = yup.object().shape({
    user_name: yup.string()
        .required('Username is required') // Username is required
})


export const formCreateScheduleNotificationSchema = yup.object().shape({
    selectedTriggerDate: yup
        .date()
        .required('Scheduled date is required')
});


export const formCreateNewNotificationSchema = yup.object().shape({
    title: yup.string().required("Please enter title"),
    description: yup.string().required("Please enter description"),
    message: yup.object(),
    message_html: yup
        .string().required(htmlBodyValidation)
        .test(notEmptyValidation, emptyHtmlValidation, value =>
            typeof value === 'string' && value.trim() !== '<p></p>'),
})

export const formCreateNewAppSettingSchema = yup.object().shape({
    fileSize: yup.number().required("Please enter file size")
        .max(notificationMaxValue, "File size cannot exceed 500MB"),
})

export const formCreateDuoCircleSchema = yup.object().shape({
    recipient_to_email_input: yup.string()
        .test('is-shell', 'Email must end with @shell.com', value => {  {/* NOSONAR */ }
            if (value) {
                const emails = value.split(';').map(email => email.trim());
                return emails.every(email => {
                    // Check if each email ends with '@shell.com'
                    if (email.endsWith(EMAIL_DOMAIN)) {
                        return true;
                    }
                });
            }
            return true; // Allow empty email
        }),
    recipient_cc_email_input: yup.string()
        .test('is-shell', 'Email must end with @shell.com', value => {  {/* NOSONAR */ }
            if (value) {
                const emails = value.split(';').map(email => email.trim());
                return emails.every(email => {
                    // Check if each email ends with '@shell.com'
                    if (email.endsWith(EMAIL_DOMAIN)) {  
                        return true;
                    }
                });
            }
            return true; // Allow empty email
        }),
    recipient_bcc_email_input: yup.string()
        .test('is-shell', 'Email must end with @shell.com', value => {    {/* NOSONAR */ }
            if (value) {
                const emails = value.split(';').map(email => email.trim());
                return emails.every(email => {
                    // Check if each email ends with '@shell.com'
                    if (email.endsWith(EMAIL_DOMAIN)) { 
                        return true;
                    }
                });
            }
            return true; // Allow empty email
        }),
})

export const formCreateHomeScreenSchema = yup.object().shape({
    message: yup.object(),
    message_html: yup.string().required(htmlBodyValidation).test(notEmptyValidation, emptyHtmlValidation, value =>
        typeof value === 'string' && value.trim() !== '<p></p>'),
})
export const formCreateStaticScreenSchema = yup.object().shape({
    message: yup.object(),
    message_html: yup.string().required(htmlBodyValidation).test(notEmptyValidation, emptyHtmlValidation, value =>
        typeof value === 'string' && value.trim() !== '<p></p>'),
    title: yup.string().required("Please enter valid title"),
    slug: yup.string().required("Please enter valid slug"),
})

export const formUpdateSystemConfigSchema = yup.object().shape({
    primaryGoc: yup.string().required("Please enter Asias GOC Number"),
    secondaryGoc: yup.string().required("Please enter Europes GOC Number"),
    tertiaryGoc: yup.string().required("Please enter Americas GOC Number"),
    emailId: yup.string().email()
        .required("Please enter Support Email Id")
        .test('is-shell', 'Email must end with @shell.com', value => {   {/* NOSONAR */ }
            if (value) {
                return value.endsWith(EMAIL_DOMAIN);
            }
            return true; // Allow empty email
        }),
    subject: yup.string().required("Please enter Support Email Subject"),
    versionNumber: yup.string().required("Please enter IOS Current Version"),
    buildNumber: yup.string().required("Please enter IOS Build Code"),
})

export const formRolePermissionsSchema = yup.object().shape({
    roleName: yup.string()
        .required("Please enter role name")
        .min(minValue, 'Role name must be at least 2 characters')
        .max(notificationMaxValue, 'Role name must be at most 500 characters'),
    permissionName: yup.array().test(IS_NOT_EMPTY, 'Roles must have at least one element', function (value) {
        if (value) {
            return value.length > 0;
        }
        return false;
    })
        .min(1, 'Please select at least one permission').nullable(),
})