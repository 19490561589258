import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client'; {/* NOSONAR */ }
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type ApiPermissionsMap = {
  __typename?: 'APIPermissionsMap';
  accessType?: Maybe<Scalars['String']['output']>;
  assignedPermissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type AirShipChannelsInfo = {
  __typename?: 'AirShipChannelsInfo';
  channelsInfo?: Maybe<Scalars['String']['output']>;
};

export type AirshipDeviceMetadata = {
  __typename?: 'AirshipDeviceMetadata';
  channel_id?: Maybe<Scalars['String']['output']>;
  device_registered?: Maybe<Scalars['Boolean']['output']>;
  device_type?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
};

export type AirshipDeviceMetadataInput = {
  channel_id: Scalars['String']['input'];
  device_type: Scalars['String']['input'];
  tag: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
};

export type AppVersionInputObj = {
  ios?: InputMaybe<Scalars['String']['input']>;
};

export type AppVersionObj = {
  __typename?: 'AppVersionObj';
  ios?: Maybe<Scalars['String']['output']>;
};

export type ArchivedUsersList = {
  __typename?: 'ArchivedUsersList';
  archivedUsersList?: Maybe<Array<Maybe<UserInfoForAdmin>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AuditLogCronJobList = {
  __typename?: 'AuditLogCronJobList';
  cronAuditLogs?: Maybe<Array<Maybe<CronJobListByName>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AuditLogPages = {
  __typename?: 'AuditLogPages';
  auditLogsForAdmin?: Maybe<Array<Maybe<UserAuditLogs>>>;
  pageInfo?: Maybe<PageInfo>;
};

/** Bookmark object */
export type Bookmark = {
  __typename?: 'Bookmark';
  created_at?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** Bookmark object response */
export type BookmarkRespoonse = {
  __typename?: 'BookmarkRespoonse';
  created_at?: Maybe<Scalars['Float']['output']>;
  file_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

export type CronJob = {
  __typename?: 'CronJob';
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  cronjobname?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  lastrunat?: Maybe<Scalars['Float']['output']>;
  lastrunstatus?: Maybe<Scalars['String']['output']>;
  nextrunat?: Maybe<Scalars['Float']['output']>;
  rows_affected?: Maybe<Scalars['Int']['output']>;
  uniqueId?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type CronJobList = {
  __typename?: 'CronJobList';
  cronJobList?: Maybe<Array<Maybe<CronJob>>>;
  pageInfo?: Maybe<PageInfo>;
};

/** CronJob object */
export type CronJobListByName = {
  __typename?: 'CronJobListByName';
  action?: Maybe<Scalars['String']['output']>;
  actorEmail?: Maybe<Scalars['String']['output']>;
  endTimestamp?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  recordPrimaryKey?: Maybe<Scalars['String']['output']>;
  rowsAffected?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tableName?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Float']['output']>;
};

export type Documents = {
  __typename?: 'Documents';
  documentName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type DocumentsByGroupIdList = {
  __typename?: 'DocumentsByGroupIdList';
  documentsByGroupIdList?: Maybe<Array<Maybe<DocumentsList>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type DocumentsDownload = {
  __typename?: 'DocumentsDownload';
  downloadURL?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** Documents Folder object */
export type DocumentsFolder = {
  __typename?: 'DocumentsFolder';
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  display_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  notify_user?: Maybe<Scalars['Boolean']['output']>;
  storage_path?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** Document object */
export type DocumentsList = {
  __typename?: 'DocumentsList';
  documentName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

/** email notification object response */
export type EmailNotification = {
  __typename?: 'EmailNotification';
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['Float']['output']>;
  deleted_by?: Maybe<Scalars['String']['output']>;
  notification_name?: Maybe<Scalars['String']['output']>;
  recipient_bcc?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  recipient_cc?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  recipient_to?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sender?: Maybe<Scalars['String']['output']>;
  sender_name?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  uniqueId?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type EmailService = {
  __typename?: 'EmailService';
  response?: Maybe<Scalars['String']['output']>;
};

/** feeback and rating object response */
export type FeedBackAndRating = {
  __typename?: 'FeedBackAndRating';
  app_version?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  next_trigger_at?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['Int']['output']>;
};

/** FeedbackAndRating object */
export type FeedbackAndRating = {
  __typename?: 'FeedbackAndRating';
  app_version?: Maybe<Scalars['String']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  next_trigger_at?: Maybe<Scalars['Float']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type FeedbackAndRatingList = {
  __typename?: 'FeedbackAndRatingList';
  feedbackAndRatingList?: Maybe<Array<Maybe<FeedBackAndRating>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type FileDownloadMetadata = {
  checksum?: InputMaybe<Scalars['String']['input']>;
  fileURL?: InputMaybe<Scalars['String']['input']>;
  tenantName?: InputMaybe<Scalars['String']['input']>;
};

export type FileMetadata = {
  __typename?: 'FileMetadata';
  auto_download?: Maybe<Scalars['Boolean']['output']>;
  bookmarkedId?: Maybe<Scalars['Int']['output']>;
  checksum?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['Float']['output']>;
  downloadURL?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  filePath?: Maybe<Scalars['String']['output']>;
  fileURL?: Maybe<Scalars['String']['output']>;
  file_bytes?: Maybe<Scalars['Float']['output']>;
  folder_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_support?: Maybe<Scalars['Boolean']['output']>;
  is_video?: Maybe<Scalars['Boolean']['output']>;
  notify_user?: Maybe<Scalars['Boolean']['output']>;
  roleId?: Maybe<Scalars['Int']['output']>;
  roleName?: Maybe<Scalars['String']['output']>;
  section?: Maybe<Scalars['String']['output']>;
  sectionOrder?: Maybe<Scalars['Int']['output']>;
  sensitivity_label?: Maybe<Scalars['String']['output']>;
  tenantName?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
};

export type FileMetadataAdminForm = {
  __typename?: 'FileMetadataAdminForm';
  auto_download?: Maybe<Scalars['Boolean']['output']>;
  checksum?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['Float']['output']>;
  downloadURL?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  filePath?: Maybe<Scalars['String']['output']>;
  fileURL?: Maybe<Scalars['String']['output']>;
  file_bytes?: Maybe<Scalars['Float']['output']>;
  folder_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_support?: Maybe<Scalars['Boolean']['output']>;
  is_video?: Maybe<Scalars['Boolean']['output']>;
  notify_user?: Maybe<Scalars['Boolean']['output']>;
  roles?: Maybe<Array<Maybe<UserRoles>>>;
  section?: Maybe<Scalars['String']['output']>;
  sectionOrder?: Maybe<Scalars['String']['output']>;
  sensitivity_label?: Maybe<Scalars['String']['output']>;
  tenantName?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
};

export type FileMetadataList = {
  __typename?: 'FileMetadataList';
  fileMetadataList?: Maybe<Array<Maybe<FileMetadataAdminForm>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type FileMetadataResponse = {
  __typename?: 'FileMetadataResponse';
  deletedFilesUrl?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  filesMetadata?: Maybe<Array<Maybe<FileMetadata>>>;
};

export type FileUploadSettingsObj = {
  __typename?: 'FileUploadSettingsObj';
  maxUploadSize?: Maybe<MaxUploadSizeObj>;
};

export type FolderByGroupIdList = {
  __typename?: 'FolderByGroupIdList';
  foldersByGroupIdList?: Maybe<Array<Maybe<FoldersList>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type FolderList = {
  __typename?: 'FolderList';
  folderList?: Maybe<Array<Maybe<DocumentsFolder>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Folders = {
  __typename?: 'Folders';
  folderName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

/** Folder object */
export type FoldersList = {
  __typename?: 'FoldersList';
  folderName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type GraphFilesMetadata = {
  __typename?: 'GraphFilesMetadata';
  auto_download?: Maybe<Scalars['Boolean']['output']>;
  bookmarked_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['Float']['output']>;
  download_url?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  file_bytes?: Maybe<Scalars['Float']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  file_url?: Maybe<Scalars['String']['output']>;
  folder_id?: Maybe<Scalars['String']['output']>;
  folder_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_video?: Maybe<Scalars['Boolean']['output']>;
  notify_user?: Maybe<Scalars['Boolean']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
};

export type GroupList = {
  __typename?: 'GroupList';
  groupList?: Maybe<Array<Maybe<GroupSectionsDocsResponse>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Groups = {
  __typename?: 'Groups';
  description?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type GroupsUser = {
  __typename?: 'GroupsUser';
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

/** Home Screen Representation Object */
export type HomeScreen = {
  __typename?: 'HomeScreen';
  adminContent?: Maybe<Scalars['String']['output']>;
  mobileContent?: Maybe<Scalars['String']['output']>;
};

/** Home Screen Representation Object */
export type HomeScreenOld = {
  __typename?: 'HomeScreenOld';
  content?: Maybe<Scalars['String']['output']>;
};

export type ListOfFilesResponse = {
  __typename?: 'ListOfFilesResponse';
  docFiles?: Maybe<Array<Maybe<GraphFilesMetadata>>>;
  supportFiles?: Maybe<Array<Maybe<GraphFilesMetadata>>>;
  videoFiles?: Maybe<Array<Maybe<GraphFilesMetadata>>>;
};

export type MaxUploadSizeInputObj = {
  KB?: InputMaybe<Scalars['String']['input']>;
};

export type MaxUploadSizeObj = {
  __typename?: 'MaxUploadSizeObj';
  KB?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Delete FileMetadata By File URL */
  addUserToGroup?: Maybe<Array<Maybe<UserGroup>>>;
  /** Create Bookmark mutation */
  createBookmarkMutation?: Maybe<Bookmark>;
  /** Create Documents Folder */
  createDocumentsFolder?: Maybe<DocumentsFolder>;
  /** Create Feedback and Rating mutation */
  createFeedbackAndRatingMutation?: Maybe<FeedbackAndRating>;
  /** Create Feedback and Rating mutation */
  createFeedbackAndRatingMutationV1?: Maybe<FeedbackAndRating>;
  /** Creating FileMetadata */
  createFileMutation?: Maybe<Array<Maybe<FileMetadata>>>;
  /** Toggle cronJob list */
  cronJobListToggle?: Maybe<Array<Maybe<CronJob>>>;
  /** De-Register Airship Device */
  deRegisterAirshipDeviceMutation?: Maybe<AirshipDeviceMetadata>;
  /** Deleting fileMetadata In Bulk By File URL */
  deleteBulkFileMetadataByUrl?: Maybe<Array<Maybe<FileMetadata>>>;
  /** Delete Documents Folder by unique folder ID */
  deleteDocumentsFolder?: Maybe<Array<Maybe<DocumentsFolder>>>;
  /** delete feedback and rating mutation */
  deleteFeedbackAndRatingMutation?: Maybe<Array<Maybe<FeedBackAndRating>>>;
  /** Delete FileMetadata By File URL */
  deleteMutationForFileMetadata?: Maybe<FileMetadata>;
  /** Deleting multiple notification schedules By Id */
  deleteNotificationSchedulesById?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Deleting multiple notifications By Id */
  deleteNotificationsById?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Deleting multiple rolePermissions By roleId */
  deleteRolePermissionsByRoleId?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Save Static page mutation */
  deleteStaticPage?: Maybe<StaticPage>;
  /** Deleting multiple UserInfos By Id */
  deleteUserInfosById?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Deleting multiple groupSectionsDocs By groupId */
  deletegroupSectionsDocsByGroupId?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** delete Bookmarked record mutation */
  deletetBookmarkedRecordMutation?: Maybe<BookmarkRespoonse>;
  /** Download All Notification Response */
  downloadUserNotificationResponse?: Maybe<Array<Maybe<NotificationRespForAdmin>>>;
  /** Enable or disable email */
  emailEnableDisable?: Maybe<EmailService>;
  /** Get User Statuses from AAD */
  getAADUserStatusByEmailMutation?: Maybe<GetAadUserStatusByEmailMutation>;
  /** Get All actor and action */
  getAllActionsAndActors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get page-wise Archived UsersInfo List */
  getAllArchivedUserPages?: Maybe<ArchivedUsersList>;
  /** Get All Cron Job */
  getAllCronJobPages?: Maybe<CronJobList>;
  /** fetch page-wise folder */
  getAllFolders?: Maybe<FolderList>;
  /** fetch page-wise group */
  getAllGroupSectionsDocsPages?: Maybe<GroupList>;
  /** Get All Notification Schedules List */
  getAllNotificationSchedulePages?: Maybe<NotificationScheduleList>;
  /** Get page wise Notifications List */
  getAllNotificationsPages?: Maybe<NotificationList>;
  /** Get all permissions */
  getAllPermissionsPages?: Maybe<PermissionList>;
  /** fetch page-wise feedback and rating */
  getAllRolePermissionsPages?: Maybe<RolePermissionList>;
  /** get static page */
  getAllStaticPage?: Maybe<StaticPageList>;
  /** fetch page-wise audit logs */
  getAuditLogPagesMutation?: Maybe<AuditLogPages>;
  /** Get cron job response */
  getCronJobAuditLog?: Maybe<AuditLogCronJobList>;
  /** Get cron job response */
  getCronJobAuditLogDownloadReport?: Maybe<Array<Maybe<CronJobListByName>>>;
  /** Get page-wise Video Metadata for Admin Forms */
  getDocMetadataForAdminPages?: Maybe<FileMetadataList>;
  /** Get file downloadUrl by ids */
  getDocumentDownloadUrlByIds?: Maybe<Array<Maybe<DocumentsDownload>>>;
  /** Get docFolder details */
  getDocumentsByFolderIdPages?: Maybe<FileMetadataList>;
  /** Get doc details */
  getDocumentsByGroupIdPages?: Maybe<DocumentsByGroupIdList>;
  /** Get folder details by id */
  getDocumentsFolderById?: Maybe<DocumentsFolder>;
  /** fetch page-wise feedback and rating */
  getFeedbackAndRatingPages?: Maybe<FeedbackAndRatingList>;
  /** Get doc details */
  getFilesByUserId?: Maybe<ListOfFilesResponse>;
  /** Get folder details */
  getFoldersByGroupIdPages?: Maybe<FolderByGroupIdList>;
  /** Get group details */
  getGroupDetailsById?: Maybe<Array<Maybe<GroupDetailsResponse>>>;
  /** Get Cron Job Details */
  getGroupDetailsByName?: Maybe<CronJob>;
  /** Fetch notification by Id */
  getNotificationById?: Maybe<NotificationInfoForAdmin>;
  /** Get notification details by id */
  getNotificationDetailsById?: Maybe<NotificationDetails>;
  /** get static page */
  getStaticPageById?: Maybe<StaticPage>;
  /** Get user details by id mutation */
  getUserDetailsById?: Maybe<UserDeatilsForAdmin>;
  /** Get user details by id mutation */
  getUserInfoById?: Maybe<UserDeatilsForAdmin>;
  /** fetch page-wise userInfo */
  getUserInfoPagesMutation?: Maybe<UserInfoPages>;
  /** Get All Notification Schedules List */
  getUserNotificationResponsePages?: Maybe<UserNotificationResponseList>;
  /** Fetch notification by Id */
  getUserResponseByScheduleId?: Maybe<NotificationInfoForAdmin>;
  /** Get user details */
  getUsersByGroupIdPages?: Maybe<UsersByGroupIdList>;
  /** Get user details */
  getUsersExcludedFromGroup?: Maybe<Array<Maybe<UserInfo>>>;
  /** Get page-wise Video Metadata for Admin Forms */
  getVideosMetadataForAdminPages?: Maybe<VideoMetadataList>;
  /** Creating Group-Sections-Docs Mapping(s) */
  groupSectionsDocsMappingListCreate?: Maybe<Array<Maybe<GroupSectionsDocs>>>;
  /** Updating Group-Sections-Docs Mapping(s) */
  groupSectionsDocsMappingListUpdate?: Maybe<Array<Maybe<GroupSectionsDocs>>>;
  /** Modify System Configs */
  modifyFileUploadSettings?: Maybe<FileUploadSettingsObj>;
  /** Creating notification schedule list */
  notificationScheduleListCreate?: Maybe<Array<Maybe<NotificationScheduleForAdmin>>>;
  /** Updating notification schedule list */
  notificationScheduleListUpdate?: Maybe<Array<Maybe<NotificationScheduleForAdmin>>>;
  /** Creating notifications list */
  notificationsListCreate?: Maybe<Array<Maybe<NotificationInfoForAdmin>>>;
  /** Updating notifications list */
  notificationsListUpdate?: Maybe<Array<Maybe<NotificationInfoForAdmin>>>;
  /** Recovey of User's Archived Records By Id */
  recoverArchivedUsersMutation?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Register Airship Device Metadata */
  registerAirshipDeviceMetadataMutation?: Maybe<AirshipDeviceMetadata>;
  /** Delete FileMetadata By File URL */
  removeUserFromGroup?: Maybe<Array<Maybe<UserGroup>>>;
  /** Creating rolePermission(s) */
  rolePermissionsListCreate?: Maybe<Array<Maybe<RolePermissions>>>;
  /** Updating rolePermission(s) */
  rolePermissionsListUpdate?: Maybe<Array<Maybe<RolePermissions>>>;
  /** Save Static page mutation */
  saveStaticPage?: Maybe<StaticPage>;
  /** Set Home Screen Content */
  setHomeScreen?: Maybe<SetHomeScreenResponse>;
  /** Modify System Configs */
  systemConfigRequestForm?: Maybe<SystemConfigsResponse>;
  /** Update Documents Folder by unique folder ID */
  updateDocumentsFolder?: Maybe<DocumentsFolder>;
  /** Updating notifications list */
  updateEmailNotification?: Maybe<EmailNotification>;
  /** update feedback and rating mutation */
  updateFeedbackAndRatingMutation?: Maybe<FeedbackAndRating>;
  /** update feedback and rating mutation */
  updateFeedbackAndRatingMutationV1?: Maybe<FeedbackAndRating>;
  /** Updating FileMetadata */
  updateMutationForFileMetadata?: Maybe<FileMetadata>;
  /** update next trigger date by id */
  updateNextTriggerDateById?: Maybe<Array<Maybe<FeedBackAndRating>>>;
  /** Updating notifications list */
  updateNotificationResponse?: Maybe<Array<Maybe<NotificationInfoForAdmin>>>;
  /** Save Static page mutation */
  updateStaticPage?: Maybe<StaticPage>;
  /** Creating Thumbnail and Duration FileMetadata */
  updateThumbnailAndDuration?: Maybe<Array<Maybe<FileMetadata>>>;
  /** Creating UserInfo(s) */
  userInfoListCreate?: Maybe<Array<Maybe<UserInfo>>>;
  /** Updating UserInfo(s) */
  userInfoListUpdate?: Maybe<Array<Maybe<UserInfo>>>;
};


export type MutationAddUserToGroupArgs = {
  addUserToGroupArgsList?: InputMaybe<Array<InputMaybe<AddUserToGroupArgs>>>;
};


export type MutationCreateBookmarkMutationArgs = {
  file_url: Scalars['String']['input'];
};


export type MutationCreateDocumentsFolderArgs = {
  display_name: Scalars['String']['input'];
  notify_user?: InputMaybe<Scalars['Boolean']['input']>;
  storage_path: Scalars['String']['input'];
};


export type MutationCreateFeedbackAndRatingMutationArgs = {
  app_version?: InputMaybe<Scalars['String']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateFeedbackAndRatingMutationV1Args = {
  app_version?: InputMaybe<Scalars['String']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateFileMutationArgs = {
  fileURLMetadataList?: InputMaybe<Array<FileMetadata>>;
};


export type MutationCronJobListToggleArgs = {
  cronJobList?: InputMaybe<Array<InputMaybe<CronJobToggle>>>;
};


export type MutationDeRegisterAirshipDeviceMutationArgs = {
  aishipDeviceMetadata?: InputMaybe<AirshipDeviceMetadataInput>;
};


export type MutationDeleteBulkFileMetadataByUrlArgs = {
  fileUrls?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationDeleteDocumentsFolderArgs = {
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationDeleteFeedbackAndRatingMutationArgs = {
  id?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationDeleteMutationForFileMetadataArgs = {
  fileUrl: Scalars['String']['input'];
};


export type MutationDeleteNotificationSchedulesByIdArgs = {
  uniqueId?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationDeleteNotificationsByIdArgs = {
  uniqueId?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationDeleteRolePermissionsByRoleIdArgs = {
  uniqueId?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationDeleteStaticPageArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationDeleteUserInfosByIdArgs = {
  uniqueId?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationDeletegroupSectionsDocsByGroupIdArgs = {
  uniqueId?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationDeletetBookmarkedRecordMutationArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDownloadUserNotificationResponseArgs = {
  notificationScheduleId?: InputMaybe<Scalars['Int']['input']>;
  uniqueId: Scalars['Int']['input'];
};


export type MutationEmailEnableDisableArgs = {
  enabled?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGetAadUserStatusByEmailMutationArgs = {
  userEmail: Scalars['String']['input'];
};


export type MutationGetAllActionsAndActorsArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGetAllArchivedUserPagesArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
};


export type MutationGetAllCronJobPagesArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
};


export type MutationGetAllFoldersArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
};


export type MutationGetAllGroupSectionsDocsPagesArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
};


export type MutationGetAllNotificationSchedulePagesArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
  uniqueId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationGetAllNotificationsPagesArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
};


export type MutationGetAllPermissionsPagesArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
};


export type MutationGetAllRolePermissionsPagesArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
};


export type MutationGetAllStaticPageArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
};


export type MutationGetAuditLogPagesMutationArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
};


export type MutationGetCronJobAuditLogArgs = {
  cronJob?: InputMaybe<Scalars['String']['input']>;
  pageInfo?: InputMaybe<PageInfoInput>;
};


export type MutationGetCronJobAuditLogDownloadReportArgs = {
  cronJob?: InputMaybe<Scalars['String']['input']>;
  pageInfo?: InputMaybe<PageInfoInput>;
};


export type MutationGetDocMetadataForAdminPagesArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
};


export type MutationGetDocumentDownloadUrlByIdsArgs = {
  getDocumentDownloadUrlList?: InputMaybe<Array<InputMaybe<GetDocumentDownloadUrl>>>;
};


export type MutationGetDocumentsByFolderIdPagesArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
  uniqueId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGetDocumentsByGroupIdPagesArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
  uniqueId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationGetDocumentsFolderByIdArgs = {
  uniqueId: Scalars['ID']['input'];
};


export type MutationGetFeedbackAndRatingPagesArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
};


export type MutationGetFilesByUserIdArgs = {
  fileType?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationGetFoldersByGroupIdPagesArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
  uniqueId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationGetGroupDetailsByIdArgs = {
  uniqueId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationGetGroupDetailsByNameArgs = {
  uniqueName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGetNotificationByIdArgs = {
  notificationScheduleId?: InputMaybe<Scalars['Int']['input']>;
  uniqueId: Scalars['Int']['input'];
};


export type MutationGetNotificationDetailsByIdArgs = {
  notificationScheduleId?: InputMaybe<Scalars['Int']['input']>;
  uniqueId: Scalars['Int']['input'];
};


export type MutationGetStaticPageByIdArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGetUserDetailsByIdArgs = {
  uniqueId: Scalars['Int']['input'];
};


export type MutationGetUserInfoByIdArgs = {
  uniqueName: Scalars['String']['input'];
};


export type MutationGetUserInfoPagesMutationArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
};


export type MutationGetUserNotificationResponsePagesArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
  uniqueId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationGetUserResponseByScheduleIdArgs = {
  notificationScheduleId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationGetUsersByGroupIdPagesArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
  uniqueId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationGetUsersExcludedFromGroupArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
  uniqueId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationGetVideosMetadataForAdminPagesArgs = {
  pageInfo?: InputMaybe<PageInfoInput>;
};


export type MutationGroupSectionsDocsMappingListCreateArgs = {
  groupSectionsDocsList?: InputMaybe<Array<InputMaybe<GroupSectionsDocsCreate>>>;
};


export type MutationGroupSectionsDocsMappingListUpdateArgs = {
  groupSectionsDocsList?: InputMaybe<Array<InputMaybe<GroupSectionsDocsUpdate>>>;
};


export type MutationModifyFileUploadSettingsArgs = {
  maxUploadSize?: InputMaybe<MaxUploadSizeInputObj>;
};


export type MutationNotificationScheduleListCreateArgs = {
  notificationScheduleList?: InputMaybe<Array<InputMaybe<NotificationScheduleCreate>>>;
};


export type MutationNotificationScheduleListUpdateArgs = {
  notificationScheduleList?: InputMaybe<Array<InputMaybe<NotificationScheduleUpdate>>>;
};


export type MutationNotificationsListCreateArgs = {
  notificationList?: InputMaybe<Array<InputMaybe<NotificationCreate>>>;
};


export type MutationNotificationsListUpdateArgs = {
  notificationList?: InputMaybe<Array<InputMaybe<NotificationUpdate>>>;
};


export type MutationRecoverArchivedUsersMutationArgs = {
  uniqueId?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationRegisterAirshipDeviceMetadataMutationArgs = {
  aishipDeviceMetadata?: InputMaybe<AirshipDeviceMetadataInput>;
};


export type MutationRemoveUserFromGroupArgs = {
  addUserToGroupArgsList?: InputMaybe<Array<InputMaybe<AddUserToGroupArgs>>>;
};


export type MutationRolePermissionsListCreateArgs = {
  rolePermissionsList?: InputMaybe<Array<InputMaybe<RolePermissionsCreate>>>;
};


export type MutationRolePermissionsListUpdateArgs = {
  rolePermissionsList?: InputMaybe<Array<InputMaybe<RolePermissionsUpdate>>>;
};


export type MutationSaveStaticPageArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetHomeScreenArgs = {
  adminContent?: InputMaybe<Scalars['String']['input']>;
  mobileContent?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSystemConfigRequestFormArgs = {
  app_support_email?: InputMaybe<AppSupportEmailInputObj>;
  app_version?: InputMaybe<AppVersionInputObj>;
  gocNumbers?: InputMaybe<GocNumberInputObject>;
  service_desk?: InputMaybe<Array<ServiceDeskInputObj>>;
};


export type MutationUpdateDocumentsFolderArgs = {
  display_name: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  notify_user?: InputMaybe<Scalars['Boolean']['input']>;
  storage_path: Scalars['String']['input'];
};


export type MutationUpdateEmailNotificationArgs = {
  recipient_bcc?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  recipient_cc?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  recipient_to?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sender?: InputMaybe<Scalars['String']['input']>;
  sender_name?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  uniqueId: Scalars['ID']['input'];
};


export type MutationUpdateFeedbackAndRatingMutationArgs = {
  app_version?: InputMaybe<Scalars['String']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateFeedbackAndRatingMutationV1Args = {
  app_version?: InputMaybe<Scalars['String']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateMutationForFileMetadataArgs = {
  fileMetadataListForUpdate?: InputMaybe<Array<InputMaybe<FileMetadataForUpdate>>>;
};


export type MutationUpdateNextTriggerDateByIdArgs = {
  nextTriggerDateUpdateList?: InputMaybe<Array<InputMaybe<UpdateNextTriggerDate>>>;
};


export type MutationUpdateNotificationResponseArgs = {
  updateNotificationList?: InputMaybe<Array<InputMaybe<UpdateNotification>>>;
};


export type MutationUpdateStaticPageArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateThumbnailAndDurationArgs = {
  thumbnailAndDurationUpdateList?: InputMaybe<Array<InputMaybe<UpdateThumbnailAndDuration>>>;
};


export type MutationUserInfoListCreateArgs = {
  userInfoList?: InputMaybe<Array<InputMaybe<UserInfoCreate>>>;
};


export type MutationUserInfoListUpdateArgs = {
  userInfoList?: InputMaybe<Array<InputMaybe<UserInfoUpdate>>>;
};

export type NotificationDetails = {
  __typename?: 'NotificationDetails';
  body?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dynamicResponse?: Maybe<Array<Maybe<DynamicResponses>>>;
  notificationId?: Maybe<Scalars['Int']['output']>;
  notificationSchedule?: Maybe<Array<Maybe<NotificationScheduleInfo>>>;
  title?: Maybe<Scalars['String']['output']>;
  uniqueId?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  userResponse?: Maybe<Array<Maybe<UserResponses>>>;
};

export type NotificationInfoForAdmin = {
  __typename?: 'NotificationInfoForAdmin';
  body?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dynamicResponse?: Maybe<Array<Maybe<DynamicResponses>>>;
  notifyAt?: Maybe<Scalars['Float']['output']>;
  respondedBy?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uniqueId?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  userResponse?: Maybe<Array<Maybe<UserResponses>>>;
};

export type NotificationList = {
  __typename?: 'NotificationList';
  notificationList?: Maybe<Array<Maybe<NotificationInfoForAdmin>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type NotificationRespForAdmin = {
  __typename?: 'NotificationRespForAdmin';
  createdAt?: Maybe<Scalars['Float']['output']>;
  notificationScheduleId?: Maybe<Scalars['Int']['output']>;
  notifyAt?: Maybe<Scalars['Float']['output']>;
  respondedBy?: Maybe<Scalars['String']['output']>;
  uniqueId?: Maybe<Scalars['Int']['output']>;
  userResponse?: Maybe<Array<Maybe<UserResponses>>>;
};

export type NotificationScheduleForAdmin = {
  __typename?: 'NotificationScheduleForAdmin';
  auto_download?: Maybe<Scalars['Boolean']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dynamicResponse?: Maybe<Array<Maybe<DynamicResponses>>>;
  fileId?: Maybe<Scalars['ID']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  filePath?: Maybe<Scalars['String']['output']>;
  fileURL?: Maybe<Scalars['String']['output']>;
  file_bytes?: Maybe<Scalars['Float']['output']>;
  folder_id?: Maybe<Scalars['String']['output']>;
  groupIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  groupNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isFileNotification?: Maybe<Scalars['Boolean']['output']>;
  isNew?: Maybe<Scalars['Boolean']['output']>;
  isPushNotification?: Maybe<Scalars['Boolean']['output']>;
  isRead?: Maybe<Scalars['Boolean']['output']>;
  is_support?: Maybe<Scalars['Boolean']['output']>;
  is_video?: Maybe<Scalars['Boolean']['output']>;
  notificationId?: Maybe<Scalars['Int']['output']>;
  notificationMetadata?: Maybe<Scalars['String']['output']>;
  notificationStatus?: Maybe<Scalars['String']['output']>;
  notifyAt?: Maybe<Scalars['Float']['output']>;
  section?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uniqueId?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  userIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  userNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  userResponse?: Maybe<Array<Maybe<UserResponses>>>;
};

export type NotificationScheduleList = {
  __typename?: 'NotificationScheduleList';
  notificationScheduleList?: Maybe<Array<Maybe<NotificationScheduleForAdmin>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  current_page?: Maybe<Scalars['Int']['output']>;
  endTime?: Maybe<Scalars['Float']['output']>;
  fetched_records?: Maybe<Scalars['Int']['output']>;
  from?: Maybe<Scalars['Int']['output']>;
  hasNext?: Maybe<Scalars['String']['output']>;
  order_by?: Maybe<Scalars['String']['output']>;
  order_by_column?: Maybe<Scalars['String']['output']>;
  per_page?: Maybe<Scalars['Int']['output']>;
  search_column?: Maybe<Scalars['String']['output']>;
  search_term?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['Float']['output']>;
  time_window_column?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['Int']['output']>;
  total_pages?: Maybe<Scalars['Int']['output']>;
  total_records?: Maybe<Scalars['Int']['output']>;
};

export type PageInfoInput = {
  current_page?: InputMaybe<Scalars['Int']['input']>;
  endTime?: InputMaybe<Scalars['Float']['input']>;
  order_by?: InputMaybe<Scalars['String']['input']>;
  order_by_column?: InputMaybe<Scalars['String']['input']>;
  per_page?: InputMaybe<Scalars['Int']['input']>;
  search_column?: InputMaybe<Scalars['String']['input']>;
  search_term?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['Float']['input']>;
  time_window_column?: InputMaybe<Scalars['String']['input']>;
};

export type PermissionList = {
  __typename?: 'PermissionList';
  pageInfo?: Maybe<PageInfo>;
  permissionList?: Maybe<Array<Maybe<Permissions>>>;
};

export type Permissions = {
  __typename?: 'Permissions';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  permissionName?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** Clean 90 days old activity logs */
  cleanAuditLogs?: Maybe<CronJob>;
  /** Get Metadata For Files Download */
  fileDownloadsMetadata?: Maybe<FileMetadataResponse>;
  /** Get file Metadata for Admin Forms */
  fileMetadataForAdmin?: Maybe<Array<Maybe<FileMetadataAdminForm>>>;
  /** fetch airship channels report */
  getAirshipChannelsReport?: Maybe<AirShipChannelsInfo>;
  /** Get all API permissions */
  getAllAPIPermissions?: Maybe<Array<Maybe<ApiPermissionsMap>>>;
  /** Get All UsersInfo List */
  getAllArchivedUser?: Maybe<Array<Maybe<UserInfoForAdmin>>>;
  /** Get All the activity Logs */
  getAllAuditLogs?: Maybe<Array<Maybe<UserAuditLogs>>>;
  /** Get All Cron Job */
  getAllCronJobs?: Maybe<Array<Maybe<CronJob>>>;
  /** Get List of All Documents Folders */
  getAllDocumentsFolders?: Maybe<Array<Maybe<DocumentsFolder>>>;
  /** get all feedback and rating query */
  getAllFeedbackAndRating?: Maybe<Array<Maybe<FeedBackAndRating>>>;
  /** Get All GroupSectionsDocs List */
  getAllGroupSectionsDocs?: Maybe<Array<Maybe<GroupSectionsDocsResponse>>>;
  /** Get All UserGroups List */
  getAllGroups?: Maybe<Array<Maybe<Groups>>>;
  /** Get All Notification Schedules List */
  getAllNotificationSchedulesList?: Maybe<Array<Maybe<NotificationScheduleForAdmin>>>;
  /** Get All Notifications List */
  getAllNotificationsList?: Maybe<Array<Maybe<NotificationInfoForAdmin>>>;
  /** Get all permissions */
  getAllPermissions?: Maybe<Array<Maybe<Permissions>>>;
  /** Get All User Create Logs */
  getAllResourceCounts?: Maybe<GetAllResourceCounts>;
  /** Get All RolePermissions List */
  getAllRolePermissions?: Maybe<Array<Maybe<RolePermissionsResponse>>>;
  /** Get All Roles List */
  getAllRoles?: Maybe<Array<Maybe<Roles>>>;
  /** Get All Sensitivity Label */
  getAllSenestivityLabel?: Maybe<Array<Maybe<SensitivityLabel>>>;
  /** Get All UsersInfo List */
  getAllUserInfoList?: Maybe<Array<Maybe<UserInfoForAdmin>>>;
  /** Get Enable or disable email */
  getAllValidUsersUniqueName?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Get Audit Log Cron Job */
  getAuditLogCronJob?: Maybe<CronJob>;
  /** get Bookmarked record query */
  getBookmarkedRecord?: Maybe<Array<Maybe<BookmarkRespoonse>>>;
  /** Get All Notifications List */
  getCurrentUserNotificationsList?: Maybe<Array<Maybe<NotificationScheduleForAdmin>>>;
  /** Get all permissions assigned to current user */
  getCurrentUserPermissions?: Maybe<UserPermissions>;
  /** Get Docs Metadata for Admin Forms */
  getDocsMetadataForAdmin?: Maybe<Array<Maybe<FileMetadataAdminForm>>>;
  /** Get Metadata For documents Download */
  getDocumentsMetadata?: Maybe<Array<Maybe<DocumentMetadata>>>;
  /** get email notifications */
  getEmailNotificationDeatils?: Maybe<EmailNotification>;
  /** Get Enable or disable email */
  getEmailSetting?: Maybe<EmailService>;
  /** get feedback and rating record query */
  getFeedbackAndRating?: Maybe<FeedBackAndRating>;
  /** Getting File Upload Settings */
  getFileUploadSettings?: Maybe<FileUploadSettingsObj>;
  /** Get Home Screen Content */
  getHomeScreen?: Maybe<HomeScreenOld>;
  /** Get Home Screen Content */
  getHomeScreenForAdmin?: Maybe<HomeScreen>;
  /** Get Metadata For Support Download */
  getSupportDocMetadata?: Maybe<Array<Maybe<SupportDocumentMetadata>>>;
  /** Get Home Screen Content */
  getUsersHomeScreen?: Maybe<HomeScreen>;
  /** Get Metadata For Video Download */
  getVideosMetadata?: Maybe<Array<Maybe<VideoMetadata>>>;
  greetings?: Maybe<Scalars['String']['output']>;
  /** sync pancake users with AAD */
  isPushNotificationEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** process current push notificstions in the system */
  processPushNotifications?: Maybe<CronJob>;
  /** sync pancake users with AAD */
  syncUsersWithAAD?: Maybe<CronJob>;
  /** Getting System Configs */
  systemConfigsResponse?: Maybe<Array<Maybe<SystemConfigsResponse>>>;
  /** Getting System Configs */
  systemConfigsResponseForAdmin?: Maybe<Array<Maybe<SystemConfigsResponse>>>;
  /** Update the NotificationRead for this user */
  updateNotificationReadTime?: Maybe<Scalars['Float']['output']>;
};


export type QueryFileDownloadsMetadataArgs = {
  fileDownloadsMetadataList?: InputMaybe<Array<InputMaybe<FileDownloadMetadata>>>;
};

export type RolePermissionList = {
  __typename?: 'RolePermissionList';
  pageInfo?: Maybe<PageInfo>;
  rolePermissionList?: Maybe<Array<Maybe<RolePermissionsResponse>>>;
};

export type Roles = {
  __typename?: 'Roles';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  roleName?: Maybe<Scalars['String']['output']>;
};

export type RolesUser = {
  __typename?: 'RolesUser';
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  roleName?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type SensitivityLabel = {
  __typename?: 'SensitivityLabel';
  key?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** Set Home Screen Response */
export type SetHomeScreenResponse = {
  __typename?: 'SetHomeScreenResponse';
  data?: Maybe<Scalars['String']['output']>;
};

/** StaticPage object */
export type StaticPage = {
  __typename?: 'StaticPage';
  body?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uniqueId?: Maybe<Scalars['ID']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type StaticPageList = {
  __typename?: 'StaticPageList';
  pageInfo?: Maybe<PageInfo>;
  staticPageList?: Maybe<Array<Maybe<StaticPage>>>;
};

export type UserAuditLogs = {
  __typename?: 'UserAuditLogs';
  action?: Maybe<Scalars['String']['output']>;
  actorEmail?: Maybe<Scalars['String']['output']>;
  recordPrimaryKey?: Maybe<Scalars['String']['output']>;
  tableName?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Float']['output']>;
};

export type UserDeatilsForAdmin = {
  __typename?: 'UserDeatilsForAdmin';
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['Float']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  groupNames?: Maybe<Array<Maybe<GroupsUser>>>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  last_active_at?: Maybe<Scalars['Float']['output']>;
  lineOfBusiness?: Maybe<Scalars['String']['output']>;
  roleNames?: Maybe<Array<Maybe<RolesUser>>>;
  uniqueId?: Maybe<Scalars['Int']['output']>;
  uniqueName?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  groupIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lineOfBusiness?: Maybe<Scalars['String']['output']>;
  roleIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  uniqueId?: Maybe<Scalars['Int']['output']>;
  uniqueName?: Maybe<Scalars['String']['output']>;
};

export type UserInfoForAdmin = {
  __typename?: 'UserInfoForAdmin';
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['Float']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  groupIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  groupNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  last_active_at?: Maybe<Scalars['Float']['output']>;
  lineOfBusiness?: Maybe<Scalars['String']['output']>;
  roleIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  roleNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  uniqueId?: Maybe<Scalars['Int']['output']>;
  uniqueName?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type UserInfoPages = {
  __typename?: 'UserInfoPages';
  pageInfo?: Maybe<PageInfo>;
  userInfoForAdmin?: Maybe<Array<Maybe<UserInfoForAdmin>>>;
};

/** User object */
export type UserList = {
  __typename?: 'UserList';
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  uniqueId?: Maybe<Scalars['Int']['output']>;
  uniqueName?: Maybe<Scalars['String']['output']>;
};

export type UserNotificationResponseList = {
  __typename?: 'UserNotificationResponseList';
  pageInfo?: Maybe<PageInfo>;
  userNotificationResponseList?: Maybe<Array<Maybe<NotificationRespForAdmin>>>;
};

export type UserPermissions = {
  __typename?: 'UserPermissions';
  assignedPermissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  uniqueId?: Maybe<Scalars['Int']['output']>;
  uniqueName?: Maybe<Scalars['String']['output']>;
};

export type UserRoles = {
  __typename?: 'UserRoles';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Users = {
  __typename?: 'Users';
  Id?: Maybe<Scalars['Int']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  uniqueName?: Maybe<Scalars['String']['output']>;
};

export type UsersByGroupIdList = {
  __typename?: 'UsersByGroupIdList';
  pageInfo?: Maybe<PageInfo>;
  usersByGroupIdList?: Maybe<Array<Maybe<UserList>>>;
};

export type VideoMetadataList = {
  __typename?: 'VideoMetadataList';
  pageInfo?: Maybe<PageInfo>;
  videoMetadataList?: Maybe<Array<Maybe<FileMetadataAdminForm>>>;
};

export type AddUserToGroupArgs = {
  groupId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type AppSupportEmailInputObj = {
  email_id?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type AppSupportEmailObj = {
  __typename?: 'appSupportEmailObj';
  email_id?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type CronJobToggle = {
  cronjobname?: InputMaybe<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
};

export type DocumentMetadata = {
  __typename?: 'documentMetadata';
  auto_download?: Maybe<Scalars['Boolean']['output']>;
  bookmarked_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['Float']['output']>;
  download_url?: Maybe<Scalars['String']['output']>;
  file_bytes?: Maybe<Scalars['Float']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  file_url?: Maybe<Scalars['String']['output']>;
  folder_id?: Maybe<Scalars['String']['output']>;
  folder_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  notify_user?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
};

export type DynamicResponses = {
  __typename?: 'dynamicResponses';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};
export type FileMetadataForUpdate = {
  auto_download?: InputMaybe<Scalars['Boolean']['input']>;
  fileName: Scalars['String']['input'];
  file_bytes?: InputMaybe<Scalars['Float']['input']>;
  file_url: Scalars['String']['input'];
  folder_id: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  is_support: Scalars['Boolean']['input'];
  is_video?: InputMaybe<Scalars['Boolean']['input']>;
  notify_user?: InputMaybe<Scalars['Boolean']['input']>;
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sensitivity_label?: InputMaybe<Scalars['String']['input']>;
};

export type GetAadUserStatusByEmailMutation = {
  __typename?: 'getAADUserStatusByEmailMutation';
  userStatus?: Maybe<Scalars['String']['output']>;
};

export type GetAllResourceCounts = {
  __typename?: 'getAllResourceCounts';
  documents?: Maybe<Scalars['Int']['output']>;
  feedbackAndRating?: Maybe<Scalars['Int']['output']>;
  groups?: Maybe<Scalars['Int']['output']>;
  notifications?: Maybe<Scalars['Int']['output']>;
  roles?: Maybe<Scalars['Int']['output']>;
  sections?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Scalars['Int']['output']>;
  videos?: Maybe<Scalars['Int']['output']>;
};

export type GetDocumentDownloadUrl = {
  uniqueId?: InputMaybe<Scalars['Int']['input']>;
};

export type GocNumberInputObject = {
  primary?: InputMaybe<Scalars['String']['input']>;
  secondary?: InputMaybe<Scalars['String']['input']>;
  tertiary?: InputMaybe<Scalars['String']['input']>;
};

export type GocNumberObject = {
  __typename?: 'gocNumberObject';
  primary?: Maybe<Scalars['String']['output']>;
  secondary?: Maybe<Scalars['String']['output']>;
  tertiary?: Maybe<Scalars['String']['output']>;
};

export type GroupDetailsResponse = {
  __typename?: 'groupDetailsResponse';
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<Documents>>>;
  folders?: Maybe<Array<Maybe<Folders>>>;
  groupDescription?: Maybe<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['Int']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  users?: Maybe<Array<Maybe<Users>>>;
};

export type GroupSectionsDocs = {
  __typename?: 'groupSectionsDocs';
  documentIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  groupDescription?: Maybe<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['Int']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  sectionIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type GroupSectionsDocsCreate = {
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  groupDescription?: InputMaybe<Scalars['String']['input']>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  sectionIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GroupSectionsDocsResponse = {
  __typename?: 'groupSectionsDocsResponse';
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  documentCount?: Maybe<Scalars['Int']['output']>;
  folderCount?: Maybe<Scalars['Int']['output']>;
  groupDescription?: Maybe<Scalars['String']['output']>;
  groupId?: Maybe<Scalars['Int']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type GroupSectionsDocsUpdate = {
  documentIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  groupDescription?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['Int']['input'];
  groupName?: InputMaybe<Scalars['String']['input']>;
  sectionIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type KeyValueInputObj = {
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type KeyValueInputObjTest = {
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type KeyValueObj = {
  __typename?: 'keyValueObj';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type NotificationCreate = {
  body: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  dynamicResponse?: InputMaybe<Array<InputMaybe<KeyValueInputObjTest>>>;
  title: Scalars['String']['input'];
};

export type NotificationScheduleCreate = {
  body: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  isPushNotification?: InputMaybe<Scalars['Boolean']['input']>;
  notificationId: Scalars['Int']['input'];
  notifyAt: Scalars['Float']['input'];
  title: Scalars['String']['input'];
  userIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type NotificationScheduleInfo = {
  __typename?: 'notificationScheduleInfo';
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  groupIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  groupNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isPushNotification?: Maybe<Scalars['Boolean']['output']>;
  notificationMetadata?: Maybe<Scalars['String']['output']>;
  notificationStatus?: Maybe<Scalars['String']['output']>;
  notifyAt?: Maybe<Scalars['Float']['output']>;
  uniqueId?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
  userIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  userNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type NotificationScheduleUpdate = {
  body: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  groupIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  isPushNotification?: InputMaybe<Scalars['Boolean']['input']>;
  notificationId: Scalars['Int']['input'];
  notificationMetadata: Scalars['String']['input'];
  notificationStatus: Scalars['String']['input'];
  notifyAt: Scalars['Float']['input'];
  title: Scalars['String']['input'];
  uniqueId: Scalars['Int']['input'];
  userIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type NotificationUpdate = {
  body: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  dynamicResponse?: InputMaybe<Array<InputMaybe<KeyValueInputObjTest>>>;
  title: Scalars['String']['input'];
  uniqueId?: InputMaybe<Scalars['Int']['input']>;
};

export type RolePermissions = {
  __typename?: 'rolePermissions';
  permissionIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  roleDescription?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['Int']['output']>;
  roleName?: Maybe<Scalars['String']['output']>;
};

export type RolePermissionsCreate = {
  permissionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  roleDescription?: InputMaybe<Scalars['String']['input']>;
  roleName?: InputMaybe<Scalars['String']['input']>;
};

export type RolePermissionsResponse = {
  __typename?: 'rolePermissionsResponse';
  created_at?: Maybe<Scalars['Float']['output']>;
  created_by?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Array<Maybe<Permissions>>>;
  roleDescription?: Maybe<Scalars['String']['output']>;
  roleId?: Maybe<Scalars['Int']['output']>;
  roleName?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type RolePermissionsUpdate = {
  permissionIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  roleDescription?: InputMaybe<Scalars['String']['input']>;
  roleId: Scalars['Int']['input'];
  roleName?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceDeskInputObj = {
  key?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Array<InputMaybe<KeyValueInputObj>>>;
};

export type ServiceDeskObj = {
  __typename?: 'serviceDeskObj';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Array<Maybe<KeyValueObj>>>;
};

export type SupportDocumentMetadata = {
  __typename?: 'supportDocumentMetadata';
  auto_download?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['Float']['output']>;
  download_url?: Maybe<Scalars['String']['output']>;
  file_bytes?: Maybe<Scalars['Float']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  file_url?: Maybe<Scalars['String']['output']>;
  folder_id?: Maybe<Scalars['String']['output']>;
  folder_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_support?: Maybe<Scalars['Boolean']['output']>;
  notify_user?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
};

export type SystemConfigsResponse = {
  __typename?: 'systemConfigsResponse';
  app_support_email?: Maybe<AppSupportEmailObj>;
  app_version?: Maybe<AppVersionObj>;
  gocConfigs?: Maybe<GocNumberObject>;
  service_desk?: Maybe<Array<Maybe<ServiceDeskObj>>>;
};

export type UpdateNextTriggerDate = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  nextTriggerDate?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateNotification = {
  createdAt?: InputMaybe<Scalars['Float']['input']>;
  notifyAt?: InputMaybe<Scalars['Float']['input']>;
  respondedBy?: InputMaybe<Scalars['String']['input']>;
  uniqueId?: InputMaybe<Scalars['Int']['input']>;
  userResponse?: InputMaybe<Array<InputMaybe<KeyValueInputObjTest>>>;
};

export type UpdateThumbnailAndDuration = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  file_url: Scalars['String']['input'];
  thumbnail?: InputMaybe<Scalars['String']['input']>;
};

export type UserGroup = {
  __typename?: 'userGroup';
  groupId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type UserInfoCreate = {
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  groupIds: Array<InputMaybe<Scalars['Int']['input']>>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lineOfBusiness?: InputMaybe<Scalars['String']['input']>;
  roleIds: Array<InputMaybe<Scalars['Int']['input']>>;
  uniqueId?: InputMaybe<Scalars['Int']['input']>;
  uniqueName: Scalars['String']['input'];
};

export type UserInfoUpdate = {
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  groupIds: Array<InputMaybe<Scalars['Int']['input']>>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lineOfBusiness?: InputMaybe<Scalars['String']['input']>;
  roleIds: Array<InputMaybe<Scalars['Int']['input']>>;
  uniqueId: Scalars['Int']['input'];
  uniqueName: Scalars['String']['input'];
};

export type UserResponses = {
  __typename?: 'userResponses';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type VideoMetadata = {
  __typename?: 'videoMetadata';
  auto_download?: Maybe<Scalars['Boolean']['output']>;
  bookmarked_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['Float']['output']>;
  download_url?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  file_bytes?: Maybe<Scalars['Float']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  file_url?: Maybe<Scalars['String']['output']>;
  folder_id?: Maybe<Scalars['String']['output']>;
  folder_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  is_video?: Maybe<Scalars['Boolean']['output']>;
  notify_user?: Maybe<Scalars['Boolean']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['Float']['output']>;
};

export type GetAllDocumentsFoldersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDocumentsFoldersQuery = { __typename?: 'Query', getAllDocumentsFolders?: Array<{ __typename?: 'DocumentsFolder', display_name?: string | null, id?: string | null, storage_path?: string | null, created_at?: number | null, updated_at?: number | null, notify_user?: boolean | null } | null> | null };

export type SystemConfigsResponseQueryVariables = Exact<{ [key: string]: never; }>;


export type SystemConfigsResponseQuery = { __typename?: 'Query', systemConfigsResponse?: Array<{ __typename?: 'systemConfigsResponse', app_support_email?: { __typename?: 'appSupportEmailObj', email_id?: string | null, subject?: string | null } | null, app_version?: { __typename?: 'AppVersionObj', ios?: string | null } | null, gocConfigs?: { __typename?: 'gocNumberObject', primary?: string | null, secondary?: string | null, tertiary?: string | null } | null, service_desk?: Array<{ __typename?: 'serviceDeskObj', key?: string | null, value?: Array<{ __typename?: 'keyValueObj', key?: string | null, value?: string | null } | null> | null } | null> | null } | null> | null };

export type GetCurrentUserPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserPermissionsQuery = { __typename?: 'Query', getCurrentUserPermissions?: { __typename?: 'UserPermissions', uniqueName?: string | null, uniqueId?: number | null, assignedPermissions?: Array<string | null> | null } | null, getAllAPIPermissions?: Array<{ __typename?: 'APIPermissionsMap', accessType?: string | null, assignedPermissions?: Array<string | null> | null } | null> | null };

export type GetAllPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllPermissionsQuery = { __typename?: 'Query', getAllPermissions?: Array<{ __typename?: 'Permissions', description?: string | null, id?: number | null, permissionName?: string | null } | null> | null };

export type GetAllRolePermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRolePermissionsQuery = { __typename?: 'Query', getAllRolePermissions?: Array<{ __typename?: 'rolePermissionsResponse', roleId?: number | null, roleName?: string | null, roleDescription?: string | null, created_by?: string | null, created_at?: number | null, updated_by?: string | null, updated_at?: number | null, permissions?: Array<{ __typename?: 'Permissions', id?: number | null, permissionName?: string | null, description?: string | null } | null> | null } | null> | null, getCurrentUserPermissions?: { __typename?: 'UserPermissions', uniqueName?: string | null, assignedPermissions?: Array<string | null> | null } | null, getAllAPIPermissions?: Array<{ __typename?: 'APIPermissionsMap', accessType?: string | null, assignedPermissions?: Array<string | null> | null } | null> | null, getAllPermissions?: Array<{ __typename?: 'Permissions', description?: string | null, id?: number | null, permissionName?: string | null } | null> | null };

export type GetAllUserInfoListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUserInfoListQuery = { __typename?: 'Query', getAllUserInfoList?: Array<{ __typename?: 'UserInfoForAdmin', familyName?: string | null, givenName?: string | null, groupNames?: Array<string | null> | null, roleNames?: Array<string | null> | null, uniqueName?: string | null, uniqueId?: number | null, roleIds?: Array<number | null> | null, groupIds?: Array<number | null> | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null, last_active_at?: number | null, jobTitle?: string | null, lineOfBusiness?: string | null } | null> | null, getAllGroups?: Array<{ __typename?: 'Groups', description?: string | null, id?: number | null, groupName?: string | null } | null> | null, getAllRoles?: Array<{ __typename?: 'Roles', description?: string | null, id?: number | null, roleName?: string | null } | null> | null };

export type FileMetadataForAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type FileMetadataForAdminQuery = { __typename?: 'Query', fileMetadataForAdmin?: Array<{ __typename?: 'FileMetadataAdminForm', is_support?: boolean | null, fileName?: string | null, fileURL?: string | null, file_bytes?: number | null, is_video?: boolean | null, auto_download?: boolean | null, section?: string | null, downloadURL?: string | null, sectionOrder?: string | null, sensitivity_label?: string | null, thumbnail?: string | null, duration?: number | null, created_at?: number | null, updated_at?: number | null, notify_user?: boolean | null, id?: string | null, folder_id?: string | null, tenantName?: string | null, filePath?: string | null, roles?: Array<{ __typename?: 'UserRoles', id?: number | null, name?: string | null } | null> | null } | null> | null, getAllDocumentsFolders?: Array<{ __typename?: 'DocumentsFolder', display_name?: string | null, id?: string | null, storage_path?: string | null, notify_user?: boolean | null } | null> | null, getAllSenestivityLabel?: Array<{ __typename?: 'SensitivityLabel', key?: number | null, value?: string | null } | null> | null };

export type GetDocsMetadataForAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDocsMetadataForAdminQuery = { __typename?: 'Query', getDocsMetadataForAdmin?: Array<{ __typename?: 'FileMetadataAdminForm', is_support?: boolean | null, fileName?: string | null, fileURL?: string | null, file_bytes?: number | null, is_video?: boolean | null, auto_download?: boolean | null, section?: string | null, downloadURL?: string | null, sectionOrder?: string | null, sensitivity_label?: string | null, thumbnail?: string | null, duration?: number | null, created_at?: number | null, updated_at?: number | null, notify_user?: boolean | null, id?: string | null, folder_id?: string | null, tenantName?: string | null, filePath?: string | null, roles?: Array<{ __typename?: 'UserRoles', id?: number | null, name?: string | null } | null> | null } | null> | null, getAllDocumentsFolders?: Array<{ __typename?: 'DocumentsFolder', display_name?: string | null, id?: string | null, storage_path?: string | null, notify_user?: boolean | null } | null> | null, getAllSenestivityLabel?: Array<{ __typename?: 'SensitivityLabel', key?: number | null, value?: string | null } | null> | null };

export type CreateDocumentsFolderMutationVariables = Exact<{
  display_name: Scalars['String']['input'];
  storage_path: Scalars['String']['input'];
  notify_user: Scalars['Boolean']['input'];
}>;


export type CreateDocumentsFolderMutation = { __typename?: 'Mutation', createDocumentsFolder?: { __typename?: 'DocumentsFolder', display_name?: string | null, id?: string | null, storage_path?: string | null, notify_user?: boolean | null } | null };

export type GetAllFeedbackAndRatingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllFeedbackAndRatingQuery = { __typename?: 'Query', getAllFeedbackAndRating?: Array<{ __typename: 'FeedBackAndRating', id?: number | null, feedback?: string | null, rating?: number | null, created_by?: string | null, created_at?: number | null, updated_by?: string | null, updated_at?: number | null, next_trigger_at?: number | null } | null> | null };

export type GetCurrentUserNotificationsListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserNotificationsListQuery = { __typename?: 'Query', getCurrentUserNotificationsList?: Array<{ __typename: 'NotificationScheduleForAdmin', uniqueId?: number | null, notificationId?: number | null, title?: string | null, body?: string | null, description?: string | null, notificationStatus?: string | null, isPushNotification?: boolean | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null, notifyAt?: number | null, fileId?: string | null, fileURL?: string | null, filePath?: string | null, fileName?: string | null, file_bytes?: number | null, section?: string | null, folder_id?: string | null, is_support?: boolean | null, is_video?: boolean | null, auto_download?: boolean | null, isFileNotification?: boolean | null, isNew?: boolean | null, isRead?: boolean | null } | null> | null };

export type GetAllCronJobsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCronJobsQuery = { __typename?: 'Query', getAllCronJobs?: Array<{ __typename?: 'CronJob', uniqueId?: number | null, cronjobname?: string | null, lastrunat?: number | null, nextrunat?: number | null, lastrunstatus?: string | null, description?: string | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null } | null> | null };

export type CreateBookmarkMutationMutationVariables = Exact<{
  file_url: Scalars['String']['input'];
}>;


export type CreateBookmarkMutationMutation = { __typename?: 'Mutation', createBookmarkMutation?: { __typename: 'Bookmark', user_id?: number | null, file_id?: number | null, created_at?: number | null, id?: number | null } | null };

export type GetUserDetailsByIdMutationVariables = Exact<{
  uniqueId: Scalars['Int']['input'];
}>;


export type GetUserDetailsByIdMutation = { __typename?: 'Mutation', getUserDetailsById?: { __typename: 'UserDeatilsForAdmin', uniqueId?: number | null, familyName?: string | null, givenName?: string | null, uniqueName?: string | null, lineOfBusiness?: string | null, jobTitle?: string | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null, last_active_at?: number | null, groupNames?: Array<{ __typename?: 'GroupsUser', id?: number | null, groupName?: string | null, description?: string | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null } | null> | null, roleNames?: Array<{ __typename?: 'RolesUser', id?: number | null, roleName?: string | null, description?: string | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null } | null> | null } | null };

export type GetDocumentsFolderByIdMutationVariables = Exact<{
  uniqueId: Scalars['ID']['input'];
}>;


export type GetDocumentsFolderByIdMutation = { __typename?: 'Mutation', getDocumentsFolderById?: { __typename?: 'DocumentsFolder', id?: string | null, display_name?: string | null, storage_path?: string | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null } | null };

export type GetAllResourceCountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllResourceCountsQuery = { __typename?: 'Query', getAllResourceCounts?: { __typename?: 'getAllResourceCounts', users?: number | null, documents?: number | null, videos?: number | null, sections?: number | null, feedbackAndRating?: number | null, groups?: number | null, roles?: number | null, notifications?: number | null } | null };

export type UserInfoListCreateMutationVariables = Exact<{
  userInfoCreate?: InputMaybe<Array<InputMaybe<UserInfoCreate>> | InputMaybe<UserInfoCreate>>;
}>;


export type UserInfoListCreateMutation = { __typename?: 'Mutation', userInfoListCreate?: Array<{ __typename?: 'UserInfo', familyName?: string | null, givenName?: string | null, groupIds?: Array<number | null> | null, roleIds?: Array<number | null> | null, uniqueName?: string | null, uniqueId?: number | null } | null> | null };

export type DeleteUserInfosByIdMutationVariables = Exact<{
  uniqueId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteUserInfosByIdMutation = { __typename?: 'Mutation', deleteUserInfosById?: Array<number | null> | null };

export type UserInfoListUpdateMutationVariables = Exact<{
  userInfoUpdate?: InputMaybe<Array<InputMaybe<UserInfoUpdate>> | InputMaybe<UserInfoUpdate>>;
}>;


export type UserInfoListUpdateMutation = { __typename?: 'Mutation', userInfoListUpdate?: Array<{ __typename?: 'UserInfo', familyName?: string | null, givenName?: string | null, groupIds?: Array<number | null> | null, roleIds?: Array<number | null> | null, uniqueName?: string | null } | null> | null };

export type UpdateDocumentsFolderMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  display_name: Scalars['String']['input'];
  storage_path: Scalars['String']['input'];
  notify_user: Scalars['Boolean']['input'];
}>;


export type UpdateDocumentsFolderMutation = { __typename?: 'Mutation', updateDocumentsFolder?: { __typename?: 'DocumentsFolder', display_name?: string | null, id?: string | null, storage_path?: string | null, notify_user?: boolean | null } | null };

export type DeleteDocumentsFolderMutationVariables = Exact<{
  id?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type DeleteDocumentsFolderMutation = { __typename?: 'Mutation', deleteDocumentsFolder?: Array<{ __typename: 'DocumentsFolder', id?: string | null } | null> | null };

export type DeleteBulkFileMetadataByUrlMutationVariables = Exact<{
  fileUrls?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type DeleteBulkFileMetadataByUrlMutation = { __typename?: 'Mutation', deleteBulkFileMetadataByUrl?: Array<{ __typename?: 'FileMetadata', fileName?: string | null } | null> | null };

export type UpdateMutationForFileMetadataMutationVariables = Exact<{
  fileMetadataListForUpdate: FileMetadataForUpdate;
}>;


export type UpdateMutationForFileMetadataMutation = { __typename?: 'Mutation', updateMutationForFileMetadata?: { __typename?: 'FileMetadata', checksum?: string | null, downloadURL?: string | null, fileName?: string | null, sensitivity_label?: string | null, notify_user?: boolean | null, fileURL?: string | null } | null };

export type UpdateThumbnailAndDurationMutationVariables = Exact<{
  thumbnailAndDurationUpdateList?: InputMaybe<Array<InputMaybe<UpdateThumbnailAndDuration>> | InputMaybe<UpdateThumbnailAndDuration>>;
}>;


export type UpdateThumbnailAndDurationMutation = { __typename?: 'Mutation', updateThumbnailAndDuration?: Array<{ __typename?: 'FileMetadata', fileURL?: string | null, created_at?: number | null, id?: string | null } | null> | null };

export type NotificationsListCreateMutationVariables = Exact<{
  notificationCreate?: InputMaybe<Array<InputMaybe<NotificationCreate>> | InputMaybe<NotificationCreate>>;
}>;


export type NotificationsListCreateMutation = { __typename?: 'Mutation', notificationsListCreate?: Array<{ __typename: 'NotificationInfoForAdmin', uniqueId?: number | null, title?: string | null, body?: string | null, description?: string | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null, dynamicResponse?: Array<{ __typename?: 'dynamicResponses', key?: string | null, value?: string | null } | null> | null } | null> | null };

export type NotificationsListUpdateMutationVariables = Exact<{
  notificationUpdate?: InputMaybe<Array<InputMaybe<NotificationUpdate>> | InputMaybe<NotificationUpdate>>;
}>;


export type NotificationsListUpdateMutation = { __typename?: 'Mutation', notificationsListUpdate?: Array<{ __typename: 'NotificationInfoForAdmin', uniqueId?: number | null, title?: string | null, body?: string | null, description?: string | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null } | null> | null };

export type DeleteFeedbackAndRatingMutationMutationVariables = Exact<{
  id?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteFeedbackAndRatingMutationMutation = { __typename?: 'Mutation', deleteFeedbackAndRatingMutation?: Array<{ __typename: 'FeedBackAndRating', id?: number | null, feedback?: string | null, rating?: number | null, next_trigger_at?: number | null } | null> | null };

export type UpdateNextTriggerDateByIdMutationVariables = Exact<{
  nextTriggerDateUpdateList?: InputMaybe<Array<InputMaybe<UpdateNextTriggerDate>> | InputMaybe<UpdateNextTriggerDate>>;
}>;


export type UpdateNextTriggerDateByIdMutation = { __typename?: 'Mutation', updateNextTriggerDateById?: Array<{ __typename: 'FeedBackAndRating', id?: number | null, next_trigger_at?: number | null } | null> | null };

export type DeleteNotificationsByIdMutationVariables = Exact<{
  uniqueId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteNotificationsByIdMutation = { __typename?: 'Mutation', deleteNotificationsById?: Array<number | null> | null };

export type GetNotificationDetailsByIdMutationVariables = Exact<{
  uniqueId: Scalars['Int']['input'];
}>;


export type GetNotificationDetailsByIdMutation = { __typename?: 'Mutation', getNotificationDetailsById?: { __typename?: 'NotificationDetails', uniqueId?: number | null, title?: string | null, body?: string | null, description?: string | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null, dynamicResponse?: Array<{ __typename?: 'dynamicResponses', key?: string | null, value?: string | null } | null> | null, notificationSchedule?: Array<{ __typename?: 'notificationScheduleInfo', uniqueId?: number | null, notifyAt?: number | null, isPushNotification?: boolean | null, notificationStatus?: string | null, notificationMetadata?: string | null, userIds?: Array<number | null> | null, userNames?: Array<string | null> | null, groupIds?: Array<number | null> | null, groupNames?: Array<string | null> | null, created_by?: string | null, created_at?: number | null, updated_by?: string | null, updated_at?: number | null } | null> | null } | null };

export type GetUserNotificationResponsePagesMutationVariables = Exact<{
  uniqueId: Scalars['Int']['input'];
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetUserNotificationResponsePagesMutation = { __typename?: 'Mutation', getUserNotificationResponsePages?: { __typename?: 'UserNotificationResponseList', userNotificationResponseList?: Array<{ __typename?: 'NotificationRespForAdmin', uniqueId?: number | null, notificationScheduleId?: number | null, notifyAt?: number | null, createdAt?: number | null, respondedBy?: string | null, userResponse?: Array<{ __typename?: 'userResponses', key?: string | null, value?: string | null } | null> | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null } | null };

export type GetUserInfoPagesMutationMutationVariables = Exact<{
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetUserInfoPagesMutationMutation = { __typename?: 'Mutation', getUserInfoPagesMutation?: { __typename: 'UserInfoPages', userInfoForAdmin?: Array<{ __typename: 'UserInfoForAdmin', familyName?: string | null, givenName?: string | null, groupNames?: Array<string | null> | null, roleNames?: Array<string | null> | null, uniqueName?: string | null, uniqueId?: number | null, roleIds?: Array<number | null> | null, groupIds?: Array<number | null> | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null, last_active_at?: number | null, lineOfBusiness?: string | null, jobTitle?: string | null } | null> | null, pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null } | null };

export type GetAllGroupSectionsDocsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllGroupSectionsDocsQuery = { __typename?: 'Query', getAllGroupSectionsDocs?: Array<{ __typename: 'groupSectionsDocsResponse', groupId?: number | null, groupName?: string | null, groupDescription?: string | null, folderCount?: number | null, documentCount?: number | null, created_by?: string | null, created_at?: number | null, updated_by?: string | null, updated_at?: number | null } | null> | null };

export type GroupSectionsDocsMappingListCreateMutationVariables = Exact<{
  groupSectionsDocsCreate?: InputMaybe<Array<InputMaybe<GroupSectionsDocsCreate>> | InputMaybe<GroupSectionsDocsCreate>>;
}>;


export type GroupSectionsDocsMappingListCreateMutation = { __typename?: 'Mutation', groupSectionsDocsMappingListCreate?: Array<{ __typename?: 'groupSectionsDocs', groupId?: number | null, groupName?: string | null, groupDescription?: string | null, sectionIds?: Array<string | null> | null, documentIds?: Array<number | null> | null } | null> | null };

export type GetGroupDetailsByIdMutationVariables = Exact<{
  uniqueId: Scalars['Int']['input'];
}>;


export type GetGroupDetailsByIdMutation = { __typename?: 'Mutation', getGroupDetailsById?: Array<{ __typename: 'groupDetailsResponse', groupId?: number | null, groupName?: string | null, groupDescription?: string | null, created_by?: string | null, created_at?: number | null, updated_by?: string | null, updated_at?: number | null, folders?: Array<{ __typename: 'Folders', id?: string | null, folderName?: string | null } | null> | null, documents?: Array<{ __typename: 'Documents', id?: number | null, documentName?: string | null } | null> | null, users?: Array<{ __typename: 'Users', Id?: number | null, familyName?: string | null, givenName?: string | null, uniqueName?: string | null } | null> | null } | null> | null };

export type GroupSectionsDocsMappingListUpdateMutationVariables = Exact<{
  groupSectionsDocsUpdate?: InputMaybe<Array<InputMaybe<GroupSectionsDocsUpdate>> | InputMaybe<GroupSectionsDocsUpdate>>;
}>;


export type GroupSectionsDocsMappingListUpdateMutation = { __typename?: 'Mutation', groupSectionsDocsMappingListUpdate?: Array<{ __typename?: 'groupSectionsDocs', groupId?: number | null, groupName?: string | null, groupDescription?: string | null, sectionIds?: Array<string | null> | null, documentIds?: Array<number | null> | null } | null> | null };

export type DeletegroupSectionsDocsByGroupIdMutationVariables = Exact<{
  uniqueId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeletegroupSectionsDocsByGroupIdMutation = { __typename?: 'Mutation', deletegroupSectionsDocsByGroupId?: Array<number | null> | null };

export type RecoverArchivedUsersMutationMutationVariables = Exact<{
  uniqueId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type RecoverArchivedUsersMutationMutation = { __typename?: 'Mutation', recoverArchivedUsersMutation?: Array<number | null> | null };

export type GetAuditLogPagesMutationMutationVariables = Exact<{
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetAuditLogPagesMutationMutation = { __typename?: 'Mutation', getAuditLogPagesMutation?: { __typename: 'AuditLogPages', pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null, auditLogsForAdmin?: Array<{ __typename: 'UserAuditLogs', action?: string | null, actorEmail?: string | null, recordPrimaryKey?: string | null, tableName?: string | null, timestamp?: number | null } | null> | null } | null };

export type GetHomeScreenForAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHomeScreenForAdminQuery = { __typename?: 'Query', getHomeScreenForAdmin?: { __typename: 'HomeScreen', adminContent?: string | null, mobileContent?: string | null } | null };

export type SetHomeScreenMutationVariables = Exact<{
  adminContent: Scalars['String']['input'];
  mobileContent: Scalars['String']['input'];
}>;


export type SetHomeScreenMutation = { __typename?: 'Mutation', setHomeScreen?: { __typename?: 'SetHomeScreenResponse', data?: string | null } | null };

export type IsPushNotificationEnabledQueryVariables = Exact<{ [key: string]: never; }>;


export type IsPushNotificationEnabledQuery = { __typename?: 'Query', isPushNotificationEnabled?: boolean | null };

export type GetFileUploadSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFileUploadSettingsQuery = { __typename?: 'Query', getFileUploadSettings?: { __typename: 'FileUploadSettingsObj', maxUploadSize?: { __typename: 'MaxUploadSizeObj', KB?: string | null } | null } | null };

export type CronJobListToggleMutationVariables = Exact<{
  cronJobToggle?: InputMaybe<Array<InputMaybe<CronJobToggle>> | InputMaybe<CronJobToggle>>;
}>;


export type CronJobListToggleMutation = { __typename?: 'Mutation', cronJobListToggle?: Array<{ __typename: 'CronJob', uniqueId?: number | null, cronjobname?: string | null, isActive?: boolean | null, lastrunat?: number | null, nextrunat?: number | null, lastrunstatus?: string | null, description?: string | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null } | null> | null };

export type ModifyFileUploadSettingsMutationVariables = Exact<{
  MaxUploadSizeInputObj: MaxUploadSizeInputObj;
}>;


export type ModifyFileUploadSettingsMutation = { __typename?: 'Mutation', modifyFileUploadSettings?: { __typename: 'FileUploadSettingsObj', maxUploadSize?: { __typename: 'MaxUploadSizeObj', KB?: string | null } | null } | null };

export type SystemConfigsResponseForAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type SystemConfigsResponseForAdminQuery = { __typename?: 'Query', systemConfigsResponseForAdmin?: Array<{ __typename?: 'systemConfigsResponse', gocConfigs?: { __typename?: 'gocNumberObject', primary?: string | null, secondary?: string | null, tertiary?: string | null } | null, app_support_email?: { __typename?: 'appSupportEmailObj', email_id?: string | null, subject?: string | null } | null, app_version?: { __typename?: 'AppVersionObj', ios?: string | null } | null, service_desk?: Array<{ __typename?: 'serviceDeskObj', key?: string | null, value?: Array<{ __typename?: 'keyValueObj', key?: string | null, value?: string | null } | null> | null } | null> | null } | null> | null };

export type SystemConfigRequestFormMutationVariables = Exact<{
  gocNumberInputObject: GocNumberInputObject;
  app_version: AppVersionInputObj;
  app_support_email: AppSupportEmailInputObj;
  service_desk?: InputMaybe<Array<ServiceDeskInputObj> | ServiceDeskInputObj>;
}>;


export type SystemConfigRequestFormMutation = { __typename?: 'Mutation', systemConfigRequestForm?: { __typename?: 'systemConfigsResponse', gocConfigs?: { __typename?: 'gocNumberObject', primary?: string | null, secondary?: string | null, tertiary?: string | null } | null, app_support_email?: { __typename?: 'appSupportEmailObj', email_id?: string | null, subject?: string | null } | null, app_version?: { __typename?: 'AppVersionObj', ios?: string | null } | null, service_desk?: Array<{ __typename?: 'serviceDeskObj', key?: string | null, value?: Array<{ __typename?: 'keyValueObj', key?: string | null, value?: string | null } | null> | null } | null> | null } | null };

export type NotificationScheduleListCreateMutationVariables = Exact<{
  notificationScheduleCreate?: InputMaybe<Array<InputMaybe<NotificationScheduleCreate>> | InputMaybe<NotificationScheduleCreate>>;
}>;


export type NotificationScheduleListCreateMutation = { __typename?: 'Mutation', notificationScheduleListCreate?: Array<{ __typename?: 'NotificationScheduleForAdmin', uniqueId?: number | null, notificationId?: number | null, title?: string | null, body?: string | null, description?: string | null, groupIds?: Array<number | null> | null, userIds?: Array<number | null> | null, notifyAt?: number | null, isPushNotification?: boolean | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null } | null> | null };

export type DeleteRolePermissionsByRoleIdMutationVariables = Exact<{
  uniqueId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteRolePermissionsByRoleIdMutation = { __typename?: 'Mutation', deleteRolePermissionsByRoleId?: Array<number | null> | null };

export type CreateRolePermissionsMutationVariables = Exact<{
  rolePermissionsCreate?: InputMaybe<Array<InputMaybe<RolePermissionsCreate>> | InputMaybe<RolePermissionsCreate>>;
}>;


export type CreateRolePermissionsMutation = { __typename?: 'Mutation', rolePermissionsListCreate?: Array<{ __typename?: 'rolePermissions', roleId?: number | null, roleName?: string | null, roleDescription?: string | null, permissionIds?: Array<number | null> | null } | null> | null };

export type RolePermissionsListUpdateMutationVariables = Exact<{
  rolePermissionsUpdate?: InputMaybe<Array<InputMaybe<RolePermissionsUpdate>> | InputMaybe<RolePermissionsUpdate>>;
}>;


export type RolePermissionsListUpdateMutation = { __typename?: 'Mutation', rolePermissionsListUpdate?: Array<{ __typename?: 'rolePermissions', roleId?: number | null, roleName?: string | null, roleDescription?: string | null, permissionIds?: Array<number | null> | null } | null> | null };

export type GetAllFoldersMutationVariables = Exact<{
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetAllFoldersMutation = { __typename?: 'Mutation', getAllFolders?: { __typename: 'FolderList', folderList?: Array<{ __typename: 'DocumentsFolder', display_name?: string | null, id?: string | null, storage_path?: string | null, created_at?: number | null, updated_at?: number | null, notify_user?: boolean | null } | null> | null, pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null } | null };

export type GetAirshipChannelsReportQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAirshipChannelsReportQuery = { __typename?: 'Query', getAirshipChannelsReport?: { __typename: 'AirShipChannelsInfo', channelsInfo?: string | null } | null };

export type GetFeedbackAndRatingPagesMutationVariables = Exact<{
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetFeedbackAndRatingPagesMutation = { __typename?: 'Mutation', getFeedbackAndRatingPages?: { __typename: 'FeedbackAndRatingList', feedbackAndRatingList?: Array<{ __typename: 'FeedBackAndRating', id?: number | null, feedback?: string | null, rating?: number | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null, next_trigger_at?: number | null, app_version?: string | null } | null> | null, pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null } | null };

export type GetAllRolePermissionsPagesMutationVariables = Exact<{
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetAllRolePermissionsPagesMutation = { __typename?: 'Mutation', getAllRolePermissionsPages?: { __typename: 'RolePermissionList', rolePermissionList?: Array<{ __typename: 'rolePermissionsResponse', roleId?: number | null, roleName?: string | null, roleDescription?: string | null, created_by?: string | null, created_at?: number | null, updated_by?: string | null, updated_at?: number | null, permissions?: Array<{ __typename: 'Permissions', id?: number | null, permissionName?: string | null, description?: string | null } | null> | null } | null> | null, pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null } | null };

export type GetAllArchivedUserPagesMutationVariables = Exact<{
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetAllArchivedUserPagesMutation = { __typename?: 'Mutation', getAllArchivedUserPages?: { __typename: 'ArchivedUsersList', archivedUsersList?: Array<{ __typename: 'UserInfoForAdmin', familyName?: string | null, givenName?: string | null, groupNames?: Array<string | null> | null, roleNames?: Array<string | null> | null, uniqueName?: string | null, uniqueId?: number | null, roleIds?: Array<number | null> | null, deleted_at?: number | null, groupIds?: Array<number | null> | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null, last_active_at?: number | null } | null> | null, pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null } | null };

export type GetAllGroupSectionsDocsPagesMutationVariables = Exact<{
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetAllGroupSectionsDocsPagesMutation = { __typename?: 'Mutation', getAllGroupSectionsDocsPages?: { __typename: 'GroupList', groupList?: Array<{ __typename: 'groupSectionsDocsResponse', groupId?: number | null, groupName?: string | null, groupDescription?: string | null, folderCount?: number | null, documentCount?: number | null, created_by?: string | null, created_at?: number | null, updated_by?: string | null, updated_at?: number | null } | null> | null, pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null } | null };

export type GetVideosMetadataForAdminPagesMutationVariables = Exact<{
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetVideosMetadataForAdminPagesMutation = { __typename?: 'Mutation', getVideosMetadataForAdminPages?: { __typename: 'VideoMetadataList', videoMetadataList?: Array<{ __typename: 'FileMetadataAdminForm', is_support?: boolean | null, fileName?: string | null, fileURL?: string | null, file_bytes?: number | null, is_video?: boolean | null, auto_download?: boolean | null, downloadURL?: string | null, thumbnail?: string | null, duration?: number | null, created_at?: number | null, updated_at?: number | null, notify_user?: boolean | null, sensitivity_label?: string | null, id?: string | null, folder_id?: string | null, filePath?: string | null, roles?: Array<{ __typename: 'UserRoles', id?: number | null, name?: string | null } | null> | null } | null> | null, pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null } | null };

export type GetDocMetadataForAdminPagesMutationVariables = Exact<{
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetDocMetadataForAdminPagesMutation = { __typename?: 'Mutation', getDocMetadataForAdminPages?: { __typename: 'FileMetadataList', fileMetadataList?: Array<{ __typename: 'FileMetadataAdminForm', is_support?: boolean | null, fileName?: string | null, fileURL?: string | null, file_bytes?: number | null, is_video?: boolean | null, auto_download?: boolean | null, sensitivity_label?: string | null, downloadURL?: string | null, thumbnail?: string | null, duration?: number | null, created_at?: number | null, updated_at?: number | null, notify_user?: boolean | null, section?: string | null, id?: string | null, folder_id?: string | null, filePath?: string | null, roles?: Array<{ __typename: 'UserRoles', id?: number | null, name?: string | null } | null> | null } | null> | null, pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null } | null };

export type GetAllNotificationsPagesMutationVariables = Exact<{
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetAllNotificationsPagesMutation = { __typename?: 'Mutation', getAllNotificationsPages?: { __typename: 'NotificationList', notificationList?: Array<{ __typename: 'NotificationInfoForAdmin', title?: string | null, description?: string | null, body?: string | null, uniqueId?: number | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null } | null> | null, pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null } | null };

export type GetAllCronJobPagesMutationVariables = Exact<{
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetAllCronJobPagesMutation = { __typename?: 'Mutation', getAllCronJobPages?: { __typename: 'CronJobList', cronJobList?: Array<{ __typename: 'CronJob', uniqueId?: number | null, cronjobname?: string | null, lastrunat?: number | null, nextrunat?: number | null, lastrunstatus?: string | null, description?: string | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null, isActive?: boolean | null } | null> | null, pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null } | null };

export type GetAllPermissionsPagesMutationVariables = Exact<{
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetAllPermissionsPagesMutation = { __typename?: 'Mutation', getAllPermissionsPages?: { __typename: 'PermissionList', permissionList?: Array<{ __typename?: 'Permissions', id?: number | null, permissionName?: string | null, description?: string | null } | null> | null, pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null } | null };

export type DeleteNotificationSchedulesByIdMutationVariables = Exact<{
  uniqueId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteNotificationSchedulesByIdMutation = { __typename?: 'Mutation', deleteNotificationSchedulesById?: Array<number | null> | null };

export type NotificationScheduleListUpdateMutationVariables = Exact<{
  notificationScheduleUpdate?: InputMaybe<Array<InputMaybe<NotificationScheduleUpdate>> | InputMaybe<NotificationScheduleUpdate>>;
}>;


export type NotificationScheduleListUpdateMutation = { __typename?: 'Mutation', notificationScheduleListUpdate?: Array<{ __typename?: 'NotificationScheduleForAdmin', uniqueId?: number | null, notificationId?: number | null, title?: string | null, body?: string | null, description?: string | null, groupIds?: Array<number | null> | null, userIds?: Array<number | null> | null, notifyAt?: number | null, isPushNotification?: boolean | null, notificationStatus?: string | null, notificationMetadata?: string | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null } | null> | null };

export type GetAllNotificationSchedulePagesMutationVariables = Exact<{
  uniqueId: Scalars['Int']['input'];
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetAllNotificationSchedulePagesMutation = { __typename?: 'Mutation', getAllNotificationSchedulePages?: { __typename: 'NotificationScheduleList', notificationScheduleList?: Array<{ __typename: 'NotificationScheduleForAdmin', title?: string | null, description?: string | null, body?: string | null, uniqueId?: number | null, notificationId?: number | null, groupIds?: Array<number | null> | null, groupNames?: Array<string | null> | null, userIds?: Array<number | null> | null, userNames?: Array<string | null> | null, notifyAt?: number | null, isPushNotification?: boolean | null, notificationStatus?: string | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null } | null> | null, pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null } | null };

export type GetDocumentsByFolderIdPagesMutationVariables = Exact<{
  uniqueId: Scalars['String']['input'];
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetDocumentsByFolderIdPagesMutation = { __typename?: 'Mutation', getDocumentsByFolderIdPages?: { __typename: 'FileMetadataList', pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, startTime?: number | null, endTime?: number | null, time_window_column?: string | null, hasNext?: string | null } | null, fileMetadataList?: Array<{ __typename: 'FileMetadataAdminForm', file_bytes?: number | null, id?: string | null, is_support?: boolean | null, fileName?: string | null, created_at?: number | null, updated_at?: number | null, sensitivity_label?: string | null, fileURL?: string | null, downloadURL?: string | null } | null> | null } | null };

export type GetAllAuditLogsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllAuditLogsQuery = { __typename?: 'Query', getAllAuditLogs?: Array<{ __typename: 'UserAuditLogs', action?: string | null, actorEmail?: string | null, recordPrimaryKey?: string | null, tableName?: string | null, timestamp?: number | null } | null> | null, getAuditLogCronJob?: { __typename: 'CronJob', uniqueId?: number | null, cronjobname?: string | null, lastrunat?: number | null, nextrunat?: number | null, lastrunstatus?: string | null, description?: string | null, created_by?: string | null, created_at?: number | null, updated_by?: string | null } | null };

export type GetDocumentsByGroupIdPagesMutationVariables = Exact<{
  uniqueId: Scalars['Int']['input'];
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetDocumentsByGroupIdPagesMutation = { __typename?: 'Mutation', getDocumentsByGroupIdPages?: { __typename: 'DocumentsByGroupIdList', pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, startTime?: number | null, endTime?: number | null, time_window_column?: string | null, hasNext?: string | null } | null, documentsByGroupIdList?: Array<{ __typename: 'DocumentsList', id?: string | null, documentName?: string | null } | null> | null } | null };

export type GetFoldersByGroupIdPagesMutationVariables = Exact<{
  uniqueId: Scalars['Int']['input'];
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetFoldersByGroupIdPagesMutation = { __typename?: 'Mutation', getFoldersByGroupIdPages?: { __typename: 'FolderByGroupIdList', pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, startTime?: number | null, endTime?: number | null, time_window_column?: string | null, hasNext?: string | null } | null, foldersByGroupIdList?: Array<{ __typename: 'FoldersList', id?: string | null, folderName?: string | null } | null> | null } | null };

export type GetUsersByGroupIdPagesMutationVariables = Exact<{
  uniqueId: Scalars['Int']['input'];
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetUsersByGroupIdPagesMutation = { __typename?: 'Mutation', getUsersByGroupIdPages?: { __typename: 'UsersByGroupIdList', pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, startTime?: number | null, endTime?: number | null, time_window_column?: string | null, hasNext?: string | null } | null, usersByGroupIdList?: Array<{ __typename: 'UserList', uniqueId?: number | null, familyName?: string | null, givenName?: string | null, uniqueName?: string | null } | null> | null } | null };

export type GetAllActionsAndActorsMutationVariables = Exact<{
  key: Scalars['String']['input'];
}>;


export type GetAllActionsAndActorsMutation = { __typename?: 'Mutation', getAllActionsAndActors?: Array<string | null> | null };

export type EmailEnableDisableMutationVariables = Exact<{
  enabled: Scalars['String']['input'];
}>;


export type EmailEnableDisableMutation = { __typename?: 'Mutation', emailEnableDisable?: { __typename?: 'EmailService', response?: string | null } | null };

export type GetEmailSettingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailSettingQuery = { __typename?: 'Query', getEmailSetting?: { __typename?: 'EmailService', response?: string | null } | null };

export type GetAllValidUsersUniqueNameQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllValidUsersUniqueNameQuery = { __typename?: 'Query', getAllValidUsersUniqueName?: Array<string | null> | null };

export type GetEmailNotificationDeatilsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmailNotificationDeatilsQuery = { __typename?: 'Query', getEmailNotificationDeatils?: { __typename?: 'EmailNotification', uniqueId?: string | null, subject?: string | null, notification_name?: string | null, recipient_to?: Array<string | null> | null, recipient_cc?: Array<string | null> | null, recipient_bcc?: Array<string | null> | null, sender?: string | null, sender_name?: string | null, created_by?: string | null, created_at?: number | null, updated_by?: string | null, updated_at?: number | null, deleted_by?: string | null, deleted_at?: number | null } | null };

export type UpdateEmailNotificationMutationVariables = Exact<{
  uniqueId: Scalars['ID']['input'];
  recipient_to: Array<Scalars['String']['input']> | Scalars['String']['input'];
  recipient_cc: Array<Scalars['String']['input']> | Scalars['String']['input'];
  recipient_bcc: Array<Scalars['String']['input']> | Scalars['String']['input'];
  sender: Scalars['String']['input'];
  sender_name: Scalars['String']['input'];
  subject: Scalars['String']['input'];
}>;


export type UpdateEmailNotificationMutation = { __typename?: 'Mutation', updateEmailNotification?: { __typename: 'EmailNotification', uniqueId?: string | null, recipient_to?: Array<string | null> | null, recipient_cc?: Array<string | null> | null, recipient_bcc?: Array<string | null> | null, sender?: string | null, sender_name?: string | null, subject?: string | null } | null };

export type GetCronJobAuditLogMutationVariables = Exact<{
  cronJob: Scalars['String']['input'];
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetCronJobAuditLogMutation = { __typename?: 'Mutation', getCronJobAuditLog?: { __typename: 'AuditLogCronJobList', cronAuditLogs?: Array<{ __typename: 'CronJobListByName', id?: number | null, actorEmail?: string | null, tableName?: string | null, action?: string | null, recordPrimaryKey?: string | null, status?: string | null, timestamp?: number | null, rowsAffected?: number | null, endTimestamp?: number | null } | null> | null, pageInfo?: { __typename: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null } | null };

export type GetUsersExcludedFromGroupMutationVariables = Exact<{
  uniqueId: Scalars['Int']['input'];
}>;


export type GetUsersExcludedFromGroupMutation = { __typename?: 'Mutation', getUsersExcludedFromGroup?: Array<{ __typename: 'UserInfo', uniqueId?: number | null, uniqueName?: string | null } | null> | null };

export type GetCronJobAuditLogDownloadReportMutationVariables = Exact<{
  cronJob: Scalars['String']['input'];
}>;


export type GetCronJobAuditLogDownloadReportMutation = { __typename?: 'Mutation', getCronJobAuditLogDownloadReport?: Array<{ __typename: 'CronJobListByName', id?: number | null, actorEmail?: string | null, tableName?: string | null, action?: string | null, recordPrimaryKey?: string | null, status?: string | null, rowsAffected?: number | null, timestamp?: number | null, endTimestamp?: number | null } | null> | null };

export type RemoveUserFromGroupMutationVariables = Exact<{
  addUserToGroupArgsList?: InputMaybe<Array<InputMaybe<AddUserToGroupArgs>> | InputMaybe<AddUserToGroupArgs>>;
}>;


export type RemoveUserFromGroupMutation = { __typename?: 'Mutation', removeUserFromGroup?: Array<{ __typename: 'userGroup', userId?: number | null, groupId?: string | null } | null> | null };

export type AddUserToGroupMutationVariables = Exact<{
  addUserToGroupArgsList?: InputMaybe<Array<InputMaybe<AddUserToGroupArgs>> | InputMaybe<AddUserToGroupArgs>>;
}>;


export type AddUserToGroupMutation = { __typename?: 'Mutation', addUserToGroup?: Array<{ __typename: 'userGroup', userId?: number | null, groupId?: string | null } | null> | null };

export type SyncUsersWithAadQueryVariables = Exact<{ [key: string]: never; }>;


export type SyncUsersWithAadQuery = { __typename?: 'Query', syncUsersWithAAD?: { __typename: 'CronJob', uniqueId?: number | null, cronjobname?: string | null, lastrunat?: number | null, nextrunat?: number | null, lastrunstatus?: string | null, description?: string | null, isActive?: boolean | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null } | null };

export type ProcessPushNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProcessPushNotificationsQuery = { __typename?: 'Query', processPushNotifications?: { __typename: 'CronJob', uniqueId?: number | null, cronjobname?: string | null, lastrunat?: number | null, nextrunat?: number | null, lastrunstatus?: string | null, description?: string | null, isActive?: boolean | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null } | null };

export type CleanAuditLogsQueryVariables = Exact<{ [key: string]: never; }>;


export type CleanAuditLogsQuery = { __typename?: 'Query', cleanAuditLogs?: { __typename: 'CronJob', uniqueId?: number | null, cronjobname?: string | null, lastrunat?: number | null, nextrunat?: number | null, lastrunstatus?: string | null, description?: string | null, isActive?: boolean | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null } | null };

export type GetFilesByUserIdMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
}>;


export type GetFilesByUserIdMutation = { __typename?: 'Mutation', getFilesByUserId?: { __typename?: 'ListOfFilesResponse', videoFiles?: Array<{ __typename?: 'GraphFilesMetadata', id?: string | null, file_url?: string | null, file_name?: string | null, file_bytes?: number | null, created_at?: number | null, updated_at?: number | null, folder_id?: string | null, folder_name?: string | null, auto_download?: boolean | null, notify_user?: boolean | null, bookmarked_id?: number | null, duration?: number | null, thumbnail?: string | null, is_video?: boolean | null, download_url?: string | null } | null> | null, supportFiles?: Array<{ __typename?: 'GraphFilesMetadata', id?: string | null, file_url?: string | null, file_name?: string | null, file_bytes?: number | null, created_at?: number | null, updated_at?: number | null, folder_id?: string | null, folder_name?: string | null, auto_download?: boolean | null, notify_user?: boolean | null, bookmarked_id?: number | null, duration?: number | null, thumbnail?: string | null, is_video?: boolean | null, download_url?: string | null } | null> | null, docFiles?: Array<{ __typename?: 'GraphFilesMetadata', id?: string | null, file_url?: string | null, file_name?: string | null, file_bytes?: number | null, created_at?: number | null, updated_at?: number | null, folder_id?: string | null, folder_name?: string | null, auto_download?: boolean | null, notify_user?: boolean | null, bookmarked_id?: number | null, duration?: number | null, thumbnail?: string | null, is_video?: boolean | null, download_url?: string | null } | null> | null } | null };

export type SaveStaticPageMutationVariables = Exact<{
  title: Scalars['String']['input'];
  body: Scalars['String']['input'];
  slug: Scalars['String']['input'];
}>;


export type SaveStaticPageMutation = { __typename?: 'Mutation', saveStaticPage?: { __typename?: 'StaticPage', title?: string | null, body?: string | null, slug?: string | null } | null };

export type UpdateStaticPageMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  body: Scalars['String']['input'];
  slug: Scalars['String']['input'];
}>;


export type UpdateStaticPageMutation = { __typename?: 'Mutation', updateStaticPage?: { __typename?: 'StaticPage', id?: number | null, title?: string | null, body?: string | null, slug?: string | null } | null };

export type DeleteStaticPageMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type DeleteStaticPageMutation = { __typename?: 'Mutation', deleteStaticPage?: { __typename: 'StaticPage', id?: number | null } | null };

export type GetAllStaticPageMutationVariables = Exact<{
  PageInfoInput?: InputMaybe<PageInfoInput>;
}>;


export type GetAllStaticPageMutation = { __typename?: 'Mutation', getAllStaticPage?: { __typename?: 'StaticPageList', staticPageList?: Array<{ __typename?: 'StaticPage', id?: number | null, uniqueId?: string | null, body?: string | null, title?: string | null, slug?: string | null, created_at?: number | null, created_by?: string | null, updated_at?: number | null, updated_by?: string | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', current_page?: number | null, per_page?: number | null, total_pages?: number | null, total_records?: number | null, fetched_records?: number | null, from?: number | null, to?: number | null, search_term?: string | null, search_column?: string | null, order_by?: string | null, order_by_column?: string | null, hasNext?: string | null } | null } | null };

export type GetStaticPageByIdMutationVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type GetStaticPageByIdMutation = { __typename?: 'Mutation', getStaticPageById?: { __typename: 'StaticPage', id?: number | null, uniqueId?: string | null, title?: string | null, body?: string | null, slug?: string | null, created_by?: string | null, created_at?: number | null, updated_by?: string | null, updated_at?: number | null } | null };

export type DownloadUserNotificationResponseMutationVariables = Exact<{
  uniqueId: Scalars['Int']['input'];
}>;


export type DownloadUserNotificationResponseMutation = { __typename?: 'Mutation', downloadUserNotificationResponse?: Array<{ __typename?: 'NotificationRespForAdmin', uniqueId?: number | null, notificationScheduleId?: number | null, notifyAt?: number | null, respondedBy?: string | null, createdAt?: number | null, userResponse?: Array<{ __typename?: 'userResponses', key?: string | null, value?: string | null } | null> | null } | null> | null };


export const GetAllDocumentsFoldersDocument = gql`
    query getAllDocumentsFolders {
  getAllDocumentsFolders {
    display_name
    id
    storage_path
    created_at
    updated_at
    notify_user
  }
}
    `;

/**
 * __useGetAllDocumentsFoldersQuery__
 *
 * To run a query within a React component, call `useGetAllDocumentsFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDocumentsFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDocumentsFoldersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDocumentsFoldersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDocumentsFoldersQuery, GetAllDocumentsFoldersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDocumentsFoldersQuery, GetAllDocumentsFoldersQueryVariables>(GetAllDocumentsFoldersDocument, options);
      }
export function useGetAllDocumentsFoldersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDocumentsFoldersQuery, GetAllDocumentsFoldersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDocumentsFoldersQuery, GetAllDocumentsFoldersQueryVariables>(GetAllDocumentsFoldersDocument, options);
        }
export type GetAllDocumentsFoldersQueryHookResult = ReturnType<typeof useGetAllDocumentsFoldersQuery>;
export type GetAllDocumentsFoldersLazyQueryHookResult = ReturnType<typeof useGetAllDocumentsFoldersLazyQuery>;
export type GetAllDocumentsFoldersQueryResult = Apollo.QueryResult<GetAllDocumentsFoldersQuery, GetAllDocumentsFoldersQueryVariables>;
export const SystemConfigsResponseDocument = gql`
    query systemConfigsResponse {
  systemConfigsResponse {
    app_support_email {
      email_id
      subject
    }
    app_version {
      ios
    }
    gocConfigs {
      primary
      secondary
      tertiary
    }
    service_desk {
      key
      value {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useSystemConfigsResponseQuery__
 *
 * To run a query within a React component, call `useSystemConfigsResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemConfigsResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemConfigsResponseQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemConfigsResponseQuery(baseOptions?: Apollo.QueryHookOptions<SystemConfigsResponseQuery, SystemConfigsResponseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemConfigsResponseQuery, SystemConfigsResponseQueryVariables>(SystemConfigsResponseDocument, options);
      }
export function useSystemConfigsResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemConfigsResponseQuery, SystemConfigsResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemConfigsResponseQuery, SystemConfigsResponseQueryVariables>(SystemConfigsResponseDocument, options);
        }
export type SystemConfigsResponseQueryHookResult = ReturnType<typeof useSystemConfigsResponseQuery>;
export type SystemConfigsResponseLazyQueryHookResult = ReturnType<typeof useSystemConfigsResponseLazyQuery>;
export type SystemConfigsResponseQueryResult = Apollo.QueryResult<SystemConfigsResponseQuery, SystemConfigsResponseQueryVariables>;
export const GetCurrentUserPermissionsDocument = gql`
    query getCurrentUserPermissions {
  getCurrentUserPermissions {
    uniqueName
    uniqueId
    assignedPermissions
  }
  getAllAPIPermissions {
    accessType
    assignedPermissions
  }
}
    `;

/**
 * __useGetCurrentUserPermissionsQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserPermissionsQuery, GetCurrentUserPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserPermissionsQuery, GetCurrentUserPermissionsQueryVariables>(GetCurrentUserPermissionsDocument, options);
      }
export function useGetCurrentUserPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserPermissionsQuery, GetCurrentUserPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserPermissionsQuery, GetCurrentUserPermissionsQueryVariables>(GetCurrentUserPermissionsDocument, options);
        }
export type GetCurrentUserPermissionsQueryHookResult = ReturnType<typeof useGetCurrentUserPermissionsQuery>;
export type GetCurrentUserPermissionsLazyQueryHookResult = ReturnType<typeof useGetCurrentUserPermissionsLazyQuery>;
export type GetCurrentUserPermissionsQueryResult = Apollo.QueryResult<GetCurrentUserPermissionsQuery, GetCurrentUserPermissionsQueryVariables>;
export const GetAllPermissionsDocument = gql`
    query getAllPermissions {
  getAllPermissions {
    description
    id
    permissionName
  }
}
    `;

/**
 * __useGetAllPermissionsQuery__
 *
 * To run a query within a React component, call `useGetAllPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPermissionsQuery, GetAllPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPermissionsQuery, GetAllPermissionsQueryVariables>(GetAllPermissionsDocument, options);
      }
export function useGetAllPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPermissionsQuery, GetAllPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPermissionsQuery, GetAllPermissionsQueryVariables>(GetAllPermissionsDocument, options);
        }
export type GetAllPermissionsQueryHookResult = ReturnType<typeof useGetAllPermissionsQuery>;
export type GetAllPermissionsLazyQueryHookResult = ReturnType<typeof useGetAllPermissionsLazyQuery>;
export type GetAllPermissionsQueryResult = Apollo.QueryResult<GetAllPermissionsQuery, GetAllPermissionsQueryVariables>;
export const GetAllRolePermissionsDocument = gql`
    query getAllRolePermissions {
  getAllRolePermissions {
    roleId
    roleName
    roleDescription
    created_by
    created_at
    updated_by
    updated_at
    permissions {
      id
      permissionName
      description
    }
  }
  getCurrentUserPermissions {
    uniqueName
    assignedPermissions
  }
  getAllAPIPermissions {
    accessType
    assignedPermissions
  }
  getAllPermissions {
    description
    id
    permissionName
  }
}
    `;

/**
 * __useGetAllRolePermissionsQuery__
 *
 * To run a query within a React component, call `useGetAllRolePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRolePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRolePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRolePermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRolePermissionsQuery, GetAllRolePermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRolePermissionsQuery, GetAllRolePermissionsQueryVariables>(GetAllRolePermissionsDocument, options);
      }
export function useGetAllRolePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRolePermissionsQuery, GetAllRolePermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRolePermissionsQuery, GetAllRolePermissionsQueryVariables>(GetAllRolePermissionsDocument, options);
        }
export type GetAllRolePermissionsQueryHookResult = ReturnType<typeof useGetAllRolePermissionsQuery>;
export type GetAllRolePermissionsLazyQueryHookResult = ReturnType<typeof useGetAllRolePermissionsLazyQuery>;
export type GetAllRolePermissionsQueryResult = Apollo.QueryResult<GetAllRolePermissionsQuery, GetAllRolePermissionsQueryVariables>;
export const GetAllUserInfoListDocument = gql`
    query getAllUserInfoList {
  getAllUserInfoList {
    familyName
    givenName
    groupNames
    roleNames
    uniqueName
    uniqueId
    roleIds
    groupIds
    created_at
    created_by
    updated_at
    updated_by
    last_active_at
    jobTitle
    lineOfBusiness
  }
  getAllGroups {
    description
    id
    groupName
  }
  getAllRoles {
    description
    id
    roleName
  }
}
    `;

/**
 * __useGetAllUserInfoListQuery__
 *
 * To run a query within a React component, call `useGetAllUserInfoListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUserInfoListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUserInfoListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUserInfoListQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUserInfoListQuery, GetAllUserInfoListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUserInfoListQuery, GetAllUserInfoListQueryVariables>(GetAllUserInfoListDocument, options);
      }
export function useGetAllUserInfoListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUserInfoListQuery, GetAllUserInfoListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUserInfoListQuery, GetAllUserInfoListQueryVariables>(GetAllUserInfoListDocument, options);
        }
export type GetAllUserInfoListQueryHookResult = ReturnType<typeof useGetAllUserInfoListQuery>;
export type GetAllUserInfoListLazyQueryHookResult = ReturnType<typeof useGetAllUserInfoListLazyQuery>;
export type GetAllUserInfoListQueryResult = Apollo.QueryResult<GetAllUserInfoListQuery, GetAllUserInfoListQueryVariables>;
export const FileMetadataForAdminDocument = gql`
    query fileMetadataForAdmin {
  fileMetadataForAdmin {
    is_support
    fileName
    fileURL
    file_bytes
    is_video
    auto_download
    section
    downloadURL
    sectionOrder
    sensitivity_label
    thumbnail
    duration
    created_at
    updated_at
    roles {
      id
      name
    }
    notify_user
    id
    folder_id
    tenantName
    filePath
  }
  getAllDocumentsFolders {
    display_name
    id
    storage_path
    notify_user
  }
  getAllSenestivityLabel {
    key
    value
  }
}
    `;

/**
 * __useFileMetadataForAdminQuery__
 *
 * To run a query within a React component, call `useFileMetadataForAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileMetadataForAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileMetadataForAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useFileMetadataForAdminQuery(baseOptions?: Apollo.QueryHookOptions<FileMetadataForAdminQuery, FileMetadataForAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileMetadataForAdminQuery, FileMetadataForAdminQueryVariables>(FileMetadataForAdminDocument, options);
      }
export function useFileMetadataForAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileMetadataForAdminQuery, FileMetadataForAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileMetadataForAdminQuery, FileMetadataForAdminQueryVariables>(FileMetadataForAdminDocument, options);
        }
export type FileMetadataForAdminQueryHookResult = ReturnType<typeof useFileMetadataForAdminQuery>;
export type FileMetadataForAdminLazyQueryHookResult = ReturnType<typeof useFileMetadataForAdminLazyQuery>;
export type FileMetadataForAdminQueryResult = Apollo.QueryResult<FileMetadataForAdminQuery, FileMetadataForAdminQueryVariables>;
export const GetDocsMetadataForAdminDocument = gql`
    query getDocsMetadataForAdmin {
  getDocsMetadataForAdmin {
    is_support
    fileName
    fileURL
    file_bytes
    is_video
    auto_download
    section
    downloadURL
    sectionOrder
    sensitivity_label
    thumbnail
    duration
    created_at
    updated_at
    roles {
      id
      name
    }
    notify_user
    id
    folder_id
    tenantName
    filePath
  }
  getAllDocumentsFolders {
    display_name
    id
    storage_path
    notify_user
  }
  getAllSenestivityLabel {
    key
    value
  }
}
    `;

/**
 * __useGetDocsMetadataForAdminQuery__
 *
 * To run a query within a React component, call `useGetDocsMetadataForAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocsMetadataForAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocsMetadataForAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDocsMetadataForAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetDocsMetadataForAdminQuery, GetDocsMetadataForAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocsMetadataForAdminQuery, GetDocsMetadataForAdminQueryVariables>(GetDocsMetadataForAdminDocument, options);
      }
export function useGetDocsMetadataForAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocsMetadataForAdminQuery, GetDocsMetadataForAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocsMetadataForAdminQuery, GetDocsMetadataForAdminQueryVariables>(GetDocsMetadataForAdminDocument, options);
        }
export type GetDocsMetadataForAdminQueryHookResult = ReturnType<typeof useGetDocsMetadataForAdminQuery>;
export type GetDocsMetadataForAdminLazyQueryHookResult = ReturnType<typeof useGetDocsMetadataForAdminLazyQuery>;
export type GetDocsMetadataForAdminQueryResult = Apollo.QueryResult<GetDocsMetadataForAdminQuery, GetDocsMetadataForAdminQueryVariables>;
export const CreateDocumentsFolderDocument = gql`
    mutation CreateDocumentsFolder($display_name: String!, $storage_path: String!, $notify_user: Boolean!) {
  createDocumentsFolder(
    display_name: $display_name
    storage_path: $storage_path
    notify_user: $notify_user
  ) {
    display_name
    id
    storage_path
    notify_user
  }
}
    `;
export type CreateDocumentsFolderMutationFn = Apollo.MutationFunction<CreateDocumentsFolderMutation, CreateDocumentsFolderMutationVariables>;

/**
 * __useCreateDocumentsFolderMutation__
 *
 * To run a mutation, you first call `useCreateDocumentsFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentsFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentsFolderMutation, { data, loading, error }] = useCreateDocumentsFolderMutation({
 *   variables: {
 *      display_name: // value for 'display_name'
 *      storage_path: // value for 'storage_path'
 *      notify_user: // value for 'notify_user'
 *   },
 * });
 */
export function useCreateDocumentsFolderMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentsFolderMutation, CreateDocumentsFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentsFolderMutation, CreateDocumentsFolderMutationVariables>(CreateDocumentsFolderDocument, options);
      }
export type CreateDocumentsFolderMutationHookResult = ReturnType<typeof useCreateDocumentsFolderMutation>;
export type CreateDocumentsFolderMutationResult = Apollo.MutationResult<CreateDocumentsFolderMutation>;
export type CreateDocumentsFolderMutationOptions = Apollo.BaseMutationOptions<CreateDocumentsFolderMutation, CreateDocumentsFolderMutationVariables>;
export const GetAllFeedbackAndRatingDocument = gql`
    query getAllFeedbackAndRating {
  getAllFeedbackAndRating {
    id
    feedback
    rating
    created_by
    created_at
    updated_by
    updated_at
    next_trigger_at
    __typename
  }
}
    `;

/**
 * __useGetAllFeedbackAndRatingQuery__
 *
 * To run a query within a React component, call `useGetAllFeedbackAndRatingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFeedbackAndRatingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFeedbackAndRatingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllFeedbackAndRatingQuery(baseOptions?: Apollo.QueryHookOptions<GetAllFeedbackAndRatingQuery, GetAllFeedbackAndRatingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllFeedbackAndRatingQuery, GetAllFeedbackAndRatingQueryVariables>(GetAllFeedbackAndRatingDocument, options);
      }
export function useGetAllFeedbackAndRatingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFeedbackAndRatingQuery, GetAllFeedbackAndRatingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllFeedbackAndRatingQuery, GetAllFeedbackAndRatingQueryVariables>(GetAllFeedbackAndRatingDocument, options);
        }
export type GetAllFeedbackAndRatingQueryHookResult = ReturnType<typeof useGetAllFeedbackAndRatingQuery>;
export type GetAllFeedbackAndRatingLazyQueryHookResult = ReturnType<typeof useGetAllFeedbackAndRatingLazyQuery>;
export type GetAllFeedbackAndRatingQueryResult = Apollo.QueryResult<GetAllFeedbackAndRatingQuery, GetAllFeedbackAndRatingQueryVariables>;
export const GetCurrentUserNotificationsListDocument = gql`
    query getCurrentUserNotificationsList {
  getCurrentUserNotificationsList {
    uniqueId
    notificationId
    title
    body
    description
    notificationStatus
    isPushNotification
    created_at
    created_by
    updated_at
    updated_by
    notifyAt
    fileId
    fileURL
    filePath
    fileName
    file_bytes
    section
    folder_id
    is_support
    is_video
    auto_download
    isFileNotification
    isNew
    isRead
    __typename
  }
}
    `;

/**
 * __useGetCurrentUserNotificationsListQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserNotificationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserNotificationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserNotificationsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserNotificationsListQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserNotificationsListQuery, GetCurrentUserNotificationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserNotificationsListQuery, GetCurrentUserNotificationsListQueryVariables>(GetCurrentUserNotificationsListDocument, options);
      }
export function useGetCurrentUserNotificationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserNotificationsListQuery, GetCurrentUserNotificationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserNotificationsListQuery, GetCurrentUserNotificationsListQueryVariables>(GetCurrentUserNotificationsListDocument, options);
        }
export type GetCurrentUserNotificationsListQueryHookResult = ReturnType<typeof useGetCurrentUserNotificationsListQuery>;
export type GetCurrentUserNotificationsListLazyQueryHookResult = ReturnType<typeof useGetCurrentUserNotificationsListLazyQuery>;
export type GetCurrentUserNotificationsListQueryResult = Apollo.QueryResult<GetCurrentUserNotificationsListQuery, GetCurrentUserNotificationsListQueryVariables>;
export const GetAllCronJobsDocument = gql`
    query getAllCronJobs {
  getAllCronJobs {
    uniqueId
    cronjobname
    lastrunat
    nextrunat
    lastrunstatus
    description
    created_at
    created_by
    updated_at
    updated_by
  }
}
    `;

/**
 * __useGetAllCronJobsQuery__
 *
 * To run a query within a React component, call `useGetAllCronJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCronJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCronJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCronJobsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCronJobsQuery, GetAllCronJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCronJobsQuery, GetAllCronJobsQueryVariables>(GetAllCronJobsDocument, options);
      }
export function useGetAllCronJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCronJobsQuery, GetAllCronJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCronJobsQuery, GetAllCronJobsQueryVariables>(GetAllCronJobsDocument, options);
        }
export type GetAllCronJobsQueryHookResult = ReturnType<typeof useGetAllCronJobsQuery>;
export type GetAllCronJobsLazyQueryHookResult = ReturnType<typeof useGetAllCronJobsLazyQuery>;
export type GetAllCronJobsQueryResult = Apollo.QueryResult<GetAllCronJobsQuery, GetAllCronJobsQueryVariables>;
export const CreateBookmarkMutationDocument = gql`
    mutation CreateBookmarkMutation($file_url: String!) {
  createBookmarkMutation(file_url: $file_url) {
    user_id
    file_id
    created_at
    id
    __typename
  }
}
    `;
export type CreateBookmarkMutationMutationFn = Apollo.MutationFunction<CreateBookmarkMutationMutation, CreateBookmarkMutationMutationVariables>;

/**
 * __useCreateBookmarkMutationMutation__
 *
 * To run a mutation, you first call `useCreateBookmarkMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookmarkMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookmarkMutationMutation, { data, loading, error }] = useCreateBookmarkMutationMutation({
 *   variables: {
 *      file_url: // value for 'file_url'
 *   },
 * });
 */
export function useCreateBookmarkMutationMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookmarkMutationMutation, CreateBookmarkMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookmarkMutationMutation, CreateBookmarkMutationMutationVariables>(CreateBookmarkMutationDocument, options);
      }
export type CreateBookmarkMutationMutationHookResult = ReturnType<typeof useCreateBookmarkMutationMutation>;
export type CreateBookmarkMutationMutationResult = Apollo.MutationResult<CreateBookmarkMutationMutation>;
export type CreateBookmarkMutationMutationOptions = Apollo.BaseMutationOptions<CreateBookmarkMutationMutation, CreateBookmarkMutationMutationVariables>;
export const GetUserDetailsByIdDocument = gql`
    mutation getUserDetailsById($uniqueId: Int!) {
  getUserDetailsById(uniqueId: $uniqueId) {
    uniqueId
    familyName
    givenName
    groupNames {
      id
      groupName
      description
      created_at
      created_by
      updated_at
      updated_by
    }
    roleNames {
      id
      roleName
      description
      created_at
      created_by
      updated_at
      updated_by
    }
    uniqueName
    lineOfBusiness
    jobTitle
    uniqueId
    created_at
    created_by
    updated_at
    updated_by
    last_active_at
    __typename
  }
}
    `;
export type GetUserDetailsByIdMutationFn = Apollo.MutationFunction<GetUserDetailsByIdMutation, GetUserDetailsByIdMutationVariables>;

/**
 * __useGetUserDetailsByIdMutation__
 *
 * To run a mutation, you first call `useGetUserDetailsByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUserDetailsByIdMutation, { data, loading, error }] = useGetUserDetailsByIdMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *   },
 * });
 */
export function useGetUserDetailsByIdMutation(baseOptions?: Apollo.MutationHookOptions<GetUserDetailsByIdMutation, GetUserDetailsByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetUserDetailsByIdMutation, GetUserDetailsByIdMutationVariables>(GetUserDetailsByIdDocument, options);
      }
export type GetUserDetailsByIdMutationHookResult = ReturnType<typeof useGetUserDetailsByIdMutation>;
export type GetUserDetailsByIdMutationResult = Apollo.MutationResult<GetUserDetailsByIdMutation>;
export type GetUserDetailsByIdMutationOptions = Apollo.BaseMutationOptions<GetUserDetailsByIdMutation, GetUserDetailsByIdMutationVariables>;
export const GetDocumentsFolderByIdDocument = gql`
    mutation getDocumentsFolderById($uniqueId: ID!) {
  getDocumentsFolderById(uniqueId: $uniqueId) {
    id
    display_name
    storage_path
    created_at
    created_by
    updated_at
    updated_by
  }
}
    `;
export type GetDocumentsFolderByIdMutationFn = Apollo.MutationFunction<GetDocumentsFolderByIdMutation, GetDocumentsFolderByIdMutationVariables>;

/**
 * __useGetDocumentsFolderByIdMutation__
 *
 * To run a mutation, you first call `useGetDocumentsFolderByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsFolderByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDocumentsFolderByIdMutation, { data, loading, error }] = useGetDocumentsFolderByIdMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *   },
 * });
 */
export function useGetDocumentsFolderByIdMutation(baseOptions?: Apollo.MutationHookOptions<GetDocumentsFolderByIdMutation, GetDocumentsFolderByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetDocumentsFolderByIdMutation, GetDocumentsFolderByIdMutationVariables>(GetDocumentsFolderByIdDocument, options);
      }
export type GetDocumentsFolderByIdMutationHookResult = ReturnType<typeof useGetDocumentsFolderByIdMutation>;
export type GetDocumentsFolderByIdMutationResult = Apollo.MutationResult<GetDocumentsFolderByIdMutation>;
export type GetDocumentsFolderByIdMutationOptions = Apollo.BaseMutationOptions<GetDocumentsFolderByIdMutation, GetDocumentsFolderByIdMutationVariables>;
export const GetAllResourceCountsDocument = gql`
    query getAllResourceCounts {
  getAllResourceCounts {
    users
    documents
    videos
    sections
    feedbackAndRating
    groups
    roles
    notifications
  }
}
    `;

/**
 * __useGetAllResourceCountsQuery__
 *
 * To run a query within a React component, call `useGetAllResourceCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllResourceCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllResourceCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllResourceCountsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllResourceCountsQuery, GetAllResourceCountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllResourceCountsQuery, GetAllResourceCountsQueryVariables>(GetAllResourceCountsDocument, options);
      }
export function useGetAllResourceCountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllResourceCountsQuery, GetAllResourceCountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllResourceCountsQuery, GetAllResourceCountsQueryVariables>(GetAllResourceCountsDocument, options);
        }
export type GetAllResourceCountsQueryHookResult = ReturnType<typeof useGetAllResourceCountsQuery>;
export type GetAllResourceCountsLazyQueryHookResult = ReturnType<typeof useGetAllResourceCountsLazyQuery>;
export type GetAllResourceCountsQueryResult = Apollo.QueryResult<GetAllResourceCountsQuery, GetAllResourceCountsQueryVariables>;
export const UserInfoListCreateDocument = gql`
    mutation userInfoListCreate($userInfoCreate: [userInfoCreate]) {
  userInfoListCreate(userInfoList: $userInfoCreate) {
    familyName
    givenName
    groupIds
    roleIds
    uniqueName
    uniqueId
  }
}
    `;
export type UserInfoListCreateMutationFn = Apollo.MutationFunction<UserInfoListCreateMutation, UserInfoListCreateMutationVariables>;

/**
 * __useUserInfoListCreateMutation__
 *
 * To run a mutation, you first call `useUserInfoListCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserInfoListCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userInfoListCreateMutation, { data, loading, error }] = useUserInfoListCreateMutation({
 *   variables: {
 *      userInfoCreate: // value for 'userInfoCreate'
 *   },
 * });
 */
export function useUserInfoListCreateMutation(baseOptions?: Apollo.MutationHookOptions<UserInfoListCreateMutation, UserInfoListCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserInfoListCreateMutation, UserInfoListCreateMutationVariables>(UserInfoListCreateDocument, options);
      }
export type UserInfoListCreateMutationHookResult = ReturnType<typeof useUserInfoListCreateMutation>;
export type UserInfoListCreateMutationResult = Apollo.MutationResult<UserInfoListCreateMutation>;
export type UserInfoListCreateMutationOptions = Apollo.BaseMutationOptions<UserInfoListCreateMutation, UserInfoListCreateMutationVariables>;
export const DeleteUserInfosByIdDocument = gql`
    mutation deleteUserInfosById($uniqueId: [Int!]) {
  deleteUserInfosById(uniqueId: $uniqueId)
}
    `;
export type DeleteUserInfosByIdMutationFn = Apollo.MutationFunction<DeleteUserInfosByIdMutation, DeleteUserInfosByIdMutationVariables>;

/**
 * __useDeleteUserInfosByIdMutation__
 *
 * To run a mutation, you first call `useDeleteUserInfosByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserInfosByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserInfosByIdMutation, { data, loading, error }] = useDeleteUserInfosByIdMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *   },
 * });
 */
export function useDeleteUserInfosByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserInfosByIdMutation, DeleteUserInfosByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserInfosByIdMutation, DeleteUserInfosByIdMutationVariables>(DeleteUserInfosByIdDocument, options);
      }
export type DeleteUserInfosByIdMutationHookResult = ReturnType<typeof useDeleteUserInfosByIdMutation>;
export type DeleteUserInfosByIdMutationResult = Apollo.MutationResult<DeleteUserInfosByIdMutation>;
export type DeleteUserInfosByIdMutationOptions = Apollo.BaseMutationOptions<DeleteUserInfosByIdMutation, DeleteUserInfosByIdMutationVariables>;
export const UserInfoListUpdateDocument = gql`
    mutation userInfoListUpdate($userInfoUpdate: [userInfoUpdate]) {
  userInfoListUpdate(userInfoList: $userInfoUpdate) {
    familyName
    givenName
    groupIds
    roleIds
    uniqueName
  }
}
    `;
export type UserInfoListUpdateMutationFn = Apollo.MutationFunction<UserInfoListUpdateMutation, UserInfoListUpdateMutationVariables>;

/**
 * __useUserInfoListUpdateMutation__
 *
 * To run a mutation, you first call `useUserInfoListUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserInfoListUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userInfoListUpdateMutation, { data, loading, error }] = useUserInfoListUpdateMutation({
 *   variables: {
 *      userInfoUpdate: // value for 'userInfoUpdate'
 *   },
 * });
 */
export function useUserInfoListUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserInfoListUpdateMutation, UserInfoListUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserInfoListUpdateMutation, UserInfoListUpdateMutationVariables>(UserInfoListUpdateDocument, options);
      }
export type UserInfoListUpdateMutationHookResult = ReturnType<typeof useUserInfoListUpdateMutation>;
export type UserInfoListUpdateMutationResult = Apollo.MutationResult<UserInfoListUpdateMutation>;
export type UserInfoListUpdateMutationOptions = Apollo.BaseMutationOptions<UserInfoListUpdateMutation, UserInfoListUpdateMutationVariables>;
export const UpdateDocumentsFolderDocument = gql`
    mutation updateDocumentsFolder($id: ID!, $display_name: String!, $storage_path: String!, $notify_user: Boolean!) {
  updateDocumentsFolder(
    id: $id
    display_name: $display_name
    storage_path: $storage_path
    notify_user: $notify_user
  ) {
    display_name
    id
    storage_path
    notify_user
  }
}
    `;
export type UpdateDocumentsFolderMutationFn = Apollo.MutationFunction<UpdateDocumentsFolderMutation, UpdateDocumentsFolderMutationVariables>;

/**
 * __useUpdateDocumentsFolderMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentsFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentsFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentsFolderMutation, { data, loading, error }] = useUpdateDocumentsFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      display_name: // value for 'display_name'
 *      storage_path: // value for 'storage_path'
 *      notify_user: // value for 'notify_user'
 *   },
 * });
 */
export function useUpdateDocumentsFolderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentsFolderMutation, UpdateDocumentsFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentsFolderMutation, UpdateDocumentsFolderMutationVariables>(UpdateDocumentsFolderDocument, options);
      }
export type UpdateDocumentsFolderMutationHookResult = ReturnType<typeof useUpdateDocumentsFolderMutation>;
export type UpdateDocumentsFolderMutationResult = Apollo.MutationResult<UpdateDocumentsFolderMutation>;
export type UpdateDocumentsFolderMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentsFolderMutation, UpdateDocumentsFolderMutationVariables>;
export const DeleteDocumentsFolderDocument = gql`
    mutation deleteDocumentsFolder($id: [ID!]) {
  deleteDocumentsFolder(id: $id) {
    id
    __typename
  }
}
    `;
export type DeleteDocumentsFolderMutationFn = Apollo.MutationFunction<DeleteDocumentsFolderMutation, DeleteDocumentsFolderMutationVariables>;

/**
 * __useDeleteDocumentsFolderMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentsFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentsFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentsFolderMutation, { data, loading, error }] = useDeleteDocumentsFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentsFolderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentsFolderMutation, DeleteDocumentsFolderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentsFolderMutation, DeleteDocumentsFolderMutationVariables>(DeleteDocumentsFolderDocument, options);
      }
export type DeleteDocumentsFolderMutationHookResult = ReturnType<typeof useDeleteDocumentsFolderMutation>;
export type DeleteDocumentsFolderMutationResult = Apollo.MutationResult<DeleteDocumentsFolderMutation>;
export type DeleteDocumentsFolderMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentsFolderMutation, DeleteDocumentsFolderMutationVariables>;
export const DeleteBulkFileMetadataByUrlDocument = gql`
    mutation deleteBulkFileMetadataByUrl($fileUrls: [String!]) {
  deleteBulkFileMetadataByUrl(fileUrls: $fileUrls) {
    fileName
  }
}
    `;
export type DeleteBulkFileMetadataByUrlMutationFn = Apollo.MutationFunction<DeleteBulkFileMetadataByUrlMutation, DeleteBulkFileMetadataByUrlMutationVariables>;

/**
 * __useDeleteBulkFileMetadataByUrlMutation__
 *
 * To run a mutation, you first call `useDeleteBulkFileMetadataByUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBulkFileMetadataByUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBulkFileMetadataByUrlMutation, { data, loading, error }] = useDeleteBulkFileMetadataByUrlMutation({
 *   variables: {
 *      fileUrls: // value for 'fileUrls'
 *   },
 * });
 */
export function useDeleteBulkFileMetadataByUrlMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBulkFileMetadataByUrlMutation, DeleteBulkFileMetadataByUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBulkFileMetadataByUrlMutation, DeleteBulkFileMetadataByUrlMutationVariables>(DeleteBulkFileMetadataByUrlDocument, options);
      }
export type DeleteBulkFileMetadataByUrlMutationHookResult = ReturnType<typeof useDeleteBulkFileMetadataByUrlMutation>;
export type DeleteBulkFileMetadataByUrlMutationResult = Apollo.MutationResult<DeleteBulkFileMetadataByUrlMutation>;
export type DeleteBulkFileMetadataByUrlMutationOptions = Apollo.BaseMutationOptions<DeleteBulkFileMetadataByUrlMutation, DeleteBulkFileMetadataByUrlMutationVariables>;
export const UpdateMutationForFileMetadataDocument = gql`
    mutation updateMutationForFileMetadata($fileMetadataListForUpdate: fileMetadataForUpdate!) {
  updateMutationForFileMetadata(
    fileMetadataListForUpdate: [$fileMetadataListForUpdate]
  ) {
    checksum
    downloadURL
    fileName
    sensitivity_label
    notify_user
    fileURL
  }
}
    `;
export type UpdateMutationForFileMetadataMutationFn = Apollo.MutationFunction<UpdateMutationForFileMetadataMutation, UpdateMutationForFileMetadataMutationVariables>;

/**
 * __useUpdateMutationForFileMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateMutationForFileMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMutationForFileMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMutationForFileMetadataMutation, { data, loading, error }] = useUpdateMutationForFileMetadataMutation({
 *   variables: {
 *      fileMetadataListForUpdate: // value for 'fileMetadataListForUpdate'
 *   },
 * });
 */
export function useUpdateMutationForFileMetadataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMutationForFileMetadataMutation, UpdateMutationForFileMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMutationForFileMetadataMutation, UpdateMutationForFileMetadataMutationVariables>(UpdateMutationForFileMetadataDocument, options);
      }
export type UpdateMutationForFileMetadataMutationHookResult = ReturnType<typeof useUpdateMutationForFileMetadataMutation>;
export type UpdateMutationForFileMetadataMutationResult = Apollo.MutationResult<UpdateMutationForFileMetadataMutation>;
export type UpdateMutationForFileMetadataMutationOptions = Apollo.BaseMutationOptions<UpdateMutationForFileMetadataMutation, UpdateMutationForFileMetadataMutationVariables>;
export const UpdateThumbnailAndDurationDocument = gql`
    mutation updateThumbnailAndDuration($thumbnailAndDurationUpdateList: [updateThumbnailAndDuration]) {
  updateThumbnailAndDuration(
    thumbnailAndDurationUpdateList: $thumbnailAndDurationUpdateList
  ) {
    fileURL
    created_at
    id
  }
}
    `;
export type UpdateThumbnailAndDurationMutationFn = Apollo.MutationFunction<UpdateThumbnailAndDurationMutation, UpdateThumbnailAndDurationMutationVariables>;

/**
 * __useUpdateThumbnailAndDurationMutation__
 *
 * To run a mutation, you first call `useUpdateThumbnailAndDurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThumbnailAndDurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThumbnailAndDurationMutation, { data, loading, error }] = useUpdateThumbnailAndDurationMutation({
 *   variables: {
 *      thumbnailAndDurationUpdateList: // value for 'thumbnailAndDurationUpdateList'
 *   },
 * });
 */
export function useUpdateThumbnailAndDurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateThumbnailAndDurationMutation, UpdateThumbnailAndDurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateThumbnailAndDurationMutation, UpdateThumbnailAndDurationMutationVariables>(UpdateThumbnailAndDurationDocument, options);
      }
export type UpdateThumbnailAndDurationMutationHookResult = ReturnType<typeof useUpdateThumbnailAndDurationMutation>;
export type UpdateThumbnailAndDurationMutationResult = Apollo.MutationResult<UpdateThumbnailAndDurationMutation>;
export type UpdateThumbnailAndDurationMutationOptions = Apollo.BaseMutationOptions<UpdateThumbnailAndDurationMutation, UpdateThumbnailAndDurationMutationVariables>;
export const NotificationsListCreateDocument = gql`
    mutation notificationsListCreate($notificationCreate: [notificationCreate]) {
  notificationsListCreate(notificationList: $notificationCreate) {
    uniqueId
    title
    body
    description
    dynamicResponse {
      key
      value
    }
    created_at
    created_by
    updated_at
    updated_by
    __typename
  }
}
    `;
export type NotificationsListCreateMutationFn = Apollo.MutationFunction<NotificationsListCreateMutation, NotificationsListCreateMutationVariables>;

/**
 * __useNotificationsListCreateMutation__
 *
 * To run a mutation, you first call `useNotificationsListCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationsListCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationsListCreateMutation, { data, loading, error }] = useNotificationsListCreateMutation({
 *   variables: {
 *      notificationCreate: // value for 'notificationCreate'
 *   },
 * });
 */
export function useNotificationsListCreateMutation(baseOptions?: Apollo.MutationHookOptions<NotificationsListCreateMutation, NotificationsListCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationsListCreateMutation, NotificationsListCreateMutationVariables>(NotificationsListCreateDocument, options);
      }
export type NotificationsListCreateMutationHookResult = ReturnType<typeof useNotificationsListCreateMutation>;
export type NotificationsListCreateMutationResult = Apollo.MutationResult<NotificationsListCreateMutation>;
export type NotificationsListCreateMutationOptions = Apollo.BaseMutationOptions<NotificationsListCreateMutation, NotificationsListCreateMutationVariables>;
export const NotificationsListUpdateDocument = gql`
    mutation notificationsListUpdate($notificationUpdate: [notificationUpdate]) {
  notificationsListUpdate(notificationList: $notificationUpdate) {
    uniqueId
    title
    body
    description
    created_at
    created_by
    updated_at
    updated_by
    __typename
  }
}
    `;
export type NotificationsListUpdateMutationFn = Apollo.MutationFunction<NotificationsListUpdateMutation, NotificationsListUpdateMutationVariables>;

/**
 * __useNotificationsListUpdateMutation__
 *
 * To run a mutation, you first call `useNotificationsListUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationsListUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationsListUpdateMutation, { data, loading, error }] = useNotificationsListUpdateMutation({
 *   variables: {
 *      notificationUpdate: // value for 'notificationUpdate'
 *   },
 * });
 */
export function useNotificationsListUpdateMutation(baseOptions?: Apollo.MutationHookOptions<NotificationsListUpdateMutation, NotificationsListUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationsListUpdateMutation, NotificationsListUpdateMutationVariables>(NotificationsListUpdateDocument, options);
      }
export type NotificationsListUpdateMutationHookResult = ReturnType<typeof useNotificationsListUpdateMutation>;
export type NotificationsListUpdateMutationResult = Apollo.MutationResult<NotificationsListUpdateMutation>;
export type NotificationsListUpdateMutationOptions = Apollo.BaseMutationOptions<NotificationsListUpdateMutation, NotificationsListUpdateMutationVariables>;
export const DeleteFeedbackAndRatingMutationDocument = gql`
    mutation deleteFeedbackAndRatingMutation($id: [Int!]) {
  deleteFeedbackAndRatingMutation(id: $id) {
    id
    feedback
    rating
    next_trigger_at
    __typename
  }
}
    `;
export type DeleteFeedbackAndRatingMutationMutationFn = Apollo.MutationFunction<DeleteFeedbackAndRatingMutationMutation, DeleteFeedbackAndRatingMutationMutationVariables>;

/**
 * __useDeleteFeedbackAndRatingMutationMutation__
 *
 * To run a mutation, you first call `useDeleteFeedbackAndRatingMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedbackAndRatingMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedbackAndRatingMutationMutation, { data, loading, error }] = useDeleteFeedbackAndRatingMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFeedbackAndRatingMutationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeedbackAndRatingMutationMutation, DeleteFeedbackAndRatingMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeedbackAndRatingMutationMutation, DeleteFeedbackAndRatingMutationMutationVariables>(DeleteFeedbackAndRatingMutationDocument, options);
      }
export type DeleteFeedbackAndRatingMutationMutationHookResult = ReturnType<typeof useDeleteFeedbackAndRatingMutationMutation>;
export type DeleteFeedbackAndRatingMutationMutationResult = Apollo.MutationResult<DeleteFeedbackAndRatingMutationMutation>;
export type DeleteFeedbackAndRatingMutationMutationOptions = Apollo.BaseMutationOptions<DeleteFeedbackAndRatingMutationMutation, DeleteFeedbackAndRatingMutationMutationVariables>;
export const UpdateNextTriggerDateByIdDocument = gql`
    mutation updateNextTriggerDateById($nextTriggerDateUpdateList: [updateNextTriggerDate]) {
  updateNextTriggerDateById(nextTriggerDateUpdateList: $nextTriggerDateUpdateList) {
    id
    next_trigger_at
    __typename
  }
}
    `;
export type UpdateNextTriggerDateByIdMutationFn = Apollo.MutationFunction<UpdateNextTriggerDateByIdMutation, UpdateNextTriggerDateByIdMutationVariables>;

/**
 * __useUpdateNextTriggerDateByIdMutation__
 *
 * To run a mutation, you first call `useUpdateNextTriggerDateByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNextTriggerDateByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNextTriggerDateByIdMutation, { data, loading, error }] = useUpdateNextTriggerDateByIdMutation({
 *   variables: {
 *      nextTriggerDateUpdateList: // value for 'nextTriggerDateUpdateList'
 *   },
 * });
 */
export function useUpdateNextTriggerDateByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNextTriggerDateByIdMutation, UpdateNextTriggerDateByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNextTriggerDateByIdMutation, UpdateNextTriggerDateByIdMutationVariables>(UpdateNextTriggerDateByIdDocument, options);
      }
export type UpdateNextTriggerDateByIdMutationHookResult = ReturnType<typeof useUpdateNextTriggerDateByIdMutation>;
export type UpdateNextTriggerDateByIdMutationResult = Apollo.MutationResult<UpdateNextTriggerDateByIdMutation>;
export type UpdateNextTriggerDateByIdMutationOptions = Apollo.BaseMutationOptions<UpdateNextTriggerDateByIdMutation, UpdateNextTriggerDateByIdMutationVariables>;
export const DeleteNotificationsByIdDocument = gql`
    mutation deleteNotificationsById($uniqueId: [Int!]) {
  deleteNotificationsById(uniqueId: $uniqueId)
}
    `;
export type DeleteNotificationsByIdMutationFn = Apollo.MutationFunction<DeleteNotificationsByIdMutation, DeleteNotificationsByIdMutationVariables>;

/**
 * __useDeleteNotificationsByIdMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationsByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationsByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationsByIdMutation, { data, loading, error }] = useDeleteNotificationsByIdMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *   },
 * });
 */
export function useDeleteNotificationsByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationsByIdMutation, DeleteNotificationsByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNotificationsByIdMutation, DeleteNotificationsByIdMutationVariables>(DeleteNotificationsByIdDocument, options);
      }
export type DeleteNotificationsByIdMutationHookResult = ReturnType<typeof useDeleteNotificationsByIdMutation>;
export type DeleteNotificationsByIdMutationResult = Apollo.MutationResult<DeleteNotificationsByIdMutation>;
export type DeleteNotificationsByIdMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationsByIdMutation, DeleteNotificationsByIdMutationVariables>;
export const GetNotificationDetailsByIdDocument = gql`
    mutation getNotificationDetailsById($uniqueId: Int!) {
  getNotificationDetailsById(uniqueId: $uniqueId) {
    uniqueId
    title
    body
    description
    created_at
    created_by
    updated_at
    updated_by
    dynamicResponse {
      key
      value
    }
    notificationSchedule {
      uniqueId
      notifyAt
      isPushNotification
      notificationStatus
      notificationMetadata
      userIds
      userNames
      groupIds
      groupNames
      created_by
      created_at
      updated_by
      updated_at
    }
  }
}
    `;
export type GetNotificationDetailsByIdMutationFn = Apollo.MutationFunction<GetNotificationDetailsByIdMutation, GetNotificationDetailsByIdMutationVariables>;

/**
 * __useGetNotificationDetailsByIdMutation__
 *
 * To run a mutation, you first call `useGetNotificationDetailsByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationDetailsByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getNotificationDetailsByIdMutation, { data, loading, error }] = useGetNotificationDetailsByIdMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *   },
 * });
 */
export function useGetNotificationDetailsByIdMutation(baseOptions?: Apollo.MutationHookOptions<GetNotificationDetailsByIdMutation, GetNotificationDetailsByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetNotificationDetailsByIdMutation, GetNotificationDetailsByIdMutationVariables>(GetNotificationDetailsByIdDocument, options);
      }
export type GetNotificationDetailsByIdMutationHookResult = ReturnType<typeof useGetNotificationDetailsByIdMutation>;
export type GetNotificationDetailsByIdMutationResult = Apollo.MutationResult<GetNotificationDetailsByIdMutation>;
export type GetNotificationDetailsByIdMutationOptions = Apollo.BaseMutationOptions<GetNotificationDetailsByIdMutation, GetNotificationDetailsByIdMutationVariables>;
export const GetUserNotificationResponsePagesDocument = gql`
    mutation getUserNotificationResponsePages($uniqueId: Int!, $PageInfoInput: PageInfoInput) {
  getUserNotificationResponsePages(uniqueId: $uniqueId, pageInfo: $PageInfoInput) {
    userNotificationResponseList {
      uniqueId
      notificationScheduleId
      notifyAt
      createdAt
      userResponse {
        key
        value
      }
      respondedBy
    }
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
    }
  }
}
    `;
export type GetUserNotificationResponsePagesMutationFn = Apollo.MutationFunction<GetUserNotificationResponsePagesMutation, GetUserNotificationResponsePagesMutationVariables>;

/**
 * __useGetUserNotificationResponsePagesMutation__
 *
 * To run a mutation, you first call `useGetUserNotificationResponsePagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationResponsePagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUserNotificationResponsePagesMutation, { data, loading, error }] = useGetUserNotificationResponsePagesMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetUserNotificationResponsePagesMutation(baseOptions?: Apollo.MutationHookOptions<GetUserNotificationResponsePagesMutation, GetUserNotificationResponsePagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetUserNotificationResponsePagesMutation, GetUserNotificationResponsePagesMutationVariables>(GetUserNotificationResponsePagesDocument, options);
      }
export type GetUserNotificationResponsePagesMutationHookResult = ReturnType<typeof useGetUserNotificationResponsePagesMutation>;
export type GetUserNotificationResponsePagesMutationResult = Apollo.MutationResult<GetUserNotificationResponsePagesMutation>;
export type GetUserNotificationResponsePagesMutationOptions = Apollo.BaseMutationOptions<GetUserNotificationResponsePagesMutation, GetUserNotificationResponsePagesMutationVariables>;
export const GetUserInfoPagesMutationDocument = gql`
    mutation getUserInfoPagesMutation($PageInfoInput: PageInfoInput) {
  getUserInfoPagesMutation(pageInfo: $PageInfoInput) {
    userInfoForAdmin {
      familyName
      givenName
      groupNames
      roleNames
      uniqueName
      uniqueId
      roleIds
      groupIds
      created_at
      created_by
      updated_at
      updated_by
      last_active_at
      lineOfBusiness
      jobTitle
      __typename
    }
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
      __typename
    }
    __typename
  }
}
    `;
export type GetUserInfoPagesMutationMutationFn = Apollo.MutationFunction<GetUserInfoPagesMutationMutation, GetUserInfoPagesMutationMutationVariables>;

/**
 * __useGetUserInfoPagesMutationMutation__
 *
 * To run a mutation, you first call `useGetUserInfoPagesMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoPagesMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUserInfoPagesMutationMutation, { data, loading, error }] = useGetUserInfoPagesMutationMutation({
 *   variables: {
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetUserInfoPagesMutationMutation(baseOptions?: Apollo.MutationHookOptions<GetUserInfoPagesMutationMutation, GetUserInfoPagesMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetUserInfoPagesMutationMutation, GetUserInfoPagesMutationMutationVariables>(GetUserInfoPagesMutationDocument, options);
      }
export type GetUserInfoPagesMutationMutationHookResult = ReturnType<typeof useGetUserInfoPagesMutationMutation>;
export type GetUserInfoPagesMutationMutationResult = Apollo.MutationResult<GetUserInfoPagesMutationMutation>;
export type GetUserInfoPagesMutationMutationOptions = Apollo.BaseMutationOptions<GetUserInfoPagesMutationMutation, GetUserInfoPagesMutationMutationVariables>;
export const GetAllGroupSectionsDocsDocument = gql`
    query getAllGroupSectionsDocs {
  getAllGroupSectionsDocs {
    groupId
    groupName
    groupDescription
    folderCount
    documentCount
    created_by
    created_at
    updated_by
    updated_at
    __typename
  }
}
    `;

/**
 * __useGetAllGroupSectionsDocsQuery__
 *
 * To run a query within a React component, call `useGetAllGroupSectionsDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGroupSectionsDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGroupSectionsDocsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllGroupSectionsDocsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllGroupSectionsDocsQuery, GetAllGroupSectionsDocsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllGroupSectionsDocsQuery, GetAllGroupSectionsDocsQueryVariables>(GetAllGroupSectionsDocsDocument, options);
      }
export function useGetAllGroupSectionsDocsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllGroupSectionsDocsQuery, GetAllGroupSectionsDocsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllGroupSectionsDocsQuery, GetAllGroupSectionsDocsQueryVariables>(GetAllGroupSectionsDocsDocument, options);
        }
export type GetAllGroupSectionsDocsQueryHookResult = ReturnType<typeof useGetAllGroupSectionsDocsQuery>;
export type GetAllGroupSectionsDocsLazyQueryHookResult = ReturnType<typeof useGetAllGroupSectionsDocsLazyQuery>;
export type GetAllGroupSectionsDocsQueryResult = Apollo.QueryResult<GetAllGroupSectionsDocsQuery, GetAllGroupSectionsDocsQueryVariables>;
export const GroupSectionsDocsMappingListCreateDocument = gql`
    mutation groupSectionsDocsMappingListCreate($groupSectionsDocsCreate: [groupSectionsDocsCreate]) {
  groupSectionsDocsMappingListCreate(
    groupSectionsDocsList: $groupSectionsDocsCreate
  ) {
    groupId
    groupName
    groupDescription
    sectionIds
    documentIds
  }
}
    `;
export type GroupSectionsDocsMappingListCreateMutationFn = Apollo.MutationFunction<GroupSectionsDocsMappingListCreateMutation, GroupSectionsDocsMappingListCreateMutationVariables>;

/**
 * __useGroupSectionsDocsMappingListCreateMutation__
 *
 * To run a mutation, you first call `useGroupSectionsDocsMappingListCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupSectionsDocsMappingListCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupSectionsDocsMappingListCreateMutation, { data, loading, error }] = useGroupSectionsDocsMappingListCreateMutation({
 *   variables: {
 *      groupSectionsDocsCreate: // value for 'groupSectionsDocsCreate'
 *   },
 * });
 */
export function useGroupSectionsDocsMappingListCreateMutation(baseOptions?: Apollo.MutationHookOptions<GroupSectionsDocsMappingListCreateMutation, GroupSectionsDocsMappingListCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GroupSectionsDocsMappingListCreateMutation, GroupSectionsDocsMappingListCreateMutationVariables>(GroupSectionsDocsMappingListCreateDocument, options);
      }
export type GroupSectionsDocsMappingListCreateMutationHookResult = ReturnType<typeof useGroupSectionsDocsMappingListCreateMutation>;
export type GroupSectionsDocsMappingListCreateMutationResult = Apollo.MutationResult<GroupSectionsDocsMappingListCreateMutation>;
export type GroupSectionsDocsMappingListCreateMutationOptions = Apollo.BaseMutationOptions<GroupSectionsDocsMappingListCreateMutation, GroupSectionsDocsMappingListCreateMutationVariables>;
export const GetGroupDetailsByIdDocument = gql`
    mutation getGroupDetailsById($uniqueId: Int!) {
  getGroupDetailsById(uniqueId: $uniqueId) {
    groupId
    groupName
    groupDescription
    created_by
    created_at
    updated_by
    updated_at
    folders {
      id
      folderName
      __typename
    }
    documents {
      id
      documentName
      __typename
    }
    users {
      Id
      familyName
      givenName
      uniqueName
      __typename
    }
    __typename
  }
}
    `;
export type GetGroupDetailsByIdMutationFn = Apollo.MutationFunction<GetGroupDetailsByIdMutation, GetGroupDetailsByIdMutationVariables>;

/**
 * __useGetGroupDetailsByIdMutation__
 *
 * To run a mutation, you first call `useGetGroupDetailsByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetGroupDetailsByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getGroupDetailsByIdMutation, { data, loading, error }] = useGetGroupDetailsByIdMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *   },
 * });
 */
export function useGetGroupDetailsByIdMutation(baseOptions?: Apollo.MutationHookOptions<GetGroupDetailsByIdMutation, GetGroupDetailsByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetGroupDetailsByIdMutation, GetGroupDetailsByIdMutationVariables>(GetGroupDetailsByIdDocument, options);
      }
export type GetGroupDetailsByIdMutationHookResult = ReturnType<typeof useGetGroupDetailsByIdMutation>;
export type GetGroupDetailsByIdMutationResult = Apollo.MutationResult<GetGroupDetailsByIdMutation>;
export type GetGroupDetailsByIdMutationOptions = Apollo.BaseMutationOptions<GetGroupDetailsByIdMutation, GetGroupDetailsByIdMutationVariables>;
export const GroupSectionsDocsMappingListUpdateDocument = gql`
    mutation groupSectionsDocsMappingListUpdate($groupSectionsDocsUpdate: [groupSectionsDocsUpdate]) {
  groupSectionsDocsMappingListUpdate(
    groupSectionsDocsList: $groupSectionsDocsUpdate
  ) {
    groupId
    groupName
    groupDescription
    sectionIds
    documentIds
  }
}
    `;
export type GroupSectionsDocsMappingListUpdateMutationFn = Apollo.MutationFunction<GroupSectionsDocsMappingListUpdateMutation, GroupSectionsDocsMappingListUpdateMutationVariables>;

/**
 * __useGroupSectionsDocsMappingListUpdateMutation__
 *
 * To run a mutation, you first call `useGroupSectionsDocsMappingListUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupSectionsDocsMappingListUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupSectionsDocsMappingListUpdateMutation, { data, loading, error }] = useGroupSectionsDocsMappingListUpdateMutation({
 *   variables: {
 *      groupSectionsDocsUpdate: // value for 'groupSectionsDocsUpdate'
 *   },
 * });
 */
export function useGroupSectionsDocsMappingListUpdateMutation(baseOptions?: Apollo.MutationHookOptions<GroupSectionsDocsMappingListUpdateMutation, GroupSectionsDocsMappingListUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GroupSectionsDocsMappingListUpdateMutation, GroupSectionsDocsMappingListUpdateMutationVariables>(GroupSectionsDocsMappingListUpdateDocument, options);
      }
export type GroupSectionsDocsMappingListUpdateMutationHookResult = ReturnType<typeof useGroupSectionsDocsMappingListUpdateMutation>;
export type GroupSectionsDocsMappingListUpdateMutationResult = Apollo.MutationResult<GroupSectionsDocsMappingListUpdateMutation>;
export type GroupSectionsDocsMappingListUpdateMutationOptions = Apollo.BaseMutationOptions<GroupSectionsDocsMappingListUpdateMutation, GroupSectionsDocsMappingListUpdateMutationVariables>;
export const DeletegroupSectionsDocsByGroupIdDocument = gql`
    mutation deletegroupSectionsDocsByGroupId($uniqueId: [Int!]) {
  deletegroupSectionsDocsByGroupId(uniqueId: $uniqueId)
}
    `;
export type DeletegroupSectionsDocsByGroupIdMutationFn = Apollo.MutationFunction<DeletegroupSectionsDocsByGroupIdMutation, DeletegroupSectionsDocsByGroupIdMutationVariables>;

/**
 * __useDeletegroupSectionsDocsByGroupIdMutation__
 *
 * To run a mutation, you first call `useDeletegroupSectionsDocsByGroupIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletegroupSectionsDocsByGroupIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletegroupSectionsDocsByGroupIdMutation, { data, loading, error }] = useDeletegroupSectionsDocsByGroupIdMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *   },
 * });
 */
export function useDeletegroupSectionsDocsByGroupIdMutation(baseOptions?: Apollo.MutationHookOptions<DeletegroupSectionsDocsByGroupIdMutation, DeletegroupSectionsDocsByGroupIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletegroupSectionsDocsByGroupIdMutation, DeletegroupSectionsDocsByGroupIdMutationVariables>(DeletegroupSectionsDocsByGroupIdDocument, options);
      }
export type DeletegroupSectionsDocsByGroupIdMutationHookResult = ReturnType<typeof useDeletegroupSectionsDocsByGroupIdMutation>;
export type DeletegroupSectionsDocsByGroupIdMutationResult = Apollo.MutationResult<DeletegroupSectionsDocsByGroupIdMutation>;
export type DeletegroupSectionsDocsByGroupIdMutationOptions = Apollo.BaseMutationOptions<DeletegroupSectionsDocsByGroupIdMutation, DeletegroupSectionsDocsByGroupIdMutationVariables>;
export const RecoverArchivedUsersMutationDocument = gql`
    mutation recoverArchivedUsersMutation($uniqueId: [Int!]) {
  recoverArchivedUsersMutation(uniqueId: $uniqueId)
}
    `;
export type RecoverArchivedUsersMutationMutationFn = Apollo.MutationFunction<RecoverArchivedUsersMutationMutation, RecoverArchivedUsersMutationMutationVariables>;

/**
 * __useRecoverArchivedUsersMutationMutation__
 *
 * To run a mutation, you first call `useRecoverArchivedUsersMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecoverArchivedUsersMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recoverArchivedUsersMutationMutation, { data, loading, error }] = useRecoverArchivedUsersMutationMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *   },
 * });
 */
export function useRecoverArchivedUsersMutationMutation(baseOptions?: Apollo.MutationHookOptions<RecoverArchivedUsersMutationMutation, RecoverArchivedUsersMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecoverArchivedUsersMutationMutation, RecoverArchivedUsersMutationMutationVariables>(RecoverArchivedUsersMutationDocument, options);
      }
export type RecoverArchivedUsersMutationMutationHookResult = ReturnType<typeof useRecoverArchivedUsersMutationMutation>;
export type RecoverArchivedUsersMutationMutationResult = Apollo.MutationResult<RecoverArchivedUsersMutationMutation>;
export type RecoverArchivedUsersMutationMutationOptions = Apollo.BaseMutationOptions<RecoverArchivedUsersMutationMutation, RecoverArchivedUsersMutationMutationVariables>;
export const GetAuditLogPagesMutationDocument = gql`
    mutation getAuditLogPagesMutation($PageInfoInput: PageInfoInput) {
  getAuditLogPagesMutation(pageInfo: $PageInfoInput) {
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
      __typename
    }
    auditLogsForAdmin {
      action
      actorEmail
      recordPrimaryKey
      tableName
      timestamp
      __typename
    }
    __typename
  }
}
    `;
export type GetAuditLogPagesMutationMutationFn = Apollo.MutationFunction<GetAuditLogPagesMutationMutation, GetAuditLogPagesMutationMutationVariables>;

/**
 * __useGetAuditLogPagesMutationMutation__
 *
 * To run a mutation, you first call `useGetAuditLogPagesMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAuditLogPagesMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAuditLogPagesMutationMutation, { data, loading, error }] = useGetAuditLogPagesMutationMutation({
 *   variables: {
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetAuditLogPagesMutationMutation(baseOptions?: Apollo.MutationHookOptions<GetAuditLogPagesMutationMutation, GetAuditLogPagesMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAuditLogPagesMutationMutation, GetAuditLogPagesMutationMutationVariables>(GetAuditLogPagesMutationDocument, options);
      }
export type GetAuditLogPagesMutationMutationHookResult = ReturnType<typeof useGetAuditLogPagesMutationMutation>;
export type GetAuditLogPagesMutationMutationResult = Apollo.MutationResult<GetAuditLogPagesMutationMutation>;
export type GetAuditLogPagesMutationMutationOptions = Apollo.BaseMutationOptions<GetAuditLogPagesMutationMutation, GetAuditLogPagesMutationMutationVariables>;
export const GetHomeScreenForAdminDocument = gql`
    query getHomeScreenForAdmin {
  getHomeScreenForAdmin {
    adminContent
    mobileContent
    __typename
  }
}
    `;

/**
 * __useGetHomeScreenForAdminQuery__
 *
 * To run a query within a React component, call `useGetHomeScreenForAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeScreenForAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeScreenForAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeScreenForAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetHomeScreenForAdminQuery, GetHomeScreenForAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHomeScreenForAdminQuery, GetHomeScreenForAdminQueryVariables>(GetHomeScreenForAdminDocument, options);
      }
export function useGetHomeScreenForAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHomeScreenForAdminQuery, GetHomeScreenForAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHomeScreenForAdminQuery, GetHomeScreenForAdminQueryVariables>(GetHomeScreenForAdminDocument, options);
        }
export type GetHomeScreenForAdminQueryHookResult = ReturnType<typeof useGetHomeScreenForAdminQuery>;
export type GetHomeScreenForAdminLazyQueryHookResult = ReturnType<typeof useGetHomeScreenForAdminLazyQuery>;
export type GetHomeScreenForAdminQueryResult = Apollo.QueryResult<GetHomeScreenForAdminQuery, GetHomeScreenForAdminQueryVariables>;
export const SetHomeScreenDocument = gql`
    mutation setHomeScreen($adminContent: String!, $mobileContent: String!) {
  setHomeScreen(adminContent: $adminContent, mobileContent: $mobileContent) {
    data
  }
}
    `;
export type SetHomeScreenMutationFn = Apollo.MutationFunction<SetHomeScreenMutation, SetHomeScreenMutationVariables>;

/**
 * __useSetHomeScreenMutation__
 *
 * To run a mutation, you first call `useSetHomeScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHomeScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHomeScreenMutation, { data, loading, error }] = useSetHomeScreenMutation({
 *   variables: {
 *      adminContent: // value for 'adminContent'
 *      mobileContent: // value for 'mobileContent'
 *   },
 * });
 */
export function useSetHomeScreenMutation(baseOptions?: Apollo.MutationHookOptions<SetHomeScreenMutation, SetHomeScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetHomeScreenMutation, SetHomeScreenMutationVariables>(SetHomeScreenDocument, options);
      }
export type SetHomeScreenMutationHookResult = ReturnType<typeof useSetHomeScreenMutation>;
export type SetHomeScreenMutationResult = Apollo.MutationResult<SetHomeScreenMutation>;
export type SetHomeScreenMutationOptions = Apollo.BaseMutationOptions<SetHomeScreenMutation, SetHomeScreenMutationVariables>;
export const IsPushNotificationEnabledDocument = gql`
    query isPushNotificationEnabled {
  isPushNotificationEnabled
}
    `;

/**
 * __useIsPushNotificationEnabledQuery__
 *
 * To run a query within a React component, call `useIsPushNotificationEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPushNotificationEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPushNotificationEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsPushNotificationEnabledQuery(baseOptions?: Apollo.QueryHookOptions<IsPushNotificationEnabledQuery, IsPushNotificationEnabledQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsPushNotificationEnabledQuery, IsPushNotificationEnabledQueryVariables>(IsPushNotificationEnabledDocument, options);
      }
export function useIsPushNotificationEnabledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsPushNotificationEnabledQuery, IsPushNotificationEnabledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsPushNotificationEnabledQuery, IsPushNotificationEnabledQueryVariables>(IsPushNotificationEnabledDocument, options);
        }
export type IsPushNotificationEnabledQueryHookResult = ReturnType<typeof useIsPushNotificationEnabledQuery>;
export type IsPushNotificationEnabledLazyQueryHookResult = ReturnType<typeof useIsPushNotificationEnabledLazyQuery>;
export type IsPushNotificationEnabledQueryResult = Apollo.QueryResult<IsPushNotificationEnabledQuery, IsPushNotificationEnabledQueryVariables>;
export const GetFileUploadSettingsDocument = gql`
    query getFileUploadSettings {
  getFileUploadSettings {
    maxUploadSize {
      KB
      __typename
    }
    __typename
  }
}
    `;

/**
 * __useGetFileUploadSettingsQuery__
 *
 * To run a query within a React component, call `useGetFileUploadSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileUploadSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileUploadSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFileUploadSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetFileUploadSettingsQuery, GetFileUploadSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileUploadSettingsQuery, GetFileUploadSettingsQueryVariables>(GetFileUploadSettingsDocument, options);
      }
export function useGetFileUploadSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileUploadSettingsQuery, GetFileUploadSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileUploadSettingsQuery, GetFileUploadSettingsQueryVariables>(GetFileUploadSettingsDocument, options);
        }
export type GetFileUploadSettingsQueryHookResult = ReturnType<typeof useGetFileUploadSettingsQuery>;
export type GetFileUploadSettingsLazyQueryHookResult = ReturnType<typeof useGetFileUploadSettingsLazyQuery>;
export type GetFileUploadSettingsQueryResult = Apollo.QueryResult<GetFileUploadSettingsQuery, GetFileUploadSettingsQueryVariables>;
export const CronJobListToggleDocument = gql`
    mutation cronJobListToggle($cronJobToggle: [cronJobToggle]) {
  cronJobListToggle(cronJobList: $cronJobToggle) {
    uniqueId
    cronjobname
    isActive
    lastrunat
    nextrunat
    lastrunstatus
    description
    created_at
    created_by
    updated_at
    updated_by
    __typename
  }
}
    `;
export type CronJobListToggleMutationFn = Apollo.MutationFunction<CronJobListToggleMutation, CronJobListToggleMutationVariables>;

/**
 * __useCronJobListToggleMutation__
 *
 * To run a mutation, you first call `useCronJobListToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCronJobListToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cronJobListToggleMutation, { data, loading, error }] = useCronJobListToggleMutation({
 *   variables: {
 *      cronJobToggle: // value for 'cronJobToggle'
 *   },
 * });
 */
export function useCronJobListToggleMutation(baseOptions?: Apollo.MutationHookOptions<CronJobListToggleMutation, CronJobListToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CronJobListToggleMutation, CronJobListToggleMutationVariables>(CronJobListToggleDocument, options);
      }
export type CronJobListToggleMutationHookResult = ReturnType<typeof useCronJobListToggleMutation>;
export type CronJobListToggleMutationResult = Apollo.MutationResult<CronJobListToggleMutation>;
export type CronJobListToggleMutationOptions = Apollo.BaseMutationOptions<CronJobListToggleMutation, CronJobListToggleMutationVariables>;
export const ModifyFileUploadSettingsDocument = gql`
    mutation modifyFileUploadSettings($MaxUploadSizeInputObj: MaxUploadSizeInputObj!) {
  modifyFileUploadSettings(maxUploadSize: $MaxUploadSizeInputObj) {
    maxUploadSize {
      KB
      __typename
    }
    __typename
  }
}
    `;
export type ModifyFileUploadSettingsMutationFn = Apollo.MutationFunction<ModifyFileUploadSettingsMutation, ModifyFileUploadSettingsMutationVariables>;

/**
 * __useModifyFileUploadSettingsMutation__
 *
 * To run a mutation, you first call `useModifyFileUploadSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyFileUploadSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyFileUploadSettingsMutation, { data, loading, error }] = useModifyFileUploadSettingsMutation({
 *   variables: {
 *      MaxUploadSizeInputObj: // value for 'MaxUploadSizeInputObj'
 *   },
 * });
 */
export function useModifyFileUploadSettingsMutation(baseOptions?: Apollo.MutationHookOptions<ModifyFileUploadSettingsMutation, ModifyFileUploadSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyFileUploadSettingsMutation, ModifyFileUploadSettingsMutationVariables>(ModifyFileUploadSettingsDocument, options);
      }
export type ModifyFileUploadSettingsMutationHookResult = ReturnType<typeof useModifyFileUploadSettingsMutation>;
export type ModifyFileUploadSettingsMutationResult = Apollo.MutationResult<ModifyFileUploadSettingsMutation>;
export type ModifyFileUploadSettingsMutationOptions = Apollo.BaseMutationOptions<ModifyFileUploadSettingsMutation, ModifyFileUploadSettingsMutationVariables>;
export const SystemConfigsResponseForAdminDocument = gql`
    query systemConfigsResponseForAdmin {
  systemConfigsResponseForAdmin {
    gocConfigs {
      primary
      secondary
      tertiary
    }
    app_support_email {
      email_id
      subject
    }
    app_version {
      ios
    }
    service_desk {
      key
      value {
        key
        value
      }
    }
  }
}
    `;

/**
 * __useSystemConfigsResponseForAdminQuery__
 *
 * To run a query within a React component, call `useSystemConfigsResponseForAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemConfigsResponseForAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemConfigsResponseForAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemConfigsResponseForAdminQuery(baseOptions?: Apollo.QueryHookOptions<SystemConfigsResponseForAdminQuery, SystemConfigsResponseForAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SystemConfigsResponseForAdminQuery, SystemConfigsResponseForAdminQueryVariables>(SystemConfigsResponseForAdminDocument, options);
      }
export function useSystemConfigsResponseForAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SystemConfigsResponseForAdminQuery, SystemConfigsResponseForAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SystemConfigsResponseForAdminQuery, SystemConfigsResponseForAdminQueryVariables>(SystemConfigsResponseForAdminDocument, options);
        }
export type SystemConfigsResponseForAdminQueryHookResult = ReturnType<typeof useSystemConfigsResponseForAdminQuery>;
export type SystemConfigsResponseForAdminLazyQueryHookResult = ReturnType<typeof useSystemConfigsResponseForAdminLazyQuery>;
export type SystemConfigsResponseForAdminQueryResult = Apollo.QueryResult<SystemConfigsResponseForAdminQuery, SystemConfigsResponseForAdminQueryVariables>;
export const SystemConfigRequestFormDocument = gql`
    mutation systemConfigRequestForm($gocNumberInputObject: gocNumberInputObject!, $app_version: AppVersionInputObj!, $app_support_email: appSupportEmailInputObj!, $service_desk: [serviceDeskInputObj!]) {
  systemConfigRequestForm(
    gocNumbers: $gocNumberInputObject
    app_version: $app_version
    app_support_email: $app_support_email
    service_desk: $service_desk
  ) {
    gocConfigs {
      primary
      secondary
      tertiary
    }
    app_support_email {
      email_id
      subject
    }
    app_version {
      ios
    }
    service_desk {
      key
      value {
        key
        value
      }
    }
  }
}
    `;
export type SystemConfigRequestFormMutationFn = Apollo.MutationFunction<SystemConfigRequestFormMutation, SystemConfigRequestFormMutationVariables>;

/**
 * __useSystemConfigRequestFormMutation__
 *
 * To run a mutation, you first call `useSystemConfigRequestFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSystemConfigRequestFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [systemConfigRequestFormMutation, { data, loading, error }] = useSystemConfigRequestFormMutation({
 *   variables: {
 *      gocNumberInputObject: // value for 'gocNumberInputObject'
 *      app_version: // value for 'app_version'
 *      app_support_email: // value for 'app_support_email'
 *      service_desk: // value for 'service_desk'
 *   },
 * });
 */
export function useSystemConfigRequestFormMutation(baseOptions?: Apollo.MutationHookOptions<SystemConfigRequestFormMutation, SystemConfigRequestFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SystemConfigRequestFormMutation, SystemConfigRequestFormMutationVariables>(SystemConfigRequestFormDocument, options);
      }
export type SystemConfigRequestFormMutationHookResult = ReturnType<typeof useSystemConfigRequestFormMutation>;
export type SystemConfigRequestFormMutationResult = Apollo.MutationResult<SystemConfigRequestFormMutation>;
export type SystemConfigRequestFormMutationOptions = Apollo.BaseMutationOptions<SystemConfigRequestFormMutation, SystemConfigRequestFormMutationVariables>;
export const NotificationScheduleListCreateDocument = gql`
    mutation notificationScheduleListCreate($notificationScheduleCreate: [notificationScheduleCreate]) {
  notificationScheduleListCreate(
    notificationScheduleList: $notificationScheduleCreate
  ) {
    uniqueId
    notificationId
    title
    body
    description
    groupIds
    userIds
    notifyAt
    isPushNotification
    created_at
    created_by
    updated_at
    updated_by
  }
}
    `;
export type NotificationScheduleListCreateMutationFn = Apollo.MutationFunction<NotificationScheduleListCreateMutation, NotificationScheduleListCreateMutationVariables>;

/**
 * __useNotificationScheduleListCreateMutation__
 *
 * To run a mutation, you first call `useNotificationScheduleListCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationScheduleListCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationScheduleListCreateMutation, { data, loading, error }] = useNotificationScheduleListCreateMutation({
 *   variables: {
 *      notificationScheduleCreate: // value for 'notificationScheduleCreate'
 *   },
 * });
 */
export function useNotificationScheduleListCreateMutation(baseOptions?: Apollo.MutationHookOptions<NotificationScheduleListCreateMutation, NotificationScheduleListCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationScheduleListCreateMutation, NotificationScheduleListCreateMutationVariables>(NotificationScheduleListCreateDocument, options);
      }
export type NotificationScheduleListCreateMutationHookResult = ReturnType<typeof useNotificationScheduleListCreateMutation>;
export type NotificationScheduleListCreateMutationResult = Apollo.MutationResult<NotificationScheduleListCreateMutation>;
export type NotificationScheduleListCreateMutationOptions = Apollo.BaseMutationOptions<NotificationScheduleListCreateMutation, NotificationScheduleListCreateMutationVariables>;
export const DeleteRolePermissionsByRoleIdDocument = gql`
    mutation DeleteRolePermissionsByRoleId($uniqueId: [Int!]) {
  deleteRolePermissionsByRoleId(uniqueId: $uniqueId)
}
    `;
export type DeleteRolePermissionsByRoleIdMutationFn = Apollo.MutationFunction<DeleteRolePermissionsByRoleIdMutation, DeleteRolePermissionsByRoleIdMutationVariables>;

/**
 * __useDeleteRolePermissionsByRoleIdMutation__
 *
 * To run a mutation, you first call `useDeleteRolePermissionsByRoleIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRolePermissionsByRoleIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRolePermissionsByRoleIdMutation, { data, loading, error }] = useDeleteRolePermissionsByRoleIdMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *   },
 * });
 */
export function useDeleteRolePermissionsByRoleIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRolePermissionsByRoleIdMutation, DeleteRolePermissionsByRoleIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRolePermissionsByRoleIdMutation, DeleteRolePermissionsByRoleIdMutationVariables>(DeleteRolePermissionsByRoleIdDocument, options);
      }
export type DeleteRolePermissionsByRoleIdMutationHookResult = ReturnType<typeof useDeleteRolePermissionsByRoleIdMutation>;
export type DeleteRolePermissionsByRoleIdMutationResult = Apollo.MutationResult<DeleteRolePermissionsByRoleIdMutation>;
export type DeleteRolePermissionsByRoleIdMutationOptions = Apollo.BaseMutationOptions<DeleteRolePermissionsByRoleIdMutation, DeleteRolePermissionsByRoleIdMutationVariables>;
export const CreateRolePermissionsDocument = gql`
    mutation CreateRolePermissions($rolePermissionsCreate: [rolePermissionsCreate]) {
  rolePermissionsListCreate(rolePermissionsList: $rolePermissionsCreate) {
    roleId
    roleName
    roleDescription
    permissionIds
  }
}
    `;
export type CreateRolePermissionsMutationFn = Apollo.MutationFunction<CreateRolePermissionsMutation, CreateRolePermissionsMutationVariables>;

/**
 * __useCreateRolePermissionsMutation__
 *
 * To run a mutation, you first call `useCreateRolePermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRolePermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRolePermissionsMutation, { data, loading, error }] = useCreateRolePermissionsMutation({
 *   variables: {
 *      rolePermissionsCreate: // value for 'rolePermissionsCreate'
 *   },
 * });
 */
export function useCreateRolePermissionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateRolePermissionsMutation, CreateRolePermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRolePermissionsMutation, CreateRolePermissionsMutationVariables>(CreateRolePermissionsDocument, options);
      }
export type CreateRolePermissionsMutationHookResult = ReturnType<typeof useCreateRolePermissionsMutation>;
export type CreateRolePermissionsMutationResult = Apollo.MutationResult<CreateRolePermissionsMutation>;
export type CreateRolePermissionsMutationOptions = Apollo.BaseMutationOptions<CreateRolePermissionsMutation, CreateRolePermissionsMutationVariables>;
export const RolePermissionsListUpdateDocument = gql`
    mutation rolePermissionsListUpdate($rolePermissionsUpdate: [rolePermissionsUpdate]) {
  rolePermissionsListUpdate(rolePermissionsList: $rolePermissionsUpdate) {
    roleId
    roleName
    roleDescription
    permissionIds
  }
}
    `;
export type RolePermissionsListUpdateMutationFn = Apollo.MutationFunction<RolePermissionsListUpdateMutation, RolePermissionsListUpdateMutationVariables>;

/**
 * __useRolePermissionsListUpdateMutation__
 *
 * To run a mutation, you first call `useRolePermissionsListUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRolePermissionsListUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rolePermissionsListUpdateMutation, { data, loading, error }] = useRolePermissionsListUpdateMutation({
 *   variables: {
 *      rolePermissionsUpdate: // value for 'rolePermissionsUpdate'
 *   },
 * });
 */
export function useRolePermissionsListUpdateMutation(baseOptions?: Apollo.MutationHookOptions<RolePermissionsListUpdateMutation, RolePermissionsListUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RolePermissionsListUpdateMutation, RolePermissionsListUpdateMutationVariables>(RolePermissionsListUpdateDocument, options);
      }
export type RolePermissionsListUpdateMutationHookResult = ReturnType<typeof useRolePermissionsListUpdateMutation>;
export type RolePermissionsListUpdateMutationResult = Apollo.MutationResult<RolePermissionsListUpdateMutation>;
export type RolePermissionsListUpdateMutationOptions = Apollo.BaseMutationOptions<RolePermissionsListUpdateMutation, RolePermissionsListUpdateMutationVariables>;
export const GetAllFoldersDocument = gql`
    mutation getAllFolders($PageInfoInput: PageInfoInput) {
  getAllFolders(pageInfo: $PageInfoInput) {
    folderList {
      display_name
      id
      storage_path
      created_at
      updated_at
      notify_user
      __typename
    }
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
      __typename
    }
    __typename
  }
}
    `;
export type GetAllFoldersMutationFn = Apollo.MutationFunction<GetAllFoldersMutation, GetAllFoldersMutationVariables>;

/**
 * __useGetAllFoldersMutation__
 *
 * To run a mutation, you first call `useGetAllFoldersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAllFoldersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAllFoldersMutation, { data, loading, error }] = useGetAllFoldersMutation({
 *   variables: {
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetAllFoldersMutation(baseOptions?: Apollo.MutationHookOptions<GetAllFoldersMutation, GetAllFoldersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAllFoldersMutation, GetAllFoldersMutationVariables>(GetAllFoldersDocument, options);
      }
export type GetAllFoldersMutationHookResult = ReturnType<typeof useGetAllFoldersMutation>;
export type GetAllFoldersMutationResult = Apollo.MutationResult<GetAllFoldersMutation>;
export type GetAllFoldersMutationOptions = Apollo.BaseMutationOptions<GetAllFoldersMutation, GetAllFoldersMutationVariables>;
export const GetAirshipChannelsReportDocument = gql`
    query getAirshipChannelsReport {
  getAirshipChannelsReport {
    channelsInfo
    __typename
  }
}
    `;

/**
 * __useGetAirshipChannelsReportQuery__
 *
 * To run a query within a React component, call `useGetAirshipChannelsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAirshipChannelsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAirshipChannelsReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAirshipChannelsReportQuery(baseOptions?: Apollo.QueryHookOptions<GetAirshipChannelsReportQuery, GetAirshipChannelsReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAirshipChannelsReportQuery, GetAirshipChannelsReportQueryVariables>(GetAirshipChannelsReportDocument, options);
      }
export function useGetAirshipChannelsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAirshipChannelsReportQuery, GetAirshipChannelsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAirshipChannelsReportQuery, GetAirshipChannelsReportQueryVariables>(GetAirshipChannelsReportDocument, options);
        }
export type GetAirshipChannelsReportQueryHookResult = ReturnType<typeof useGetAirshipChannelsReportQuery>;
export type GetAirshipChannelsReportLazyQueryHookResult = ReturnType<typeof useGetAirshipChannelsReportLazyQuery>;
export type GetAirshipChannelsReportQueryResult = Apollo.QueryResult<GetAirshipChannelsReportQuery, GetAirshipChannelsReportQueryVariables>;
export const GetFeedbackAndRatingPagesDocument = gql`
    mutation getFeedbackAndRatingPages($PageInfoInput: PageInfoInput) {
  getFeedbackAndRatingPages(pageInfo: $PageInfoInput) {
    feedbackAndRatingList {
      id
      feedback
      rating
      created_at
      created_by
      updated_at
      updated_by
      next_trigger_at
      app_version
      __typename
    }
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
      __typename
    }
    __typename
  }
}
    `;
export type GetFeedbackAndRatingPagesMutationFn = Apollo.MutationFunction<GetFeedbackAndRatingPagesMutation, GetFeedbackAndRatingPagesMutationVariables>;

/**
 * __useGetFeedbackAndRatingPagesMutation__
 *
 * To run a mutation, you first call `useGetFeedbackAndRatingPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackAndRatingPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getFeedbackAndRatingPagesMutation, { data, loading, error }] = useGetFeedbackAndRatingPagesMutation({
 *   variables: {
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetFeedbackAndRatingPagesMutation(baseOptions?: Apollo.MutationHookOptions<GetFeedbackAndRatingPagesMutation, GetFeedbackAndRatingPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetFeedbackAndRatingPagesMutation, GetFeedbackAndRatingPagesMutationVariables>(GetFeedbackAndRatingPagesDocument, options);
      }
export type GetFeedbackAndRatingPagesMutationHookResult = ReturnType<typeof useGetFeedbackAndRatingPagesMutation>;
export type GetFeedbackAndRatingPagesMutationResult = Apollo.MutationResult<GetFeedbackAndRatingPagesMutation>;
export type GetFeedbackAndRatingPagesMutationOptions = Apollo.BaseMutationOptions<GetFeedbackAndRatingPagesMutation, GetFeedbackAndRatingPagesMutationVariables>;
export const GetAllRolePermissionsPagesDocument = gql`
    mutation getAllRolePermissionsPages($PageInfoInput: PageInfoInput) {
  getAllRolePermissionsPages(pageInfo: $PageInfoInput) {
    rolePermissionList {
      roleId
      roleName
      roleDescription
      created_by
      created_at
      updated_by
      updated_at
      permissions {
        id
        permissionName
        description
        __typename
      }
      __typename
    }
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
      __typename
    }
    __typename
  }
}
    `;
export type GetAllRolePermissionsPagesMutationFn = Apollo.MutationFunction<GetAllRolePermissionsPagesMutation, GetAllRolePermissionsPagesMutationVariables>;

/**
 * __useGetAllRolePermissionsPagesMutation__
 *
 * To run a mutation, you first call `useGetAllRolePermissionsPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAllRolePermissionsPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAllRolePermissionsPagesMutation, { data, loading, error }] = useGetAllRolePermissionsPagesMutation({
 *   variables: {
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetAllRolePermissionsPagesMutation(baseOptions?: Apollo.MutationHookOptions<GetAllRolePermissionsPagesMutation, GetAllRolePermissionsPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAllRolePermissionsPagesMutation, GetAllRolePermissionsPagesMutationVariables>(GetAllRolePermissionsPagesDocument, options);
      }
export type GetAllRolePermissionsPagesMutationHookResult = ReturnType<typeof useGetAllRolePermissionsPagesMutation>;
export type GetAllRolePermissionsPagesMutationResult = Apollo.MutationResult<GetAllRolePermissionsPagesMutation>;
export type GetAllRolePermissionsPagesMutationOptions = Apollo.BaseMutationOptions<GetAllRolePermissionsPagesMutation, GetAllRolePermissionsPagesMutationVariables>;
export const GetAllArchivedUserPagesDocument = gql`
    mutation getAllArchivedUserPages($PageInfoInput: PageInfoInput) {
  getAllArchivedUserPages(pageInfo: $PageInfoInput) {
    archivedUsersList {
      familyName
      givenName
      groupNames
      roleNames
      uniqueName
      uniqueId
      roleIds
      deleted_at
      groupIds
      created_at
      created_by
      updated_at
      updated_by
      last_active_at
      __typename
    }
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
      __typename
    }
    __typename
  }
}
    `;
export type GetAllArchivedUserPagesMutationFn = Apollo.MutationFunction<GetAllArchivedUserPagesMutation, GetAllArchivedUserPagesMutationVariables>;

/**
 * __useGetAllArchivedUserPagesMutation__
 *
 * To run a mutation, you first call `useGetAllArchivedUserPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAllArchivedUserPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAllArchivedUserPagesMutation, { data, loading, error }] = useGetAllArchivedUserPagesMutation({
 *   variables: {
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetAllArchivedUserPagesMutation(baseOptions?: Apollo.MutationHookOptions<GetAllArchivedUserPagesMutation, GetAllArchivedUserPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAllArchivedUserPagesMutation, GetAllArchivedUserPagesMutationVariables>(GetAllArchivedUserPagesDocument, options);
      }
export type GetAllArchivedUserPagesMutationHookResult = ReturnType<typeof useGetAllArchivedUserPagesMutation>;
export type GetAllArchivedUserPagesMutationResult = Apollo.MutationResult<GetAllArchivedUserPagesMutation>;
export type GetAllArchivedUserPagesMutationOptions = Apollo.BaseMutationOptions<GetAllArchivedUserPagesMutation, GetAllArchivedUserPagesMutationVariables>;
export const GetAllGroupSectionsDocsPagesDocument = gql`
    mutation getAllGroupSectionsDocsPages($PageInfoInput: PageInfoInput) {
  getAllGroupSectionsDocsPages(pageInfo: $PageInfoInput) {
    groupList {
      groupId
      groupName
      groupDescription
      folderCount
      documentCount
      created_by
      created_at
      updated_by
      updated_at
      __typename
    }
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
      __typename
    }
    __typename
  }
}
    `;
export type GetAllGroupSectionsDocsPagesMutationFn = Apollo.MutationFunction<GetAllGroupSectionsDocsPagesMutation, GetAllGroupSectionsDocsPagesMutationVariables>;

/**
 * __useGetAllGroupSectionsDocsPagesMutation__
 *
 * To run a mutation, you first call `useGetAllGroupSectionsDocsPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAllGroupSectionsDocsPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAllGroupSectionsDocsPagesMutation, { data, loading, error }] = useGetAllGroupSectionsDocsPagesMutation({
 *   variables: {
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetAllGroupSectionsDocsPagesMutation(baseOptions?: Apollo.MutationHookOptions<GetAllGroupSectionsDocsPagesMutation, GetAllGroupSectionsDocsPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAllGroupSectionsDocsPagesMutation, GetAllGroupSectionsDocsPagesMutationVariables>(GetAllGroupSectionsDocsPagesDocument, options);
      }
export type GetAllGroupSectionsDocsPagesMutationHookResult = ReturnType<typeof useGetAllGroupSectionsDocsPagesMutation>;
export type GetAllGroupSectionsDocsPagesMutationResult = Apollo.MutationResult<GetAllGroupSectionsDocsPagesMutation>;
export type GetAllGroupSectionsDocsPagesMutationOptions = Apollo.BaseMutationOptions<GetAllGroupSectionsDocsPagesMutation, GetAllGroupSectionsDocsPagesMutationVariables>;
export const GetVideosMetadataForAdminPagesDocument = gql`
    mutation getVideosMetadataForAdminPages($PageInfoInput: PageInfoInput) {
  getVideosMetadataForAdminPages(pageInfo: $PageInfoInput) {
    videoMetadataList {
      is_support
      fileName
      fileURL
      file_bytes
      is_video
      auto_download
      downloadURL
      thumbnail
      duration
      created_at
      updated_at
      notify_user
      sensitivity_label
      id
      folder_id
      filePath
      roles {
        id
        name
        __typename
      }
      __typename
    }
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
      __typename
    }
    __typename
  }
}
    `;
export type GetVideosMetadataForAdminPagesMutationFn = Apollo.MutationFunction<GetVideosMetadataForAdminPagesMutation, GetVideosMetadataForAdminPagesMutationVariables>;

/**
 * __useGetVideosMetadataForAdminPagesMutation__
 *
 * To run a mutation, you first call `useGetVideosMetadataForAdminPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetVideosMetadataForAdminPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getVideosMetadataForAdminPagesMutation, { data, loading, error }] = useGetVideosMetadataForAdminPagesMutation({
 *   variables: {
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetVideosMetadataForAdminPagesMutation(baseOptions?: Apollo.MutationHookOptions<GetVideosMetadataForAdminPagesMutation, GetVideosMetadataForAdminPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetVideosMetadataForAdminPagesMutation, GetVideosMetadataForAdminPagesMutationVariables>(GetVideosMetadataForAdminPagesDocument, options);
      }
export type GetVideosMetadataForAdminPagesMutationHookResult = ReturnType<typeof useGetVideosMetadataForAdminPagesMutation>;
export type GetVideosMetadataForAdminPagesMutationResult = Apollo.MutationResult<GetVideosMetadataForAdminPagesMutation>;
export type GetVideosMetadataForAdminPagesMutationOptions = Apollo.BaseMutationOptions<GetVideosMetadataForAdminPagesMutation, GetVideosMetadataForAdminPagesMutationVariables>;
export const GetDocMetadataForAdminPagesDocument = gql`
    mutation getDocMetadataForAdminPages($PageInfoInput: PageInfoInput) {
  getDocMetadataForAdminPages(pageInfo: $PageInfoInput) {
    fileMetadataList {
      is_support
      fileName
      fileURL
      file_bytes
      is_video
      auto_download
      sensitivity_label
      downloadURL
      thumbnail
      duration
      created_at
      updated_at
      notify_user
      section
      id
      folder_id
      filePath
      roles {
        id
        name
        __typename
      }
      __typename
    }
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
      __typename
    }
    __typename
  }
}
    `;
export type GetDocMetadataForAdminPagesMutationFn = Apollo.MutationFunction<GetDocMetadataForAdminPagesMutation, GetDocMetadataForAdminPagesMutationVariables>;

/**
 * __useGetDocMetadataForAdminPagesMutation__
 *
 * To run a mutation, you first call `useGetDocMetadataForAdminPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDocMetadataForAdminPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDocMetadataForAdminPagesMutation, { data, loading, error }] = useGetDocMetadataForAdminPagesMutation({
 *   variables: {
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetDocMetadataForAdminPagesMutation(baseOptions?: Apollo.MutationHookOptions<GetDocMetadataForAdminPagesMutation, GetDocMetadataForAdminPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetDocMetadataForAdminPagesMutation, GetDocMetadataForAdminPagesMutationVariables>(GetDocMetadataForAdminPagesDocument, options);
      }
export type GetDocMetadataForAdminPagesMutationHookResult = ReturnType<typeof useGetDocMetadataForAdminPagesMutation>;
export type GetDocMetadataForAdminPagesMutationResult = Apollo.MutationResult<GetDocMetadataForAdminPagesMutation>;
export type GetDocMetadataForAdminPagesMutationOptions = Apollo.BaseMutationOptions<GetDocMetadataForAdminPagesMutation, GetDocMetadataForAdminPagesMutationVariables>;
export const GetAllNotificationsPagesDocument = gql`
    mutation getAllNotificationsPages($PageInfoInput: PageInfoInput) {
  getAllNotificationsPages(pageInfo: $PageInfoInput) {
    notificationList {
      title
      description
      body
      uniqueId
      created_at
      created_by
      updated_at
      updated_by
      __typename
    }
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
      __typename
    }
    __typename
  }
}
    `;
export type GetAllNotificationsPagesMutationFn = Apollo.MutationFunction<GetAllNotificationsPagesMutation, GetAllNotificationsPagesMutationVariables>;

/**
 * __useGetAllNotificationsPagesMutation__
 *
 * To run a mutation, you first call `useGetAllNotificationsPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAllNotificationsPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAllNotificationsPagesMutation, { data, loading, error }] = useGetAllNotificationsPagesMutation({
 *   variables: {
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetAllNotificationsPagesMutation(baseOptions?: Apollo.MutationHookOptions<GetAllNotificationsPagesMutation, GetAllNotificationsPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAllNotificationsPagesMutation, GetAllNotificationsPagesMutationVariables>(GetAllNotificationsPagesDocument, options);
      }
export type GetAllNotificationsPagesMutationHookResult = ReturnType<typeof useGetAllNotificationsPagesMutation>;
export type GetAllNotificationsPagesMutationResult = Apollo.MutationResult<GetAllNotificationsPagesMutation>;
export type GetAllNotificationsPagesMutationOptions = Apollo.BaseMutationOptions<GetAllNotificationsPagesMutation, GetAllNotificationsPagesMutationVariables>;
export const GetAllCronJobPagesDocument = gql`
    mutation getAllCronJobPages($PageInfoInput: PageInfoInput) {
  getAllCronJobPages(pageInfo: $PageInfoInput) {
    cronJobList {
      uniqueId
      cronjobname
      lastrunat
      nextrunat
      lastrunstatus
      description
      created_at
      created_by
      updated_at
      updated_by
      isActive
      __typename
    }
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
      __typename
    }
    __typename
  }
}
    `;
export type GetAllCronJobPagesMutationFn = Apollo.MutationFunction<GetAllCronJobPagesMutation, GetAllCronJobPagesMutationVariables>;

/**
 * __useGetAllCronJobPagesMutation__
 *
 * To run a mutation, you first call `useGetAllCronJobPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAllCronJobPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAllCronJobPagesMutation, { data, loading, error }] = useGetAllCronJobPagesMutation({
 *   variables: {
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetAllCronJobPagesMutation(baseOptions?: Apollo.MutationHookOptions<GetAllCronJobPagesMutation, GetAllCronJobPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAllCronJobPagesMutation, GetAllCronJobPagesMutationVariables>(GetAllCronJobPagesDocument, options);
      }
export type GetAllCronJobPagesMutationHookResult = ReturnType<typeof useGetAllCronJobPagesMutation>;
export type GetAllCronJobPagesMutationResult = Apollo.MutationResult<GetAllCronJobPagesMutation>;
export type GetAllCronJobPagesMutationOptions = Apollo.BaseMutationOptions<GetAllCronJobPagesMutation, GetAllCronJobPagesMutationVariables>;
export const GetAllPermissionsPagesDocument = gql`
    mutation getAllPermissionsPages($PageInfoInput: PageInfoInput) {
  getAllPermissionsPages(pageInfo: $PageInfoInput) {
    permissionList {
      id
      permissionName
      description
    }
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
      __typename
    }
    __typename
  }
}
    `;
export type GetAllPermissionsPagesMutationFn = Apollo.MutationFunction<GetAllPermissionsPagesMutation, GetAllPermissionsPagesMutationVariables>;

/**
 * __useGetAllPermissionsPagesMutation__
 *
 * To run a mutation, you first call `useGetAllPermissionsPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAllPermissionsPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAllPermissionsPagesMutation, { data, loading, error }] = useGetAllPermissionsPagesMutation({
 *   variables: {
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetAllPermissionsPagesMutation(baseOptions?: Apollo.MutationHookOptions<GetAllPermissionsPagesMutation, GetAllPermissionsPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAllPermissionsPagesMutation, GetAllPermissionsPagesMutationVariables>(GetAllPermissionsPagesDocument, options);
      }
export type GetAllPermissionsPagesMutationHookResult = ReturnType<typeof useGetAllPermissionsPagesMutation>;
export type GetAllPermissionsPagesMutationResult = Apollo.MutationResult<GetAllPermissionsPagesMutation>;
export type GetAllPermissionsPagesMutationOptions = Apollo.BaseMutationOptions<GetAllPermissionsPagesMutation, GetAllPermissionsPagesMutationVariables>;
export const DeleteNotificationSchedulesByIdDocument = gql`
    mutation deleteNotificationSchedulesById($uniqueId: [Int!]) {
  deleteNotificationSchedulesById(uniqueId: $uniqueId)
}
    `;
export type DeleteNotificationSchedulesByIdMutationFn = Apollo.MutationFunction<DeleteNotificationSchedulesByIdMutation, DeleteNotificationSchedulesByIdMutationVariables>;

/**
 * __useDeleteNotificationSchedulesByIdMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationSchedulesByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationSchedulesByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationSchedulesByIdMutation, { data, loading, error }] = useDeleteNotificationSchedulesByIdMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *   },
 * });
 */
export function useDeleteNotificationSchedulesByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationSchedulesByIdMutation, DeleteNotificationSchedulesByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNotificationSchedulesByIdMutation, DeleteNotificationSchedulesByIdMutationVariables>(DeleteNotificationSchedulesByIdDocument, options);
      }
export type DeleteNotificationSchedulesByIdMutationHookResult = ReturnType<typeof useDeleteNotificationSchedulesByIdMutation>;
export type DeleteNotificationSchedulesByIdMutationResult = Apollo.MutationResult<DeleteNotificationSchedulesByIdMutation>;
export type DeleteNotificationSchedulesByIdMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationSchedulesByIdMutation, DeleteNotificationSchedulesByIdMutationVariables>;
export const NotificationScheduleListUpdateDocument = gql`
    mutation notificationScheduleListUpdate($notificationScheduleUpdate: [notificationScheduleUpdate]) {
  notificationScheduleListUpdate(
    notificationScheduleList: $notificationScheduleUpdate
  ) {
    uniqueId
    notificationId
    title
    body
    description
    groupIds
    userIds
    notifyAt
    isPushNotification
    notificationStatus
    notificationMetadata
    created_at
    created_by
    updated_at
    updated_by
  }
}
    `;
export type NotificationScheduleListUpdateMutationFn = Apollo.MutationFunction<NotificationScheduleListUpdateMutation, NotificationScheduleListUpdateMutationVariables>;

/**
 * __useNotificationScheduleListUpdateMutation__
 *
 * To run a mutation, you first call `useNotificationScheduleListUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationScheduleListUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationScheduleListUpdateMutation, { data, loading, error }] = useNotificationScheduleListUpdateMutation({
 *   variables: {
 *      notificationScheduleUpdate: // value for 'notificationScheduleUpdate'
 *   },
 * });
 */
export function useNotificationScheduleListUpdateMutation(baseOptions?: Apollo.MutationHookOptions<NotificationScheduleListUpdateMutation, NotificationScheduleListUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationScheduleListUpdateMutation, NotificationScheduleListUpdateMutationVariables>(NotificationScheduleListUpdateDocument, options);
      }
export type NotificationScheduleListUpdateMutationHookResult = ReturnType<typeof useNotificationScheduleListUpdateMutation>;
export type NotificationScheduleListUpdateMutationResult = Apollo.MutationResult<NotificationScheduleListUpdateMutation>;
export type NotificationScheduleListUpdateMutationOptions = Apollo.BaseMutationOptions<NotificationScheduleListUpdateMutation, NotificationScheduleListUpdateMutationVariables>;
export const GetAllNotificationSchedulePagesDocument = gql`
    mutation getAllNotificationSchedulePages($uniqueId: Int!, $PageInfoInput: PageInfoInput) {
  getAllNotificationSchedulePages(uniqueId: $uniqueId, pageInfo: $PageInfoInput) {
    notificationScheduleList {
      title
      description
      body
      uniqueId
      notificationId
      groupIds
      groupNames
      userIds
      userNames
      notifyAt
      isPushNotification
      notificationStatus
      created_at
      created_by
      updated_at
      updated_by
      __typename
    }
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
      __typename
    }
    __typename
  }
}
    `;
export type GetAllNotificationSchedulePagesMutationFn = Apollo.MutationFunction<GetAllNotificationSchedulePagesMutation, GetAllNotificationSchedulePagesMutationVariables>;

/**
 * __useGetAllNotificationSchedulePagesMutation__
 *
 * To run a mutation, you first call `useGetAllNotificationSchedulePagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAllNotificationSchedulePagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAllNotificationSchedulePagesMutation, { data, loading, error }] = useGetAllNotificationSchedulePagesMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetAllNotificationSchedulePagesMutation(baseOptions?: Apollo.MutationHookOptions<GetAllNotificationSchedulePagesMutation, GetAllNotificationSchedulePagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAllNotificationSchedulePagesMutation, GetAllNotificationSchedulePagesMutationVariables>(GetAllNotificationSchedulePagesDocument, options);
      }
export type GetAllNotificationSchedulePagesMutationHookResult = ReturnType<typeof useGetAllNotificationSchedulePagesMutation>;
export type GetAllNotificationSchedulePagesMutationResult = Apollo.MutationResult<GetAllNotificationSchedulePagesMutation>;
export type GetAllNotificationSchedulePagesMutationOptions = Apollo.BaseMutationOptions<GetAllNotificationSchedulePagesMutation, GetAllNotificationSchedulePagesMutationVariables>;
export const GetDocumentsByFolderIdPagesDocument = gql`
    mutation getDocumentsByFolderIdPages($uniqueId: String!, $PageInfoInput: PageInfoInput) {
  getDocumentsByFolderIdPages(uniqueId: $uniqueId, pageInfo: $PageInfoInput) {
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      startTime
      endTime
      time_window_column
      hasNext
      __typename
    }
    fileMetadataList {
      file_bytes
      id
      is_support
      fileName
      created_at
      updated_at
      sensitivity_label
      fileURL
      downloadURL
      __typename
    }
    __typename
  }
}
    `;
export type GetDocumentsByFolderIdPagesMutationFn = Apollo.MutationFunction<GetDocumentsByFolderIdPagesMutation, GetDocumentsByFolderIdPagesMutationVariables>;

/**
 * __useGetDocumentsByFolderIdPagesMutation__
 *
 * To run a mutation, you first call `useGetDocumentsByFolderIdPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsByFolderIdPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDocumentsByFolderIdPagesMutation, { data, loading, error }] = useGetDocumentsByFolderIdPagesMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetDocumentsByFolderIdPagesMutation(baseOptions?: Apollo.MutationHookOptions<GetDocumentsByFolderIdPagesMutation, GetDocumentsByFolderIdPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetDocumentsByFolderIdPagesMutation, GetDocumentsByFolderIdPagesMutationVariables>(GetDocumentsByFolderIdPagesDocument, options);
      }
export type GetDocumentsByFolderIdPagesMutationHookResult = ReturnType<typeof useGetDocumentsByFolderIdPagesMutation>;
export type GetDocumentsByFolderIdPagesMutationResult = Apollo.MutationResult<GetDocumentsByFolderIdPagesMutation>;
export type GetDocumentsByFolderIdPagesMutationOptions = Apollo.BaseMutationOptions<GetDocumentsByFolderIdPagesMutation, GetDocumentsByFolderIdPagesMutationVariables>;
export const GetAllAuditLogsDocument = gql`
    query getAllAuditLogs {
  getAllAuditLogs {
    action
    actorEmail
    recordPrimaryKey
    tableName
    timestamp
    __typename
  }
  getAuditLogCronJob {
    uniqueId
    cronjobname
    lastrunat
    nextrunat
    lastrunstatus
    description
    created_by
    created_at
    updated_by
    updated_by
    __typename
  }
}
    `;

/**
 * __useGetAllAuditLogsQuery__
 *
 * To run a query within a React component, call `useGetAllAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAuditLogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAuditLogsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAuditLogsQuery, GetAllAuditLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAuditLogsQuery, GetAllAuditLogsQueryVariables>(GetAllAuditLogsDocument, options);
      }
export function useGetAllAuditLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAuditLogsQuery, GetAllAuditLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAuditLogsQuery, GetAllAuditLogsQueryVariables>(GetAllAuditLogsDocument, options);
        }
export type GetAllAuditLogsQueryHookResult = ReturnType<typeof useGetAllAuditLogsQuery>;
export type GetAllAuditLogsLazyQueryHookResult = ReturnType<typeof useGetAllAuditLogsLazyQuery>;
export type GetAllAuditLogsQueryResult = Apollo.QueryResult<GetAllAuditLogsQuery, GetAllAuditLogsQueryVariables>;
export const GetDocumentsByGroupIdPagesDocument = gql`
    mutation getDocumentsByGroupIdPages($uniqueId: Int!, $PageInfoInput: PageInfoInput) {
  getDocumentsByGroupIdPages(uniqueId: $uniqueId, pageInfo: $PageInfoInput) {
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      startTime
      endTime
      time_window_column
      hasNext
      __typename
    }
    documentsByGroupIdList {
      id
      documentName
      __typename
    }
    __typename
  }
}
    `;
export type GetDocumentsByGroupIdPagesMutationFn = Apollo.MutationFunction<GetDocumentsByGroupIdPagesMutation, GetDocumentsByGroupIdPagesMutationVariables>;

/**
 * __useGetDocumentsByGroupIdPagesMutation__
 *
 * To run a mutation, you first call `useGetDocumentsByGroupIdPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsByGroupIdPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDocumentsByGroupIdPagesMutation, { data, loading, error }] = useGetDocumentsByGroupIdPagesMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetDocumentsByGroupIdPagesMutation(baseOptions?: Apollo.MutationHookOptions<GetDocumentsByGroupIdPagesMutation, GetDocumentsByGroupIdPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetDocumentsByGroupIdPagesMutation, GetDocumentsByGroupIdPagesMutationVariables>(GetDocumentsByGroupIdPagesDocument, options);
      }
export type GetDocumentsByGroupIdPagesMutationHookResult = ReturnType<typeof useGetDocumentsByGroupIdPagesMutation>;
export type GetDocumentsByGroupIdPagesMutationResult = Apollo.MutationResult<GetDocumentsByGroupIdPagesMutation>;
export type GetDocumentsByGroupIdPagesMutationOptions = Apollo.BaseMutationOptions<GetDocumentsByGroupIdPagesMutation, GetDocumentsByGroupIdPagesMutationVariables>;
export const GetFoldersByGroupIdPagesDocument = gql`
    mutation getFoldersByGroupIdPages($uniqueId: Int!, $PageInfoInput: PageInfoInput) {
  getFoldersByGroupIdPages(uniqueId: $uniqueId, pageInfo: $PageInfoInput) {
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      startTime
      endTime
      time_window_column
      hasNext
      __typename
    }
    foldersByGroupIdList {
      id
      folderName
      __typename
    }
    __typename
  }
}
    `;
export type GetFoldersByGroupIdPagesMutationFn = Apollo.MutationFunction<GetFoldersByGroupIdPagesMutation, GetFoldersByGroupIdPagesMutationVariables>;

/**
 * __useGetFoldersByGroupIdPagesMutation__
 *
 * To run a mutation, you first call `useGetFoldersByGroupIdPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetFoldersByGroupIdPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getFoldersByGroupIdPagesMutation, { data, loading, error }] = useGetFoldersByGroupIdPagesMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetFoldersByGroupIdPagesMutation(baseOptions?: Apollo.MutationHookOptions<GetFoldersByGroupIdPagesMutation, GetFoldersByGroupIdPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetFoldersByGroupIdPagesMutation, GetFoldersByGroupIdPagesMutationVariables>(GetFoldersByGroupIdPagesDocument, options);
      }
export type GetFoldersByGroupIdPagesMutationHookResult = ReturnType<typeof useGetFoldersByGroupIdPagesMutation>;
export type GetFoldersByGroupIdPagesMutationResult = Apollo.MutationResult<GetFoldersByGroupIdPagesMutation>;
export type GetFoldersByGroupIdPagesMutationOptions = Apollo.BaseMutationOptions<GetFoldersByGroupIdPagesMutation, GetFoldersByGroupIdPagesMutationVariables>;
export const GetUsersByGroupIdPagesDocument = gql`
    mutation getUsersByGroupIdPages($uniqueId: Int!, $PageInfoInput: PageInfoInput) {
  getUsersByGroupIdPages(uniqueId: $uniqueId, pageInfo: $PageInfoInput) {
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      startTime
      endTime
      time_window_column
      hasNext
      __typename
    }
    usersByGroupIdList {
      uniqueId
      familyName
      givenName
      uniqueName
      __typename
    }
    __typename
  }
}
    `;
export type GetUsersByGroupIdPagesMutationFn = Apollo.MutationFunction<GetUsersByGroupIdPagesMutation, GetUsersByGroupIdPagesMutationVariables>;

/**
 * __useGetUsersByGroupIdPagesMutation__
 *
 * To run a mutation, you first call `useGetUsersByGroupIdPagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByGroupIdPagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUsersByGroupIdPagesMutation, { data, loading, error }] = useGetUsersByGroupIdPagesMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetUsersByGroupIdPagesMutation(baseOptions?: Apollo.MutationHookOptions<GetUsersByGroupIdPagesMutation, GetUsersByGroupIdPagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetUsersByGroupIdPagesMutation, GetUsersByGroupIdPagesMutationVariables>(GetUsersByGroupIdPagesDocument, options);
      }
export type GetUsersByGroupIdPagesMutationHookResult = ReturnType<typeof useGetUsersByGroupIdPagesMutation>;
export type GetUsersByGroupIdPagesMutationResult = Apollo.MutationResult<GetUsersByGroupIdPagesMutation>;
export type GetUsersByGroupIdPagesMutationOptions = Apollo.BaseMutationOptions<GetUsersByGroupIdPagesMutation, GetUsersByGroupIdPagesMutationVariables>;
export const GetAllActionsAndActorsDocument = gql`
    mutation getAllActionsAndActors($key: String!) {
  getAllActionsAndActors(key: $key)
}
    `;
export type GetAllActionsAndActorsMutationFn = Apollo.MutationFunction<GetAllActionsAndActorsMutation, GetAllActionsAndActorsMutationVariables>;

/**
 * __useGetAllActionsAndActorsMutation__
 *
 * To run a mutation, you first call `useGetAllActionsAndActorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAllActionsAndActorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAllActionsAndActorsMutation, { data, loading, error }] = useGetAllActionsAndActorsMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetAllActionsAndActorsMutation(baseOptions?: Apollo.MutationHookOptions<GetAllActionsAndActorsMutation, GetAllActionsAndActorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAllActionsAndActorsMutation, GetAllActionsAndActorsMutationVariables>(GetAllActionsAndActorsDocument, options);
      }
export type GetAllActionsAndActorsMutationHookResult = ReturnType<typeof useGetAllActionsAndActorsMutation>;
export type GetAllActionsAndActorsMutationResult = Apollo.MutationResult<GetAllActionsAndActorsMutation>;
export type GetAllActionsAndActorsMutationOptions = Apollo.BaseMutationOptions<GetAllActionsAndActorsMutation, GetAllActionsAndActorsMutationVariables>;
export const EmailEnableDisableDocument = gql`
    mutation emailEnableDisable($enabled: String!) {
  emailEnableDisable(enabled: $enabled) {
    response
  }
}
    `;
export type EmailEnableDisableMutationFn = Apollo.MutationFunction<EmailEnableDisableMutation, EmailEnableDisableMutationVariables>;

/**
 * __useEmailEnableDisableMutation__
 *
 * To run a mutation, you first call `useEmailEnableDisableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailEnableDisableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailEnableDisableMutation, { data, loading, error }] = useEmailEnableDisableMutation({
 *   variables: {
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useEmailEnableDisableMutation(baseOptions?: Apollo.MutationHookOptions<EmailEnableDisableMutation, EmailEnableDisableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailEnableDisableMutation, EmailEnableDisableMutationVariables>(EmailEnableDisableDocument, options);
      }
export type EmailEnableDisableMutationHookResult = ReturnType<typeof useEmailEnableDisableMutation>;
export type EmailEnableDisableMutationResult = Apollo.MutationResult<EmailEnableDisableMutation>;
export type EmailEnableDisableMutationOptions = Apollo.BaseMutationOptions<EmailEnableDisableMutation, EmailEnableDisableMutationVariables>;
export const GetEmailSettingDocument = gql`
    query getEmailSetting {
  getEmailSetting {
    response
  }
}
    `;

/**
 * __useGetEmailSettingQuery__
 *
 * To run a query within a React component, call `useGetEmailSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailSettingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailSettingQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailSettingQuery, GetEmailSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailSettingQuery, GetEmailSettingQueryVariables>(GetEmailSettingDocument, options);
      }
export function useGetEmailSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailSettingQuery, GetEmailSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailSettingQuery, GetEmailSettingQueryVariables>(GetEmailSettingDocument, options);
        }
export type GetEmailSettingQueryHookResult = ReturnType<typeof useGetEmailSettingQuery>;
export type GetEmailSettingLazyQueryHookResult = ReturnType<typeof useGetEmailSettingLazyQuery>;
export type GetEmailSettingQueryResult = Apollo.QueryResult<GetEmailSettingQuery, GetEmailSettingQueryVariables>;
export const GetAllValidUsersUniqueNameDocument = gql`
    query getAllValidUsersUniqueName {
  getAllValidUsersUniqueName
}
    `;

/**
 * __useGetAllValidUsersUniqueNameQuery__
 *
 * To run a query within a React component, call `useGetAllValidUsersUniqueNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllValidUsersUniqueNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllValidUsersUniqueNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllValidUsersUniqueNameQuery(baseOptions?: Apollo.QueryHookOptions<GetAllValidUsersUniqueNameQuery, GetAllValidUsersUniqueNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllValidUsersUniqueNameQuery, GetAllValidUsersUniqueNameQueryVariables>(GetAllValidUsersUniqueNameDocument, options);
      }
export function useGetAllValidUsersUniqueNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllValidUsersUniqueNameQuery, GetAllValidUsersUniqueNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllValidUsersUniqueNameQuery, GetAllValidUsersUniqueNameQueryVariables>(GetAllValidUsersUniqueNameDocument, options);
        }
export type GetAllValidUsersUniqueNameQueryHookResult = ReturnType<typeof useGetAllValidUsersUniqueNameQuery>;
export type GetAllValidUsersUniqueNameLazyQueryHookResult = ReturnType<typeof useGetAllValidUsersUniqueNameLazyQuery>;
export type GetAllValidUsersUniqueNameQueryResult = Apollo.QueryResult<GetAllValidUsersUniqueNameQuery, GetAllValidUsersUniqueNameQueryVariables>;
export const GetEmailNotificationDeatilsDocument = gql`
    query getEmailNotificationDeatils {
  getEmailNotificationDeatils {
    uniqueId
    subject
    notification_name
    recipient_to
    recipient_cc
    recipient_bcc
    sender
    sender_name
    created_by
    created_at
    updated_by
    updated_at
    deleted_by
    deleted_at
  }
}
    `;

/**
 * __useGetEmailNotificationDeatilsQuery__
 *
 * To run a query within a React component, call `useGetEmailNotificationDeatilsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailNotificationDeatilsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailNotificationDeatilsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailNotificationDeatilsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmailNotificationDeatilsQuery, GetEmailNotificationDeatilsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailNotificationDeatilsQuery, GetEmailNotificationDeatilsQueryVariables>(GetEmailNotificationDeatilsDocument, options);
      }
export function useGetEmailNotificationDeatilsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailNotificationDeatilsQuery, GetEmailNotificationDeatilsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailNotificationDeatilsQuery, GetEmailNotificationDeatilsQueryVariables>(GetEmailNotificationDeatilsDocument, options);
        }
export type GetEmailNotificationDeatilsQueryHookResult = ReturnType<typeof useGetEmailNotificationDeatilsQuery>;
export type GetEmailNotificationDeatilsLazyQueryHookResult = ReturnType<typeof useGetEmailNotificationDeatilsLazyQuery>;
export type GetEmailNotificationDeatilsQueryResult = Apollo.QueryResult<GetEmailNotificationDeatilsQuery, GetEmailNotificationDeatilsQueryVariables>;
export const UpdateEmailNotificationDocument = gql`
    mutation updateEmailNotification($uniqueId: ID!, $recipient_to: [String!]!, $recipient_cc: [String!]!, $recipient_bcc: [String!]!, $sender: String!, $sender_name: String!, $subject: String!) {
  updateEmailNotification(
    uniqueId: $uniqueId
    recipient_to: $recipient_to
    recipient_cc: $recipient_cc
    recipient_bcc: $recipient_bcc
    sender: $sender
    sender_name: $sender_name
    subject: $subject
  ) {
    uniqueId
    recipient_to
    recipient_cc
    recipient_bcc
    sender
    sender_name
    subject
    __typename
  }
}
    `;
export type UpdateEmailNotificationMutationFn = Apollo.MutationFunction<UpdateEmailNotificationMutation, UpdateEmailNotificationMutationVariables>;

/**
 * __useUpdateEmailNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateEmailNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailNotificationMutation, { data, loading, error }] = useUpdateEmailNotificationMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *      recipient_to: // value for 'recipient_to'
 *      recipient_cc: // value for 'recipient_cc'
 *      recipient_bcc: // value for 'recipient_bcc'
 *      sender: // value for 'sender'
 *      sender_name: // value for 'sender_name'
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useUpdateEmailNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailNotificationMutation, UpdateEmailNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailNotificationMutation, UpdateEmailNotificationMutationVariables>(UpdateEmailNotificationDocument, options);
      }
export type UpdateEmailNotificationMutationHookResult = ReturnType<typeof useUpdateEmailNotificationMutation>;
export type UpdateEmailNotificationMutationResult = Apollo.MutationResult<UpdateEmailNotificationMutation>;
export type UpdateEmailNotificationMutationOptions = Apollo.BaseMutationOptions<UpdateEmailNotificationMutation, UpdateEmailNotificationMutationVariables>;
export const GetCronJobAuditLogDocument = gql`
    mutation getCronJobAuditLog($cronJob: String!, $PageInfoInput: PageInfoInput) {
  getCronJobAuditLog(cronJob: $cronJob, pageInfo: $PageInfoInput) {
    cronAuditLogs {
      id
      actorEmail
      tableName
      action
      recordPrimaryKey
      status
      timestamp
      rowsAffected
      endTimestamp
      __typename
    }
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
      __typename
    }
    __typename
  }
}
    `;
export type GetCronJobAuditLogMutationFn = Apollo.MutationFunction<GetCronJobAuditLogMutation, GetCronJobAuditLogMutationVariables>;

/**
 * __useGetCronJobAuditLogMutation__
 *
 * To run a mutation, you first call `useGetCronJobAuditLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCronJobAuditLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCronJobAuditLogMutation, { data, loading, error }] = useGetCronJobAuditLogMutation({
 *   variables: {
 *      cronJob: // value for 'cronJob'
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetCronJobAuditLogMutation(baseOptions?: Apollo.MutationHookOptions<GetCronJobAuditLogMutation, GetCronJobAuditLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetCronJobAuditLogMutation, GetCronJobAuditLogMutationVariables>(GetCronJobAuditLogDocument, options);
      }
export type GetCronJobAuditLogMutationHookResult = ReturnType<typeof useGetCronJobAuditLogMutation>;
export type GetCronJobAuditLogMutationResult = Apollo.MutationResult<GetCronJobAuditLogMutation>;
export type GetCronJobAuditLogMutationOptions = Apollo.BaseMutationOptions<GetCronJobAuditLogMutation, GetCronJobAuditLogMutationVariables>;
export const GetUsersExcludedFromGroupDocument = gql`
    mutation getUsersExcludedFromGroup($uniqueId: Int!) {
  getUsersExcludedFromGroup(uniqueId: $uniqueId) {
    uniqueId
    uniqueName
    __typename
  }
}
    `;
export type GetUsersExcludedFromGroupMutationFn = Apollo.MutationFunction<GetUsersExcludedFromGroupMutation, GetUsersExcludedFromGroupMutationVariables>;

/**
 * __useGetUsersExcludedFromGroupMutation__
 *
 * To run a mutation, you first call `useGetUsersExcludedFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUsersExcludedFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUsersExcludedFromGroupMutation, { data, loading, error }] = useGetUsersExcludedFromGroupMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *   },
 * });
 */
export function useGetUsersExcludedFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<GetUsersExcludedFromGroupMutation, GetUsersExcludedFromGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetUsersExcludedFromGroupMutation, GetUsersExcludedFromGroupMutationVariables>(GetUsersExcludedFromGroupDocument, options);
      }
export type GetUsersExcludedFromGroupMutationHookResult = ReturnType<typeof useGetUsersExcludedFromGroupMutation>;
export type GetUsersExcludedFromGroupMutationResult = Apollo.MutationResult<GetUsersExcludedFromGroupMutation>;
export type GetUsersExcludedFromGroupMutationOptions = Apollo.BaseMutationOptions<GetUsersExcludedFromGroupMutation, GetUsersExcludedFromGroupMutationVariables>;
export const GetCronJobAuditLogDownloadReportDocument = gql`
    mutation getCronJobAuditLogDownloadReport($cronJob: String!) {
  getCronJobAuditLogDownloadReport(cronJob: $cronJob) {
    id
    actorEmail
    tableName
    action
    recordPrimaryKey
    status
    rowsAffected
    timestamp
    endTimestamp
    __typename
  }
}
    `;
export type GetCronJobAuditLogDownloadReportMutationFn = Apollo.MutationFunction<GetCronJobAuditLogDownloadReportMutation, GetCronJobAuditLogDownloadReportMutationVariables>;

/**
 * __useGetCronJobAuditLogDownloadReportMutation__
 *
 * To run a mutation, you first call `useGetCronJobAuditLogDownloadReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCronJobAuditLogDownloadReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCronJobAuditLogDownloadReportMutation, { data, loading, error }] = useGetCronJobAuditLogDownloadReportMutation({
 *   variables: {
 *      cronJob: // value for 'cronJob'
 *   },
 * });
 */
export function useGetCronJobAuditLogDownloadReportMutation(baseOptions?: Apollo.MutationHookOptions<GetCronJobAuditLogDownloadReportMutation, GetCronJobAuditLogDownloadReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetCronJobAuditLogDownloadReportMutation, GetCronJobAuditLogDownloadReportMutationVariables>(GetCronJobAuditLogDownloadReportDocument, options);
      }
export type GetCronJobAuditLogDownloadReportMutationHookResult = ReturnType<typeof useGetCronJobAuditLogDownloadReportMutation>;
export type GetCronJobAuditLogDownloadReportMutationResult = Apollo.MutationResult<GetCronJobAuditLogDownloadReportMutation>;
export type GetCronJobAuditLogDownloadReportMutationOptions = Apollo.BaseMutationOptions<GetCronJobAuditLogDownloadReportMutation, GetCronJobAuditLogDownloadReportMutationVariables>;
export const RemoveUserFromGroupDocument = gql`
    mutation removeUserFromGroup($addUserToGroupArgsList: [addUserToGroupArgs]) {
  removeUserFromGroup(addUserToGroupArgsList: $addUserToGroupArgsList) {
    userId
    groupId
    __typename
  }
}
    `;
export type RemoveUserFromGroupMutationFn = Apollo.MutationFunction<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>;

/**
 * __useRemoveUserFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromGroupMutation, { data, loading, error }] = useRemoveUserFromGroupMutation({
 *   variables: {
 *      addUserToGroupArgsList: // value for 'addUserToGroupArgsList'
 *   },
 * });
 */
export function useRemoveUserFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>(RemoveUserFromGroupDocument, options);
      }
export type RemoveUserFromGroupMutationHookResult = ReturnType<typeof useRemoveUserFromGroupMutation>;
export type RemoveUserFromGroupMutationResult = Apollo.MutationResult<RemoveUserFromGroupMutation>;
export type RemoveUserFromGroupMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>;
export const AddUserToGroupDocument = gql`
    mutation addUserToGroup($addUserToGroupArgsList: [addUserToGroupArgs]) {
  addUserToGroup(addUserToGroupArgsList: $addUserToGroupArgsList) {
    userId
    groupId
    __typename
  }
}
    `;
export type AddUserToGroupMutationFn = Apollo.MutationFunction<AddUserToGroupMutation, AddUserToGroupMutationVariables>;

/**
 * __useAddUserToGroupMutation__
 *
 * To run a mutation, you first call `useAddUserToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToGroupMutation, { data, loading, error }] = useAddUserToGroupMutation({
 *   variables: {
 *      addUserToGroupArgsList: // value for 'addUserToGroupArgsList'
 *   },
 * });
 */
export function useAddUserToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToGroupMutation, AddUserToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserToGroupMutation, AddUserToGroupMutationVariables>(AddUserToGroupDocument, options);
      }
export type AddUserToGroupMutationHookResult = ReturnType<typeof useAddUserToGroupMutation>;
export type AddUserToGroupMutationResult = Apollo.MutationResult<AddUserToGroupMutation>;
export type AddUserToGroupMutationOptions = Apollo.BaseMutationOptions<AddUserToGroupMutation, AddUserToGroupMutationVariables>;
export const SyncUsersWithAadDocument = gql`
    query syncUsersWithAAD {
  syncUsersWithAAD {
    uniqueId
    cronjobname
    lastrunat
    nextrunat
    lastrunstatus
    description
    isActive
    created_at
    created_by
    updated_at
    updated_by
    __typename
  }
}
    `;

/**
 * __useSyncUsersWithAadQuery__
 *
 * To run a query within a React component, call `useSyncUsersWithAadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncUsersWithAadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncUsersWithAadQuery({
 *   variables: {
 *   },
 * });
 */
export function useSyncUsersWithAadQuery(baseOptions?: Apollo.QueryHookOptions<SyncUsersWithAadQuery, SyncUsersWithAadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SyncUsersWithAadQuery, SyncUsersWithAadQueryVariables>(SyncUsersWithAadDocument, options);
      }
export function useSyncUsersWithAadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SyncUsersWithAadQuery, SyncUsersWithAadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SyncUsersWithAadQuery, SyncUsersWithAadQueryVariables>(SyncUsersWithAadDocument, options);
        }
export type SyncUsersWithAadQueryHookResult = ReturnType<typeof useSyncUsersWithAadQuery>;
export type SyncUsersWithAadLazyQueryHookResult = ReturnType<typeof useSyncUsersWithAadLazyQuery>;
export type SyncUsersWithAadQueryResult = Apollo.QueryResult<SyncUsersWithAadQuery, SyncUsersWithAadQueryVariables>;
export const ProcessPushNotificationsDocument = gql`
    query processPushNotifications {
  processPushNotifications {
    uniqueId
    cronjobname
    lastrunat
    nextrunat
    lastrunstatus
    description
    isActive
    created_at
    created_by
    updated_at
    updated_by
    __typename
  }
}
    `;

/**
 * __useProcessPushNotificationsQuery__
 *
 * To run a query within a React component, call `useProcessPushNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessPushNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessPushNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProcessPushNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<ProcessPushNotificationsQuery, ProcessPushNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProcessPushNotificationsQuery, ProcessPushNotificationsQueryVariables>(ProcessPushNotificationsDocument, options);
      }
export function useProcessPushNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProcessPushNotificationsQuery, ProcessPushNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProcessPushNotificationsQuery, ProcessPushNotificationsQueryVariables>(ProcessPushNotificationsDocument, options);
        }
export type ProcessPushNotificationsQueryHookResult = ReturnType<typeof useProcessPushNotificationsQuery>;
export type ProcessPushNotificationsLazyQueryHookResult = ReturnType<typeof useProcessPushNotificationsLazyQuery>;
export type ProcessPushNotificationsQueryResult = Apollo.QueryResult<ProcessPushNotificationsQuery, ProcessPushNotificationsQueryVariables>;
export const CleanAuditLogsDocument = gql`
    query cleanAuditLogs {
  cleanAuditLogs {
    uniqueId
    cronjobname
    lastrunat
    nextrunat
    lastrunstatus
    description
    isActive
    created_at
    created_by
    updated_at
    updated_by
    __typename
  }
}
    `;

/**
 * __useCleanAuditLogsQuery__
 *
 * To run a query within a React component, call `useCleanAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCleanAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCleanAuditLogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCleanAuditLogsQuery(baseOptions?: Apollo.QueryHookOptions<CleanAuditLogsQuery, CleanAuditLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CleanAuditLogsQuery, CleanAuditLogsQueryVariables>(CleanAuditLogsDocument, options);
      }
export function useCleanAuditLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CleanAuditLogsQuery, CleanAuditLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CleanAuditLogsQuery, CleanAuditLogsQueryVariables>(CleanAuditLogsDocument, options);
        }
export type CleanAuditLogsQueryHookResult = ReturnType<typeof useCleanAuditLogsQuery>;
export type CleanAuditLogsLazyQueryHookResult = ReturnType<typeof useCleanAuditLogsLazyQuery>;
export type CleanAuditLogsQueryResult = Apollo.QueryResult<CleanAuditLogsQuery, CleanAuditLogsQueryVariables>;
export const GetFilesByUserIdDocument = gql`
    mutation getFilesByUserId($userId: Int!) {
  getFilesByUserId(userId: $userId) {
    videoFiles {
      id
      file_url
      file_name
      file_bytes
      created_at
      updated_at
      folder_id
      folder_name
      auto_download
      notify_user
      bookmarked_id
      duration
      thumbnail
      is_video
      download_url
    }
    supportFiles {
      id
      file_url
      file_name
      file_bytes
      created_at
      updated_at
      folder_id
      folder_name
      auto_download
      notify_user
      bookmarked_id
      duration
      thumbnail
      is_video
      download_url
    }
    docFiles {
      id
      file_url
      file_name
      file_bytes
      created_at
      updated_at
      folder_id
      folder_name
      auto_download
      notify_user
      bookmarked_id
      duration
      thumbnail
      is_video
      download_url
    }
  }
}
    `;
export type GetFilesByUserIdMutationFn = Apollo.MutationFunction<GetFilesByUserIdMutation, GetFilesByUserIdMutationVariables>;

/**
 * __useGetFilesByUserIdMutation__
 *
 * To run a mutation, you first call `useGetFilesByUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetFilesByUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getFilesByUserIdMutation, { data, loading, error }] = useGetFilesByUserIdMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetFilesByUserIdMutation(baseOptions?: Apollo.MutationHookOptions<GetFilesByUserIdMutation, GetFilesByUserIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetFilesByUserIdMutation, GetFilesByUserIdMutationVariables>(GetFilesByUserIdDocument, options);
      }
export type GetFilesByUserIdMutationHookResult = ReturnType<typeof useGetFilesByUserIdMutation>;
export type GetFilesByUserIdMutationResult = Apollo.MutationResult<GetFilesByUserIdMutation>;
export type GetFilesByUserIdMutationOptions = Apollo.BaseMutationOptions<GetFilesByUserIdMutation, GetFilesByUserIdMutationVariables>;
export const SaveStaticPageDocument = gql`
    mutation saveStaticPage($title: String!, $body: String!, $slug: String!) {
  saveStaticPage(title: $title, body: $body, slug: $slug) {
    title
    body
    slug
  }
}
    `;
export type SaveStaticPageMutationFn = Apollo.MutationFunction<SaveStaticPageMutation, SaveStaticPageMutationVariables>;

/**
 * __useSaveStaticPageMutation__
 *
 * To run a mutation, you first call `useSaveStaticPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveStaticPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveStaticPageMutation, { data, loading, error }] = useSaveStaticPageMutation({
 *   variables: {
 *      title: // value for 'title'
 *      body: // value for 'body'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSaveStaticPageMutation(baseOptions?: Apollo.MutationHookOptions<SaveStaticPageMutation, SaveStaticPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveStaticPageMutation, SaveStaticPageMutationVariables>(SaveStaticPageDocument, options);
      }
export type SaveStaticPageMutationHookResult = ReturnType<typeof useSaveStaticPageMutation>;
export type SaveStaticPageMutationResult = Apollo.MutationResult<SaveStaticPageMutation>;
export type SaveStaticPageMutationOptions = Apollo.BaseMutationOptions<SaveStaticPageMutation, SaveStaticPageMutationVariables>;
export const UpdateStaticPageDocument = gql`
    mutation updateStaticPage($id: Int!, $title: String!, $body: String!, $slug: String!) {
  updateStaticPage(id: $id, title: $title, body: $body, slug: $slug) {
    id
    title
    body
    slug
  }
}
    `;
export type UpdateStaticPageMutationFn = Apollo.MutationFunction<UpdateStaticPageMutation, UpdateStaticPageMutationVariables>;

/**
 * __useUpdateStaticPageMutation__
 *
 * To run a mutation, you first call `useUpdateStaticPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaticPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaticPageMutation, { data, loading, error }] = useUpdateStaticPageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      body: // value for 'body'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUpdateStaticPageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStaticPageMutation, UpdateStaticPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStaticPageMutation, UpdateStaticPageMutationVariables>(UpdateStaticPageDocument, options);
      }
export type UpdateStaticPageMutationHookResult = ReturnType<typeof useUpdateStaticPageMutation>;
export type UpdateStaticPageMutationResult = Apollo.MutationResult<UpdateStaticPageMutation>;
export type UpdateStaticPageMutationOptions = Apollo.BaseMutationOptions<UpdateStaticPageMutation, UpdateStaticPageMutationVariables>;
export const DeleteStaticPageDocument = gql`
    mutation deleteStaticPage($ids: [Int!]) {
  deleteStaticPage(ids: $ids) {
    id
    __typename
  }
}
    `;
export type DeleteStaticPageMutationFn = Apollo.MutationFunction<DeleteStaticPageMutation, DeleteStaticPageMutationVariables>;

/**
 * __useDeleteStaticPageMutation__
 *
 * To run a mutation, you first call `useDeleteStaticPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStaticPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStaticPageMutation, { data, loading, error }] = useDeleteStaticPageMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteStaticPageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStaticPageMutation, DeleteStaticPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStaticPageMutation, DeleteStaticPageMutationVariables>(DeleteStaticPageDocument, options);
      }
export type DeleteStaticPageMutationHookResult = ReturnType<typeof useDeleteStaticPageMutation>;
export type DeleteStaticPageMutationResult = Apollo.MutationResult<DeleteStaticPageMutation>;
export type DeleteStaticPageMutationOptions = Apollo.BaseMutationOptions<DeleteStaticPageMutation, DeleteStaticPageMutationVariables>;
export const GetAllStaticPageDocument = gql`
    mutation getAllStaticPage($PageInfoInput: PageInfoInput) {
  getAllStaticPage(pageInfo: $PageInfoInput) {
    staticPageList {
      id
      uniqueId
      body
      title
      slug
      created_at
      created_by
      updated_at
      updated_by
    }
    pageInfo {
      current_page
      per_page
      total_pages
      total_records
      fetched_records
      from
      to
      search_term
      search_column
      order_by
      order_by_column
      hasNext
    }
  }
}
    `;
export type GetAllStaticPageMutationFn = Apollo.MutationFunction<GetAllStaticPageMutation, GetAllStaticPageMutationVariables>;

/**
 * __useGetAllStaticPageMutation__
 *
 * To run a mutation, you first call `useGetAllStaticPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAllStaticPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAllStaticPageMutation, { data, loading, error }] = useGetAllStaticPageMutation({
 *   variables: {
 *      PageInfoInput: // value for 'PageInfoInput'
 *   },
 * });
 */
export function useGetAllStaticPageMutation(baseOptions?: Apollo.MutationHookOptions<GetAllStaticPageMutation, GetAllStaticPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetAllStaticPageMutation, GetAllStaticPageMutationVariables>(GetAllStaticPageDocument, options);
      }
export type GetAllStaticPageMutationHookResult = ReturnType<typeof useGetAllStaticPageMutation>;
export type GetAllStaticPageMutationResult = Apollo.MutationResult<GetAllStaticPageMutation>;
export type GetAllStaticPageMutationOptions = Apollo.BaseMutationOptions<GetAllStaticPageMutation, GetAllStaticPageMutationVariables>;
export const GetStaticPageByIdDocument = gql`
    mutation getStaticPageById($slug: String!) {
  getStaticPageById(slug: $slug) {
    id
    uniqueId
    title
    body
    slug
    created_by
    created_at
    updated_by
    updated_at
    __typename
  }
}
    `;
export type GetStaticPageByIdMutationFn = Apollo.MutationFunction<GetStaticPageByIdMutation, GetStaticPageByIdMutationVariables>;

/**
 * __useGetStaticPageByIdMutation__
 *
 * To run a mutation, you first call `useGetStaticPageByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetStaticPageByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getStaticPageByIdMutation, { data, loading, error }] = useGetStaticPageByIdMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetStaticPageByIdMutation(baseOptions?: Apollo.MutationHookOptions<GetStaticPageByIdMutation, GetStaticPageByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetStaticPageByIdMutation, GetStaticPageByIdMutationVariables>(GetStaticPageByIdDocument, options);
      }
export type GetStaticPageByIdMutationHookResult = ReturnType<typeof useGetStaticPageByIdMutation>;
export type GetStaticPageByIdMutationResult = Apollo.MutationResult<GetStaticPageByIdMutation>;
export type GetStaticPageByIdMutationOptions = Apollo.BaseMutationOptions<GetStaticPageByIdMutation, GetStaticPageByIdMutationVariables>;
export const DownloadUserNotificationResponseDocument = gql`
    mutation downloadUserNotificationResponse($uniqueId: Int!) {
  downloadUserNotificationResponse(uniqueId: $uniqueId) {
    uniqueId
    notificationScheduleId
    notifyAt
    userResponse {
      key
      value
    }
    respondedBy
    createdAt
  }
}
    `;
export type DownloadUserNotificationResponseMutationFn = Apollo.MutationFunction<DownloadUserNotificationResponseMutation, DownloadUserNotificationResponseMutationVariables>;

/**
 * __useDownloadUserNotificationResponseMutation__
 *
 * To run a mutation, you first call `useDownloadUserNotificationResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadUserNotificationResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadUserNotificationResponseMutation, { data, loading, error }] = useDownloadUserNotificationResponseMutation({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *   },
 * });
 */
export function useDownloadUserNotificationResponseMutation(baseOptions?: Apollo.MutationHookOptions<DownloadUserNotificationResponseMutation, DownloadUserNotificationResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadUserNotificationResponseMutation, DownloadUserNotificationResponseMutationVariables>(DownloadUserNotificationResponseDocument, options);
      }
export type DownloadUserNotificationResponseMutationHookResult = ReturnType<typeof useDownloadUserNotificationResponseMutation>;
export type DownloadUserNotificationResponseMutationResult = Apollo.MutationResult<DownloadUserNotificationResponseMutation>;
export type DownloadUserNotificationResponseMutationOptions = Apollo.BaseMutationOptions<DownloadUserNotificationResponseMutation, DownloadUserNotificationResponseMutationVariables>;