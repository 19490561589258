import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ConfigSection from '../../../components/form/input/ConfigSection';
import { useCronJobListToggleMutation } from '../../../graphql/generated/graphql.ts';
import { toast } from "react-toastify";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../components/button/Button.js';

const EditAuditCronJob = () => {
    const navigate = useNavigate()
    const state = useLocation()?.state || {};
    const [auditLogToggleMuation, { data: toggleData, loading }] = useCronJobListToggleMutation();
    const { cronJobListToggle } = toggleData || {};
    const [isEnabled, setIsEnabled] = useState(state?.isActive|| false);

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Cron Job | Edit`;
        document.title = pageTitle;
    }, [])




    const handleSubmit = () => {
        const cronJobToggle = [
            {
                cronjobname: state?.cronjobname,
                isActive: isEnabled
            }]
        auditLogToggleMuation({ variables: { cronJobToggle } })
        .then(()=>{
            toast.success("Cron job updated successfuly.")
            navigate('/audit/cron-jobs')
        })
            .catch(err => {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    }

    const toggleSwitch = () => {
        setIsEnabled(previousState => !previousState);

    };
    useEffect(() => {
        if (cronJobListToggle) {
            setIsEnabled(cronJobListToggle?.[0]?.isActive)
        }
    }, [cronJobListToggle])

    return (
        <div className="mt-16 container justify-center mx-auto px-2 sm:px-20 md:px-32 lg:px-40 xl:40">
            {loading ? <div className="flex justify-center items-center h-screen">
                <td colSpan={3} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
                    <div>
                        <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                        <span>Loading Data</span>
                    </div>
                </td>
            </div> :
                <div>
                    <div>
                        <div className="py-6">
                            <h2 className="flex flex-row items-center text-2xl font-bold dark:text-gray-100 mb-4">Edit Cron Job Details</h2>
                            <hr />
                        </div>
                    </div>
                    <ConfigSection
                        title={'Cron Job '}
                        description={`${state?.cronjobname}: ${isEnabled ? "Enable" : "Disable"}`}>
                        <div>
                            <div
                                className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-3 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                                <h2 className=" text-m font-semibold tracking-tight text-gray-900 dark:text-gray-200">{state?.cronjobname}</h2>
                                <button className={` w-16 h-8 rounded-2xl ${isEnabled ? "bg-green-600" : ' bg-gray-400'} flex justify-between cursor-pointer p-1`}
                                    onClick={toggleSwitch}>
                                    <div className={` w-6 h-6 rounded-xl bg-white ${isEnabled ? ' translate-x-8 transition-transform duration-200' : 'translate-x-0 transition-transform duration-200'}`}
                                    />
                                </button>
                            </div>
                            <div className="mt-10 flex justify-start">
                                {!loading ?
                                    <Button type="submit"
                                        className={loading ? "block rounded-md bg-gray-600 px-3.5 py-2.5 mr-3 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" : "mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"} text="Update"
                                        onClick={() => { handleSubmit() }} />
                                    : <Button type="button"
                                        className={"block rounded-md bg-gray-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"} text="Update" />
                                }
                                <Link to={`/audit/cron-jobs/${state?.uniqueId}`} state={{ cronJobData: state }}
                                    className="block rounded-md bg-gray-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                                {loading && <FontAwesomeIcon className="w-5 h-5 mr-4 ml-5 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                            </div>
                        </div>
                    </ConfigSection>
                </div>
            }
        </div>
    )

};
export default EditAuditCronJob;