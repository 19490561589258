import React from 'react';
import PropTypes from 'prop-types';

const TableHeadingSection = ({ heading, description, count=0 }) => {
  return (
    <div className="sm:flex sm:items-center sm:justify-between">
      <div>
        <div className="flex items-center gap-x-3">
          <h2 className="text-lg font-medium text-gray-800 dark:text-white">{heading}</h2>

         {count>0 && <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-gray-800 dark:text-blue-400">{count}</span> }
        </div>
        <p className="text-sm text-gray-500 dark:text-gray-300">{description}</p>
      </div>

      <div className="md:flex md:items-center mt-4 gap-x-3">
      </div>
    </div>
  );
};
TableHeadingSection.propTypes = {
  heading : PropTypes.any,
  description : PropTypes.any,
  count : PropTypes.any,
}

export default TableHeadingSection;