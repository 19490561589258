import PageWrapper from "../../../layouts/PageWrapper";
import SelectSingleInput from "../../../components/form/input/SelectSingleInput";
import InputField from "../../../components/form/input/InputField";
import { toast } from 'react-toastify';
import * as Auth from "../../../utils/AuthClient"; {/* NOSONAR */ }
import axios from 'axios';
import { useFormik } from "formik";
import { formCreateNewVideoSchema } from "../../../validations/FormValidationSchema"
import {
  Link,
  useNavigate
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import FileInputComponent from "../../../components/form/input/FileInputComponent";
import { uploadDocumentsMute } from '../../../graphql/graphql.queries'
import Button from "../../../components/button/Button";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetFileUploadSettingsQuery, useUpdateThumbnailAndDurationMutation, useGetDocumentsFolderByIdMutation } from '../../../graphql/generated/graphql.ts';
import CheckboxButton from "../../../components/form/input/CheckboxButton.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'


const AddNewVideos = () => {
  const navigate = useNavigate();
  const timeDuration = 1000;
  let folderOptions = [];
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [thumbnailLoader, setThumbnailLoader] = useState(false);
  const [getUserDetailsByIdMutation, { data: documentData, loading: load, error }] = useGetDocumentsFolderByIdMutation({
    variables: {
      uniqueId: process.env.REACT_APP_VIDEOSECTIONID
    },
  });
  const { data: maxUploadSizeData } = useGetFileUploadSettingsQuery();
  const [addThumbnailAndDuration,] = useUpdateThumbnailAndDurationMutation();
  const { KB } = maxUploadSizeData?.getFileUploadSettings?.maxUploadSize || {};

  useEffect(() => {
    const envVariable = process.env.REACT_APP_ENV;
    const prefix = 'SIERA';
    const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
    const pageTitle = `${prefix}${suffix} |Manage Videos | Create`;
    document.title = pageTitle;
  }, [])

  useEffect(() => {
    if (documentData?.getDocumentsFolderById) {
      folderOptions = [{ label: documentData?.getDocumentsFolderById?.storage_path, value: documentData?.getDocumentsFolderById?.id }];
    }
  }, [documentData])

  if (error) {
    navigate('/error', { state: { errorData: JSON.stringify(error) } });
  }

  const [initialValues, setInitialValues] = useState({
    video_name: "",
    folder: {},
    video_url: "",
    is_critical: "",
    is_notify: false
  });

  useEffect(() => {
    getUserDetailsByIdMutation()
  }, [])

  useEffect(() => {
    setInitialValues({
      video_name: "",
      folder: folderOptions[0],
      video_url: "",
      is_critical: "",
      is_notify: false
    })
  }, [documentData])

  const generateThumbnail = async (url, file_url) => {
    const video = document.createElement('video');
    video.src = url;
    video.crossOrigin = 'anonymous';

    const loadedMetadataPromise = new Promise(resolve => {
      video.addEventListener('loadedmetadata', () => resolve());
    });

    await Promise.all([loadedMetadataPromise, video.play()]);

    const canvas = document.createElement('canvas');
    canvas.width = 256;
    canvas.height = 144;

    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const thumbnail = canvas.toDataURL('image/jpeg');
    const duration = Math.round(video.duration * timeDuration);

    video.pause();
    video.removeAttribute('src');
    video.load();

    return {
      file_url: file_url || null,
      duration,
      thumbnail,
    };
  };


  const { values, errors, touched, setFieldValue, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formCreateNewVideoSchema,
    onSubmit: async value => {
      let sum = 0;
      value?.video_url?.filter(item => sum += item.size)
      try {
        const reqFile = [];
        const reqList = [];

        value?.video_url.forEach((item, i) => {
          const finalObj = {};
          reqFile.push([`variables.req.${i}.file`]);
          finalObj["fileName"] = value?.video_name?.trim();
          finalObj["description"] = "This is test file!!";
          finalObj["roleIds"] = [1];
          finalObj["isSupport"] = false;
          finalObj["isVideo"] = true;
          finalObj["folderId"] = value?.folder.value;
          finalObj["file"] = item;
          finalObj["notifyUser"] = value?.is_notify;
          finalObj["sensitivityLabel"] = "internal";
          finalObj['autoDownload'] = value?.is_critical || false
          reqList.push(finalObj);
        });
        const variables = { "req": [...reqList] }
        const data = new FormData();
        data.append("operations", JSON.stringify({ query: uploadDocumentsMute, variables }));
        data.append('map', JSON.stringify({ ...reqFile }))
        value?.video_url?.forEach((item, i) => {
          data.append(i, item);
        })
        const tokenData = JSON.parse(localStorage.getItem('user'))
        const token = tokenData.access_token

        const tokenExpiration = tokenData.id_claims.exp
        const dateNow = new Date();

        setLoading(true)
        const dateSecond = timeDuration
        if (tokenExpiration < dateNow.getTime() / dateSecond) {
          Auth.requestRefreshTokenSimmas(tokenData.refresh_token);
        } else {
          axios({
            method: "post",
            url: process.env.REACT_APP_GRAPHQLHOSTUPLOAD,
            data,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`
            },
          }).then(async res => {
            const responseData = res?.data?.errors
            const downloadedURL = res?.data?.data?.multipleUploadWithMeta[0]?.downloadURL;
            const file_url = res?.data?.data?.multipleUploadWithMeta[0]?.fileUrl;
            if (!responseData && downloadedURL) {
              toast.success(`${value?.video_url?.length} - Video uploaded successfully.`)
              setThumbnailLoader(true);
              setLoading(false)
              const thumbnailData = await generateThumbnail(downloadedURL, file_url);
              addThumbnailAndDuration({ variables: { thumbnailAndDurationUpdateList: [thumbnailData] } })
                .then(() => {
                  toast.success('Thumbnail updated successfully.');
                  setThumbnailLoader(false);
                  navigate('/manage/videos');
                })
                .catch(e => {
                  toast.error(e.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                  });
                });
            } else {
              toast.error(responseData[0]?.message, {
                autoClose: false,
              });
              setLoading(false)
            }
          }).catch(er => {
            setLoading(false)
            toast.error(er.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
              autoClose: false,
            });
          })
        }
      } catch (err) {
        setLoading(false);
        toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
          autoClose: false,
        });
      }
    }
  })

  const handleFileChange = newFiles => {
    setFieldValue('video_url', [...values.video_url, ...newFiles])
    setSelectedFiles([...selectedFiles, ...newFiles]);
  };

  const handleRemoveFile = file => {
    setFieldValue('video_url', selectedFiles?.filter(selectedFile => selectedFile !== file))
    setSelectedFiles(selectedFiles?.filter(selectedFile => selectedFile !== file));
  };

  return (

    <PageWrapper heading={'Create new Video'}>
      {load ? <div className="flex justify-center items-center h-screen">
        <td colSpan={5} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
          <div>
            <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
            <span>Loading Data</span>
          </div>
        </td>
      </div> :
        <div className="py-6">
          <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
            <div className="col-span-4">
              <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">General Information
              </h2>
              <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Fill in the video details</p>
            </div>
            <form method="POST" onSubmit={handleSubmit} className="col-span-8">
              <div
                className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                <div className="sm:col-span-2">
                  <InputField type={'text'} name={'video_name'} label={'Video Name'} value={values.video_name} disabled={loading} onChange={handleChange} onBlur={handleBlur} required error={(errors.video_name && touched.video_name) ? errors.video_name : ''} />
                </div>
                <div className="sm:col-span-2">
                  <SelectSingleInput name={'folder'} value={values.folder} options={folderOptions} label={'Select folder'} disabled={true} required error={(errors.folder && touched.folder) ? errors.folder : ''} />
                </div>
                <div className="sm:col-span-2">
                  <div className="mt-2.5">
                    <fieldset>
                      <div className="space-y-4">
                        <div className="space-y-4">
                          <CheckboxButton
                            name="is_critical"
                            label="Mark as Critical Video"
                            value={values?.is_critical}
                            checked={values?.is_critical}
                            onChange={e => setFieldValue("is_critical", e.target.checked)}
                            description="The critical video will be auto downloaded to the mobile application. "
                            disabled={loading}
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2 mt-5">
                        <CheckboxButton
                          name="is_notify"
                          label="Notify to users"
                          value={values?.is_notify}
                          checked={values?.is_notify}
                          onChange={e => setFieldValue("is_notify", e.target.checked)}
                          description="This will enable in-app notification to end users. "
                          disabled={loading}
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <FileInputComponent
                    label={'Upload Video'}
                    name={'video_url'}
                    required={true}
                    multiple={false}
                    maxSize={KB}
                    selectedFiles={selectedFiles}
                    acceptedData=".mp4, .mov"
                    handleFileChange={handleFileChange}
                    handleRemoveFile={handleRemoveFile}
                    disabled={false}
                    error={(errors.video_url && touched.video_url) ? errors.video_url : ''}
                  />
                  {thumbnailLoader && <div className=" pt-2 justify-start flex">
                    <p className=" text-gray-400 dark:text-gray-600">Thumbnail is getting generated</p>
                    <FontAwesomeIcon className="w-5 h-5 ml-2 mt-1 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                  </div>}
                </div>
              </div>
              <div className="mt-10 flex justify-start">
                {loading ? <Button type="button"
                  className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  text="Create" />
                  : <Button type="submit"
                    className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    text="Create" />
                }
                <Link to="/manage/videos"
                  className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                {loading && <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
              </div>
            </form>
          </div>
        </div>
      }
    </PageWrapper>
  );
};
export default AddNewVideos;
