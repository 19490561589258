import InputField from "../../../components/form/input/InputField";
import PageWrapper from "../../../layouts/PageWrapper";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useFormik } from "formik";
import { formUpdateSystemConfigSchema } from "./../../../validations/FormValidationSchema";
import { useSystemConfigsResponseForAdminQuery, useSystemConfigRequestFormMutation, useGetCurrentUserPermissionsQuery } from '../../../graphql/generated/graphql.ts'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSpinner, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import Button from "../../../components/button/Button.js";
import { toast } from "react-toastify";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'



const ConfigurationSystemConfig = () => {
  const navigate = useNavigate()
  const { data, error, loading } = useSystemConfigsResponseForAdminQuery();
  const [systemConfigUpdate, { loading: load }] = useSystemConfigRequestFormMutation();
  const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
  const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
  const permissionAPIList = permissionData?.getAllAPIPermissions
  const createSystemConfigs = permissionAPIList?.filter(item => item.accessType === "UpdateSystemConfigs")
  const deleteSystemConfigs = permissionAPIList?.filter(item => item.accessType === "UpdateSystemConfigs")
  const addPermissions = createSystemConfigs?.length && createSystemConfigs[0].assignedPermissions.some(val => addPermission.includes(val));
  const deleteUserPermission = deleteSystemConfigs?.length && deleteSystemConfigs[0].assignedPermissions.some(val => addPermission.includes(val));
  const { systemConfigsResponseForAdmin } = data || [];

  const [initialValues, setInitialValues] = useState({
    primaryGoc: "",
    secondaryGoc: "",
    tertiaryGoc: "",
    emailId: "",
    subject: "",
    serviceDesk: [],
    versionNumber: "",
    buildNumber: "",
    newCountry: ""
  })

  function handleNavigationError() {
    if (error) {
      navigate('/error', { state: { error: JSON.stringify(error) } });
    }
  }

  // Call the function to handle navigation error
  handleNavigationError();

  useEffect(() => {
    const envVariable = process.env.REACT_APP_ENV;
    const prefix = 'SIERA';
    const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
    const pageTitle = `${prefix}${suffix} | Manage System Config`;
    document.title = pageTitle;
  }, [])


  const { values, errors, touched, handleBlur, handleChange, handleSubmit, } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formUpdateSystemConfigSchema,
    onSubmit: value => {
      const updatedServiceDeskArray = value?.serviceDesk?.map(serviceDeskItem => {
        const updatedServiceDesk = { ...serviceDeskItem };
        delete updatedServiceDesk.__typename;
        const updatedValue = updatedServiceDesk.value.map(item => {
          const updatedItem = { ...item };
          delete updatedItem.__typename;
          return updatedItem;
        });
        return { ...updatedServiceDesk, value: updatedValue };
      });

      const variables = {
        gocNumberInputObject: { primary: value.primaryGoc, secondary: value.secondaryGoc, tertiary: value.tertiaryGoc },
        app_version: { ios: `${value.versionNumber}(${value.buildNumber})` },
        app_support_email: { email_id: value.emailId, subject: value.subject },
        service_desk: updatedServiceDeskArray
      };
      systemConfigUpdate({ variables })
        .then(() => {
          toast.success('System config information updated successfully.')
        }).catch(err => {
          toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
            autoClose: false, // Set the timeout to 5000 milliseconds (5 seconds)
          });
        })
    }
  });

  useEffect(() => {
    if (systemConfigsResponseForAdmin?.length > 0) {
      const systemConfigData = systemConfigsResponseForAdmin?.[0] || [];
      setInitialValues({
        primaryGoc: systemConfigData.gocConfigs.primary || "",
        secondaryGoc: systemConfigData?.gocConfigs?.secondary || "",
        tertiaryGoc: systemConfigData?.gocConfigs?.tertiary || "",
        versionNumber: systemConfigData?.app_version?.ios?.split('(')?.[0] || "",
        buildNumber: systemConfigData?.app_version?.ios?.split('(')[1].replace(')', ''),
        emailId: systemConfigData?.app_support_email?.email_id || '',
        subject: systemConfigData?.app_support_email.subject || "",
        serviceDesk: systemConfigData?.service_desk || []
      })
    }
  }, [loading, systemConfigsResponseForAdmin])

  const handleAddCountry = () => {
    setInitialValues(prevState => ({
      ...prevState,
      serviceDesk: [...prevState.serviceDesk, { key: '', value: [] }],
    }));
  };

  const handleDeleteCountry = index => {
    setInitialValues(prevState => ({
      ...prevState,
      serviceDesk: prevState.serviceDesk.filter((_, idx) => idx !== index),
    }));
  };

  const handleAddDevice = index => {
    setInitialValues(prevState => ({
      ...prevState,
      serviceDesk: prevState.serviceDesk.map((country, idx) =>
        idx === index
          ? { ...country, value: [...country.value, { key: '', value: '' }] }
          : country
      ),
    }));
  };

  const handleDeleteDevice = (countryIndex, deviceIndex) => {
    setInitialValues(prevState => ({
      ...prevState,
      serviceDesk: prevState.serviceDesk.map((country, idx) =>
        idx === countryIndex
          ? { ...country, value: country.value.filter((_, dIdx) => dIdx !== deviceIndex) }
          : country
      ),
    }));
  };

  const handleDeviceNameChange = (event, parentIndex, childIndex) => {
    const { value } = event.target;
    setInitialValues(prevState => ({
      ...prevState,
      serviceDesk: prevState.serviceDesk.map((country, idx) =>
        idx === parentIndex
          ? {
            ...country,
            value: country.value.map((device, dIdx) =>
              dIdx === childIndex ? { ...device, key: value } : device
            ),
          }
          : country
      ),
    }));
  };

  const handlePhoneNumberChange = (event, parentIndex, childIndex) => {
    const { value } = event.target;
    setInitialValues(prevState => ({
      ...prevState,
      serviceDesk: prevState.serviceDesk.map((country, idx) =>
        idx === parentIndex
          ? {
            ...country,
            value: country.value.map((device, dIdx) =>
              dIdx === childIndex ? { ...device, value } : device
            ),
          }
          : country
      ),
    }));
  };

  const handleAddCountryChange = (event, parentIndex) => {
    const { value } = event.target;
    setInitialValues(prevState => ({
      ...prevState,
      serviceDesk: prevState.serviceDesk.map((country, idx) =>
        idx === parentIndex ? { ...country, key: value } : country
      ),
    }));
  };

  const deviceDefaultColor = addPermissions ?"bg-indigo-500": "bg-gray-500";
  const deviceHoverColor = addPermissions ? "hover:bg-indigo-600 dark:bg-indigo-600": "hover:bg-gray600 dark:bg-gray600";
  const deleteButtonColor = deleteSystemConfigs ? "bg-red-500  hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600" : "bg-gray-500  hover:bg-gray-600 dark:hover:bg-gray-500 dark:bg-gray-600";

  const buttonContent = () => {
    return (
      <div className="py-6">
        <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
          <div className="col-span-4">
            <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">Application Version
            </h2>
            <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm capitalize">The version needs to be updated after every release.</p>
          </div>
          <form method="POST" onSubmit={handleSubmit} className="col-span-8">
            <div
              className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
              <div className="">
                <InputField type={'text'} name={'versionNumber'} label={'Version Number'} value={values.versionNumber} required onChange={handleChange} onBlur={handleBlur} error={(errors.versionNumber && touched.versionNumber) ? errors.versionNumber : ''} />
              </div>
              <div className="">
                <InputField type={'text'} name={'buildNumber'} label={'Build Number'} value={values.buildNumber} required onChange={handleChange} onBlur={handleBlur} error={(errors.buildNumber && touched.buildNumber) ? errors.buildNumber : ''} />
              </div>
            </div>
            <div className="mt-10 flex justify-start">
              {
                load && !addPermissions ? <Button type="button"
                  className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  text="Update" />
                  : <Button type="submit"
                    className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    text="Update"
                  />
              }
              <Button type="button"
                className="block rounded-md bg-gray-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" text="Cancel"
                onClick={() => { navigate("/manage/videos") }} />
            </div>
          </form>
        </div>
      </div>
    )
  }

  const handleCountryContent = () => {
    return (
      <form method="POST" onSubmit={handleSubmit} className="col-span-8">
        <div className="flex items-end justify-end pb-5">
          <Button
            type="button"
            className={`flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 ${deviceDefaultColor} ${deviceHoverColor} rounded-lg shrink-0 gap-x-2 `}
            onClick={addPermission && handleAddCountry}
            icon={faPlus}
            text="Add Country"
          />
        </div>
        {values.serviceDesk?.map((serviceDeskObj, index) => (
          <div
            key={index} // Make sure to add a unique key for each mapped element
            className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8"
          >
            <div className="items-center justify-center grid grid-flow-col grid-cols-2 ">
              <div className="">
                <InputField
                  type={'text'}
                  name={`serviceDesk[${index}].title`}
                  label={'Service Desk Country'}
                  value={serviceDeskObj.key}
                  required
                  onChange={e => handleAddCountryChange(e, index)}
                  onBlur={handleBlur}
                  error={(errors.serviceDesk && touched.serviceDesk && errors.serviceDesk[index]?.title) ? errors.serviceDesk[index].title : ''}
                />
              </div>
            </div>
            <div className="flex justify-center items-center space-x-4 pt-6">
              <Button
                type="button"
                className={`flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 ${deviceDefaultColor} rounded-lg shrink-0 gap-x-2 ${deviceHoverColor}`}
                icon={faPlus}
                onClick={() => { addPermission && handleAddDevice(index) }}
                text="Add Devices"
              />
              <Button
                type="button"
                className={`flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 ${deleteButtonColor} rounded-lg shrink-0 gap-x-2`}
                onClick={() => { deleteUserPermission && handleDeleteCountry(index) }}
                icon={faTrashCan}
                text="Delete Country"
              />
            </div>
            <div className="sm:col-span-2">

              <div className="grid grid-cols-1 gap-x-8 gap-y-2 sm:grid-cols-3 px-8">
                {serviceDeskObj?.value?.length > 0 && <div className="text-sm font-semibold tracking-tight text-gray-900 dark:text-gray-200"></div>}
                {serviceDeskObj?.value?.length > 0 && <div className="text-sm font-semibold tracking-tight text-gray-900 dark:text-gray-200"></div>}
                <div></div>
                {serviceDeskObj?.value?.map((item, subIndex) => (
                  <React.Fragment key={subIndex}>
                    <div className="">
                      <InputField
                        type={'text'}
                        name={`serviceDesk[${index}].value[${subIndex}].deviceName`}
                        label={'Service Desk'}
                        value={item.key}
                        required
                        onChange={e => handleDeviceNameChange(e, index, subIndex)}
                        onBlur={handleBlur}
                        error={(errors?.serviceDesk && touched?.serviceDesk && errors?.serviceDesk?.[index]?.values?.[subIndex]?.deviceName) ? errors.serviceDesk?.[index]?.values[subIndex].deviceName : ''}
                      />
                    </div>
                    <div className="">
                      <InputField
                        type={'text'}
                        name={`serviceDesk[${index}].value[${subIndex}].phoneNumber`}
                        label={'Service Number'}
                        value={item.value}
                        required
                        onChange={e => handlePhoneNumberChange(e, index, subIndex)}
                        onBlur={handleBlur}
                        error={(errors.serviceDesk && touched.serviceDesk && errors.serviceDesk?.[index]?.values?.[subIndex]?.phoneNumber) ? errors.serviceDesk?.[index]?.values?.[subIndex]?.phoneNumber : ''}
                      />
                    </div>
                    <div className="mt-8">
                      <Button
                        type="button"
                        className={`flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 ${deleteButtonColor} rounded-lg shrink-0 gap-x-2`}
                        onClick={() => { deleteUserPermission && handleDeleteDevice(index, subIndex) }}
                        icon={faTrashCan}
                        text="Delete"
                      /></div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        ))}
      </form>
    )
  }

  const emailIdContent = () => {
    return (
      <form method="POST" onSubmit={handleSubmit} className="col-span-8">
        <div
          className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
          <div className="">
            <InputField type={'text'} name={'emailId'} label={'Support Email'} value={values.emailId} required onChange={handleChange} onBlur={handleBlur} error={(errors.emailId && touched.emailId) ? errors.emailId : ''} />
          </div>
          <div className="">
            <InputField type={'text'} name={'subject'} label={'Support Email Subject'} value={values.subject} required onChange={handleChange} onBlur={handleBlur} error={(errors.subject && touched.subject) ? errors.subject : ''} />
          </div>
        </div>
      </form>
    )
  }

  const gocConfigsContent = () => {
    return(
      <form method="POST" onSubmit={handleSubmit} className="col-span-8">
        <div
          className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-3 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
          <div className="">
            <InputField type={'text'} name={'tertiaryGoc'} label={"Asia's GOC Number "} value={values.tertiaryGoc} required onChange={handleChange} onBlur={handleBlur} error={(errors.tertiaryGoc && touched.tertiaryGoc) ? errors.tertiaryGoc : ''} />
          </div>
          <div className="">
            <InputField type={'text'} name={'primaryGoc'} label={"America's GOC Number"} value={values.primaryGoc} required onChange={handleChange} onBlur={handleBlur} error={(errors.primaryGoc && touched.primaryGoc) ? errors.primaryGoc : ''} />

          </div>
          <div className="">
            <InputField type={'text'} name={'secondaryGoc'} label={"Europe's GOC Number"} value={values.secondaryGoc} required onChange={handleChange} onBlur={handleBlur} error={(errors.secondaryGoc && touched.secondaryGoc) ? errors.secondaryGoc : ''} />
          </div>
        </div>
      </form>
    )
  }


  return (
    <PageWrapper heading={'Configure System Configurations'}>
      {loading || load ? <div className="flex justify-center items-center h-screen">
        <td colSpan={5} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
          <div>
            <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
            <span>Loading Data</span>
          </div>
        </td>
      </div> : <div>
        <div className="py-6">
          <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
            <div className="col-span-4">
              <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">GOC Numbers
              </h2>
              <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm capitalize">The GOC numbers will be available for call out feature.</p>
            </div>
              {gocConfigsContent()}
          </div>
        </div>
        <div className="py-6">
          <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
            <div className="col-span-4">
              <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">Application Support
              </h2>
              <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm capitalize">The draft support email for end user reaching out.</p>
            </div>
              {emailIdContent()}
          </div>
        </div>
        <div className="py-6">
          <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
            <div className="col-span-4">
              <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">Service Desk Phone Numbers
              </h2>
              <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm capitalize">Add multiple country service desk phone numbers.</p>
            </div>
            {handleCountryContent()}
          </div>
        </div>
        {buttonContent()}
      </div>}
    </PageWrapper>
  );
};

export default ConfigurationSystemConfig;