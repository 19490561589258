import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import {
    UsersIcon,
    ChatBubbleLeftRightIcon,
    ClipboardDocumentListIcon,
    SwatchIcon,
    UserGroupIcon,
    FolderIcon,
    VideoCameraIcon,
    BellIcon,
    Squares2X2Icon,
    ClipboardDocumentCheckIcon,
    CursorArrowRippleIcon,
    RectangleStackIcon,
    UserMinusIcon,
    DevicePhoneMobileIcon,
    Cog8ToothIcon,
    ClipboardDocumentIcon
} from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';

const SideBar = ({ onSidebarItemClick }) => {
    const [sidebarMenuList, setSidebarMenuList] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const sidebarRef = useRef(null);
    useEffect(() => {
        const sideberMenuListData = [
            {
                title: 'Administrator',
                open: true,
                menu_list: [
                    { title: 'Home', span_color: 'red', redirect_url: '/home', Icon: Squares2X2Icon },
                ]
            },
            {
                title: 'Management',
                open: true,
                menu_list: [
                    { title: 'Users', span_color: 'red', redirect_url: '/manage/users', Icon: UsersIcon },
                    { title: 'Folders', span_color: 'red', redirect_url: '/manage/folders', Icon: FolderIcon },
                    { title: 'Documents', span_color: 'red', redirect_url: '/manage/documents', Icon: ClipboardDocumentListIcon },
                    { title: 'Videos', span_color: 'red', redirect_url: '/manage/videos', Icon: VideoCameraIcon },
                    { title: 'Notifications', span_color: 'red', redirect_url: '/manage/notifications', Icon: BellIcon },
                    { title: 'Feedbacks', span_color: 'red', redirect_url: '/manage/feedbacks', Icon: ChatBubbleLeftRightIcon },
                    { title: 'Static Pages', span_color: 'red', redirect_url: '/manage/static-pages', Icon: ClipboardDocumentIcon },
                ]
            },
            {
                title: 'Access Control',
                open: true,
                menu_list: [
                    { title: 'Roles', span_color: 'red', redirect_url: '/access-control/roles', Icon: SwatchIcon },
                    { title: 'Groups', span_color: 'red', redirect_url: '/access-control/groups', Icon: UserGroupIcon },
                    { title: 'Permissions', span_color: 'red', redirect_url: '/access-control/permissions', Icon: ClipboardDocumentCheckIcon },
                ]
            },
            {
                title: 'Audit Logs',
                open: true,
                menu_list: [
                    { title: 'Activity Logs', span_color: 'red', redirect_url: '/audit/activity-logs', Icon: CursorArrowRippleIcon },
                    { title: 'Cron Jobs', span_color: 'red', redirect_url: '/audit/cron-jobs', Icon: RectangleStackIcon },
                    { title: 'Archived Users', span_color: 'red', redirect_url: '/audit/archived-users', Icon: UserMinusIcon },
                ]
            },
            {
                title: 'Configurations',
                open: true,
                menu_list: [
                    { title: 'Mobile Homepage', span_color: 'red', redirect_url: '/configuration/mobile-homepage', Icon: DevicePhoneMobileIcon },
                    { title: 'System Config', span_color: 'red', redirect_url: '/configuration/system-config', Icon: Cog8ToothIcon },
                    { title: 'Application Settings', span_color: 'red', redirect_url: '/configuration/application-config', Icon: Cog8ToothIcon },
                ]
            },
        ];
        setSidebarMenuList(sideberMenuListData);
    }, []);
    
    useEffect(() => {
        // Function to handle clicks outside the sidebar
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setIsSidebarOpen(false);
            }
        };

        // Add event listener when the component is mounted
        document.addEventListener("mousedown", handleClickOutside);

        // Remove event listener when the component is unmounted
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <aside ref={sidebarRef}             className={`w-60 h-screen px-5 py-8 pt-20 overflow-y-auto bg-white top-0 shadow dark:bg-neutral-900 dark:border-neutral-900 2xl:block z-30 fixed left-0 p-2 drop-shadow-xl ${
                isSidebarOpen ? "block" : "hidden"
            }`}
>
            <div className="flex flex-col flex-1 mt-2">
                <nav className="-mx-3 space-y-6">
                    {sidebarMenuList.map(MenuList => (
                        <div key={MenuList?.title}>
                            <div className="px-3 mb-0.5 text-sm text-left text-gray-500 uppercase dark:text-gray-400 font-bold tracking-wide">
                                {MenuList.title}
                            </div>
                            {MenuList.menu_list.map(MenuItem => (
                                <NavLink
                                    key={MenuItem?.title}
                                    to={MenuItem.redirect_url}
                                    onClick={onSidebarItemClick} // Close sidebar on click
                                    className={({ isActive }) =>
                                        isActive
                                            ? "flex items-center mb-0.5 px-3 py-2 text-gray-800 bg-[#FFCD00] transition-colors duration-300 transform rounded-lg hover:bg-[#FFCD00] dark:hover:bg-[#FFCD00] dark:hover:text-gray-800 hover:text-gray-800"
                                            : "flex items-center mb-0.5 px-3 py-2 text-gray-800 transition-colors duration-300 transform rounded-lg dark:text-gray-200 hover:bg-[#FFCD00] dark:hover:bg-[#FFCD00] dark:hover:text-gray-800 hover:text-gray-800"
                                    }
                                >
                                    <MenuItem.Icon className="w-5 h-5" />
                                    <span className="mx-2 text-sm font-medium">{MenuItem.title}</span>
                                </NavLink>
                            ))}
                        </div>
                    ))}
                </nav>
            </div>
        </aside>
    );
};

SideBar.propTypes = {
    onSidebarItemClick: PropTypes.func.isRequired,
}

export default SideBar;
