import RadioButton from "./RadioButton";
import React from 'react';
import PropTypes from 'prop-types';

const RadioInputGroup = ({ label, description, options, selectedOption, error, required, onChange }) => {
    return (
        <>
            {label &&
                <label className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">{label}
                    {required && <span className="text-red-600 dark:text-red-400 ml-1">*</span>}
                </label>
            }
            <div className="mt-2.5">
                <fieldset>
                    {description && <p className="text-sm mb-2 leading-6 text-gray-600 dark:text-gray-400">{description}</p>}
                    <div className="my-3 space-y-3">
                        {options.map(option => (
                            <RadioButton
                                key={option.value}
                                label={option.label}
                                value={option.value}
                                checked={selectedOption === option.value}
                                onChange={onChange}
                            />
                        ))}
                    </div>
                </fieldset>
                {error && <span className="text-sm text-red-600 dark:text-red-400  font-semibold">{error}</span>}
            </div>
        </>
    );
};

RadioInputGroup.propTypes = {
    label: PropTypes.any,
    description: PropTypes.any,
    options: PropTypes.any,
    onChange: PropTypes.any,
    selectedOption: PropTypes.any,
    error: PropTypes.any,
    required: PropTypes.any,
}

export default RadioInputGroup;