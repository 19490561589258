import React, { useState } from "react";
import InputField from "../../components/form/input/InputField";
import SelectSingleInput from "../../components/form/input/SelectSingleInput";
import SelectMultipleInput from "../../components/form/input/SelectMultipleInput";
import TextAreaInput from "../../components/form/input/TextAreaInput";
import SwitchInput from "../../components/form/input/SwitchInput";
import RadioInputGroup from "../../components/form/input/RadioInputGroup";
import CheckboxInputGroup from "../../components/form/input/CheckboxInputGroup";
import DateTimeInput from "../../components/form/input/DateTimeInput";
const roleOptions = [
  { value: "administrator", label: "Administrator" },
  { value: "support", label: "Support" },
  { value: "user", label: "User" }
];

const groupOptions = [
  { value: "admin", label: "Admin" },
  { value: "default", label: "Default" },
  { value: "custom", label: "Custom" }
];

const singleOptions = [
  { value: "dog", label: "Dog" },
  { value: "cat", label: "Cat" },
  { value: "mouse", label: "Mouse" }
];


const Radioptions = [
  { label: 'Option 1', value: 'option1' },
  { label: 'Option 2', value: 'option2' },
  { label: 'Option 3', value: 'option3' },
  { label: 'Option 4', value: 'option4' },
];

const Checkboxoptions = [
  { label: 'Option 1', value: 'option1' },
  { label: 'Option 2', value: 'option2' },
  { label: 'Option 3', value: 'option3' },
];

const Help = () => {
  const [roles, setRoles] = useState([{ value: "user", label: "User" }]);
  const [groups, setGroups] = useState([{ value: "default", label: "Default" }]);
  const [animal, setAnimal] = useState({ value: "dog", label: "Dog" });
  const [enabled, setEnabled] = useState(false)
  const [testField, setTestField] = useState('')
  const [testMessageField, setTestMessageField] = useState('')
  const [selectedRadioOption, setSelectedRadioOption] = useState('');
  const [selectedCheckboxOptions, setSelectedCheckboxOptions] = useState([]);

  const selectRoles = value => {
    setRoles(value);
  };

  const selectGroups = value => {
    setGroups(value);
  };

  const selectAnimal = value => {
    setAnimal(value);
  };

  const handleTestFieldChange = e => {
    setTestField(e.target.value);
  };

  const handleTestMessageFieldChange = e => {
    setTestMessageField(e.target.value);
  };

  const handleSwitchChange = () => {
    setEnabled(!enabled);
  };

  const handleRadioOptionChange = e => {
    setSelectedRadioOption(e.target.value);
  };
  const handleCheckboxOptionChange = values => {
    setSelectedCheckboxOptions(values);
  };

  return (
    <div className="container mt-16 justify-center mx-auto px-2 sm:px-20 md:px-32 lg:px-40 xl:40">
      <div>
        <div className="py-6">
          <h2 className="text-2xl font-bold dark:text-gray-100 mb-4">Create new user</h2>
          <hr />
        </div>
      </div>
      <div>
        <div className="py-6">
          <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
            <div className="col-span-4">
              <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">General Information
              </h2>
              <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Aute magna irure deserunt veniam aliqua
                magna enim voluptate.</p>
            </div>
            <form action="#" method="POST" className="col-span-8">
              <div
                className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                <div>
                  <InputField type={'text'} name={'first_name'} label={'First name'} required error={'First name cannot be blank'} />
                </div>
                <div>
                  <InputField type={'text'} name={'last_name'} label={'Last name'} required error={'Last name cannot be blank'} />
                </div>
                <div className="sm:col-span-2">
                  <InputField type={'email'} name={'email'} label={'Email'} value={testField} placeholder={"example@shell.com"} required onChange={handleTestFieldChange} error={'Email cannot be blank'} />
                </div>
                <div className="sm:col-span-2">
                  <CheckboxInputGroup
                  label={'Select checkbox'}
                  error={'Select at least one checkbox'}
                  required={true}
                  options={Checkboxoptions}
                  selectedOption={selectedCheckboxOptions}
                  onChange={handleCheckboxOptionChange}
                  />
                </div>
                <div className="sm:col-span-2">
                  <RadioInputGroup
                  label={'Select Radio Option'}
                  required={true}
                  description={'The is the same description of multiple radio options.'}
                  error={'The radio input cannot be empty'}
                  options={Radioptions}
                  selectedOption={selectedRadioOption}
                  onChange={handleRadioOptionChange}
                  />
                </div>
                <div className="sm:col-span-2">
                  <SelectSingleInput value={animal} options={singleOptions} onChange={selectAnimal} label={'Select animal'} required error={'Animal field cannot be blank'} />
                </div>
                <div className="sm:col-span-2">
                  <SelectMultipleInput value={roles} options={roleOptions} onChange={selectRoles} label={'Select roles'} required error={'Select at least one role'} />
                </div>
                <div className="sm:col-span-2">
                  <SelectMultipleInput value={groups} options={groupOptions} onChange={selectGroups} label={'Select groups'} required error={'Select at least one group'} />
                </div>
                <div className="sm:col-span-2">
                  <TextAreaInput name={testMessageField} label={'Message'} value={testMessageField} rowSpan={4} required onChange={handleTestMessageFieldChange} error={'text area field cannot be blank'} />
                </div>
                <div className="sm:col-span-2">
                  <DateTimeInput type={'datetime-local'} label={'Schedule'} required error={''} />
                </div>
                <div className="sm:col-span-2">
                  <DateTimeInput type={'date'} label={'Date'} required error={''} />
                </div>
                <div className="sm:col-span-2">
                  <SwitchInput checked={enabled} label={'By selecting, this you agree to our terms and conditions'} onChange={handleSwitchChange} error={'Please agree to terms and conditions'} />
                </div>
              </div>
              <div className="mt-10">
                <button type="submit"
                  className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    {"Let's talk"}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
