import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    useGetNotificationDetailsByIdMutation, useDeleteNotificationsByIdMutation,
    useGetAllNotificationSchedulePagesMutation, useGetCurrentUserPermissionsQuery, useDeleteNotificationSchedulesByIdMutation, 
    useGetUserNotificationResponsePagesMutation, useDownloadUserNotificationResponseMutation
} from "../../../graphql/generated/graphql.ts";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowDownShortWide,
    faArrowUpWideShort,
    faEdit,
    faTrashCan,
    faSpinner,
    faClock,
    faDownload,
} from '@fortawesome/free-solid-svg-icons'
import PageWrapper from "../../../layouts/PageWrapper.js";
import TableWrapper from "../../../components/table/TableWrapper.js";
import TableContainer from "../../../components/table/TableContainer.js";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound.js";
import TableLoadingData from "../../../components/table/TableLoadingData.js";
import { TrashIcon } from '@heroicons/react/24/outline';
import TableButtonSection from "../../../components/table/TableButtonSection.js";
import DialogModal from "../../../components/modal/DialogModal.js";
import '../../../styles/Draft.css';
import { toast } from "react-toastify";
import Button from "../../../components/button/Button.js";
import { formatTimestamp } from "../../../utils/commonFormatFunctions.js";
import Pagination from "../../../components/table/Pagination.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'

const NotificationDetails = () => {
    const navigate = useNavigate();
    const maxPageNumber = 5;
    const defaultPageNumber = 1;
    const { notificationId } = useParams()
    const [sortedColumnResponse, setSortedColumnResponse] = useState('userName');
    const [sortOrderResponse, setSortOrderResponse] = useState('');
    const [searchQueryResponse, setSearchQueryResponse] = useState('');
    const [ sortedColumn, setSortedColumn] = useState("")
    const [sortOrder, setSortOrder] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItems, setSelectedItems] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [deletedItem, setDeletedItem] = useState('');
    const [getUserDetailsByIdMutation, { data: notificationData, loading: loader, error }] = useGetNotificationDetailsByIdMutation({
        variables: {
            uniqueId: notificationId
        },
    });
    const [scheduleNotificationInfoMutation, { data: paginationData, loading, error: errorData }] = useGetAllNotificationSchedulePagesMutation();
    const [scheduleNotificationResponseMutation, { data: paginationResponseData, loading: responseLoader}] = useGetUserNotificationResponsePagesMutation();

    const [deleteScheduleNotification, { loading: deleteScheduleNotificationLoader }] = useDeleteNotificationSchedulesByIdMutation();
    const [respondNotificationMutation, {data: respondData }] = useDownloadUserNotificationResponseMutation();
    const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
    const { notificationScheduleList, pageInfo } = paginationData?.getAllNotificationSchedulePages || {};
    const { getUserNotificationResponsePages} = paginationResponseData || {};
    const [deleteMutation, { loading: load }] = useDeleteNotificationsByIdMutation();
    const { getNotificationDetailsById } = notificationData || {};
    const [recordsPerPageData, setRecordsPerPageData] = useState(pageInfo?.per_page);
    const [recordsPerPageResponse, setRecordsPerPageResponse] = useState(getUserNotificationResponsePages?.pageInfo?.per_page);
    const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions;
    const permissionAPIList = permissionData?.getAllAPIPermissions
    const deleteNotification = permissionAPIList?.filter(item => item.accessType === "DeleteNotification")
    const updateNotification = permissionAPIList?.filter(item => item.accessType === "UpdateNotification")
    const pushNotification = permissionAPIList?.filter(item => item.accessType === "ScheduleNotification")
    const editUserPermission = updateNotification?.length && updateNotification[0].assignedPermissions.some(val => addPermission.includes(val));
    const deleteUserPermission = deleteNotification?.length && deleteNotification[0].assignedPermissions.some(val => addPermission.includes(val));
    const sendNotificationPermission = pushNotification?.length && pushNotification[0].assignedPermissions.some(val => addPermission.includes(val));
    const [downloadLoader, setDownloadLoader] = useState(false);

    useEffect(() => {
        getScheduleNotificationData(); getScheduleNotificationResponseData()
    }, [])

    const getScheduleNotificationData = (page = defaultPageNumber, row = pageInfo?.per_page, search_term = searchQuery) => {
        setDeletedItem('')
        setSelectedItems('');
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term,
            search_column: "",
            order_by: sortOrder,
            order_by_column: sortedColumn,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        }

        scheduleNotificationInfoMutation({ variables: { uniqueId: notificationId, PageInfoInput } })
            .catch(e => {
                toast.error(e.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    };

    const getScheduleNotificationResponseData = (page = defaultPageNumber, row = getUserNotificationResponsePages?.pageInfo?.per_page, searchTerm = searchQueryResponse) => {
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term: searchTerm,
            search_column: "",
            order_by: "DESC",
            order_by_column: "",
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        }
        scheduleNotificationResponseMutation({ variables: { uniqueId: notificationId, PageInfoInput } })
            .catch(e => {
                toast.error(e.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    }

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Notifications | Deatils | ${getNotificationDetailsById?.title}`;
        document.title = pageTitle;
    }, [notificationData])

    // Handle pagination link click
    const handlePageChange = pageNumber => {
        getScheduleNotificationData(pageNumber, pageInfo?.per_page);
    };


    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getScheduleNotificationData(defaultPageNumber, rowValue);
    };

    const handlePageChangeResponse = pageNumber => {
        getScheduleNotificationResponseData(pageNumber, getUserNotificationResponsePages?.pageInfo?.per_page);
    };


    const handleRowChangesResponse = rowValue => {
        setRecordsPerPageResponse(rowValue)
        getScheduleNotificationResponseData(defaultPageNumber, rowValue);
    };

    // Handle search input change
    const handleSearchChange = e => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm);
    };

    const handleSearchChangeResponse = e => {
        const searchTerm = e.target.value;
        setSearchQueryResponse(searchTerm);
    };

    useEffect(() => {
        const delayTime = 1000;
        if (!searchQuery) {
            getScheduleNotificationData(defaultPageNumber, pageInfo?.per_page, "");
            return () => { };// No need to proceed if searchQuery is empty
        }
        const delayDebounceFn = setTimeout(() => {
            // Make API request with the current value
            getScheduleNotificationData(defaultPageNumber, pageInfo?.per_page, searchQuery);
        }, delayTime);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [searchQuery]);

    useEffect(() => {
        const delayTime = 1000;
        if (!searchQueryResponse) {
            getScheduleNotificationResponseData(defaultPageNumber, getUserNotificationResponsePages?.pageInfo?.per_page, "");
            return () => { };// No need to proceed if searchQuery is empty
        }
        const delayDebounceFn = setTimeout(() => {
            // Make API request with the current value
            getScheduleNotificationResponseData(defaultPageNumber, getUserNotificationResponsePages?.pageInfo?.per_page, searchQueryResponse);
        }, delayTime);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [searchQueryResponse]);

    const handleClearSearch = () => {
        setSearchQuery("");
        getScheduleNotificationData(defaultPageNumber, pageInfo?.per_page, "");
    };
    const handleClearSearchResponse = () => {
        setSearchQueryResponse("");
        getScheduleNotificationResponseData(defaultPageNumber, getUserNotificationResponsePages?.pageInfo?.per_page, "");
    };

    useEffect(() => {
        getScheduleNotificationData(defaultPageNumber, pageInfo?.per_page);
    }, [sortOrder, sortedColumn]);

    useEffect(() => {
        getScheduleNotificationResponseData(defaultPageNumber, getUserNotificationResponsePages?.pageInfo?.per_page);
    }, [sortOrderResponse, sortedColumnResponse]);

    useEffect(() => {
        getUserDetailsByIdMutation()
    }, [notificationId])

    const closeModal = () => {
        setIsOpen(false)
        setDeletedItem('')
        setSelectedItems('')
    };

    // Handle column header click for sorting
    const handleSort = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrder);
        setSortedColumn(column);
    };


    // Handle column header click for sorting
    const handleSortResponse = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrderResponse(newSortOrder);
        setSortedColumnResponse(column);
    };

    const onClickDelete = selectedItem => {
        if (selectedItem) {
            deleteScheduleNotification({ variables: { uniqueId: selectedItem } })
                .then(() => {
                    toast.success("Schedule Notification deleted successfully.")
                    closeModal()
                    navigate('/manage/notifications')
                    setSelectedItems('')
                }).catch(er => {
                    toast.error(er.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                        autoClose: false,
                    });
                })
        } else {
            deleteMutation({ variables: { uniqueId: notificationId } })
                .then(() => {
                    toast.success("Notification deleted successfully.")
                    closeModal()
                    navigate('/manage/notifications')
                    setSelectedItems('')
                }).catch(err => {
                    toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                        autoClose: false,
                    });
                })
        }
    }

    const handleDelete = () => {
        setIsOpen(true)
    }
    const handDocDelete = id => {
        setDeletedItem(id)
        setIsOpen(true)
    }

    if (error) { //NOSONAR
        const errorValue = error
        navigate('/error', { state: { errorData: JSON.stringify(errorValue) } });
    }
    if (errorData) { //NOSONAR
        navigate('/error', { state: { errorData: JSON.stringify(errorData) } });
    }

    const handleDownloadRespondNotification = ( ) =>{
        setDownloadLoader(true)
        respondNotificationMutation(({ variables: {uniqueId:notificationId}}))
            .then(() => {
                // setDownloadLoader(false)
            }).catch(error => {
                toast.error(error.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            })
    }

    useEffect(() => {
        if (respondData?.downloadUserNotificationResponse?.length > 0) {
            let stringToDownload = "User Name, Response Option, Created On, Notify At \n";
            respondData?.downloadUserNotificationResponse?.forEach(notification => {
                stringToDownload += `${notification.respondedBy},"${notification.userResponse?.[0]?.value}",${formatTimestamp(notification.createdAt)},${formatTimestamp(notification?.notifyAt)} \n`;
            });
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:attachment/text,' + encodeURI(stringToDownload);
            hiddenElement.target = '_blank';
            const date = new Date();
            hiddenElement.download = `notificationResponseList${date.toString()}.csv`;
            hiddenElement.click();
            setDownloadLoader(!downloadLoader)
        }
    }, [respondData])

    return (
        <PageWrapper heading={'Notification Details'}>
            <div className="">
                {notificationData ? //NOSONAR
                    <div>
                        <div className="pb-6">
                            <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                                <div className="col-span-4">
                                    <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">General Information</h3>
                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Notification title and description</p>
                                </div>
                                <div className="col-span-8">
                                    <div className="flex gap-2 mb-3 justify-end">
                                        <div>
                                            {!editUserPermission ? <Button text="Edit"
                                                icon={faEdit}
                                                type="button"
                                                className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2"
                                            /> :
                                                <Link to={`/manage/notifications/edit/${notificationId}`} state={{ notificationData: notificationData?.getNotificationDetailsById }} className={loader ? "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2" : "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-indigo-500 rounded-lg shrink-0 gap-x-2 hover:bg-indigo-600 dark:bg-indigo-600"}>
                                                    <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faEdit} />
                                                    <span>Edit</span>
                                                </Link>}
                                        </div>
                                        <div>{
                                            !deleteUserPermission ?
                                                <Button
                                                    type="button"
                                                    className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2"
                                                    icon={faTrashCan} text={"Delete"} />
                                                :
                                                <Button
                                                    type="button"
                                                    className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-red-500 rounded-lg shrink-0 gap-x-2 hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600"
                                                    onClick={handleDelete}
                                                    icon={faTrashCan} text={"Delete"} />
                                        }

                                        </div>
                                    </div>
                                    <dl className="md:px-8 divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                                        <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                            <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Title</dt>
                                            <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{notificationData?.getNotificationDetailsById?.title}</dd>
                                        </div>
                                        <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                            <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Description</dt>
                                            <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{notificationData?.getNotificationDetailsById?.description}</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="py-6">
                            <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                                <div className="col-span-4">
                                    <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">Message Body</h3>
                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Customized notification message content</p>

                                </div>

                                <div className="col-span-8">
                                    <dl className="md:px-8 divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                                        <div className="p-4 sm:grid  sm:gap-4 sm:px-0">
                                            <div className="prose w-full" dangerouslySetInnerHTML={{ __html: notificationData?.getNotificationDetailsById?.body }}>
                                            </div>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <hr />
                        {getNotificationDetailsById?.dynamicResponse?.length > 0 && <div className="py-6">
                            <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                                <div className="col-span-4">
                                    <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">Response Options</h3>
                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Customized Response Options content for notification</p>
                                </div>
                                <div className="col-span-8">
                                    <dl className="md:px-8 divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                                        {getNotificationDetailsById?.dynamicResponse?.map(item => (
                                            <div key={item?.key} className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">{item.key}</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{item.value}</dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>
                            </div>
                        </div>
                        }
                        <hr />
                        <div className="py-6">
                            <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                                <div className="col-span-4">
                                    <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">Activity Timestamp</h3>
                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Created and Updated Timestamp</p>
                                </div>
                                <div className="col-span-8">
                                    <dl className="md:px-8 divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                                        <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                            <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Created By</dt>
                                            <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{notificationData?.getNotificationDetailsById?.created_by}</span><span className="text-xs font-semibold">{formatTimestamp(notificationData?.getNotificationDetailsById?.created_at)}</span></dd>
                                        </div>
                                        <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                            <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Updated By</dt>
                                            <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{notificationData?.getNotificationDetailsById?.updated_by}</span><span className="text-xs font-semibold">{formatTimestamp(notificationData?.getNotificationDetailsById?.updated_at)}</span></dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="flex flex-col xl:flex-row gap-4">
                            <PageWrapper className={" mt-16 mb-6 w-full"}>
                                <TableWrapper>
                                    <div className="flex-1">
                                        <div>
                                            <h2 className="text-2xl font-bold dark:text-gray-100 mb-4">Notification Schedules List</h2>
                                        </div>
                                        <TableButtonSection searchQuery={searchQuery} onSearchChange={handleSearchChange} onClearSearch={handleClearSearch}>
                                            {!sendNotificationPermission ? <Button text="Schedule"
                                                icon={faClock}
                                                type="button"
                                                className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2"
                                            /> : <Link to={`/manage/notifications/${notificationId}/schedule`} className={loader ? "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2" : "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-indigo-500 rounded-lg shrink-0 gap-x-2 hover:bg-indigo-600 dark:bg-indigo-600"}>
                                                <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faClock} />
                                                <span>Schedule</span>
                                            </Link>}
                                        </TableButtonSection>
                                        <TableContainer>
                                            <thead className="bg-gray-50 dark:bg-neutral-800">
                                                <tr>
                                                    <th scope="col" onClick={() => handleSort('no')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                                        <div className="flex items-center gap-x-3 cursor-pointer">
                                                            <span>No</span>
                                                            {sortedColumn === "no" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                                        </div>
                                                    </th>
                                                    <th scope="col" onClick={() => handleSort('groupIds')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                                        <div className="flex items-center gap-x-3 cursor-pointer">
                                                            <span>Groups</span>
                                                            {sortedColumn === "groupIds" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                                        </div>
                                                    </th>
                                                    <th scope="col" onClick={() => handleSort('isPushNotification')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                                        <div className="flex items-center gap-x-3 cursor-pointer">
                                                            <span>Push Notification</span>
                                                            {sortedColumn === "isPushNotification" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                                        </div>
                                                    </th>
                                                    <th scope="col" onClick={() => handleSort('notificationStatus')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                                        <div className="flex items-center gap-x-3 cursor-pointer">
                                                            <span>Status</span>
                                                            {sortedColumn === "notificationStatus" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                                        </div>
                                                    </th>
                                                    <th scope="col" onClick={() => handleSort('userIds')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                                        <div className="flex items-center gap-x-3 cursor-pointer">
                                                            <span>User name</span>
                                                            {sortedColumn === "userIds" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                                        </div>
                                                    </th>
                                                    <th scope="col" onClick={() => handleSort('created_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                                        <div className="flex items-center gap-x-3 cursor-pointer">
                                                            <span>Created On</span>
                                                            {sortedColumn === "created_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                                        </div>
                                                    </th>
                                                    <th scope="col" onClick={() => handleSort('updated_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                                        <div className="flex items-center gap-x-3 cursor-pointer">
                                                            <span>Updated On</span>
                                                            {sortedColumn === "updated_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                                        </div>
                                                    </th>
                                                    <th scope="col" onClick={() => handleSort('notify_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                                        <div className="flex items-center gap-x-3 cursor-pointer">
                                                            <span>Notify On</span>
                                                            {sortedColumn === "notify_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                                        <div className="flex items-center gap-x-3 cursor-pointer">
                                                            <span>Actions</span>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                                                {notificationScheduleList?.map((documentData, index) => (
                                                    <tr key={documentData?.id}>
                                                        <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                                            <div className="w-45">
                                                                <h2 className="font-semibold text-gray-700 dark:text-gray-200  ">{index + 1}</h2>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                            <div className="w-45">
                                                                <h2 className="font-semibold text-gray-700 dark:text-gray-200  ">{documentData?.groupIds?.length}</h2>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                            <div>
                                                                <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{documentData?.isPushNotification === false ? "NO" : "Yes"}</h2>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                            <div>
                                                                <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{documentData?.notificationStatus}</h2>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                            <div>
                                                                <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{documentData?.userIds?.length}</h2>
                                                            </div>
                                                        </td>

                                                        <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                            <div>
                                                                <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(documentData?.created_at)}</h4>
                                                            </div>
                                                        </td>

                                                        <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                            <div>
                                                                <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(documentData?.updated_at)}</h4>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                            <div>
                                                                <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(documentData?.notifyAt)}</h4>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                            <div className="flex item-center justify-center text-gray-700 dark:text-gray-200">
                                                                {deleteUserPermission ? <div className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                                                                    <TrashIcon className="stroke-2" onClick={() => { handDocDelete(documentData?.uniqueId) }} />
                                                                </div> : <div className="w-4 mr-2 transform text-gray-500">
                                                                    <TrashIcon className="stroke-2" />
                                                                </div>}
                                                            </div>
                                                        </td>
                                                    </tr>

                                                ))}
                                                {!notificationScheduleList?.length && !loading && <TableNoRecordFound colSpan={9}></TableNoRecordFound>}
                                                {loading && <TableLoadingData colSpan={9}></TableLoadingData>}
                                            </tbody>
                                        </TableContainer>
                                    </div>
                                </TableWrapper>
                                <Pagination totalPages={loading ? 0 : pageInfo?.total_pages} totalRecords={loading ? 0 : pageInfo?.total_records}
                                    maxPageNumbers={maxPageNumber}
                                    recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0}
                                    currentPage={loading ? 0 : pageInfo?.current_page} onPageChange={handlePageChange} onRowChanges={handleRowChanges} ></Pagination>
                            </PageWrapper>
                            <DialogModal
                                isOpen={isOpen}
                                onClose={closeModal}
                                title="Confirm Deletion"
                                closeOnClickOutside={false} // Set this prop as needed
                                pTagData={`Do you confirm to delete ${selectedItems?.length || deletedItem?.length || 1} notification?`}
                                btnOne="Confirm Delete"
                                btnTwo="Cancel"
                                loader={load || deleteScheduleNotificationLoader}
                                onClickDeleteModal={() => { onClickDelete(selectedItems || deletedItem || null) }}>
                            </DialogModal>
                        </div>
                        <hr />
                        <div className="flex flex-col xl:flex-row gap-4">
                            <PageWrapper className={" mt-16 mb-6 w-full"}>
                                <TableWrapper>
                                    <div>
                                        <h2 className="text-2xl font-bold dark:text-gray-100 mb-4">Notification Response List</h2>
                                    </div>
                                    <TableButtonSection searchQuery={searchQueryResponse} onSearchChange={handleSearchChangeResponse} onClearSearch={handleClearSearchResponse}>
                                        <Button
                                            type="button"
                                            onClick={handleDownloadRespondNotification}
                                            icon={faDownload}
                                            text="Download Report"
                                            loader={downloadLoader}
                                            className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                                        />
                                    </TableButtonSection>
                                    <TableContainer>
                                        <thead className="bg-gray-50 dark:bg-neutral-800">
                                            <tr>
                                                <th scope="col" onClick={() => handleSortResponse('respondedBy')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                                        <span>User Name</span>
                                                        {sortedColumnResponse === "respondedBy" && <FontAwesomeIcon className="h-3" icon={sortOrderResponse === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                                    </div>
                                                </th>
                                                <th scope="col" onClick={() => handleSortResponse('option')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                                        <span>Option</span>
                                                        {sortedColumnResponse === "option" && <FontAwesomeIcon className="h-3" icon={sortOrderResponse === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                                    </div>
                                                </th>
                                                <th scope="col" onClick={() => handleSortResponse('createdAt')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                                        <span>Responded On</span>
                                                        {sortedColumnResponse === "createdAt" && <FontAwesomeIcon className="h-3" icon={sortOrderResponse === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                                    </div>
                                                </th>
                                                <th scope="col" onClick={() => handleSortResponse('notify_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                                        <span>Notify On</span>
                                                        {sortedColumnResponse === "notify_at" && <FontAwesomeIcon className="h-3" icon={sortOrderResponse === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                                            {getUserNotificationResponsePages?.userNotificationResponseList?.map(userResponse=> (
                                                <tr key={userResponse?.notificationScheduleId}>
                                                    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                                        <div className="w-45">
                                                            <h2 className="font-semibold text-gray-700 dark:text-gray-200  ">{userResponse?.respondedBy}</h2>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                        <div className="w-45">
                                                            <h2 className="font-semibold text-gray-700 dark:text-gray-200  ">{userResponse?.userResponse?.[0]?.value}</h2>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                        <div>
                                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(userResponse?.createdAt)}</h4>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                        <div>
                                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(userResponse?.notifyAt)}</h4>
                                                        </div>
                                                    </td>
                                                </tr>

                                            ))}
                                            {!getUserNotificationResponsePages?.userNotificationResponseList?.length && !responseLoader && <TableNoRecordFound colSpan={9}></TableNoRecordFound>}
                                            {responseLoader && <TableLoadingData colSpan={9}></TableLoadingData>}
                                        </tbody>
                                    </TableContainer>
                                </TableWrapper>
                                <Pagination totalPages={responseLoader ? 0 : getUserNotificationResponsePages?.pageInfo?.total_pages} totalRecords={load ? 0 : getUserNotificationResponsePages?.pageInfo?.total_records}
                                    maxPageNumbers={maxPageNumber}
                                    recordsPerPage={getUserNotificationResponsePages?.pageInfo?.per_page || recordsPerPageResponse || 0}
                                    currentPage={responseLoader ? 0 : getUserNotificationResponsePages?.pageInfo?.current_page} onPageChange={handlePageChangeResponse} onRowChanges={handleRowChangesResponse} ></Pagination>
                            </PageWrapper>
                        </div>
                    </div>
                    :
                    <div className="flex justify-center items-center h-screen">
                        <td colSpan={5} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
                            <div>
                                <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                                <span>Loading Data</span>
                            </div>
                        </td>
                    </div>
                }
            </div>
        </PageWrapper>
    );
};

export default NotificationDetails;
