import React, { useEffect, useState } from "react";
import { useAddUserToGroupMutation, useGetAllUserInfoListQuery, useGetGroupDetailsByIdMutation } from "../../../graphql/generated/graphql.ts";
import Button from '../../../components/button/Button';
import { toast } from "react-toastify";
import PageWrapper from "../../../layouts/PageWrapper";
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    useCSVReader,
} from 'react-papaparse';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'

const BulkUploadGroup = () => {
    const groupId = useParams();
    const { CSVReader } = useCSVReader();
    const navigate = useNavigate();
    const [userFailedList, setUserFailedList] = useState([]);
    const [uploadedUser, setUploadedUser] = useState([]);
    const [addUserToGroupMutation, { loading }] = useAddUserToGroupMutation();
    const { data: userData, loading: loader } = useGetAllUserInfoListQuery({ variables: {}, });
    const [getGroupDetailsByIdMutation, { data: userDetails, error, loading: userDataLoader }] = useGetGroupDetailsByIdMutation({
        variables: {
            uniqueId: groupId?.groupId
        },
    });
    function handleNavigationError() {
        if (error) {
            navigate('/error', { state: { errorData: JSON.stringify(error) } });
        }
    }

    handleNavigationError();

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Group | Bulk Upload`;
        document.title = pageTitle;
        getGroupDetailsByIdMutation();
    }, []);


    //download the sample file for upload csv. 
    const handleDownloadSampleFile = () => {
        let stringToDownload = "Email List\n";
        stringToDownload = `${stringToDownload}test@shell.com`
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:attachment/text,' + encodeURI(stringToDownload);
        hiddenElement.target = '_blank';
        hiddenElement.download = `Sample file.csv`
        hiddenElement.click();
    };

    //handle file file uploaded data.
    const handleFileUpload = data => { //NOSONAR
        const indexValue = 2;
        try {
            const normalizedData = data?.data?.map(rowData => rowData?.map(cell => cell.replace(/\r$/, '')))
                .filter(rowItem => rowItem.length >= 1);
            const columns = normalizedData[0]?.map(col => {
                let colValue = col.replace(/\s+/g, '');
                const HeaderNameChanges = {
                    EmailList: "uniqueName",
                };

                colValue = colValue.replace(/\b(?:EmailList)\b/gi, item => HeaderNameChanges[item]);
                return { Headers: colValue }
            });
            const row = normalizedData?.slice(1)?.map(rowData => {
                return rowData?.reduce((acc, curr, index) => {
                    acc[columns[index].Headers] = curr;
                    return acc;
                }, {});
            });
            const existData = [];
            const mappingData = row?.filter((element, index) => {
                let errorDescription = '';
                const userNames = userData?.getAllUserInfoList?.filter(userUniqueName => element?.uniqueName?.includes(userUniqueName?.uniqueName));
                const checkExitingUser = userDetails?.getGroupDetailsById?.[0]?.users?.filter(data => element?.uniqueName?.includes(data?.uniqueName))
                if (!element.uniqueName?.toLowerCase().endsWith('@shell.com')) {
                    errorDescription += `${errorDescription ? ' & ' : ''}Please Provide Shell Email Id`;
                }
                if (!userNames?.length) {
                    errorDescription += `${errorDescription ? ' & ' : ''}Shell Email Id does not exist`;
                }
                if (checkExitingUser.length>0){
                    errorDescription += `${errorDescription ? ' & ' : ''}Email Id already exist in the group`;
                }
                else {
                    existData.push(`${element.uniqueName}`)
                }
                if (errorDescription) {
                    Object.assign(element, { description: `${index + indexValue} - ${errorDescription}` });
                    return element
                } else {
                    setUploadedUser(addedUser => [...addedUser, element]);
                    return false;
                }
            });
            setUserFailedList(mappingData)
        } catch (err) {
            toast.error("Invalid CSV file")
        }
    }



    //handle upload csv data. 
    const handleUploadCsv = () => { //try and catch.
        const userList = uploadedUser?.map((item) => { //NOSONAR
            const userIds = userData?.getAllUserInfoList?.filter(idd => item?.uniqueName?.includes(idd.uniqueName));

            if (userIds.length>0) {
                return {
                    uniqueName: userIds,
                };
            }
        }).filter(Boolean); // Filter out null values
        if (userList?.length === 0) {
            return; // No valid data to upload, handle accordingly
        }
        const updatedData = [];
        userList.forEach(item => {
                updatedData.push({
                    userId: item.uniqueName[0].uniqueId,
                    groupId: groupId?.groupId
                });
        });
        addUserToGroupMutation({ variables: { addUserToGroupArgsList: updatedData } })
            .then(() => {
                toast.success(`${userList.length} users added successfully to the group.`)
                navigate('/access-control/groups');
            }).catch(userError => {
                toast.error(userError.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: true,
                });
            })
    };

    //handle remove csv file.
    const handleRemoveCsv = () => {
        setUserFailedList('');
        setUploadedUser([])
    };

    //handle download if data has failed to upload user. 
    const handleDownloadFailedUser = () => {
        let stringToDownload = "Email List\n";
        userFailedList.forEach(user => {
            stringToDownload = `${stringToDownload},${user.uniqueName},${user.description} \n`
        });

        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:attachment/text,' + encodeURI(stringToDownload);
        hiddenElement.target = '_blank';
        const date = new Date();
        hiddenElement.download = `failed_UserList_ ${date.toString()} .csv`
        hiddenElement.click();
    };

    //handle error for csv uploaded data. 
    const validateUser = val => {
        const { uniqueName, groupName } = val;
        return [uniqueName, groupName,].some(value => !value);
    };

    const errorMsgValue = Array.isArray(userFailedList)
        ? userFailedList.filter(validateUser)
        : [];

    const errorMsgEmail = Array.isArray(userFailedList)
        ? userFailedList.filter(val => {
            const uniqueName = val?.uniqueName?.toLowerCase();
            return !uniqueName?.endsWith('@shell.com');
        })
        : [];

    const emailError = Array.isArray(userFailedList)
        ? userFailedList.filter(val => val?.emailError)
        : [];

    return (
        <PageWrapper heading={'Upload bulk user'}>
            <div className="py-6">
                <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                    <div className="col-span-4">
                        <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">Create Multiple Users</h2>
                        <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Upload the CSV template to create users.</p>
                    </div>
                    <div className="col-span-8">
                        <CSVReader
                            onUploadAccepted={results => {
                                handleFileUpload(results);
                            }}
                        >
                            {({
                                getRootProps,
                                acceptedFile,
                                ProgressBar,
                                getRemoveFileProps,
                            }) => (
                                <>
                                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                                        <p className="text-gray-600 dark:text-gray-400 text-sm">You can quickly create new users by using a .csv file. You can upload the file with set of columns defined with right data points. There is no limit to how many users you can add in the file as long as it has 1 record per row.</p>
                                        <button className="cursor-pointer" onClick={handleDownloadSampleFile}>
                                            <p className="leading-8 text-blue-600 dark:text-blue-400 text-sm underline">Click here to download the .csv template</p>
                                        </button>
                                        <div className="flex">
                                            <div className="mr-2">
                                                <Button
                                                    type="button"
                                                    {...getRootProps()}
                                                    text="Upload CSV Template"
                                                    loader={loader || userDataLoader}
                                                    disabled={loader || userDataLoader}
                                                    className="flex items-center justify-center px-2 py-2 text-sm font-small text-gray-700 transition-colors duration-200 bg-white border rounded-lg dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            {acceptedFile?.name &&
                                                <div className="flex items-center justify-between p-2 bg-gray-100 dark:bg-neutral-700 rounded-md">
                                                    <span>{acceptedFile?.name}</span>
                                                    <Button
                                                        {...getRemoveFileProps()}
                                                        onClick={event => {
                                                            getRemoveFileProps().onClick(event);
                                                            handleRemoveCsv();
                                                        }}
                                                        text="Remove"
                                                        className="text-red-600 hover:text-red-400"
                                                    />
                                                </div>}
                                        </div>
                                    </div>
                                    <ProgressBar className="bg-red-500" />
                                </>
                            )}
                        </CSVReader>
                        {userFailedList?.length >= 1 &&
                            <>
                                <br />
                                <div className="p-3 mb-3 bg-red-100 border border-red-600 rounded text-base text-red-700" role="alert">
                                    <p><strong>Whoops!</strong> There were some problems with your input.</p>
                                    <ul>
                                        <li><strong>{userFailedList?.length}</strong> -Something is wrong with Users.</li>
                                        {!errorMsgValue?.length ? '' : <li>All fields are required.</li>}
                                        {!emailError?.length ? '' : <li>Email does not found</li>}
                                        {!errorMsgEmail?.length ? '' : <li>Email should be a valid Shell email address.</li>}

                                    </ul><div className="border-b border-red-500 mt-10 mb-14" />
                                    <Button type='button' onClick={handleDownloadFailedUser}
                                        text="Download">
                                    </Button>
                                </div>
                            </>
                        }
                        <div className="mt-10 flex justify-center">
                            < Button
                                type="button"
                                className={!uploadedUser?.length || loading ? "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2" : "inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"}
                                onClick={handleUploadCsv}
                                text="Upload"
                            />
                            <Link to='/access-control/groups'
                                className="ml-2 block rounded-md bg-gray-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                            {loading && <FontAwesomeIcon className="w-5 h-5 mr-4 ml-2 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                        </div>
                    </div>
                </div>
            </div>
        </PageWrapper>

    )
}

export default BulkUploadGroup;