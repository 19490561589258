import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useGetAllFeedbackAndRatingQuery, useDeleteFeedbackAndRatingMutationMutation, useUpdateNextTriggerDateByIdMutation, useGetFeedbackAndRatingPagesMutation, useGetCurrentUserPermissionsQuery } from "../../../graphql/generated/graphql.ts";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatTimestamp } from '../../../utils/commonFormatFunctions.js'
import { ClockIcon, TrashIcon } from '@heroicons/react/24/outline'
import { faArrowDownShortWide, faArrowUpWideShort, faTrashCan, faDownload, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'
import PageWrapper from "../../../layouts/PageWrapper.js";
import Pagination from "../../../components/table/Pagination.js";
import TableWrapper from "../../../components/table/TableWrapper.js";
import TableContainer from "../../../components/table/TableContainer.js";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound.js";
import TableLoadingData from "../../../components/table/TableLoadingData.js";
import TableHeadingSection from "../../../components/table/TableHeadingSection.js";
import TableButtonSection from "../../../components/table/TableButtonSection.js";
import Button from '../../../components/button/Button.js';
import DialogModal from "../../../components/modal/DialogModal.js";
import { toast } from "react-toastify";
import DatePicker from "../../../components/datePicker/DatePicker.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import ActionButton from "../../../components/button/ActionButton.js";


const Index = () => {
    const navigate = useNavigate()
    const [feedbackPaginationMutation, { data, loading, error, }] = useGetFeedbackAndRatingPagesMutation();
    const { feedbackAndRatingList, pageInfo } = data?.getFeedbackAndRatingPages || {};
    const [deleteMutation, { loading: deleteLoader }] = useDeleteFeedbackAndRatingMutationMutation();
    const [editNextTriggerDate, { loading: editLoader }] = useUpdateNextTriggerDateByIdMutation();
    const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
    const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
    const permissionAPIList = permissionData?.getAllAPIPermissions
    const DeleteFeedbackAndRating = permissionAPIList?.filter(item => item.accessType === "DeleteFeedbackAndRating")
    const UpdateNextTriggerDate = permissionAPIList?.filter(item => item.accessType === "UpdateNextTriggerDate");
    const hasPermission = (permissionsArray, permissionToCheck) =>
        permissionsArray?.length && permissionsArray[0].assignedPermissions.some(val => permissionToCheck.includes(val));
    // Refactored permission checks
    const editUserPermission = hasPermission(UpdateNextTriggerDate, addPermission);
    const deleteUserPermission = hasPermission(DeleteFeedbackAndRating, addPermission);
    const [itemsData, setItemsData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortedColumn, setSortedColumn] = useState("created_by");
    const [sortOrder, setSortOrder] = useState('ASC');
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [deletedItem, setDeletedItem] = useState([]);
    const [datePicker, setDatePicker] = useState(false)
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState([]);
    const [recordsPerPageData, setRecordsPerPageData] = useState(pageInfo?.per_page);
    const maxPageNumber = 5;
    const defaultPageNumber = 1;

    const { error: err } = useGetAllFeedbackAndRatingQuery({
        fetchPolicy: "network-only",
        skip: !downloadLoader,
        onCompleted: feedBackDetails => {
            setItemsData(feedBackDetails?.getAllFeedbackAndRating || []);
        },
    })

    function navigationError () {
        if (error || err) {
            const errorData = JSON.stringify(error || err);
            navigate('/error', { state: { errorData } });
        }
    }

    navigationError();

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Feedbacks`;
        document.title = pageTitle;
    }, []);


    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getFeedbackData(defaultPageNumber, rowValue);
    };

    useEffect(() => {
        if(sortOrder && sortedColumn){
            getFeedbackData(defaultPageNumber, pageInfo?.per_page);
        }
    }, [sortOrder, sortedColumn]);

    const getFeedbackData = (page = defaultPageNumber, row = pageInfo?.per_page, search_term = searchQuery) => {
        setDeletedItem([])
        setSelectedItems([]); setSelectAll(false)
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term,
            search_column: "",
            order_by: sortOrder || "",
            order_by_column: sortedColumn || "",
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        }
        feedbackPaginationMutation({ variables: { PageInfoInput } })
            .catch(errorDetails => {
                toast.error(errorDetails.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    }

    //handle checkBox changes
    const handleCheckboxChange = itemId => {
        if (selectedItems?.includes(itemId)) {
            setSelectedItems(selectedItems?.filter(id => id !== itemId));
        } else {
            setSelectedItems([...selectedItems, itemId]);
        }
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(feedbackAndRatingList?.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        if (itemsData && downloadLoader) {
            let stringToDownload = "Created By, Updated By, FeedBack, Rating, Created On, Updated On, Next Trigger At \n";
            itemsData?.forEach(feedback => {
                stringToDownload = `${stringToDownload}${feedback.created_by},${feedback.updated_by},${feedback.feedback},${feedback.rating},${formatTimestamp(feedback.created_at)},${formatTimestamp(feedback.updated_at)},${formatTimestamp(feedback.next_trigger_at)} \n`
            });
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:attachment/text,' + encodeURI(stringToDownload);
            hiddenElement.target = '_blank';
            const date = new Date();
            hiddenElement.download = `Feedback_and_rating_report_ ${date.toString()} .csv`
            hiddenElement.click();
            setDownloadLoader(false)
        }
    }, [itemsData])

    //function to download feedback data in csv formate. 
    const handleDownloadButtonClick = () => {
        setDownloadLoader(true)
    }

    // Handle search input change
    const handleSearchChange = e => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm);
    };

    const handleClearSearch = () => {
        setSearchQuery("");
        getFeedbackData(defaultPageNumber, pageInfo?.per_page, "");
    };

    useEffect(() => {
        const delayTime = 1000;
        if (searchQuery) {
            const delayDebounceFn = setTimeout(() => {
                // Make API request with the current value
                getFeedbackData(defaultPageNumber, pageInfo?.per_page, searchQuery);
            }, delayTime);
            return () => {
                clearTimeout(delayDebounceFn);
            };
        }
    }, [searchQuery]);

    // Handle column header click for sorting
    const handleSort = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrder);
        setSortedColumn(column);
    };

    //open delete modal popup.
    const openModal = () => {
        if (selectedItems?.length) {
            setIsOpen(true)
        }
    };

    //close delete modal pipup
    const closeModal = () => {
        setIsOpen(false)
        setDeletedItem([])
        setSelectedItems([])
    };

    // Handle pagination link click
    const handlePageChange = pageNumber => {
        getFeedbackData(pageNumber, pageInfo?.per_page);
    };


    const onClickDelete = selectedItem => {
        deleteMutation({ variables: { id: selectedItem } })
            .then(() => {
                toast.success("Feedback deleted successfully.")
                closeModal()
                setSelectedItems([])
                getFeedbackData()
            }).catch(er => {
                toast.error(er.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            })
    }

    const onClickConfirm = selectedDate => {
        const unixTimestamp = new Date(selectedDate).getTime();
        const array = selectedItems?.length > 0 ? selectedItems : [selectedFeedback];
        const resultArray = array?.map(id => {
            const item = feedbackAndRatingList?.find(feedBackItem => feedBackItem.id === id);
            return { id, createdBy: item ? item?.created_by : null, nextTriggerDate: unixTimestamp };
        });
        editNextTriggerDate({ variables: { nextTriggerDateUpdateList: resultArray } }).then(() => {
            toast.success('Data updated successfully.')
            setDatePicker(false)
            setSelectedItems([])
            getFeedbackData();
        }).catch(e => {
            toast.error(e.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                autoClose: false,
            });
        })
    }

    const handleDelete = feedback => {
        const uniqueId = [feedback?.id]
        setDeletedItem(uniqueId)
        setIsOpen(true)
    }

    const handleEditNextTrigger = feedbackTriggerDate => {
        setSelectedFeedback(feedbackTriggerDate)
        setDatePicker(true)
    }

    const ResetAll = () => {
        setDatePicker(true)
    }

    const colseDatePicker = () => {
        setDatePicker(false)
    }



    return (
        <>
            <PageWrapper heading={'Manage Feedback'}>
                <TableWrapper>
                    <TableHeadingSection heading={'Feedback and Rating'} description={'All the feedback and rating in the database.'} count={pageInfo?.total_records} />
                    <TableButtonSection searchQuery={searchQuery} onSearchChange={handleSearchChange} onClearSearch={handleClearSearch}>
                        <div>
                            <Button
                                type="button"
                                onClick={handleDownloadButtonClick}
                                icon={faDownload}
                                loader={downloadLoader}
                                text="Download Report"
                                className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                            />
                        </div>
                        <div> {editUserPermission && selectedItems?.length > 0 &&
                            <div>
                                <Button
                                    type="button"
                                    onClick={ResetAll}
                                    icon={faCloudArrowUp}
                                    text="Reset All"
                                    className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                                />
                            </div>}
                        </div> {deleteUserPermission && selectedItems?.length > 0 &&
                            <div>
                                <Button
                                    type="button"
                                    onClick={openModal}
                                    className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-red-500 rounded-lg shrink-0 gap-x-2 hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600"
                                    icon={faTrashCan}
                                    text="Delete" />
                            </div>}
                    </TableButtonSection>
                    <TableContainer>
                        <thead className="bg-gray-50 dark:bg-neutral-800">
                            <tr>
                                <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold">
                                    <div className="flex h-6 items-center">
                                        <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('created_by')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Created By</span>
                                        {sortedColumn === "created_by" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('feedback')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>FeedBack</span>
                                        {sortedColumn === "feedback" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('rating')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Rating</span>
                                        {sortedColumn === "rating" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('app_version')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>App Version</span>
                                        {sortedColumn === "app_version" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('created_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Created On</span>
                                        {sortedColumn === "created_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('updated_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Updated On</span>
                                        {sortedColumn === "updated_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('next_trigger_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span> Next Trigger On</span>
                                        {sortedColumn === "next_trigger_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" className="px-4 py-3.5 text-sm text-center rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                            {feedbackAndRatingList?.map(feedback => (
                                <tr key={feedback?.id}>
                                    <td className="px-4 py-3 text-sm font-medium">
                                        <div className="flex h-6 items-center">
                                            <input type="checkbox" checked={selectedItems?.includes(feedback.id)} onChange={() => handleCheckboxChange(feedback?.id)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                        <div>
                                            <h2 className="font-semibold text-indigo-600 dark:text-indigo-400 ">{feedback?.created_by}</h2>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm font-small">
                                        <div className="w-full">
                                            <p className="text-gray-700 dark:text-gray-200">
                                                {feedback?.feedback}
                                            </p>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="">
                                            <p className="text-gray-700 dark:text-gray-200">{feedback?.rating}</p>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="">
                                            <p className="text-gray-700 dark:text-gray-200">{feedback?.app_version}</p>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(feedback.created_at)}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(feedback.updated_at)}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(feedback.next_trigger_at)}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="flex item-center justify-center text-gray-700 dark:text-gray-200">
                                            {deleteUserPermission ? <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                icon={TrashIcon}
                                                title="Delete"
                                                onClick={() => { handleDelete(feedback) }}
                                            />
                                                :
                                                <ActionButton className="w-4 mr-2 transform text-gray-500" title="delete" icon={TrashIcon} />}
                                            {editUserPermission ?
                                                <ActionButton title="Reset"
                                                    icon={ClockIcon}
                                                    className="hover:text-purple-500 hover:scale-110"
                                                    onClick={() => handleEditNextTrigger(feedback?.id)} />
                                                : <ActionButton className="w-4 mr-2 transform text-gray-500"
                                                    icon={ClockIcon}
                                                    title="Reset" />}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {!feedbackAndRatingList?.length && !loading && <TableNoRecordFound colSpan={8}></TableNoRecordFound>}
                            {loading && <TableLoadingData colSpan={8}></TableLoadingData>}
                        </tbody>
                    </TableContainer>
                </TableWrapper>
                <Pagination totalPages={pageInfo?.total_pages || 0} totalRecords={pageInfo?.total_records || 0}
                    maxPageNumbers={maxPageNumber}
                    recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0}
                    currentPage={pageInfo?.current_page || 0} onPageChange={handlePageChange} onRowChanges={handleRowChanges} ></Pagination>
            </PageWrapper>
            <DialogModal
                isOpen={isOpen}
                onClose={closeModal}
                title="Confirm Deletion"
                closeOnClickOutside={false} // Set this prop as needed
                pTagData={`Do you confirm to delete ${deletedItem?.length || selectedItems?.length} feedback?`}
                btnOne="Confirm Delete"
                btnTwo="Cancel"
                loader={deleteLoader}
                onClickDeleteModal={() => { onClickDelete(deletedItem.length > 0 ? deletedItem : selectedItems) }}
            />
            <DatePicker
                isOpen={datePicker}
                onClose={colseDatePicker}
                title="Edit Next Trigger Date"
                closeOnClickOutside={false} // Set this prop as needed
                pTagData={`Do you confirm to reset next trigger the date of feedback`}
                btnOne="Confirm"
                btnTwo="Cancel"
                loader={editLoader}
                onHandleConfirmDateModal={onClickConfirm}
            />
        </>
    );
};

export default Index;