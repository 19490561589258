import React, { useEffect, useRef, useState } from 'react';
import { toast } from "react-toastify";
import { formatSize, formatTime } from '../../utils/commonFormatFunctions'
import PropTypes from 'prop-types';


const VideoThumbnail = ({ videoUrl, width, height, onThumbnailBase64Change, onVideoDurationChange, onVideoSizeChange  }) => {
  const canvasRef = useRef(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoSize, setVideoSize] = useState(0);

  useEffect(() => {
    const loadVideoMetadata = (video, onLoaded) => {
      const handleCanPlay = () => {
        onLoaded();
        video.removeEventListener('canplay', handleCanPlay);
        video.pause();
      };

      video.addEventListener('canplay', handleCanPlay);
      video.load();
      video.currentTime = 1; // Set video time to capture a frame
    };

    const drawThumbnailOnCanvas = video => {
      const canvas = canvasRef.current;
      canvas.width = width || video.videoWidth;
      canvas.height = height || video.videoHeight;

      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      return canvas.toDataURL('image/jpeg');
    };

    const fetchVideoSize = async () => {
      try {
        const response = await fetch(videoUrl);
        return parseInt(response.headers.get('content-length'), 10);
      } catch (error) {
        toast.error(`Error retrieving video file size: ${error}`, {
          autoClose: false,
        });
        return null;
      }
    };

    const generateThumbnail = async () => {
      try {
        const video = document.createElement('video');
        video.src = videoUrl;
        video.crossOrigin = 'anonymous';

        const handleVideoLoaded = async () => {
          const MILLISECONDS_PER_SECOND = 1000;
          const duration = Math.round(video.duration * MILLISECONDS_PER_SECOND);
          setVideoDuration(duration);
          onVideoDurationChange(duration);

          const thumbnail = drawThumbnailOnCanvas(video);
          onThumbnailBase64Change(thumbnail);

          const fileSize = await fetchVideoSize();
          if (fileSize !== null) {
            setVideoSize(fileSize);
            onVideoSizeChange(fileSize);
          }
        };

        loadVideoMetadata(video, handleVideoLoaded);
      } catch (error) {
        toast.error(`Error retrieving video thumbnail: ${error}`, {
          autoClose: false,
        });
      }
    };

    generateThumbnail();
  }, [videoUrl, width, height, onThumbnailBase64Change, onVideoDurationChange, onVideoSizeChange]);



  return (
    <div>
      <div>
        <canvas ref={canvasRef} />
      <p className='mt-4 font-bold'>Video Duration: {formatTime(videoDuration)}</p>
        <p className='font-bold'>Video Size: {formatSize(videoSize)}</p>
      </div>
      
    </div>
  );
};
VideoThumbnail.propTypes = {
  videoUrl : PropTypes.any,
  width : PropTypes.any, 
  height : PropTypes.any,
  onThumbnailBase64Change : PropTypes.any,
  onVideoDurationChange : PropTypes.any,
  onVideoSizeChange : PropTypes.any,
}
export default VideoThumbnail;
