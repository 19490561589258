import moment from 'moment';
const perSecondValue = 1000;
const perSecondMin = 3600;
const second = 60;
const hoursForFormate = 2;
const indexValue = 1;
const isNewFileValidityTime = 7;
const SEVEN_DAYS_AGO = moment().subtract(isNewFileValidityTime, 'days');
import { faFile, faFileImage, faFilePdf, faFileWord, faFileExcel, faFilePowerpoint, faFileAudio, faFileVideo, faFileAlt, faFileCsv, faFileArchive } from '@fortawesome/free-solid-svg-icons';
export function formatTime(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / perSecondValue);
    const hours = Math.floor(totalSeconds / perSecondMin);
    const minutes = Math.floor((totalSeconds % perSecondMin) / second);
    const remainingSeconds = totalSeconds % second;
    const formattedHours = String(hours).padStart(hoursForFormate, '0');
    const formattedMinutes = String(minutes).padStart(hoursForFormate, '0');
    const formattedSeconds = String(remainingSeconds).padStart(hoursForFormate, '0');
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export function formatSize(fileSizeInBytes) {
    const toConvertIntoMb = 1024;
    const units = ['KB', "MB"];
    const size = fileSizeInBytes;
    let unitIndex = 0;
    const kbytes = size / toConvertIntoMb;
    if (kbytes > perSecondValue ) {
        unitIndex = 1;
        const megabytes = size / (toConvertIntoMb * toConvertIntoMb);
        return `${megabytes.toFixed(hoursForFormate)} ${units[unitIndex]}`;
    }
    return `${kbytes.toFixed(hoursForFormate)} ${units[unitIndex]}`;
}

export function formatTimestamp(timestamp) {
    const timeZoneData = (localStorage.getItem('timeZone'));
    if (timeZoneData === "GMT") {
        const date = new Date(timestamp);
        // Get the UTC components of the date
        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + indexValue).toString().padStart(hoursForFormate, '0'); // Month is zero-based, so add 1
        const day = date.getUTCDate().toString().padStart(hoursForFormate, '0');
        const hours = date.getUTCHours().toString().padStart(hoursForFormate, '0');
        const minutes = date.getUTCMinutes().toString().padStart(hoursForFormate, '0');
        const seconds = date.getUTCSeconds().toString().padStart(hoursForFormate, '0');
        // Format the UTC date and time
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    } else {
        return moment(Number(timestamp)).format('DD MMM YYYY hh:mm:ss');
    }
}

export function formatGmtTimestamp(timeValue) {
    return moment(timeValue).utc().format("DD MMM YY HH:mm")
}

const fileIconMapping = {
    pdf: faFilePdf,
    doc: faFileWord,
    docx: faFileWord,
    xls: faFileExcel,
    xlsx: faFileExcel,
    ppt: faFilePowerpoint,
    pptx: faFilePowerpoint,
    jpg: faFileImage,
    jpeg: faFileImage,
    png: faFileImage,
    gif: faFileImage,
    mp3: faFileAudio,
    wav: faFileAudio,
    mp4: faFileVideo,
    mov: faFileVideo,
    avi: faFileVideo,
    txt: faFileAlt,
    csv: faFileCsv,
    zip: faFileArchive,
    rar: faFileArchive,
};

export const getFileExtension = fileURL => {
    try {
        const fileExtension = `${fileURL.substring(fileURL.lastIndexOf(".") + indexValue, fileURL.length)}`;
        return fileIconMapping[fileExtension || faFile]
    } catch (err) {
        return null;
    }
}

export const checkIsNewFile = updated_at => { {/* NOSONAR */ }
    const diffDays = moment(updated_at).diff(SEVEN_DAYS_AGO, 'days');
    return diffDays >= 0;
};

export const convertMilliSecondToHrs = milliseconds  => {
    const totalSeconds = Math.floor(milliseconds / perSecondValue);
    const hours = Math.floor(totalSeconds / perSecondMin);
    const minutes = Math.floor((totalSeconds % perSecondMin) / second);
    const seconds = totalSeconds % second;

    return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
}

function padZero(number) {
    return number.toString().padStart(2, '0');
}