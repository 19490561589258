import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { faDownload, faFilter } from '@fortawesome/free-solid-svg-icons';
import PageWrapper from "../../../layouts/PageWrapper";
import Pagination from "../../../components/table/Pagination";
import TableWrapper from "../../../components/table/TableWrapper";
import TableContainer from "../../../components/table/TableContainer";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound";
import TableLoadingData from "../../../components/table/TableLoadingData";
import TableHeadingSection from "../../../components/table/TableHeadingSection";
import TableButtonSection from "../../../components/table/TableButtonSection";
import { useGetAuditLogPagesMutationMutation, useGetAllAuditLogsQuery, useGetAllActionsAndActorsMutation } from "../../../graphql/generated/graphql.ts";
import { toast } from "react-toastify";
import Button from "../../../components/button/Button";
import SelectSingleInput from "../../../components/form/input/SelectSingleInput.js";
import { formatTimestamp } from "../../../utils/commonFormatFunctions.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import InputField from "../../../components/form/input/InputField.js";
import TableHeaderComponent from "../../../components/form/input/TableHeaderComponent.js";



const AuditActivityLogs = () => {
    const timeZoneData = (localStorage.getItem('timeZone'));
    const timezoneText = timeZoneData === null || timeZoneData === "IST" ? "local" : timeZoneData;
    const [getAuditUserMutation, { data: auditData, error, loading }] = useGetAuditLogPagesMutationMutation();
    const [getFilterData, { data: filterActionActorData }] = useGetAllActionsAndActorsMutation();
    const { pageInfo, auditLogsForAdmin } = auditData?.getAuditLogPagesMutation || {};
    const [searchQuery, setSearchQuery] = useState('');
    const [searchFilter, setSearchFilter] = useState('');
    const [sortedColumn, setSortedColumn] = useState('tstamp');
    const [sortOrder, setSortOrder] = useState('DESC');
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const [selectedFilterName, setSelectedFilterName] = useState([])
    const [selecteFilterSort, setSelecteFilterSort] = useState([])
    const [selectedFilterBy, setSelectedFilterBy] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [startDate, setStartDate] = useState(0);
    const [endDate, setEndDate] = useState(0);
    const [recordsPerPageData, setRecordsPerPageData] = useState(pageInfo?.per_page);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const maxPageNumbers = 5;
    const defaultPageNumber = 1;
    const navigate = useNavigate();
    const { data, loading: auditloader, error: auditDataError } = useGetAllAuditLogsQuery({
        fetchPolicy: "network-only",
        skip: !downloadLoader,
    })


    if (error) {
        navigate('/error', { state: { errorData: JSON.stringify(error) } });
    }

    if (auditDataError) {
        navigate('/error', { state: { errorData: JSON.stringify(auditDataError) } });
    }


    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Activity Logs`;
        document.title = pageTitle;
    }, []);


    useEffect(() => {
        getAuditData(defaultPageNumber, pageInfo?.per_page);
    }, [sortOrder, sortedColumn, searchFilter]);

    const filterName = [
        { value: 'action', label: 'Action' },
        { value: 'timestamp', label: 'Time' },
        { value: 'actorEmail', label: 'Actor' },
    ];

    const filterSort = [
        { value: 'is', label: 'is' },
        { value: 'between', label: 'between' },
    ];

    const onClickSearch = () => {
        setSearchFilter(filterData?.value)
        getAuditData(defaultPageNumber, pageInfo?.per_page, filterData?.value)
    }


    const getAuditData = (page = defaultPageNumber, row = pageInfo?.per_page, search_term = searchQuery) => {
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term: search_term || searchFilter || "",
            search_column: "",
            order_by: sortOrder,
            order_by_column: sortedColumn,
            startTime: new Date(startDate).getTime(),
            endTime: new Date(endDate).getTime(),
            time_window_column: endDate && startDate ? "tstamp" : ""
        };
        getAuditUserMutation({ variables: { PageInfoInput } })
            .catch(err => {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    }

    // Handle search input change
    const handleSearchChange = e => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm);
    };
    useEffect(() => {
        const delayTime = 1000;
        if (searchQuery) {
            const delayDebounceFn = setTimeout(() => {
                // Make API request with the current value
                getAuditData(defaultPageNumber, pageInfo?.per_page, searchQuery);
            }, delayTime);
            return () => {
                clearTimeout(delayDebounceFn);
            };
        }
    }, [searchQuery]);

    const handleClearSearch = () => {
        setSearchQuery("");
        getAuditData(defaultPageNumber, pageInfo?.per_page, "");
    };

    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getAuditData(defaultPageNumber, rowValue);
    };

    // Handle column header click for sorting
    const handleSort = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrder);
        setSortedColumn(column);
    };

    // Handle pagination link click
    const handlePageChange = pageNumber => {
        getAuditData(pageNumber, pageInfo?.per_page)

    };

    const handleDownloadButtonClick = () => {
        setDownloadLoader(true)
    }

    useEffect(() => {
        if (data?.getAllAuditLogs) {
            let stringToDownload = "Table Name, Record Key, Actions, Actor Email, Timestamp \n";
            data?.getAllAuditLogs?.forEach(value => {
                stringToDownload = `${stringToDownload}${value.tableName},${value.recordPrimaryKey},${value.action},${value.actorEmail},${formatTimestamp(value.timestamp)}\n`
            });
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:attachment/text,' + encodeURI(stringToDownload);
            hiddenElement.target = '_blank';
            const date = new Date();
            hiddenElement.download = `auditLogs_report ${date.toString()} .csv`
            hiddenElement.click();
            setDownloadLoader(false)
        }
    }, [data?.getAllAuditLogs])

    const handleFilter = () => {
        setOpenFilterModal(!openFilterModal);
        setEndDate(0); setStartDate(0); setFilterData(); setSelecteFilterSort(); setSelectedFilterName(); setSearchFilter("")
        if (filterData || endDate || startDate) {
            getAuditData(defaultPageNumber, pageInfo?.per_page);
        }
    }

    const filterValue = value => {
        setSelectedFilterName(value)
        setSelecteFilterSort('')
        setFilterData('')
    }

    const selectedFilter = item => {
        setFilterData(item)
    }

    const onClickStartDateConfirm = event => {
        const date = event.target.value
        setStartDate(date)
    }

    const onClickEndDateConfirm = e => {
        const dateTime = e.target.value
        setEndDate((dateTime))
    }

    return (
        <PageWrapper heading={'Audit Activity Logs'}>
            <TableWrapper>
                <TableHeadingSection heading={'Activity Logs'} description={'All the activity logs in the database.'} count={pageInfo?.total_records} />
                <TableButtonSection searchQuery={searchQuery} onSearchChange={handleSearchChange} onClearSearch={handleClearSearch}>
                    {!loading && <Button
                        type="button"
                        onClick={handleFilter}
                        icon={faFilter}
                        text="Filter"
                        className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                    />}
                    <Button
                        type="button"
                        onClick={handleDownloadButtonClick}
                        icon={faDownload}
                        loader={downloadLoader || auditloader}
                        text="Download Report"
                        className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                    />
                </TableButtonSection>
                {openFilterModal &&
                    <div className="flex">
                        <div className=" w-full">
                            <SelectSingleInput
                                name={'filterName'}
                                value={selectedFilterName}
                                options={filterName}
                                onChange={selectedOption => { 
                                    getFilterData({
                                        variables: {
                                            key: selectedOption?.value === "action" ? "action" : "actor_email"
                                        },})
                                    filterValue(selectedOption) }}
                                // className={'w-60'} // Set the width here
                            />
                        </div>
                        <div className=" w-full pl-4">
                            <SelectSingleInput
                                name={'filterSort'}
                                value={selecteFilterSort}
                                options={filterSort}
                                onChange={selectedOption => {
                                    setSelecteFilterSort(selectedOption)
                                    if (selectedFilterName?.value === "timestamp") {
                                        setSelectedFilterBy([])
                                    } else if (selectedOption?.value === "between") {
                                        setSelectedFilterBy([])
                                    }
                                    else {
                                        const filteredData = filterActionActorData?.getAllActionsAndActors
                                            .map(item => ({
                                                label: item,
                                                value: item
                                            }));
                                        filteredData && setSelectedFilterBy(filteredData)
                                    }
                                }}
                                // className={'w-60'} // Set the width here
                            />
                        </div>
                        {selecteFilterSort?.value === "between" && selectedFilterName?.value === "timestamp" ?
                            <><div className="sm:col-span-2 pt-0.5 pl-4">
                                <InputField
                                    type="datetime-local"
                                    placeholder="Start Date"
                                    value={startDate || ""}
                                    onChange={onClickStartDateConfirm}
                                    helpDesk={`This message will be sent in ${timezoneText} time zone.`}
                                    className="border p-2 mb-4" />
                            </div><div className="sm:col-span-2 pt-0.5 pl-4">
                                    <InputField
                                        type="datetime-local"
                                        value={endDate || ""}
                                        onChange={onClickEndDateConfirm}
                                        helpDesk={`This message will be sent in ${timezoneText} time zone.`}
                                        className="border p-2 mb-4" />
                                </div></>

                            : <div className=" w-full pl-4">
                                <SelectSingleInput
                                    name={'filterBy'}
                                    value={filterData}
                                    options={selectedFilterBy}
                                    onChange={selectedOption => selectedFilter(selectedOption)}
                                    //className={'w-60'} // Set the width here
                                />
                            </div>
                        }
                        <div className=" pl-4 pt-2.5">
                            <Button type="submit"
                                className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={onClickSearch}
                                text="Search" />
                        </div>
                        <div className=" pl-4 pt-2.5">
                            <Button
                                className="block rounded-md bg-gray-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                onClick={handleFilter}
                                text="Cancel" />
                        </div>
                    </div>
                }
                <TableContainer>
                    <thead className="bg-gray-50 dark:bg-neutral-800">
                        <tr>
                            <TableHeaderComponent columnKey="tableName" label="Table Name" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                            <TableHeaderComponent columnKey="recordPrimaryKey" label="Record Key" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                            <TableHeaderComponent columnKey="action" label="Action" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                            <TableHeaderComponent columnKey="actorEmail" label="Actor Email" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                            <TableHeaderComponent columnKey="timestamp" label="Timestamp" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                        {auditLogsForAdmin?.map(activityLog => (
                            <tr key={activityLog?.id}>
                                <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                    <div>
                                        <h2 to={'/manage/users/view'} className="font-semibold text-indigo-600 dark:text-indigo-400 ">{activityLog.tableName}</h2>
                                    </div>
                                </td>
                                <td className="px-4 py-3 text-sm whitespace-pre-line" >
                                    <div className="" >
                                        <p className={`text-gray-700 dark:text-gray-400 overflow-hidden line-clamp-3`}>
                                            {activityLog.recordPrimaryKey}
                                        </p>
                                    </div>
                                </td>
                                <td className="px-4 py-3 text-sm whitespace-nowrap">
                                    <div className="">
                                        <h2 className="text-gray-700 dark:text-gray-200">{activityLog.action}</h2>
                                    </div>
                                </td>
                                <td className="px-4 py-3 text-sm whitespace-nowrap">
                                    <div className="">
                                        <h2 className="text-gray-700 dark:text-gray-200">{activityLog.actorEmail}</h2>
                                    </div>
                                </td>
                                <td className="px-4 py-3 text-sm whitespace-nowrap">
                                    <div>
                                        <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(activityLog.timestamp)}</h4>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        {!auditLogsForAdmin?.length && !loading && <TableNoRecordFound colSpan={8}></TableNoRecordFound>}
                        {loading && <TableLoadingData colSpan={8}></TableLoadingData>}
                    </tbody>
                </TableContainer>
            </TableWrapper>
            <Pagination totalPages={pageInfo?.total_pages || 0} totalRecords={pageInfo?.total_records || 0} recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0} maxPageNumbers={maxPageNumbers} currentPage={pageInfo?.current_page || 0} onPageChange={handlePageChange} onRowChanges={handleRowChanges}></Pagination>
        </PageWrapper>
    );
};

export default AuditActivityLogs;
