import PageWrapper from "../../layouts/PageWrapper";
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/button/Button.js";
import { toast } from "react-toastify";
import ConfigSection from "../../components/form/input/ConfigSection.js";

const SystemSetting = () => {
    const timeZoneData = (localStorage.getItem('timeZone'));
    const darkMode = JSON.parse(localStorage.getItem('darkMode'));
    const [isEnabled, setIsEnabled] = useState(timeZoneData === "GMT" ? true : false);
    const [darkModeEnabled, setDarkModeEnabled] = useState(darkMode)

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage System Settings`;
        document.title = pageTitle;
    }, [])
    useEffect(() => {
        setDarkModeEnabled(darkMode)
    }, [darkMode])

    useEffect(() => {
        timeZoneData === "GMT" ? setIsEnabled(true) : setIsEnabled(false)
    }, [timeZoneData])

    const initialValues = {
        timeZone: timeZoneData,
        darkMode
    }

    const { handleSubmit, } = useFormik({
        initialValues,
        onSubmit: () => {
            isEnabled ? localStorage.setItem("timeZone", "GMT") : localStorage.setItem("timeZone", "IST");
            localStorage.setItem("darkMode", darkModeEnabled);
            darkModeEnabled ? document.body.classList.toggle("dark") : document.body.classList.toggle('dark', darkModeEnabled);
            toast.success("Profile setting updated.")
        }
    });



    const toggleSwitch = () => {
        setIsEnabled(previousState => !previousState);
    };
    const toggleDrakModeSwitch = () => {
        setDarkModeEnabled(previousState => !previousState);
    };

    return (
        <PageWrapper heading={'Profile Settings'}>
            <div>
                <ConfigSection
                    title={'Time Zone Setting'}
                    description={`Selected Time Zone: ${isEnabled ? "GMT" : "IST"}`}
                >
                    <form method="POST" onSubmit={handleSubmit} className="col-span-8">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-3 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                            <p className="text-lg tracking-tight text-gray-900 dark:text-gray-200">Convert timezone to GMT</p>
                            <button type="button" className={` w-16 h-8 rounded-2xl ${isEnabled ? "bg-green-600" : ' bg-gray-400'} flex justify-between cursor-pointer p-1`}
                                onClick={() => { toggleSwitch()}}>
                                <div className={` w-6 h-6 rounded-xl bg-white ${isEnabled ? ' translate-x-8 transition-transform duration-200' : 'translate-x-0 transition-transform duration-200'}`}
                                />
                            </button>
                        </div>
                    </form>
                </ConfigSection>
                <ConfigSection
                    title={'Dark Mode Setting'}
                    description={`Selected Mode: ${darkModeEnabled}`}
                >
                    <form method="POST" onSubmit={handleSubmit} className="col-span-8">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-3 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                            <p className="text-lg tracking-tight text-gray-900 dark:text-gray-200">Dark Mode Theme</p>
                            <button type="button" className={`w-16 h-8 rounded-2xl ${darkModeEnabled ? "bg-green-600" : "bg-gray-400"} flex justify-between cursor-pointer p-1`} onClick={() => { toggleDrakModeSwitch()}}>
                                <div className={` w-6 h-6 rounded-xl bg-white ${darkModeEnabled ? ' translate-x-8 transition-transform duration-200' : 'translate-x-0 transition-transform duration-200'}`}
                                />
                            </button>
                        </div>
                        <div className="mt-10 flex justify-start">
                            <Button type="submit"
                                className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" text="Update" />
                            <Link to='/home'
                                className="block rounded-md bg-gray-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                        </div>
                    </form>
                </ConfigSection>
            </div>
        </PageWrapper>
    );
};

export default SystemSetting;