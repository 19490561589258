import React from 'react';
import PropTypes from 'prop-types';
const ConfigSection = ({ title, description, children }) => (
    <div className="py-6">
        <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
            <div className="col-span-4">
                <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">
                    {title}
                </h2>
                <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm capitalize">
                    {description}
                </p>
            </div>
            <div className="col-span-8">
                {children}
            </div>
        </div>
    </div>
);

ConfigSection.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default ConfigSection;
