import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Assuming you have FontAwesome installed
import PropTypes from 'prop-types';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
const Button = ({ type, onClick, icon, text, className, secondIcon, disabled, loader }) => {
    return (
        <button
            type={type}
            onClick={onClick}
            className={`flex items-center justify-center px-5 py-2 text-sm font-medium ${className}`}
            disabled={disabled}
        >
            {
                icon && <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={icon} />
            }
            <span>{text}</span>
            {
                secondIcon && <FontAwesomeIcon className='w-5 h-5 stroke-2' icon={secondIcon} />
            }
            {
                loader&& <FontAwesomeIcon className="w-5 h-5 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
            }
        </button>
    );
};

Button.propTypes = {
    type: PropTypes.any,
    onClick: PropTypes.any,
    icon: PropTypes.any,
    text: PropTypes.string,
    className: PropTypes.any,
    secondIcon: PropTypes.any,
    disabled: PropTypes.any,
    loader:PropTypes.any
}

export default Button;
