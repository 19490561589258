import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Button from '../button/Button';
import PropTypes from 'prop-types';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputField from '../form/input/InputField';

const DatePicker = ({
    isOpen,
    onClose,
    title,
    children,
    pTagData,
    btnOne,
    btnTwo,
    loader,
    onHandleConfirmDateModal,
    closeOnClickOutside = true,
}) => {
    const [isOpenState, setIsOpenState] = useState(isOpen);
    const [selectedDate, setSelectedDate] = useState(null);
    const timeZoneData = (localStorage.getItem('timeZone'));
    const timezoneText = timeZoneData === null || timeZoneData === "IST" ? "local" : timeZoneData;

    const handleDateChange = event => {
        const date = event.target.value;
        setSelectedDate(date);
    };

    useEffect(() => {
        setIsOpenState(isOpen);
    }, [isOpen]);

    const handleClose = () => {
        setIsOpenState(false);
        onClose();
    };

    const handleConfirmDate = () => {
        onHandleConfirmDateModal(selectedDate)
    }

    return (

        <Transition appear show={isOpenState} as={Fragment}>
            <Dialog static={!closeOnClickOutside} as="div" className="relative z-10" open={isOpenState} onClose={!closeOnClickOutside ? () => null : handleClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-800 p-6 text-left align-middle shadow-xl transition-all">
                                <div className='flex justify-between'>
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-100"
                                    >
                                        {title}

                                    </Dialog.Title>
                                    <button
                                        type="button"
                                        className="bg-gray-100 dark:bg-neutral-800 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={handleClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <hr className='my-3' />
                                <div>
                                    {children}
                                </div>
                                {pTagData && <div>
                                    <div className="mt-2">
                                        <div className="text-sm text-gray-900 dark:text-gray-200">
                                            <p>{pTagData}</p>
                                        </div>
                                    </div>
                                    <div className="mt-4 space-x-2 flex justify-start w-full">
                                        <div>
                                            <InputField
                                                type="datetime-local"
                                                value={selectedDate || ""}
                                                onChange={handleDateChange}
                                                className="border p-2 mb-4"
                                                helpDesk={`This message will be sent in ${timezoneText} time zone.`}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-4 space-x-2 flex justify-end">
                                        {loader ? <div>
                                            <Button type="button"
                                                className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                                text={btnOne} /> </div> :
                                            <div>
                                                <Button
                                                    type="button"
                                                    className={"inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"}
                                                    onClick={handleConfirmDate}
                                                    text={btnOne}
                                                />
                                            </div>}
                                        <div>
                                            <Button
                                                type='button'
                                                className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                                                text={btnTwo}
                                                onClick={handleClose}
                                            />
                                        </div>
                                        {loader && <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                                    </div>
                                </div>}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

DatePicker.propTypes = {
    isOpen: PropTypes.any,
    onClose: PropTypes.any,
    title: PropTypes.any,
    children: PropTypes.string,
    pTagData: PropTypes.any,
    btnOne: PropTypes.any,
    btnTwo: PropTypes.any,
    onHandleConfirmDateModal: PropTypes.any,
    closeOnClickOutside: PropTypes.any,
    loader: PropTypes.any
}
export default DatePicker;

