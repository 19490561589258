import PageWrapper from "../../../layouts/PageWrapper";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import React, { useEffect } from "react";
import Button from "../../../components/button/Button";
import SelectMultipleInput from "../../../components/form/input/SelectMultipleInput";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useGetUsersExcludedFromGroupMutation, useAddUserToGroupMutation, useGetGroupDetailsByIdMutation } from "../../../graphql/generated/graphql.ts";
import { toast } from "react-toastify";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import { Grid, Chip } from '@mui/material';

const AddUserToGroup = () => {
    const navigate = useNavigate();
    const groupId = useParams();
    const [getuserExcludedData, { data: userExcludedData, loading }] = useGetUsersExcludedFromGroupMutation();
    const [addUserToGroupMutation, { loading: userAddLoader }] = useAddUserToGroupMutation();
    const [getGroupDetailsByIdMutation, { data, error, loading: loader }] = useGetGroupDetailsByIdMutation({
        variables: {
            uniqueId: groupId?.groupId
        },
    });
    const { getUsersExcludedFromGroup } = userExcludedData || {};
    const userData = getUsersExcludedFromGroup?.map(group => ({
        value: group?.uniqueId,
        label: group?.uniqueName
    }));
    function handleNavigationError() {
        if (error) {
            navigate('/error', { state: { errorData: JSON.stringify(error) } });
        }
    }

    handleNavigationError();



    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Groups | Add | Users`;
        document.title = pageTitle;
        getGroupDetailsByIdMutation();
    }, [])



    useEffect(() => {
        getuserExcludedData({
            variables: {
                uniqueId: groupId?.groupId
            }
        })
    }, []);

    const initialValues = {
        user_name: null
    }

    const { values, errors, touched, setFieldValue, setFieldTouched,  handleSubmit, setErrors } = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: async value => {
            if (!value.user_name) {
                setErrors('userName')
                return;
            }
            try {
                const finalUserId = value?.user_name?.map(file => ({
                    userId: file.value,
                    groupId: groupId?.groupId || ''
                }))
                addUserToGroupMutation({ variables: { addUserToGroupArgsList: finalUserId } })
                    .then(() => {
                        toast.success("User added successfully.");
                        navigate(`/access-control/groups/${groupId?.groupId}`)
                    }).catch(userError => {
                        toast.error(userError.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                            autoClose: true,
                        });
                    })
            } catch (err) {
                toast.error(err?.message?.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
                // Handle error if needed
            }
        }
    })

    return (
        <PageWrapper heading={'Add Users To Group'}>
            <div className="py-6">
                <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                    <div className="col-span-4">
                        <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">General Information
                        </h2>
                        <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Selecte the user to add in group.</p>
                    </div>
                    <form method="POST" 
                    onSubmit={handleSubmit} 
                    className="col-span-8">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                            <div className="sm:col-span-2">
                                <SelectMultipleInput
                                    name="user_name"
                                    value={values.user_name}
                                    options={userData}
                                    onChange={selectedFolder => {
                                        setFieldValue('user_name', selectedFolder)
                                    }}
                                    onBlur={() => setFieldTouched('user_name', true)}
                                    label="Select Users"
                                    error={(errors === "user_name" && touched.user_name) ? "Please selcte at least one folder or document" : ''}
                                />
                            </div>
                            <Grid container spacing={2}>
                                {data?.getGroupDetailsById[0]?.users?.map((user, userIndex) => (
                                    <Grid item key={userIndex}>
                                        <Chip label={`${userIndex + 1}: ${user.uniqueName}`} />
                                    </Grid>
                                ))}
                            </Grid>

                        </div>
                        <div className="mt-10 flex justify-start">
                            {loading || userAddLoader || loader ? 
                            <Button type="button"
                                className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                text="Add" />
                                : 
                                <Button type="submit"
                                    className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    text="Add" />
                            }
                            <Link to='/access-control/groups'
                                className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                            {
                                userAddLoader || loader && 
                            <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                        </div>
                    </form>
                </div>
            </div>
        </PageWrapper>
    );
};

export default AddUserToGroup;
