import InputField from "../../../components/form/input/InputField";
import TextAreaInput from "../../../components/form/input/TextAreaInput";
import PageWrapper from "../../../layouts/PageWrapper";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useFormik } from "formik";
import { formCreateScheduleNotificationSchema } from "../../../validations/FormValidationSchema"
import {
    useGetNotificationDetailsByIdMutation, useGetAllUserInfoListQuery,
    useNotificationScheduleListCreateMutation, useIsPushNotificationEnabledQuery, useGetCurrentUserPermissionsQuery
} from '../../../graphql/generated/graphql.ts'
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/button/Button";
import SelectMultipleInput from "../../../components/form/input/SelectMultipleInput.js";
import React, { useEffect, useState } from "react";
import '../../../styles/Draft.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import CheckboxButton from "../../../components/form/input/CheckboxButton.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'

const ScheduleNotification = () => {
    const { notificationId } = useParams();
    const navigate = useNavigate();
    const timeZoneData = (localStorage.getItem('timeZone'));
    const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
    const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
    const permissionAPIList = permissionData?.getAllAPIPermissions
    const scheduleNotification = permissionAPIList?.filter(item => item.accessType === "SendPushNotification")
    // Helper function to determine push notification permission
    const hasPushNotificationPermission = (scheduleNotificationData, addPermissionData) => {
        if (!scheduleNotificationData?.length) {
            return false;
        }
        return scheduleNotificationData[0].assignedPermissions.some(val => addPermissionData.includes(val));
    };

    // Helper function to get timezone text
    const getTimezoneText = selectedTimeZoneData => !selectedTimeZoneData || selectedTimeZoneData === "IST" ? "local" : selectedTimeZoneData;
    // Usage of refactored functions
    const pushNotification = hasPushNotificationPermission(scheduleNotification, addPermission);
    const timezoneText = getTimezoneText(timeZoneData);
    const [getUserDetailsByIdMutation, { data: notificationDataByID, loading: loader, error }] = useGetNotificationDetailsByIdMutation({
        variables: {
            uniqueId: notificationId
        },
    });
    const { getNotificationDetailsById } = notificationDataByID || {}
    const { data: getUserData } = useGetAllUserInfoListQuery();
    const [createNotificationSchedule, { loading }] = useNotificationScheduleListCreateMutation();
    const { data: pushNotificationData, } = useIsPushNotificationEnabledQuery();

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Notifications | Schedule`;
        document.title = pageTitle;
    }, [])
    const [initialValues, setInitialValues] = useState({
        groupName: [],
        userName: [],
        isChekedBox: false,
        selectedTriggerDate: ""
    });
    const [userListFiltered, setUserListFiltered] = useState();
    const [userList, setUserList] = useState()
    const [selectedDate, setSelectedDate] = useState(false);
    const [isChekedBox, setIsChekedBox] = useState(false);
    function navigationError() {
        if (error) {
            navigate('/error', { state: { errorData: JSON.stringify(error) } });
        }
    }
    navigationError();
    useEffect(() => {
        getUserDetailsByIdMutation()
    }, [notificationId])

    useEffect(() => {
        if (notificationDataByID?.getNotificationDetailsById) {
            setInitialValues({
                ...initialValues,
                groupName: [],
                userName: [],
            });
        }
    }, [notificationDataByID?.getNotificationDetailsById]);

    const groupList = [];
    const getGroupInfo = getUserData?.getAllGroups;
    function getGroupDataInfo() {
        if (getGroupInfo) {
            getGroupInfo.forEach(function (item) {
                groupList.push({ label: item.groupName, value: item.id })
            });
        }
    }

    getGroupDataInfo();

    useEffect(() => {
        const userLists = [];
        const getUserInfo = getUserData?.getAllUserInfoList;
        const dataList = getUserInfo?.filter(val => !userListFiltered?.includes(val));
        if (dataList) {
            dataList.forEach(function (item) {
                userLists.push({ label: item.uniqueName, value: item.uniqueId })
            });
            setUserList(userLists)
        }
    }, [getUserData, userListFiltered])

    const handleGroupChange = e => {
        const groupFiltered = []
        const getUserInfo = getUserData?.getAllUserInfoList;
        e?.map(val => {
            const filteredData = getUserInfo.filter(item => item.groupIds.includes(val.value))
            groupFiltered.push(...filteredData)
        })
        setUserListFiltered(groupFiltered)
    }

    const { values, errors, touched, setFieldValue, setFieldTouched, handleBlur, handleSubmit, setErrors } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: formCreateScheduleNotificationSchema,
        onSubmit: value => {
            if (value.groupName.length === 0 && value.userName.length === 0) {
                setErrors('groupName')
                return;
            }
            const groupIDs = value?.groupName?.map(item => item.value)
            const userListIds = value?.userName?.map(id => id.value)
            const unixTimestamp = new Date(value?.selectedTriggerDate).getTime();
            const notificationScheduleCreate = [{
                notificationId,
                title: getNotificationDetailsById?.title,
                body: getNotificationDetailsById?.body,
                description: getNotificationDetailsById?.description,
                groupIds: groupIDs,
                userIds: userListIds,
                notifyAt: unixTimestamp,
                isPushNotification: isChekedBox,
            }]
            createNotificationSchedule({
                variables: {
                    notificationScheduleCreate
                }
            }).then(() => {
                toast.success("Notification schedule created successfully.")
                navigate(`/manage/notifications/${notificationId}`)
            }).catch(err => {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            })
        }
    })

    const onClickConfirm = event => {
        const date = event.target.value
        values.selectedTriggerDate = date;
        setSelectedDate(date)
    }

    const onClickChecked = e => {
        setIsChekedBox(e.target.checked);
    };


    return (
        <PageWrapper heading={'Schedule New Notification'}>
            {loader ? <div className="flex justify-center items-center h-screen">
                <td colSpan={5} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
                    <div>
                        <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                        <span>Loading Data</span>
                    </div>
                </td>
            </div> :
                <div className="py-6">
                    <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                        <div className="col-span-4">
                            <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">General Information
                            </h2>
                            <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Fill in the notification details</p>
                        </div>
                        <form method="POST" onSubmit={handleSubmit} className="col-span-8">
                            <div
                                className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                                <div className="sm:col-span-2">
                                    <InputField type={'text'} name={'title'} label={'Title'} value={getNotificationDetailsById?.title} disabled={true} onBlur={handleBlur} error={(errors.title && touched.title) ? errors.title : ''} />
                                </div>
                                <div className="sm:col-span-2">
                                    <TextAreaInput name={'description'} label={'Description'} value={getNotificationDetailsById?.description} disabled={true} rowSpan={3} onBlur={handleBlur} error={(errors.description && touched.description) ? errors.description : ''} />
                                </div>
                                <div className="sm:col-span-2">
                                    <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">Message Body</h3>
                                    <div className="prose w-full" dangerouslySetInnerHTML={{ __html: getNotificationDetailsById?.body }}>
                                    </div>
                                </div>
                                {getNotificationDetailsById?.dynamicResponse?.length > 0 && <div className="sm:col-span-2">
                                    <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200 mb-3">Response Options</h3>
                                    <dl className="md:px-8 divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                                        {getNotificationDetailsById?.dynamicResponse?.map(item => (
                                            <div key={item?.key} className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                                <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">{item.key}</dt>
                                                <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{item.value}</dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>}

                                <div className="sm:col-span-2">
                                    <SelectMultipleInput
                                        name="groupName"
                                        value={values?.groupName}
                                        options={groupList || []}
                                        onChange={selectedGroup => {
                                            setFieldValue('groupName', selectedGroup)
                                            handleGroupChange(selectedGroup)
                                        }}
                                        onBlur={() => setFieldTouched('groupName', true)}
                                        label="Select Group"
                                        error={(errors === "groupName" && touched.groupName) ? "Please selcte at least one group or name" : ''}
                                    />
                                </div>
                                <div className="sm:col-span-2">
                                    <SelectMultipleInput
                                        name="userName"
                                        value={values?.userName}
                                        options={userList}
                                        onChange={selectedRole => {
                                            setFieldValue('userName', selectedRole)
                                        }}
                                        onBlur={() => setFieldTouched('userName', true)}
                                        label="Select User"
                                    />
                                </div>
                                {pushNotificationData?.isPushNotificationEnabled && pushNotification && <div className="sm:col-span-2">
                                    <CheckboxButton
                                        label="Send as Push Notification"
                                        value={isChekedBox}
                                        checked={isChekedBox}
                                        disabled={!pushNotificationData?.isPushNotificationEnabled}
                                        required={false}
                                        onChange={onClickChecked}
                                        description="Push notification on user device through Airship."
                                    />
                                </div>}
                                <div className="sm:col-span-2">
                                    <InputField
                                        type="datetime-local"
                                        name="selectedTriggerDate"
                                        value={selectedDate || ""}
                                        onChange={onClickConfirm}
                                        className="border p-2 mb-4"
                                        required={true}
                                        label="Schedule"
                                        helpDesk={`This message will be sent in ${timezoneText} time zone.`}
                                    />
                                    {!selectedDate && (
                                        <p className=" text-red-600 font-semibold text-sm">{errors.selectedTriggerDate}</p>
                                    )}
                                </div>
                            </div>
                            <div className="mt-10 flex justify-start">
                                {loading ? <Button type="button"
                                    className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                    text="Create" />
                                    : <Button type="submit"
                                        className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        text="Create" />
                                }
                                <Link to="/manage/notifications"
                                    className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                                {loading && <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                            </div>
                        </form>
                    </div>
                </div>}
        </PageWrapper>
    )
};

export default ScheduleNotification;