import { Link, useLocation, useNavigate } from "react-router-dom";
import { BugAntIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React,{ useEffect } from "react";
const ErrorComponent = () => {
    const navigate = useNavigate();
     const location = useLocation();
     
  const errorData  = location.state!==null? location.state.errorData:JSON.stringify({"name":"NOERROR","graphQLErrors":[],"protocolErrors":[],"clientErrors":[],"networkError":{},"message":"Default Message"});
  const { message, name, graphQLErrors, networkError } =  JSON.parse(errorData);
  useEffect(() => {
    if (name ==='NOERROR'){
        navigate('/home');
    }
  }, []);

    return (
        <div className="mt-16 container justify-center mx-auto px-2 sm:px-20 md:px-32 lg:px-40 xl:40">
            <div className="mt-8 bg-white dark:bg-neutral-900 px-6 py-24 sm:py-32 lg:px-8 shadow">
                    <div className="text-center">
                    {graphQLErrors.length !==0 && <BugAntIcon className="w-32 h-32 mx-auto mb-8 dark:text-gray-400" />}
                    {graphQLErrors.length===0 && <ExclamationTriangleIcon className="w-32 h-32 mx-auto mb-8 dark:text-gray-400" />}
                        <p className="text-6xl font-bold text-red-600">{name}</p>
                        <h1 className="mt-4 mb-6 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-200 sm:text-5xl">{message}</h1>
                        { graphQLErrors.map(graphQLError => (
                            <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400" key={graphQLError?.id}>[GraphQL error]: Message: {graphQLError.message}, Location: {JSON.stringify(graphQLError.locations)}, Path: {graphQLError.path}</p>

                        ))}
                        {networkError && message.length && <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400">[Error]: Message: {message}</p> }
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <Link to="/"
                                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Go
                                back home</Link>
                            <Link to="/help" className="text-sm font-semibold text-gray-900 dark:text-gray-200">Contact support <span
                                    aria-hidden="true">&rarr;</span></Link>
                        </div>
                    </div>
                </div>

            </div>
    );
};

export default ErrorComponent;
// {"name":"ApolloError","graphQLErrors":[],"protocolErrors":[],"clientErrors":[],"networkError":{},"message":"Failed to fetch"}