import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faXmark, faTriangleExclamation, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const colorMap = {
  info: {
    color: 'bg-indigo-600',
    icon: faInfoCircle,
  },
  error: {
    color: 'bg-red-600',
    icon: faTriangleExclamation,
  },
  success: {
    color: 'bg-green-600',
    icon: faCircleCheck,
  },
  warn: {
    color: 'bg-red-600',
    icon: faTriangleExclamation,
  },
  // Add more color mappings as needed
};



const Banner = ({show = false, message="Something went Wrong", type = 'error'}) => {
  const [bannerShow, setBannerShow] = React.useState(show);
  const bannerType = colorMap[type] || colorMap.info;
  


  const hideBanner = () => {
    setBannerShow(false);
  };
  

  return (
    <div>
      {bannerShow &&  <div className={`mt-16 z-30 fixed w-full ${bannerType.color} `}>
        <div className="max-w-screen-xl mx-auto py-2 px-3 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center min-w-0">
              <div className={`flex p-2 rounded-lg ${bannerType.color} `}>
              <FontAwesomeIcon className="w-5 h-5 text-white" icon={bannerType.icon} />
            </div>

            <p className="ml-3 font-medium text-sm text-white truncate">
              {message}
            </p>
          </div>

          <div className="shrink-0 sm:ml-3">
            <button
              type="button"
              className="-mr-1 flex p-2 rounded-md focus:outline-none sm:-mr-2 transition"
              onClick={hideBanner}
            aria-label="Dismiss"
                        >
            <FontAwesomeIcon className="w-5 h-5 text-white" icon={faXmark} />
          </button>
        </div>
      </div>
    </div>
        </div >}
    </div >
  );
};
Banner.propTypes = {
  show: PropTypes.any,
  message: PropTypes.any,
  type: PropTypes.any,
}

export default Banner;
