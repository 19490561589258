import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownShortWide, faArrowUpWideShort, faPlus, faTrashCan, } from '@fortawesome/free-solid-svg-icons'
import PageWrapper from "../../../layouts/PageWrapper";
import TableWrapper from "../../../components/table/TableWrapper";
import TableContainer from "../../../components/table/TableContainer";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound";
import TableHeadingSection from "../../../components/table/TableHeadingSection";
import TableButtonSection from "../../../components/table/TableButtonSection";
import { useGetAllStaticPageMutation, useGetCurrentUserPermissionsQuery, useDeleteStaticPageMutation } from "../../../graphql/generated/graphql.ts";
import Button from '../../../components/button/Button';
import { formatTimestamp } from "../../../utils/commonFormatFunctions.js";
import ActionButton from "../../../components/button/ActionButton.js";
import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import TableLoadingData from "../../../components/table/TableLoadingData.js";
import DialogModal from "../../../components/modal/DialogModal.js";
import { toast } from "react-toastify";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import Pagination from "../../../components/table/Pagination.js";



const Index = () => {
    const navigate = useNavigate();
    const [userPageMutation, { data, error, loading }] = useGetAllStaticPageMutation();
    const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
    const [deleteStaticPageMutation, { loading: deleteLoder }] = useDeleteStaticPageMutation();
    const { pageInfo } = data?.getAllStaticPage || {};
    const [sortedColumn, setSortedColumn] = useState('title');
    const [sortOrder, setSortOrder] = useState('ASC');
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [deletedItem, setDeletedItem] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [recordsPerPageData, setRecordsPerPageData] = useState(pageInfo?.per_page);
    const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
    const permissionAPIList = permissionData?.getAllAPIPermissions
    const createUserInfo = permissionAPIList?.filter(item => item.accessType === "CreateStaticPage")
    const deleteUserInfo = permissionAPIList?.filter(item => item.accessType === "DeleteStaticPage")
    const updateUserInfo = permissionAPIList?.filter(item => item.accessType === "UpdateStaticPage")
    const editUserPermission = updateUserInfo?.length && updateUserInfo[0].assignedPermissions.some(val => addPermission.includes(val));
    const addUserPermission = createUserInfo?.length && createUserInfo[0].assignedPermissions.some(val => addPermission.includes(val));
    const deleteUserPermission = deleteUserInfo?.length && deleteUserInfo[0].assignedPermissions.some(val => addPermission.includes(val));
    const maxPageNumber = 5;
    const defaultPageNumber = 1;

    function handleNavigationError() {
        if (error) {
            navigate('/error', { state: { errorData: JSON.stringify(error) } });
        }
    }
    // Call the function to handle navigation error
    handleNavigationError();

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Static Pages`;
        document.title = pageTitle;
    }, []);

    useEffect(() => {
        if (sortOrder && sortedColumn) {
            getStaticPageData(defaultPageNumber, data?.getAllStaticPage?.pageInfo?.per_page);
        }
    }, [sortOrder, sortedColumn]);

    useEffect(() => {
        {/* NOSONAR */ }
        const delayTime = 1000;
        if (searchQuery) {
            const delayDebounceFn = setTimeout(() => {
                // Make API request with the current value
                getStaticPageData(defaultPageNumber, pageInfo?.per_page, searchQuery);
            }, delayTime);
            return () => {
                clearTimeout(delayDebounceFn);
            };
        }
    }, [searchQuery]);

    const getStaticPageData = (page = defaultPageNumber, row = data?.getAllStaticPage?.pageInfo?.per_page, searchTerm = searchQuery) => {
        setDeletedItem([])
        setSelectedItems([]); setSelectAll(false)
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term: searchTerm,
            search_column: "",
            order_by: sortOrder,
            order_by_column: sortedColumn,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        };

        userPageMutation({ variables: { PageInfoInput } })
            .catch(er => {
                toast.error(er.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    };



    const handleSort = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrder);
        setSortedColumn(column);
    };

    const openModal = () => {
        if (selectedItems.length) {
            setIsOpen(!isOpen)
        }
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(data?.getAllStaticPage?.staticPageList?.map(item => item.id));
        }
        setSelectAll(prev => !prev);
    };

    //handle checkbox
    const handleCheckboxChange = itemId => {
        if (selectedItems.includes(itemId)) {
            setSelectedItems(selectedItems.filter(id => id !== itemId));
        } else {
            setSelectedItems([...selectedItems, itemId]);
        }
    };

    const handleSearchChange = e => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm);
    };

    const handleClearSearch = () => {
        setSearchQuery("");
        getStaticPageData(defaultPageNumber, data?.getAllStaticPage?.pageInfo?.per_page, "");
    };


    const handlePageChange = pageNumber => {
        getStaticPageData(pageNumber, data?.getAllStaticPage?.pageInfo?.per_page);
    };

    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getStaticPageData(defaultPageNumber, rowValue);
    };

    const handleDelete = deleteId => {
        setDeletedItem([deleteId]);
        setIsOpen(!isOpen)
    }

    //handle close of delete modal
    const closeModal = () => {
        setIsOpen(!isOpen)
        setDeletedItem([])
        setSelectedItems([])
    };

    //handle document deletion. 
    const onClickDelete = selectedPage => {
        deleteStaticPageMutation({ variables: { ids: selectedPage } })
            .then(() => {
                toast.success('Selected page deleted successfully.')
                getStaticPageData();
                closeModal();
            }).catch(err => {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            })
    }

    const tableButtonSectionContent = () => {
        return (
            <TableButtonSection searchQuery={searchQuery} onSearchChange={handleSearchChange} onClearSearch={handleClearSearch}>
                {addUserPermission && <Link to='/manage/static-pages/add' className={"flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-indigo-500 rounded-lg shrink-0 gap-x-2 hover:bg-indigo-600 dark:bg-indigo-600"}>
                    <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faPlus} />
                    <span>Add</span>
                </Link>}
                <div>
                    {deleteUserPermission && selectedItems.length > 0 && <Button
                        type="button"
                        onClick={openModal}
                        className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-red-500 rounded-lg shrink-0 gap-x-2 hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600"
                        icon={faTrashCan}
                        text="Delete"
                    />}
                </div>
            </TableButtonSection>
        )
    }

    return (
        <>
            <PageWrapper heading={'Manage Static Pages'}>
                <TableWrapper>
                    <TableHeadingSection heading={'Static Pages'} description={'All the active pages in the database.'} count={pageInfo?.total_records} />
                    {tableButtonSectionContent()}
                    <TableContainer>
                        <thead className="bg-gray-50 dark:bg-neutral-800">
                            <tr>
                                <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold">
                                    <div className="flex h-6 items-center">
                                        <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('title')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Title</span>
                                        {sortedColumn === "title" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>

                                <th scope="col" onClick={() => handleSort('slug')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Slug</span>
                                        {sortedColumn === "slug" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>

                                <th scope="col" onClick={() => handleSort('created_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Created On</span>
                                        {sortedColumn === "created_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('updated_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Updated On</span>
                                        {sortedColumn === "updated_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>

                                <th scope="col" className="px-4 py-3.5 text-sm text-center rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                            {data?.getAllStaticPage?.staticPageList?.map(staticPage => (
                                <tr key={staticPage?.id}>
                                    <td className="px-4 py-3 text-sm font-medium">
                                        <div className="flex h-6 items-center">
                                            <input type="checkbox" checked={selectedItems.includes(staticPage.id)} onChange={() => handleCheckboxChange(staticPage?.id)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                        <div>
                                            <Link to={`/pages/${staticPage?.slug}`} className="font-semibold text-indigo-600 dark:text-indigo-400 ">{staticPage?.title}</Link>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="w-40">
                                            <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{staticPage.slug}</h2>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="w-40">
                                            <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{formatTimestamp(staticPage.created_at)}</h2>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="w-40">
                                            <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{formatTimestamp(staticPage.updated_at)}</h2>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="flex item-center justify-center text-gray-700 dark:text-gray-200">
                                            <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" title="View" icon={EyeIcon}
                                                onClick={() => { navigate(`/pages/${staticPage?.slug}`) }} />
                                            {editUserPermission ? <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" icon={PencilIcon}
                                                onClick={() => { navigate(`/manage/static-pages/edit/${staticPage?.id}`, { state: { staticPage } }) }} title="Edit" />
                                                : <ActionButton className="w-4 mr-2 transform text-gray-500" icon={PencilIcon} title="Edit" />}
                                            {deleteUserPermission ? <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                icon={TrashIcon} title="Delete"
                                                onClick={() => { handleDelete(staticPage?.id) }}
                                            />
                                                : <ActionButton className="w-4 mr-2 transform text-gray-500"
                                                    icon={TrashIcon} title="Delete" />}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {!data?.getAllStaticPage?.staticPageList.length > 0 && !loading && <TableNoRecordFound colSpan={12}></TableNoRecordFound>}
                            {loading && <TableLoadingData colSpan={12}></TableLoadingData>}
                        </tbody>
                    </TableContainer>
                </TableWrapper>
                <Pagination totalPages={pageInfo?.total_pages ? pageInfo?.total_pages : 0} totalRecords={pageInfo?.total_records || 0}
                    maxPageNumbers={maxPageNumber}
                    recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0}
                    currentPage={pageInfo?.current_page ? pageInfo?.current_page : 0} onPageChange={handlePageChange} onRowChanges={handleRowChanges} ></Pagination>
            </PageWrapper>
            <DialogModal
                isOpen={isOpen}
                onClose={closeModal}
                title="Confirm Deletion"
                closeOnClickOutside={false} // Set this prop as needed
                p pTagData={`Do you confirm to delete ${deletedItem?.length || selectedItems?.length} static page?`}
                btnOne="Confirm Delete"
                btnTwo="Cancel"
                loader={deleteLoder}
                onClickDeleteModal={() => { onClickDelete(deletedItem.length > 0 ? deletedItem : selectedItems) }}>
            </DialogModal>
        </>
    );
};

export default Index;