import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import React from 'react';
import PropTypes from 'prop-types';


const ContentEditor = ({ label, name, editorState = "", value, error, required, onEditorStateChange, toolbarHidden }) => {
  return (
    <>
      {label &&
        <label htmlFor={name} className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">{label}
          {required && <span className="text-red-600 dark:text-red-400 ml-1">*</span>}
        </label>
      }
      <div className="mt-2.5">
        {/* <input type={type} value={value} name={name} id={name} onChange={onChange} onBlur={onBlur} title={label} autoComplete="off" placeholder={placeholder} className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-200 dark:bg-neutral-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 focus:outline-none" />
         */}

        <div className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900  dark:text-gray-200 dark:bg-neutral-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 ">
          <Editor name={name} id={name} toolbarHidden={toolbarHidden} editorState={editorState} value={value} onEditorStateChange={onEditorStateChange} toolbarClassName="dark:bg-neutral-800 text-gray-900 text-sm" editorClassName="dark:text-white prose max-w-none" editorStyle={{ fontFamily: 'Arial' }}/>
        </div>

        {error && <span className="text-sm text-red-600 dark:text-red-400  font-semibold">{error}</span>}
      </div>
    </>
  );
};

ContentEditor.propTypes = {
  label: PropTypes.any,
  name: PropTypes.any,
  editorState: PropTypes.any,
  value: PropTypes.any,
  error: PropTypes.any,
  required: PropTypes.any,
  onEditorStateChange: PropTypes.any,
  toolbarHidden: PropTypes.bool
}

export default ContentEditor;
