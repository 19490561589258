import InputField from "../../../components/form/input/InputField.js";
import PageWrapper from "../../../layouts/PageWrapper.js";
import { useFormik } from "formik";
import { formCreateNewFolderSchema } from "../../../validations/FormValidationSchema.js";
import { useUpdateDocumentsFolderMutation } from '../../../graphql/generated/graphql.ts'
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/button/Button.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'

const EditFoldersDetails = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { display_name: folder_name = '', storage_path: folder_path = '', } = state?.folder || {};
    const initialValues = { folder_name, folder_path, };
    const [updateSection, { data: updateDocumentsFolder, loading }] = useUpdateDocumentsFolderMutation();

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Folders | Edit | ${values?.folder_name}`;
        document.title = pageTitle;
    }, [])

    useEffect(() => {
        if (updateDocumentsFolder) {
            navigate(`/manage/folders/${updateDocumentsFolder?.updateDocumentsFolder?.id}`)
        }
    }, [updateDocumentsFolder]) //NOSONAR

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: formCreateNewFolderSchema,
        onSubmit: (value, action) => {
            const sectionData = {
                display_name: value?.folder_name.trim(),
                storage_path: value?.folder_path?.trim(),
                notify_user: false,
                id: state?.folder?.id,
            };
            updateSection({ variables: { ...sectionData } })
                .then(() => {//NOSONAR
                    toast.success(`Folder updated successfully.`);
                    action.resetForm();
                }).catch(error => {
                    toast.error(error.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                        autoClose: false,
                    });
                })
        }
    });

    const handleFolderNameChange = e => {
        const newName = e.target.value;
        setFieldValue('folder_name', newName);
    };

    return (
        <PageWrapper heading={'Edit Folder'}>
            <div className="py-6">
                <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                    <div className="col-span-4">
                        <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">General Information
                        </h2>
                        <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Fill in the folder details</p>
                    </div>
                    <form method="POST" onSubmit={handleSubmit} className="col-span-8">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                            <div className="sm:col-span-2">
                                <InputField type={'text'} name={'folder_name'} label={'Folder Name'} value={values.folder_name} onChange={handleChange} onBlur={handleBlur} onInput={handleFolderNameChange} error={(errors.folder_name && touched.folder_name) ? errors.folder_name : ''} />
                            </div>
                            <div className="sm:col-span-2">
                                <InputField type={'text'} disabled={true} name={'folder_path'} label={'Folder Path'} value={values.folder_path} onChange={handleChange} onBlur={handleBlur} placeholder="/folder-path" error={errors.folder_path && touched.folder_path ? errors.folder_path : ''} />
                            </div>
                        </div>
                        <div className="mt-10 flex justify-start">
                            {loading ? <Button type="button"
                                className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                text="Update" />
                                : <Button type="submit"
                                    className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    text="Update" />
                            }
                            <Link to="/manage/folders"
                                className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                            {loading && <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                        </div>
                    </form>
                </div>
            </div>
        </PageWrapper>
    );
};

export default EditFoldersDetails;
