import React, { useState } from "react";
import VideoThumbnail from "../components/thumbnail/VideoThumbnail";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Test = () => {
    const [videoUrl, setVideoUrl] = useState('');
    const [thumbnailBase64, setThumbnailBase64] = useState('');
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoSize, setVideoSize] = useState(0);

  const handleVideoUrlChange = event => {
    setVideoUrl(event.target.value);
  };

  const handleThumbnailBase64Change = thumbnailBase => {
    setThumbnailBase64(thumbnailBase);
  };

  const handleVideoDurationChange = vidDuration => {
    setVideoDuration(vidDuration);
  };

  const handleVideoSizeChange = vidSize => {
    setVideoSize(vidSize);
  };

  const handleButtonClick = () => {
    // Perform any additional logic if needed before rerendering.
    // For example, you might want to validate the video URL here.

    // Rerender the component by updating the videoUrl state.
    // setVideoUrl(videoUrl);
  };
    return (
        <div className="mt-16 w-full max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mb-6">
            <div>
                <div className="py-6">
                    <h2 className="text-2xl font-bold dark:text-gray-100">Video Thumbnail</h2>
                    <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Video thumnail and duration testing</p>
                </div>
            </div>
            <div>
            <div className="py-2">
            { videoUrl &&
                    <VideoThumbnail videoUrl={videoUrl} 
                    onThumbnailBase64Change={handleThumbnailBase64Change}
                    onVideoDurationChange={handleVideoDurationChange}
                    onVideoSizeChange={handleVideoSizeChange}
                    width={256} height={144} />
                    }

            <form action="#" method="POST" className="col-span-8">
              <div
                className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                <div className="sm:col-span-2">
                  <label htmlFor="videoUrl"
                    className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">Video Url<span
                      className="text-red-600 dark:text-red-400 ml-1">*</span></label>
                  <div className="mt-2.5">
                    <input value={videoUrl} onChange={handleVideoUrlChange} type="text" name="videoUrl" id="videoUrl" autoComplete placeholder="/path-to-video-url"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-200 dark:bg-neutral-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 focus:outline-none" />
                  </div>
                </div>
                {videoSize ===0 && videoUrl!=='' && <div className="sm:col-span-2">
                <div className="flex flex-row justify-center items-center w-full">
                    <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                        <span>Generating video metadata</span>
                </div>
                </div>}
                {videoDuration!==0 &&
                <div className="sm:col-span-2">
                  <label htmlFor="videoDuration"
                    className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">Video Duration - Milliseconds<span
                      className="text-red-600 dark:text-red-400 ml-1">*</span></label>
                  <div className="mt-2.5">
                    <input value={videoDuration} type="text" name="videoDuration" id="videoDuration" autoComplete readOnly
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-200 dark:bg-neutral-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 focus:outline-none" />
                  </div>
                </div>
                }
                {videoSize!==0 &&
                <div className="sm:col-span-2">
                  <label htmlFor="videoSize"
                    className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">Video Size - Bytes<span
                      className="text-red-600 dark:text-red-400 ml-1">*</span></label>
                  <div className="mt-2.5">
                  <input value={videoSize} type="text" name="videoSize" id="videoSize" autoComplete readOnly
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-200 dark:bg-neutral-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 focus:outline-none" />
                  </div>
                </div>
                }
                {thumbnailBase64!=='' &&
                <div className="sm:col-span-2">
                  <label htmlFor="thumbnailBase64"
                    className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">Thumbnail Base64</label>
                  <div className="mt-2.5">
                    <textarea name="thumbnailBase64" id="thumbnailBase64" value={thumbnailBase64} rows="4" readOnly
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900  dark:text-gray-200 dark:bg-neutral-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "></textarea>
                  </div>
                </div>
                }
              </div>
              <div className="mt-10 flex justify-start">
                <button type="button" onClick={handleButtonClick}
                  className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Generate</button>
              </div>
            </form>
                </div>
            </div>
        </div>
    );
};

export default Test;
