import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { ArrowDownTrayIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownShortWide, faArrowUpWideShort, faPlus, faTrashCan, } from '@fortawesome/free-solid-svg-icons'
import PageWrapper from "../../../layouts/PageWrapper";
import Pagination from "../../../components/table/Pagination";
import TableWrapper from "../../../components/table/TableWrapper";
import TableContainer from "../../../components/table/TableContainer";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound";
import TableLoadingData from "../../../components/table/TableLoadingData";
import TableHeadingSection from "../../../components/table/TableHeadingSection";
import TableButtonSection from "../../../components/table/TableButtonSection";
import {
    useDeleteBulkFileMetadataByUrlMutation, useUpdateThumbnailAndDurationMutation,
    useGetCurrentUserPermissionsQuery, useGetVideosMetadataForAdminPagesMutation
} from "../../../graphql/generated/graphql.ts";
import { formatTimestamp, formatSize, formatTime } from '../../../utils/commonFormatFunctions.js'
import DialogModal from "../../../components/modal/DialogModal";
import Button from "../../../components/button/Button";
import { toast } from "react-toastify";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import ActionButton from "../../../components/button/ActionButton.js";
import axios from "axios";

const Index = () => {
    const navigate = useNavigate();
    const [videoInfoMutation, { data, loading, error }] = useGetVideosMetadataForAdminPagesMutation();
    const [deleteVideoInBulkByUrl, { loading: loader }] = useDeleteBulkFileMetadataByUrlMutation();
    const [addThumbnailAndDuration,] = useUpdateThumbnailAndDurationMutation();
    const { videoMetadataList, pageInfo } = data?.getVideosMetadataForAdminPages || {};
    const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
    const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
    const permissionAPIList = permissionData?.getAllAPIPermissions
    const createDocuments = permissionAPIList?.filter(item => item.accessType === "CreateDocuments")
    const deleteDocuments = permissionAPIList?.filter(item => item.accessType === "DeleteDocuments")
    const updateDocuments = permissionAPIList?.filter(item => item.accessType === "UpdateDocuments")
    const editUserPermission = updateDocuments?.length && updateDocuments[0].assignedPermissions.some(val => addPermission.includes(val));
    const addUserPermission = createDocuments?.length && createDocuments[0].assignedPermissions.some(val => addPermission.includes(val));
    const deleteUserPermission = deleteDocuments?.length && deleteDocuments[0].assignedPermissions.some(val => addPermission.includes(val));
    const [searchQuery, setSearchQuery] = useState('');
    const [sortedColumn, setSortedColumn] = useState('file_name');
    const [sortOrder, setSortOrder] = useState('ASC');
    const [deletedItem, setDeletedItem] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [recordsPerPageData, setRecordsPerPageData] = useState(pageInfo?.per_page)
    const maxPageNumber = 5;
    const defaultPageNumber = 1;


    if (error) {
        navigate('/error', { state: { errorData: JSON.stringify(error) } });
    }

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Videos`;
        document.title = pageTitle;
    }, [data]);

    useEffect(() => {
        if(sortOrder && sortedColumn) {
            getVideoData(defaultPageNumber, pageInfo?.per_page);
        }
    }, [sortOrder, sortedColumn]);

    useEffect(() => {
        const delayTime = 1000;
        if (searchQuery) {
            const delayDebounceFn = setTimeout(() => {
                // Make API request with the current value
                getVideoData(defaultPageNumber, pageInfo?.per_page, searchQuery);
            }, delayTime);
            return () => {
                clearTimeout(delayDebounceFn);
            };
        }
    }, [searchQuery]);

    const getVideoData = (page = defaultPageNumber, row = pageInfo?.per_page, search_term = searchQuery) => {
        setDeletedItem([])
        setSelectedItems([]); setSelectAll(false)
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term,
            search_column: "",
            order_by: sortOrder,
            order_by_column: sortedColumn,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        };

        videoInfoMutation({ variables: { PageInfoInput } })
            .catch(er => {
                toast.error(er.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    };

    const generateThumbnails = async () => {
        const timeDuration = 1000;
        const thumbnailData = videoMetadataList?.filter(item => item.is_video === true && !item?.thumbnail);

        if (!thumbnailData || thumbnailData.length === 0) {
            return;
        }

        const createThumbnail = async item => {
            const video = document.createElement('video');
            video.src = item?.downloadURL;
            video.crossOrigin = 'anonymous';

            await new Promise(resolve => video.addEventListener('loadedmetadata', resolve));
            await video.play();

            const canvas = document.createElement('canvas');
            canvas.width = 256;
            canvas.height = 144;

            const context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            const thumbnail = canvas.toDataURL('image/jpeg');
            const duration = Math.round(video.duration * timeDuration);

            video.pause();
            video.removeAttribute('src');
            video.load();

            return {
                file_url: item?.fileURL || null,
                duration,
                thumbnail,
            };
        };

        try {
            const videoDataPromises = thumbnailData.map(createThumbnail);
            const videoDataList = await Promise.all(videoDataPromises);

            await addThumbnailAndDuration({ variables: { thumbnailAndDurationUpdateList: videoDataList } });
            toast.success('Thumbnail updated successfully.');
            getVideoData(defaultPageNumber, pageInfo?.per_page);
        } catch (e) {
            toast.error(e.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                autoClose: false,
            });
        }
    };

    useEffect(() => {
        generateThumbnails();
    }, [videoMetadataList]);



    // Handle search input change
    const handleSearchChange = e => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm);
    };

    // Handle column header click for sorting
    const handleSort = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrder);
        setSortedColumn(column);
    };

    // Handle pagination link click
    const handlePageChange = pageNumber => {
        getVideoData(pageNumber, pageInfo?.per_page);
    };

    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getVideoData(defaultPageNumber, rowValue);
    };

    const handleClearSearch = () => {
        setSearchQuery("");
        getVideoData(defaultPageNumber, pageInfo?.per_page, "");
    };

    const openModal = () => {
        if (selectedItems.length) {
            setIsOpen(true)
        }
    };

    const closeModal = () => {
        setIsOpen(false)
        setDeletedItem([])
        setSelectedItems([])
    };


    const handleCheckboxChange = itemId => {
        if (selectedItems.includes(itemId)) {
            setSelectedItems(selectedItems.filter(id => id !== itemId));
        } else {
            setSelectedItems([...selectedItems, itemId]);
        }
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(videoMetadataList?.map(item => item.fileURL));
        }
        setSelectAll(!selectAll);
    };

    //handle download video
    const handleDownloadVideo = async r => {
        try {
            const response = await axios.get(r.downloadURL, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = `${r?.fileName}.mp4`;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (errData) {
            toast.error(`'Error downloading file:', ${errData}`, {
                autoClose: false,
            });
        }
    }
    const handleDelete = fileUrlData => {
        setDeletedItem([fileUrlData])
        setIsOpen(!isOpen)
    }

    const onClickDelete = selectedItem => {
        deleteVideoInBulkByUrl({ variables: { fileUrls: selectedItem } })
            .then(() => {
                toast.success('Selected video deleted successfully.')
                closeModal();
                getVideoData();
            }).catch(err => {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            })
    }

    return (
        <>
            <PageWrapper heading={'Manage Videos'}>
                <TableWrapper>
                    <TableHeadingSection heading={'Videos'} description={'All the active videos in the database.'} count={pageInfo?.total_records} />
                    <TableButtonSection searchQuery={searchQuery} onSearchChange={handleSearchChange} onClearSearch={handleClearSearch}>
                        {addUserPermission && <Link to='/manage/videos/new' className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-indigo-500 rounded-lg shrink-0 gap-x-2 hover:bg-indigo-600 dark:hover:bg-indigo-500 dark:bg-indigo-600">
                            <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faPlus} />
                            <span>Add</span>
                        </Link>}
                        {deleteUserPermission && selectedItems.length > 0 &&
                            <Button type="button" onClick={openModal} className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-red-500 rounded-lg shrink-0 gap-x-2 hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600"
                                icon={faTrashCan} text="Delete" />
                        }
                    </TableButtonSection>
                    <TableContainer>
                        <thead className="bg-gray-50 dark:bg-neutral-800">
                            <tr>
                                <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold">
                                    <div className="flex h-6 items-center">
                                        <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    </div>
                                </th>
                                <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold">
                                    <div className="flex h-6 items-center">
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('file_name')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Video Name</span>

                                        {sortedColumn === "file_name" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('file_bytes')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>File Size</span>
                                        {sortedColumn === "file_bytes" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('auto_download')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Critical</span>
                                        {sortedColumn === "auto_download" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('duration')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Duration</span>
                                        {sortedColumn === "duration" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('created_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Created On</span>
                                        {sortedColumn === "created_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('updated_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Updated On</span>
                                        {sortedColumn === "updated_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" className="px-4 py-3.5 text-sm text-center rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                            {videoMetadataList?.map(video => (
                                <tr key={video?.id}>
                                    <td className="px-4 py-3 text-sm font-medium">
                                        <div className="flex h-6 items-center">
                                            <input type="checkbox" checked={selectedItems.includes(video.fileURL)} onChange={() => handleCheckboxChange(video.fileURL)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                        <div className=" w-12">
                                            <img className=" w-14" src={video.thumbnail} alt={video.fileName} />
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm font-medium whitespace-pre-line">
                                        <div className="w-full">
                                            <p className="font-semibold text-indigo-600 dark:text-indigo-400 text-ellipsis overflow-hidden line-clamp-2">{video.fileName}</p>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{formatSize(video.file_bytes)}</h2>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{video?.auto_download ? "Yes" : "No"}</h2>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h2 className="text-gray-700 dark:text-gray-200">{formatTime(video.duration)}</h2>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(video.created_at)}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(video.updated_at)}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="flex item-center justify-center text-gray-700 dark:text-gray-200">
                                            <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                onClick={() => { handleDownloadVideo(video) }}
                                                icon={ArrowDownTrayIcon} title="Download" />
                                            {editUserPermission ? <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                icon={PencilIcon} onClick={() => { navigate('/manage/videos/edit', { state: { videoDetails: video, folderData: data?.getAllDocumentsFolders } }) }}
                                                title="Edit" />
                                                : <ActionButton className="w-4 mr-2 transform text-gray-500" icon={PencilIcon} title="Edit" />
                                            }
                                            {deleteUserPermission ?
                                                <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" icon={TrashIcon} title="Delete"
                                                    onClick={() => { handleDelete(video?.fileURL) }} />
                                                : <ActionButton className="w-4 mr-2 transform text-gray-500"
                                                    icon={TrashIcon} title="Edit" />}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {!videoMetadataList?.length && !loading && <TableNoRecordFound colSpan={10}></TableNoRecordFound>}
                            {loading && <TableLoadingData colSpan={10}></TableLoadingData>}
                        </tbody>
                    </TableContainer>
                </TableWrapper>
                <Pagination totalPages={loading ? 0 : pageInfo?.total_pages} totalRecords={loading ? 0 : pageInfo?.total_records}
                    maxPageNumbers={maxPageNumber}
                    recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0}
                    currentPage={loading ? 0 : pageInfo?.current_page} onPageChange={handlePageChange} onRowChanges={handleRowChanges} ></Pagination>
            </PageWrapper>
            <DialogModal
                isOpen={isOpen}
                onClose={closeModal}
                title="Confirm Deletion"
                closeOnClickOutside={false} // Set this prop as needed
                pTagData={`Do you confirm to delete ${deletedItem?.length || selectedItems?.length} videos?`}
                btnOne="Confirm Delete"
                btnTwo="Cancel"
                loader={loader}
                onClickDeleteModal={() => { onClickDelete(deletedItem.length > 0 ? deletedItem : selectedItems) }}>
            </DialogModal>
        </>
    );
};

export default Index;
