import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownShortWide, faArrowUpWideShort, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import PageWrapper from "../../../layouts/PageWrapper";
import Pagination from "../../../components/table/Pagination";
import TableWrapper from "../../../components/table/TableWrapper";
import TableContainer from "../../../components/table/TableContainer";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound";
import TableLoadingData from "../../../components/table/TableLoadingData";
import TableHeadingSection from "../../../components/table/TableHeadingSection";
import TableButtonSection from "../../../components/table/TableButtonSection";
import { useDeleteDocumentsFolderMutation, useGetAllFoldersMutation, useGetCurrentUserPermissionsQuery } from "../../../graphql/generated/graphql.ts";
import { formatTimestamp } from '../../../utils/commonFormatFunctions.js'
import DialogModal from "../../../components/modal/DialogModal";
import { toast } from "react-toastify";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import ActionButton from "../../../components/button/ActionButton.js";



const Index = () => {
    const navigate = useNavigate();
    const [deleteSection, { loading: loader }] = useDeleteDocumentsFolderMutation()
    const [folderInfoMutation, { loading, error, data }] = useGetAllFoldersMutation()
    const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
    const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
    const permissionAPIList = permissionData?.getAllAPIPermissions
    const createSections = permissionAPIList?.filter(item => item.accessType === "CreateSections")
    const deleteSections = permissionAPIList?.filter(item => item.accessType === "DeleteSections")
    const updateSections = permissionAPIList?.filter(item => item.accessType === "UpdateSections")
    const editUserPermission = updateSections?.length && updateSections[0].assignedPermissions.some(val => addPermission.includes(val));
    const addUserPermission = createSections?.length && createSections[0].assignedPermissions.some(val => addPermission.includes(val));
    const deleteUserPermission = deleteSections?.length && deleteSections[0].assignedPermissions.some(val => addPermission.includes(val));
    const { pageInfo, folderList } = data?.getAllFolders || {};
    const [searchQuery, setSearchQuery] = useState('');
    const [sortedColumn, setSortedColumn] = useState('display_name');
    const [sortOrder, setSortOrder] = useState('ASC');
    const [isOpen, setIsOpen] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [deletedItem, setDeletedItem] = useState([]);
    const [recordsPerPageData, setRecordsPerPageData] = useState(pageInfo?.per_page);
    const maxPageNumber = 5
    const defaultPageNumber = 1;

    if (error) {
        navigate('/error', { state: { errorData: JSON.stringify(error) } });
    }

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Folders`;
        document.title = pageTitle;
    }, []);

    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getFolderData(defaultPageNumber, rowValue);
    };

    useEffect(() => {
        if(sortOrder && sortedColumn){
            getFolderData(defaultPageNumber, pageInfo?.per_page);
        }
    }, [sortOrder, sortedColumn]);

    const getFolderData = (page = defaultPageNumber, row = pageInfo?.per_page, search_term = searchQuery) => {
        setDeletedItem([])
        setSelectedItems([]); setSelectAll(false)
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term,
            search_column: "",
            order_by: sortOrder,
            order_by_column: sortedColumn,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        };
        folderInfoMutation({ variables: { PageInfoInput } })
            .catch(e => {
                toast.error(e.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    };

    useEffect(() => {
        const delayTime = 1000;
        if( searchQuery) {
            const delayDebounceFn = setTimeout(() => {
                // Make API request with the current value
                getFolderData(defaultPageNumber, pageInfo?.per_page, searchQuery);
            }, delayTime);
            return () => {
                clearTimeout(delayDebounceFn);
            };
        }

    }, [searchQuery]);

    const handleClearSearch = () => {
        setSearchQuery("");
        getFolderData(defaultPageNumber, pageInfo?.per_page, "");
    };


    // Handle search input change
    const handleSearchChange = e => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm);
    };

    // Handle column header click for sorting
    const handleSort = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrder);
        setSortedColumn(column);
    };

    // Handle pagination link click
    const handlePageChange = pageNumber => {
        getFolderData(pageNumber, pageInfo?.per_page)
    };

    const openModal = () => {
        if (selectedItems.length) {
            setIsOpen(true)
        }
    };

    const closeModal = () => {
        setIsOpen(false)
        setDeletedItem([])
        setSelectedItems([])
    };

    const handleCheckboxChange = itemId => {
        if (selectedItems.includes(itemId)) {
            setSelectedItems(selectedItems.filter(id => id !== itemId));
        } else {
            setSelectedItems([...selectedItems, itemId]);
        }
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(folderList?.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    const onClickDelete = selectedItem => {
        deleteSection({ variables: { id: selectedItem } })
            .then(() => {//NOSONAR
                toast.success(`Selected folder deleted successfully.`);
                closeModal();
                getFolderData();
            }).catch(er => {
                toast.error(er.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            })
    }

    const handleDelete = folderData => {
        const uniqueId = [folderData?.id]
        setDeletedItem(uniqueId)
        setIsOpen(true)
    }

    return (
        <>
            <PageWrapper heading={'Manage Folders'}>
                <TableWrapper>
                    <TableHeadingSection heading={'Folders'} description={'All the active folders in the database.'} count={pageInfo?.total_records} />
                    <TableButtonSection searchQuery={searchQuery} onSearchChange={handleSearchChange} onClearSearch={handleClearSearch}>
                        {addUserPermission && !loading && <Link to={'/manage/folders/new'} className={loading ? "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2" : "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-indigo-500 rounded-lg shrink-0 gap-x-2 hover:bg-indigo-600 dark:bg-indigo-600"}>
                            <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faPlus} />
                            <span>Add</span>
                        </Link>}
                        {deleteUserPermission && selectedItems.length > 0 &&
                            <button type="button" onClick={openModal} className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-red-500 rounded-lg shrink-0 gap-x-2 hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600">
                                <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faTrashCan} />
                                <span>Delete</span>
                            </button>
                        }
                    </TableButtonSection>
                    <TableContainer>
                        <thead className="bg-gray-50 dark:bg-neutral-800">
                            <tr>
                                <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold">
                                    <div className="flex h-6 items-center">
                                        <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('display_name')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Folder Name</span>

                                        {sortedColumn === "display_name" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>

                                <th scope="col" onClick={() => handleSort('storage_path')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Folder Path</span>
                                        {sortedColumn === "storage_path" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>

                                <th scope="col" onClick={() => handleSort('created_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Created On</span>
                                        {sortedColumn === "created_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('updated_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Updated On</span>
                                        {sortedColumn === "updated_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>

                                <th scope="col" className="px-4 py-3.5 text-sm text-center rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                            {folderList?.map(folder => (
                                <tr key={folder?.id}>
                                    <td className="px-4 py-3 text-sm font-medium">
                                        <div className="flex h-6 items-center">
                                            <input type="checkbox" checked={selectedItems.includes(folder.id)} onChange={() => handleCheckboxChange(folder.id)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm font-medium">
                                        <div className="w-56">
                                            <Link to={`/manage/folders/${folder?.id}`} className="font-semibold text-indigo-600 dark:text-indigo-400 ">{folder.display_name}</Link>
                                        </div>
                                    </td>

                                    <td className="px-4 py-3 text-sm whitespace-pre-line" >
                                        <div className="" >
                                            <p className={`text-gray-700 dark:text-gray-400 text-ellipsis overflow-hidden line-clamp-1`}>
                                                {folder.storage_path}
                                            </p>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(folder.created_at)}</h4>
                                        </div>
                                    </td>

                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(folder.updated_at)}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="flex item-center justify-center text-gray-700 dark:text-gray-200">
                                            <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" icon={EyeIcon} title="View"
                                                onClick={() => {
                                                    navigate(`/manage/folders/${folder?.id}`)
                                                }} />
                                            {editUserPermission ? <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" icon={PencilIcon}
                                                title="Edit"
                                                onClick={() => navigate('/manage/folders/edit', { state: { folder } })} />
                                                : <ActionButton className="w-4 mr-2 transform text-gray-500" icon={PencilIcon} title="Edit" />}

                                            {deleteUserPermission ? <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                icon={TrashIcon} title="Delete" onClick={() => { handleDelete(folder) }} />
                                                : <ActionButton className="w-4 mr-2 transform text-gray-500" icon={TrashIcon} title="Delete" />}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {!folderList?.length && !loading && <TableNoRecordFound colSpan={8}></TableNoRecordFound>}
                            {loading && <TableLoadingData colSpan={8}></TableLoadingData>}
                        </tbody>
                    </TableContainer>
                </TableWrapper>
                <Pagination totalPages={pageInfo?.total_pages || 0} totalRecords={pageInfo?.total_records || 0}
                    maxPageNumbers={maxPageNumber}
                    recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0}
                    currentPage={pageInfo?.current_page || 0} onPageChange={handlePageChange} onRowChanges={handleRowChanges} ></Pagination>
            </PageWrapper>
            <DialogModal
                isOpen={isOpen}
                onClose={closeModal}
                title="Confirm Deletion"
                closeOnClickOutside={false} // Set this prop as needed
                pTagData={`Do you confirm to delete ${deletedItem?.length || selectedItems?.length} folders?`}
                btnOne="Confirm Delete"
                btnTwo="Cancel"
                loader={loader}
                onClickDeleteModal={() => { onClickDelete(deletedItem?.length > 0 ? deletedItem : selectedItems) }}>
            </DialogModal>
        </>
    );
};

export default Index;
