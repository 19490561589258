import CheckboxButton from "./CheckboxButton";
import React from 'react';
import PropTypes from 'prop-types';

const CheckboxInputGroup = ({ label, options, selectedOption, error, required, onChange }) => {
    return (
<>
        {label &&
            <label className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">{label}
                {required && <span className="text-red-600 dark:text-red-400 ml-1">*</span>}
            </label>
        }
                  <div className="mt-2.5">
                    <fieldset>
                      <div className="space-y-4">


                      {options.map(option => (
                        
                            <CheckboxButton
                                key={option.value}
                                label={option.label}
                                value={option.value}
                                description={option.description}
                                checked={selectedOption === option.value}
                                onChange={onChange}
                            />
                        ))}
                      </div>
                    </fieldset>
                    {/* <input type="email" name="email" id="email" autoComplete="email"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-200 dark:bg-neutral-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 focus:outline-none" /> */}
                    {error && <span className="text-sm text-red-600 dark:text-red-400  font-semibold">{error}</span>}
                  </div>
        </>
    );
};

CheckboxInputGroup.propTypes = {
  label: PropTypes.any,
  options: PropTypes.any,
  selectedOption: PropTypes.any,
  error: PropTypes.any,
  required: PropTypes.any,
  onChange: PropTypes.any,
}

export default CheckboxInputGroup;