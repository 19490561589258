import React, { useContext, useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import SideBar from './components/navigation/sideBar';
import NavBar from './components/navigation/navBar';
import { Routes, Route, useNavigate, } from 'react-router-dom'
import Home from './pages/Home';
import Index from './pages/manage/users/Index';
import AddNewUsers from './pages/manage/users/AddNewUsers';
import NoMatch from './pages/errors/NoMatch';
import Banner from './components/navigation/banner';
import AddNewFolders from './pages/manage/folders/AddNewFolders';
import FolderIndex from './pages/manage/folders/Index';
import ManageIndex from './pages/manage/documents/Index';
import AddNewDocuments from './pages/manage/documents/AddNewDocuments';
import VideosIndex from './pages/manage/videos/Index';
import AddNewVideos from './pages/manage/videos/AddNewVideos';
import EditVideos from './pages/manage/videos/EditVideos';
import Help from './pages/manage/Help';
import Login from './pages/Login';
import Test from './pages/Test';
import NotificationIndex from './pages/manage/notifications/Index';
import AddNewNotifications from './pages/manage/notifications/AddNewNotifications';
import ManageFeedbacks from './pages/manage/feedbacks/Index';
import Welcome from './pages/Welcome';
import ErrorComponent from './pages/errors/ErrorComponent';
import ProtectedRoutes from './utils/ProtectedRoutes';
import ConfigurationMobileHomepage from './pages/configuration/mobile-homepage/ConfigurationMobileHomepage';
import ConfigurationSystemConfig from './pages/configuration/system-config/ConfigurationSystemConfig';
import AuditArchivedUsers from './pages/audit-logs/archived-users/AuditArchivedUsers';
import AuditCronJobs from './pages/audit-logs/cron-jobs/AuditCronJobs';
import AuditActivityLogs from './pages/audit-logs/activity-logs/AuditActivityLogs';
import AccessControlPermissions from './pages/access-control/permissions/AccessControlPermissions';
import AccessControlRoles from './pages/access-control/roles/AccessControlRoles';
import AccessControlGroups from './pages/access-control/groups/AccessControlGroups';
import UserDetails from './pages/manage/users/UserDetails';
import EditUser from './pages/manage/users/EditUser';
import BulkUploadUser from './pages/manage/users/BulkUploadUsers';
import BulkUpdateUsers from './pages/manage/users/BulkUpdateUsers';
import FolderDetails from './pages/manage/folders/FolderDetails';
import EditFoldersDetails from './pages/manage/folders/EditFoldersDetails';
import EditDocuments from './pages/manage/documents/EditDocuments';
import EditNotifications from './pages/manage/notifications/EditNotifications';
import NotificationDetails from './pages/manage/notifications/NotificationDetails';
import AddNewGroups from './pages/access-control/groups/AddNewGroups';
import EditGroups from './pages/access-control/groups/EditGroups';
import GroupDeatils from './pages/access-control/groups/GroupDetails';
import AuditCronJobsDetails from './pages/audit-logs/cron-jobs/AuditCronJobDetails';
import EditAuditCronJob from './pages/audit-logs/cron-jobs/EditAuditCronJob';
import ConfigurationApplicationConfig from './pages/configuration/application-config/ConfigurationApplicationConfig';
import ScheduleNotification from './pages/manage/notifications/ScheduleNotification';
import AddNewRole from './pages/access-control/roles/AddNewRole';
import EditRoles from './pages/access-control/roles/EditRoles';
import SystemSetting from './pages/setting/SystemSetting';
import EditScheduleNotification from './pages/manage/notifications/EditScheduleNotification';
import UseContext from './utils/UseContext';
import ConfigurationMobileHomepageEdit from './pages/configuration/mobile-homepage/ConfigurationMobileHomepageEdit';
import BulkUploadGroup from './pages/access-control/groups/BulkUploadGroup';
import AddUserToGroup from './pages/access-control/groups/AddUserToGroup';
import UserImpersonate from './pages/manage/users/UserImpersonate';
import StaticPageIndex from './pages/configuration/static-pages/Index';
import AddNewPage from './pages/configuration/static-pages/AddNewPage';
import EditStaticPage from './pages/configuration/static-pages/EditStaticPage';
import StaticDetailsPage from './pages/configuration/static-pages/StaticDetailsPage';

function App() {
  const navigate = useNavigate();
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const [showSidebar, setShowSidebar] = useState(true);
  const {
    logout,
  } = useContext(UseContext);


  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleSignout = () => {
    localStorage.setItem("darkMode", false);
    logout();
  }


  useEffect(() => {
    if (storedUser === null || storedUser === undefined) {
      localStorage.removeItem('user');
      navigate('/login');
      setShowSidebar(false);
    }
    // Effect to refresh token every 5 minutes
    // if (storedUser?.refresh_token) {
    //   const intervalId = setInterval(refreshToken, 1000);// 5 minutes in milliseconds
    //   return () => clearInterval(intervalId); // Cleanup on unmount
    // }
  }, []);


  useEffect(() => {
    const windowWidth = 1728;
    // Function to update openAccountDrawer based on screen width
    const updateAccountDrawer = () => {
      // Set openAccountDrawer to false on medium and small screens
      if (window.innerWidth < windowWidth) {
        setShowSidebar(false);
      } else {
        setShowSidebar(true);
      }
    };
    // Call the function on component mount
    updateAccountDrawer();
    // Add event listener for window resize
    window.addEventListener('resize', updateAccountDrawer);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateAccountDrawer);
    };
  }, []);

  const handleSidebarItemClick = () => {
    const windowWidth = 1728;
    if (window.innerWidth < windowWidth) {
      setShowSidebar(false); // or however you're managing the sidebar visibility
    }
  };



  return (
    <div className='App'>
      <div className="min-h-screen bg-gray-50 dark:bg-neutral-950">
        <NavBar toggleSidebar={toggleSidebar} handleSignout={handleSignout}  />
        {showSidebar && storedUser && <SideBar onSidebarItemClick={handleSidebarItemClick}/>}
        <Banner />
        <ToastContainer />
        <div>
          <main className="flex w-full">
            {/* content here */}
            <Routes>
              <Route path="/about" element={!storedUser && <Login />}></Route>
              <Route path='/' element={<Welcome />} />
              <Route path='/login' element={<Login />}></Route>
              <Route element={<ProtectedRoutes />}>
                <Route path='/home' element={<Home />} />
                <Route path='/test' element={<Test />}></Route>
                <Route path='/manage/users' element={<Index />}></Route>
                <Route path='/manage/users/:userId' element={<UserDetails />}></Route>
                <Route path='/manage/users/new' element={<AddNewUsers />}></Route>
                <Route path='/manage/users/new/:groupId' element={<AddNewUsers />}></Route>
                <Route path='/manage/users/edit' element={<EditUser />}></Route>
                <Route path='/manage/users/bulkUpload' element={<BulkUploadUser />}></Route>
                <Route path='/manage/users/bulkUpdate' element={<BulkUpdateUsers />}></Route>
                <Route path='/manage/users/impersonate' element={<UserImpersonate />}></Route>
                <Route path='/manage/folders' element={<FolderIndex />}></Route>
                <Route path='/manage/folders/new' element={<AddNewFolders />}></Route>
                <Route path='/manage/folders/:uniqueId' element={<FolderDetails />}></Route>
                <Route path='/manage/folders/edit' element={<EditFoldersDetails />}></Route>
                <Route path='/manage/documents' element={<ManageIndex />}></Route>
                <Route path='/manage/documents/new' element={<AddNewDocuments />}></Route>
                <Route path='/manage/documents/new/:folderId' element={<AddNewDocuments />}></Route>
                <Route path='/manage/documents/edit' element={<EditDocuments />}></Route>
                <Route path='/manage/notifications' element={<NotificationIndex />}></Route>
                <Route path='/manage/notifications/new' element={<AddNewNotifications />}></Route>
                <Route path='/manage/notifications/edit/:uniqueId' element={<EditNotifications />}></Route>
                <Route path='/manage/notifications/:notificationId' element={<NotificationDetails />}></Route>
                <Route path='/manage/notifications/:notificationId/schedule' element={<ScheduleNotification />}></Route>
                <Route path='/manage/notifications/:notificationId/edit/schedule' element={<EditScheduleNotification />}></Route>
                <Route path='/manage/feedbacks' element={<ManageFeedbacks />}></Route>
                <Route path='/help' element={<Help />}></Route>
                <Route path='/manage/videos' element={<VideosIndex />}></Route>
                <Route path='/manage/videos/new' element={< AddNewVideos />}></Route>
                <Route path='/manage/videos/edit' element={< EditVideos />}></Route>
                <Route path='/audit/activity-logs' element={<AuditActivityLogs />}></Route>
                <Route path='/audit/archived-users' element={<AuditArchivedUsers />}></Route>
                <Route path='/audit/cron-jobs' element={<AuditCronJobs />}></Route>
                <Route path='/audit/cron-jobs/:uniqueId' element={<AuditCronJobsDetails />}></Route>
                <Route path="/audit/cron-jobs/edit" element={<EditAuditCronJob />}></Route>
                <Route path='/access-control/permissions' element={<AccessControlPermissions />}></Route>
                <Route path='/access-control/roles' element={<AccessControlRoles />}></Route>
                <Route path='/access-control/groups' element={<AccessControlGroups />}></Route>
                <Route path='/access-control/groups/add/:groupId' element={<AddUserToGroup />}></Route>
                <Route path='/access-control/groups/new' element={<AddNewGroups />}></Route>
                <Route path='/manage/groups/bulkUpload/:groupId' element={<BulkUploadGroup />}></Route>
                <Route path='/access-control/groups/edit/:groupId' element={<EditGroups />}></Route>
                <Route path='/access-control/groups/:groupId' element={<GroupDeatils />}></Route>
                <Route path='/configuration/mobile-homepage' element={<ConfigurationMobileHomepage />}></Route>
                <Route path='/configuration/mobile-homepage/edit' element={<ConfigurationMobileHomepageEdit />}></Route>
                <Route path='/configuration/system-config' element={<ConfigurationSystemConfig />}></Route>
                <Route path='/configuration/application-config' element={<ConfigurationApplicationConfig />}></Route>
                <Route path='/configuration/application-config' element={<ConfigurationApplicationConfig />}></Route>
                <Route path='/access-control/roles' element={<AccessControlRoles />}></Route>
                <Route path='/access-control/roles/new' element={<AddNewRole />}></Route>
                <Route path='/access-control/roles/edit' element={<EditRoles />}></Route>
                <Route path='/setting/systemSetting' element={<SystemSetting />}></Route>
                <Route path='/manage/static-pages' element={<StaticPageIndex />}></Route>
                <Route path='/manage/static-pages/add' element={<AddNewPage />}></Route>
                <Route path='/manage/static-pages/edit/:pageId' element={<EditStaticPage />}></Route>
                <Route path='/pages/:slug' element={<StaticDetailsPage />}></Route>
              </Route>
              {/* <Route path='/home' element={<Home />} /> */}
              <Route path='/error' element={<ErrorComponent />}></Route>
              <Route path='*' element={<NoMatch />}></Route>
            </Routes>
          </main>
        </div>
      </div >
    </div>
  );
}

export default App;