import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import React from 'react';
import PropTypes from 'prop-types';

const TableLoadingData = ({ colSpan }) => {
    return (
        <tr>
            <td colSpan={colSpan} className="p-4 bg-white dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
                <div className="flex flex-row justify-center items-center w-full">
                    <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                    <span>Loading Data</span>
                </div>
            </td>
        </tr>
    );
};

TableLoadingData.propTypes = {
    colSpan: PropTypes.any,
}

export default TableLoadingData;
