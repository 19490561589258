import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import React from 'react';
import PropTypes from 'prop-types';

const Pagination = ({ totalPages, totalRecords, recordsPerPage, currentPage, maxPageNumbers, onPageChange, onRowChanges }) => {

  const renderPaginationLinks = () => {
    const pageLinks = [];
    const pageNumber = 2;

    const totalPagesToDisplay = Math.min(maxPageNumbers, totalPages);
    const middlePageNumber = Math.ceil(maxPageNumbers / pageNumber);
    let startPageNumber = 1;

    if (currentPage > middlePageNumber && currentPage + middlePageNumber <= totalPages) {
      startPageNumber = currentPage - middlePageNumber + 1;
    } else if (currentPage > middlePageNumber && currentPage + middlePageNumber > totalPages) {
      startPageNumber = totalPages - maxPageNumbers + 1;
    }

    // Add this condition to handle cases when startPageNumber is less than 1
    if (startPageNumber < 1) {
      startPageNumber = 1;
    }

    for (let i = startPageNumber; i < startPageNumber + totalPagesToDisplay; i++) {
      if (i <= totalPages && i >= 1) {
        pageLinks.push(
          <button key={i} onClick={() => onPageChange(i)} className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 focus:z-20 focus:outline-offset-0 ${currentPage === i ? 'bg-indigo-600 text-white' : 'bg-white dark:bg-neutral-900 text-gray-900 dark:text-gray-200 hover:bg-gray-50'}`}>
            {i}
          </button>
        );
      }
    }

    return pageLinks;
  };

  return (

    <div className="flex items-center justify-between py-3">
      <div className="flex flex-1 justify-between sm:hidden">
        <button type="button" onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} className="relative inline-flex items-center rounded-md border border-gray-300 dark:border-gray-700 bg-white dark:bg-neutral-900 px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-neutral-800">Previous</button>
        <button type="button" onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 dark:border-gray-700 bg-white dark:bg-neutral-900 px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-neutral-800">Next</button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700 dark:text-gray-400">Showing<span className="font-medium mx-0.5">{Math.max(1, (currentPage - 1) * recordsPerPage + 1)}</span>to<span className="font-medium mx-0.5">{Math.min(totalRecords, (currentPage * recordsPerPage))}</span>of<span className="font-medium mx-0.5">{totalRecords}</span>records
          </p>
          <p className="text-sm text-gray-700 dark:text-gray-400">Total pages:{totalPages}<span className="font-medium"></span>
          </p>
        </div>
        <div className="text-sm text-gray-700 dark:text-gray-400">
          {"Rows per page: "}
          <select className="text-sm dark:bg-neutral-800 text-gray-900 dark:text-gray-200 border border-gray-300 dark:border-neutral-600 rounded-md" value={recordsPerPage} onChange={e => onRowChanges(e.target.value)}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button onClick={() => onPageChange(1)} disabled={currentPage === 1} className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-900 dark:bg-neutral-900 dark:text-gray-200 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <ChevronDoubleLeftIcon className="w-5 h-5" />
            </button>
            <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 dark:bg-neutral-900 dark:text-gray-200 hover:bg-gray-50 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700  focus:z-20 focus:outline-offset-0">
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="w-5 h-5" />
            </button>
            {renderPaginationLinks()}
            <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 dark:bg-neutral-900 dark:text-gray-200 hover:bg-gray-50 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700  focus:z-20 focus:outline-offset-0">
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="w-5 h-5" />
            </button>
            <button onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-900 dark:bg-neutral-900 dark:text-gray-200 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <ChevronDoubleRightIcon className="w-5 h-5" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};
Pagination.propTypes = {
  totalPages: PropTypes.any,
  totalRecords: PropTypes.any,
  recordsPerPage: PropTypes.any,
  currentPage: PropTypes.any,
  maxPageNumbers: PropTypes.any,
  onPageChange: PropTypes.any,
  onRowChanges: PropTypes.any,
}

export default Pagination;