import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { useFormik } from "formik";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";
import InputField from "../../../components/form/input/InputField";
import PageWrapper from "../../../layouts/PageWrapper";
import SelectMultipleInput from "../../../components/form/input/SelectMultipleInput";
import Button from "../../../components/button/Button";
import TextAreaInput from "../../../components/form/input/TextAreaInput";
import { useGroupSectionsDocsMappingListUpdateMutation, useGetGroupDetailsByIdMutation } from "../../../graphql/generated/graphql.ts";
import { formCreateNewGroupSchema } from "../../../validations/FormValidationSchema";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant';

const EditGroups = () => {
    const { groupId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const state = location?.state || {};
    const { getAllDocumentsFolders, fileMetadataForAdmin } = state?.fileMetaData || {};
    const [getGroupDetailsById, { data, error: fetchError, loading: fetching }] = useGetGroupDetailsByIdMutation({ variables: { uniqueId: groupId } });
    const [updateGroupSections, { loading: updating }] = useGroupSectionsDocsMappingListUpdateMutation();

    useEffect(() => {
        getGroupDetailsById();
    }, [groupId]);

    useEffect(() => {
        if (fetchError) {
            navigate('/error', { state: { errorData: JSON.stringify(fetchError) } });
        }
    }, [fetchError, navigate]);

    const mapFileMetadataToOptions = fileMetadata => (fileMetadata || []).map(option => ({
        value: option.id,
        label: option.fileName
    }));

    const mapDocumentFoldersToOptions = folders => (folders || []).map(option => ({
        value: option.id,
        label: option.display_name
    }));

    const documentData = mapFileMetadataToOptions(fileMetadataForAdmin);
    const folderSelectOptions = mapDocumentFoldersToOptions(getAllDocumentsFolders);

    const [initialValues, setInitialValues] = useState({
        folder_name: null,
        group_name: "",
        descriptions: "",
        document_name: null,
        documentList: documentData
    });



    const formik = useFormik({
        initialValues,
        validationSchema: formCreateNewGroupSchema,
        onSubmit: async (value, actions) => {
            try {
                if (!value.folder_name && !value.document_name) {
                    actions.setErrors({ folder_name: 'Please select at least one folder or document' });
                    return;
                }

                const groupSectionsDoc = [{
                    groupName: value?.group_name?.trim(),
                    groupDescription: value?.descriptions?.trim(),
                    sectionIds: value?.folder_name?.map(folder => folder.value),
                    documentIds: value?.document_name?.map(document => document.value),
                    groupId
                }];

                await updateGroupSections({ variables: { groupSectionsDocsUpdate: groupSectionsDoc } });
                toast.success('Group updated successfully.');
                navigate(`/access-control/groups/${groupId}`);
                actions.resetForm();
            } catch (err) {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            }
        }
    });

    useEffect(() => {
        const selectedFolderIds = formik.values.folder_name?.map(item => item.value) || [];
        const updatedDocumentData = fileMetadataForAdmin?.filter(option => !selectedFolderIds?.includes(option.folder_id))
            .map(option => ({
                value: option.id,
                label: option.fileName,
                folder_id: option.folder_id
            }));
        formik.setFieldValue('documentList', updatedDocumentData);
    }, [formik.values.folder_name, fileMetadataForAdmin]);




    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        document.title = `${prefix}${suffix} | Manage Groups | Edit | ${formik.values.group_name}`;
    }, [formik.values.group_name]);

    useEffect(() => {
        if (data?.getGroupDetailsById) {
            const groupDetails = data.getGroupDetailsById[0];
            const documentDetails = (groupDetails?.documents || []).map(option => ({
                value: option.id,
                label: option.documentName
            }));
            const folderSelection = (groupDetails?.folders || []).map(option => ({
                value: option.id,
                label: option.folderName
            }));

            formik.setFieldValue('folder_name', folderSelection.length > 0 ? folderSelection : null);
            formik.setFieldValue('group_name', groupDetails.groupName || "",);
            formik.setFieldValue('descriptions', groupDetails.groupDescription || "",);
            formik.setFieldValue('document_name', documentDetails.length > 0 ? documentDetails : null);
            setInitialValues({
                folder_name: folderSelection.length > 0 ? folderSelection : null,
                group_name: groupDetails.groupName || "",
                descriptions: groupDetails.groupDescription || "",
                document_name: documentDetails.length > 0 ? documentDetails : null
            });
        }
    }, [data]);

    return (
        <PageWrapper heading={'Edit Group'}>
            {fetching || updating ? (
                <div className="flex justify-center items-center h-screen">
                    <div className="text-center">
                        <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400" icon={faSpinner} />
                        <span>Loading Data</span>
                    </div>
                </div>
            ) : (
                <div className="py-6">
                    <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                        <div className="col-span-4">
                            <h2 className="text-lg font-semibold tracking-tight text-gray-900">General Information</h2>
                            <p className="leading-8 text-gray-600 text-sm">Fill in the Group details and documents details</p>
                        </div>
                        <form method="POST" onSubmit={formik.handleSubmit} className="col-span-8">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white shadow py-8 px-4 sm:px-8">
                                <div className="sm:col-span-2">
                                    <InputField
                                        type="text"
                                        name="group_name"
                                        label="Group Name"
                                        value={formik.values.group_name}
                                        required
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.group_name && formik.errors.group_name ? formik.errors.group_name : ''}
                                    />
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="descriptions" className="block text-sm font-semibold leading-6 text-gray-900">
                                        Descriptions
                                    </label>
                                    <TextAreaInput
                                        name="descriptions"
                                        id="descriptions"
                                        value={formik.values.descriptions}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 focus:outline-none"
                                        rows={4}
                                    />
                                    {formik.touched.descriptions && formik.errors.descriptions && (
                                        <div className="mt-2 text-red-500">{formik.errors.descriptions}</div>
                                    )}
                                </div>

                                <div className="sm:col-span-2">
                                    <SelectMultipleInput
                                        name="folder_name"
                                        value={formik.values.folder_name}
                                        options={folderSelectOptions}
                                        label="Select Folders"
                                        onChange={selectedOption => formik.setFieldValue('folder_name', selectedOption)}
                                        onBlur={() => formik.setFieldTouched('folder_name', true)}
                                        error={formik.errors.folder_name && formik.touched.folder_name ? formik.errors.folder_name : ''}
                                    />
                                </div>
                                <div className="sm:col-span-2">
                                    <SelectMultipleInput
                                        name="document_name"
                                        value={formik.values.document_name}
                                        options={formik.values.documentList}
                                        label="Select Documents"
                                        onChange={selectedOption => formik.setFieldValue('document_name', selectedOption)}
                                        onBlur={() => formik.setFieldTouched('document_name', true)}
                                        error={formik.errors.document_name && formik.touched.document_name ? formik.errors.document_name : ''}
                                    />
                                </div>
                            </div>
                            <div className="mt-10 flex justify-start">
                                    {updating ? <Button type="button"
                                        className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                        text="Update" />
                                        : <Button type="submit"
                                            className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            text="Update" />
                                    }
                                <Link to='/access-control/groups'
                                    className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                                    Cancel
                                </Link>
                                {updating && <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400" icon={faSpinner} />}
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </PageWrapper>
    );
};

export default EditGroups;