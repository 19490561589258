import PageWrapper from "../../../layouts/PageWrapper.js";
import ContentEditor from "../../../components/form/editor/ContentEditor";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useFormik } from "formik";
import { formCreateHomeScreenSchema } from "./../../../validations/FormValidationSchema"
import {
  useGetHomeScreenForAdminQuery,
  useSetHomeScreenMutation,
} from '../../../graphql/generated/graphql.ts'
import draftToHtml from "draftjs-to-html";
import Button from "../../../components/button/Button";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import htmlToDraft from 'html-to-draftjs';
import '../../../styles/Draft.css';
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'

const initialValues = {
  message: EditorState.createEmpty(),
  message_html: "",
  mobile_message: EditorState.createEmpty(),
  mobile_message_html: "",
}



const ConfigurationMobileHomepageEdit = () => {
  const navigate = useNavigate();
  const { data, loading, error } = useGetHomeScreenForAdminQuery({ variables: {} });
  const [homeContentUpdate, { loading: editLoader }] = useSetHomeScreenMutation();

  if (error) {
    navigate('/error', { state: { errorData: JSON.stringify(error) } });
  }

  useEffect(() => {
    const envVariable = process.env.REACT_APP_ENV;
    const prefix = 'SIERA';
    const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
    const pageTitle = `${prefix}${suffix} | Manage Mobile Home screen | Edit`;
    document.title = pageTitle;
  }, [])

  useEffect(() => {
    if (data?.getHomeScreenForAdmin?.adminContent) {
      convertHtmlToDraft(data?.getHomeScreenForAdmin?.adminContent);
      convertMobileHtmlToDraft(data?.getHomeScreenForAdmin?.mobileContent || "");
    }
  }, [data])

  function convertHtmlToDraft(html) {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorData = EditorState.createWithContent(contentState);
    handleEditorStateChange(editorData)
  }

  function convertMobileHtmlToDraft(Mobilehtml) {
    const blocksFromMobileHtml = htmlToDraft(Mobilehtml);
    const { contentBlocks, entityMap } = blocksFromMobileHtml;
    const MobilecontentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const MobileeditorData = EditorState.createWithContent(MobilecontentState);
    handleMobileEditorStateChange(MobileeditorData)
  }

  const { values, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    validationSchema: formCreateHomeScreenSchema,
    onSubmit: value => {
      homeContentUpdate({ variables: { adminContent: value?.message_html, mobileContent: value?.mobile_message_html } })
        .then(() => {
          toast.success('Home screen content updated successfully.')
          navigate('/configuration/mobile-homepage')
        })
        .catch(err => {
          toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
            autoClose: false,
          });
        });
    }
  });


  const handleEditorStateChange = editorState => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const contentHtml = draftToHtml(rawContent);
    setFieldValue('message', editorState);
    setFieldValue('message_html', contentHtml);
  };

  const handleMobileEditorStateChange = mobileEditorState => {
    const mobileContentState = mobileEditorState.getCurrentContent();
    const mobileRawContent = convertToRaw(mobileContentState);
    const mobileContentHtml = draftToHtml(mobileRawContent);
    setFieldValue('mobile_message', mobileEditorState);
    setFieldValue('mobile_message_html', mobileContentHtml);
  }

  return (
    <PageWrapper heading={'Configure Mobile Homepage'}>
      {loading ? <div className="flex justify-center items-center h-screen">
        <td colSpan={3} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
          <div>
            <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
            <span>Loading Data</span>
          </div>
        </td>
      </div> :
        <div className="py-6">
          <div className="flex flex-col">
            <div className="mb-4">
              <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">Edit Homepage Content
              </h2>
              <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">The content updated here will be rendered in the mobile homepage.</p>
            </div>
            <div className="py-6">
              <div className="flex flex-col">
                <form method="POST" onSubmit={handleSubmit} className="">
                  <div
                    className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                    <div className="sm:col-span-2">
                      <ContentEditor name={'content'} editorState={values.message} required={true} onEditorStateChange={handleEditorStateChange} error={(errors.message_html) ? errors.message_html : ''} />
                    </div>
                    <div className="sm:col-span-2">
                      <ContentEditor name={'content'} editorState={values.mobile_message} required={true} onEditorStateChange={handleMobileEditorStateChange} error={(errors.message_html) ? errors.message_html : ''} />
                    </div>
                  </div>
                  <div className="mt-10 flex justify-start">
                    {editLoader ? <Button type="button"
                      className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                      text="Create" />
                      : <Button type="submit"
                        className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        text="Save" />
                    }
                    <Button type="button"
                      className="block rounded-md bg-gray-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                      text={"Cancel"}
                      onClick={() => { navigate("/home") }} />
                    {editLoader && <FontAwesomeIcon className="w-5 h-5 mr-6 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      }
    </PageWrapper>
  );
};

export default ConfigurationMobileHomepageEdit;