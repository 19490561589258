import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { EyeIcon, PencilIcon, TrashIcon, ClockIcon } from '@heroicons/react/24/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownShortWide, faArrowUpWideShort, faDownload, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import PageWrapper from "../../../layouts/PageWrapper";
import Pagination from "../../../components/table/Pagination";
import TableWrapper from "../../../components/table/TableWrapper";
import TableContainer from "../../../components/table/TableContainer";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound";
import TableLoadingData from "../../../components/table/TableLoadingData";
import TableHeadingSection from "../../../components/table/TableHeadingSection";
import TableButtonSection from "../../../components/table/TableButtonSection";
import { useDeleteNotificationsByIdMutation, useGetAirshipChannelsReportQuery, useGetCurrentUserPermissionsQuery, useGetAllNotificationsPagesMutation } from "../../../graphql/generated/graphql.ts";
import { formatTimestamp } from '../../../utils/commonFormatFunctions.js'
import DialogModal from "../../../components/modal/DialogModal";
import { toast } from "react-toastify";
import Button from "../../../components/button/Button.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import ActionButton from '../../../components/button/ActionButton.js';

const Index = () => {
    const [deleteMutation, { loading: load }] = useDeleteNotificationsByIdMutation();
    const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
    const [notificationInfoMutation, { data: paginationData, loading, error }] = useGetAllNotificationsPagesMutation();
    const { notificationList, pageInfo } = paginationData?.getAllNotificationsPages || {};
    const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions;
    const permissionAPIList = permissionData?.getAllAPIPermissions
    const createNotification = permissionAPIList?.filter(item => item.accessType === "CreateNotification")
    const deleteNotification = permissionAPIList?.filter(item => item.accessType === "DeleteNotification")
    const updateNotification = permissionAPIList?.filter(item => item.accessType === "UpdateNotification")
    const pushNotification = permissionAPIList?.filter(item => item.accessType === "ScheduleNotification")
    const editUserPermission = updateNotification?.length && updateNotification[0].assignedPermissions.some(val => addPermission.includes(val));
    const addUserPermission = createNotification?.length && createNotification[0].assignedPermissions.some(val => addPermission.includes(val));
    const deleteUserPermission = deleteNotification?.length && deleteNotification[0].assignedPermissions.some(val => addPermission.includes(val));
    const sendNotificationPermission = pushNotification?.length && pushNotification[0].assignedPermissions.some(val => addPermission.includes(val));

    const [searchQuery, setSearchQuery] = useState('');
    const [sortedColumn, setSortedColumn] = useState("title");
    const [sortOrder, setSortOrder] = useState('ASC');
    const [downloadLoader, setDownloadLoader] = useState(false);
    const maxPageNumber = 5;
    const defaultPageNumber = 1;
    const [recordsPerPageData, setRecordsPerPageData] = useState(pageInfo?.per_page)
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [deletedItem, setDeletedItem] = useState([]);
    if (error) {
        navigate('/error', { state: { errorData: JSON.stringify(error) } });
    }

    const { data: airshipData, } = useGetAirshipChannelsReportQuery({
        fetchPolicy: "network-only",
        skip: !downloadLoader,
    })

    const getNotificationData = (page = defaultPageNumber, row = pageInfo?.per_page, search_term = searchQuery) => {
        setDeletedItem([])
        setSelectedItems([]); setSelectAll(false)
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term,
            search_column: "",
            order_by: sortOrder,
            order_by_column: sortedColumn,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        };
        notificationInfoMutation({ variables: { PageInfoInput } })
            .catch(er => {
                toast.error(er.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    };

    useEffect(() => {
        if (sortOrder && sortedColumn) {
            getNotificationData(defaultPageNumber, pageInfo?.per_page);
        }
    }, [sortOrder, sortedColumn]);

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Notifications`;
        document.title = pageTitle;
    }, []);

    useEffect(() => {
        const delayTime = 1000;
        if (searchQuery) {
            const delayDebounceFn = setTimeout(() => {
                // Make API request with the current value
                getNotificationData(defaultPageNumber, pageInfo?.per_page, searchQuery);
            }, delayTime);
            return () => {
                clearTimeout(delayDebounceFn);
            };
        }
    }, [searchQuery]);

    const handleClearSearch = () => {
        setSearchQuery("");
        getNotificationData(defaultPageNumber, pageInfo?.per_page, "");
    };

    // Handle search input change
    const handleSearchChange = e => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm);
    };

    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getNotificationData(defaultPageNumber, rowValue);
    };

    // Handle column header click for sorting
    const handleSort = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrder);
        setSortedColumn(column);
    };

    // Handle pagination link click
    const handlePageChange = pageNumber => {
        getNotificationData(pageNumber, pageInfo?.per_page);
    };

    const openModal = () => {
        if (selectedItems.length) {
            setIsOpen(true)
        }
    };

    const closeModal = () => {
        setIsOpen(false)
        setDeletedItem([])
        setSelectedItems([])
    };

    const handleCheckboxChange = itemId => {
        if (selectedItems.includes(itemId)) {
            setSelectedItems(selectedItems.filter(id => id !== itemId));
        } else {
            setSelectedItems([...selectedItems, itemId]);
        }
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(notificationList?.map(item => item.uniqueId));
        }
        setSelectAll(!selectAll);
    };

    const onClickDelete = selectedItem => {
        deleteMutation({ variables: { uniqueId: selectedItem } })
            .then(() => {
                toast.success("Notification deleted successfully.")
                closeModal();
                getNotificationData();
                setSelectedItems([])
            }).catch(e => {
                toast.error(e.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            })
    }

    const handleDelete = notification => {
        const uniqueId = [notification?.uniqueId]
        setDeletedItem(uniqueId)
        setIsOpen(true)
    }

    const handleDownloadButtonClick = () => {
        setDownloadLoader(true);
    };
    function flattenObject(obj, parentKey = '') {
        return Object.keys(obj).reduce((acc, key) => {
            const newKey = parentKey ? `${parentKey}.${key}` : key;
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                return { ...acc, ...flattenObject(obj[key], newKey) };
            } else {
                // Check if value contains a comma, and if so, treat it as a single string
                const value = typeof obj[key] === 'string' && obj[key].includes(',') ? `"${obj[key]}"` : obj[key];
                return { ...acc, [newKey]: value };
            }
        }, {});
    }

    const getValueOrDefault = value => value || "";
    useEffect(() => {
        if (airshipData) {
            const airshipParseData = JSON.parse(airshipData?.getAirshipChannelsReport?.channelsInfo);
            airshipParseData?.forEach(dataValue => {
                dataValue.device_attributes = flattenObject(dataValue?.device_attributes);
            });
            let stringToDownload = "channel_id, installed, push_address, named_user_id, tags, ua_app_version, ua_carrier, ua_country, ua_device_model, ua_device_os, ua_language, ua_local_tz, ua_nu_country, ua_nu_language, ua_nu_local_tz, ua_sdk_version, created, last_registration, badge \n";
            airshipParseData?.forEach(dataValue => {
                const deviceAttributes = dataValue.device_attributes || {};
                stringToDownload += [
                    getValueOrDefault(dataValue.channel_id),
                    getValueOrDefault(dataValue.installed),
                    getValueOrDefault(dataValue.push_address),
                    getValueOrDefault(dataValue.named_user_id),
                    getValueOrDefault(dataValue.tags),
                    getValueOrDefault(deviceAttributes.ua_app_version),
                    getValueOrDefault(deviceAttributes.ua_carrier),
                    getValueOrDefault(deviceAttributes.ua_country),
                    getValueOrDefault(deviceAttributes.ua_device_model),
                    getValueOrDefault(deviceAttributes.ua_device_os),
                    getValueOrDefault(deviceAttributes.ua_language),
                    getValueOrDefault(deviceAttributes.ua_local_tz),
                    getValueOrDefault(deviceAttributes.ua_nu_country),
                    getValueOrDefault(deviceAttributes.ua_nu_language),
                    getValueOrDefault(deviceAttributes.ua_nu_local_tz),
                    getValueOrDefault(deviceAttributes.ua_sdk_version),
                    getValueOrDefault(dataValue.created),
                    getValueOrDefault(dataValue.last_registration),
                    getValueOrDefault(dataValue.ios?.badge)
                ].join(",") + "\n";
            });

            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:attachment/text,' + encodeURI(stringToDownload);
            hiddenElement.target = '_blank';
            const date = new Date();
            hiddenElement.download = `audience_report_${date.toString()}.csv`;
            hiddenElement.click();
            setDownloadLoader(!downloadLoader)
        }
    }, [airshipData])

    return (
        <>
            <PageWrapper heading={'Manage Notifications'}>
                <TableWrapper>
                    <TableHeadingSection heading={'Notifications'} description={'All the active notifications in the database.'} count={pageInfo?.total_records} />
                    <TableButtonSection searchQuery={searchQuery} onSearchChange={handleSearchChange} onClearSearch={handleClearSearch}>
                        {addUserPermission && !loading && <Link to={'/manage/notifications/new'} className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-indigo-500 rounded-lg shrink-0 gap-x-2 hover:bg-indigo-600 dark:hover:bg-indigo-500 dark:bg-indigo-600">
                            <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faPlus} />
                            <span>Add</span>
                        </Link>}
                        {editUserPermission && <Button
                            type="button"
                            onClick={handleDownloadButtonClick}
                            icon={faDownload}
                            loader={downloadLoader}
                            text="Audience"
                            className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                        />}
                        {deleteUserPermission && selectedItems.length > 0 &&
                            <button type="button" onClick={openModal} className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-red-500 rounded-lg shrink-0 gap-x-2 hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600">
                                <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faTrashCan} />
                                <span>Delete</span>
                            </button>}
                    </TableButtonSection>
                    <TableContainer>
                        <thead className="bg-gray-50 dark:bg-neutral-800">
                            <tr>
                                <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold">
                                    <div className="flex h-6 items-center">
                                        <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('title')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Title</span>
                                        {sortedColumn === "title" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('created_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Created On</span>
                                        {sortedColumn === "created_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('updated_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Updated On</span>
                                        {sortedColumn === "updated_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" className="px-4 py-3.5 text-sm text-center rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                            {notificationList?.map(notification => (
                                <tr key={notification?.uniqueId}>
                                    <td className="px-4 py-3 text-sm font-medium">
                                        <div className="flex h-6 items-center">
                                            <input type="checkbox" checked={selectedItems.includes(notification.uniqueId)} onChange={() => handleCheckboxChange(notification.uniqueId)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm font-medium" >
                                        <div className="">
                                            <Link to={`/manage/notifications/${notification?.uniqueId}`} className={`text-indigo-600 font-semibold  dark:text-indigo-400 overflow-hidden`}>
                                                {notification.title}
                                            </Link>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(notification.created_at)}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(notification.updated_at)}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="flex item-center justify-center text-gray-700 dark:text-gray-200">
                                            {!sendNotificationPermission ? <ActionButton className="w-4 mr-2 transform text-gray-500"
                                                icon={ClockIcon} title="Schedule" />
                                                :
                                                <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                    icon={ClockIcon} title="Schedule" onClick={() => { navigate(`/manage/notifications/${notification?.uniqueId}/schedule`) }} />
                                            }
                                            <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                icon={EyeIcon} title="View" onClick={() => { navigate(`/manage/notifications/${notification?.uniqueId}`) }} />
                                            {editUserPermission ? <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                icon={PencilIcon} title="Edit" onClick={() => { navigate(`/manage/notifications/edit/${notification?.uniqueId}`, { state: { notificationData: notification } }) }} />
                                                : <ActionButton className="w-4 mr-2 transform text-gray-500" icon={PencilIcon}
                                                    title="Edit" />
                                            }
                                            {deleteUserPermission ? <ActionButton className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" icon={TrashIcon}
                                                title="Delete" onClick={() => { handleDelete(notification) }} />
                                                : <ActionButton className="w-4 mr-2 transform text-gray-500" icon={TrashIcon} title="Delete" />}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {!notificationList?.length && !loading && <TableNoRecordFound colSpan={6}></TableNoRecordFound>}
                            {loading && <TableLoadingData colSpan={6}></TableLoadingData>}
                        </tbody>
                    </TableContainer>
                </TableWrapper>
                <Pagination totalPages={loading ? 0 : pageInfo?.total_pages} totalRecords={loading ? 0 : pageInfo?.total_records}
                    maxPageNumbers={maxPageNumber}
                    recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0}
                    currentPage={loading ? 0 : pageInfo?.current_page} onPageChange={handlePageChange} onRowChanges={handleRowChanges} ></Pagination>
            </PageWrapper>
            <DialogModal
                isOpen={isOpen}
                onClose={closeModal}
                title="Confirm Deletion"
                closeOnClickOutside={false} // Set this prop as needed
                pTagData={`Do you confirm to delete ${deletedItem?.length || selectedItems?.length} notifications?`}
                btnOne="Confirm Delete"
                btnTwo="Cancel"
                loader={load}
                onClickDeleteModal={() => { onClickDelete(deletedItem?.length > 0 ? deletedItem : selectedItems) }}
            >
            </DialogModal>
        </>
    );
};

export default Index;
