import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownShortWide, faArrowUpWideShort } from '@fortawesome/free-solid-svg-icons'
import PageWrapper from "../../../layouts/PageWrapper";
import Pagination from "../../../components/table/Pagination";
import TableWrapper from "../../../components/table/TableWrapper";
import TableContainer from "../../../components/table/TableContainer";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound";
import TableLoadingData from "../../../components/table/TableLoadingData";
import TableHeadingSection from "../../../components/table/TableHeadingSection";
import TableButtonSection from "../../../components/table/TableButtonSection";
import {useGetAllCronJobPagesMutation, useSyncUsersWithAadQuery,useProcessPushNotificationsQuery, useCleanAuditLogsQuery } from "../../../graphql/generated/graphql.ts";
import Button from "../../../components/button/Button";
import { formatTimestamp } from "../../../utils/commonFormatFunctions.js";
import { toast } from "react-toastify";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'

const AuditCronJobs = () => {
    const [cronJobInfoMutation, { data: paginationData, loading, error }] = useGetAllCronJobPagesMutation();
    const { cronJobList, pageInfo } = paginationData?.getAllCronJobPages || {};
    const [searchQuery, setSearchQuery] = useState('');
    const [sortedColumn, setSortedColumn] = useState('cronjobname');
    const [sortOrder, setSortOrder] = useState('ASC');
    const [runReRunJob, setRunReRunJob] = useState(false);
    const [runReRunNotificationJob, setRunReRunNotificationJob] = useState(false);
    const [runReRunAuditJob, setRunReRunAuditJob] = useState(false);
    const maxPageNumber = 5;
    const [recordsPerPageData, setRecordsPerPageData] = useState(pageInfo?.per_page)
    const navigate = useNavigate();
    const defaultPageNumber = 1;



    if (error) {
        navigate('/error', { state: { errorData: JSON.stringify(error) } });
    }

    const getScheduleNotificationData = (page = defaultPageNumber, row = pageInfo?.per_page, search_term = searchQuery) => {
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term,
            search_column: "",
            order_by: sortOrder,
            order_by_column: sortedColumn,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        };

        cronJobInfoMutation({ variables: { PageInfoInput } })
            .catch(err => {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    };

    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getScheduleNotificationData(defaultPageNumber, rowValue);
    };

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Cron Jobs`;
        document.title = pageTitle;
    }, [])

    // Handle search input change
    const handleSearchChange = e => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm);
    };

    // Handle column header click for sorting
    const handleSort = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrder);
        setSortedColumn(column);
    };

    // Handle pagination link click
    const handlePageChange = pageNumber => {
        getScheduleNotificationData(pageNumber, pageInfo?.per_page);
    };

    useEffect(() => {
        const delayTime = 1000;
        if (searchQuery) {
            const delayDebounceFn = setTimeout(() => {
                // Make API request with the current value
                getScheduleNotificationData(defaultPageNumber, pageInfo?.per_page, searchQuery);
            }, delayTime);
            return () => {
                clearTimeout(delayDebounceFn);
            };
        }
    }, [searchQuery]);

    const handleClearSearch = () => {
        setSearchQuery("");
        getScheduleNotificationData(defaultPageNumber, pageInfo?.per_page, "");
    };
    useEffect(() => {
        getScheduleNotificationData(defaultPageNumber, pageInfo?.per_page);
    }, [sortOrder, sortedColumn]);


    const { loader: cronJobLoading, } = useSyncUsersWithAadQuery({
        variables: {},
        skip: !runReRunJob,
    });
    const { loader: pushNotificationLoading,  } = useProcessPushNotificationsQuery({
        variables: {},
        skip: !runReRunNotificationJob,
    });
    const { loader: auditLogLoading,} = useCleanAuditLogsQuery({
        variables: {},
        skip: !runReRunAuditJob,
    });
    useEffect(() =>{
        setRunReRunJob(false)
        setRunReRunNotificationJob(false)
        setRunReRunAuditJob(false);
        getScheduleNotificationData(defaultPageNumber, pageInfo?.per_page, "");
    },[ cronJobLoading, pushNotificationLoading, auditLogLoading])

    const onClickReRun = cronJobNameInfo =>{
        if (cronJobNameInfo ==="SyncSIMAASUsers"){
            setRunReRunJob(true)
        } else if (cronJobNameInfo === "ProcessPushNotifications"){
            setRunReRunNotificationJob(true)
        } else if (cronJobNameInfo ==="CleanAuditLogs"){
            setRunReRunAuditJob(true);
        }
    }

    return (
        <PageWrapper heading={'Audit Cron Jobs'}>
            <TableWrapper>
                <TableHeadingSection heading={'Cron Jobs'} description={'All the active cron jobs in the database.'} count={pageInfo?.total_records} />
                <TableButtonSection searchQuery={searchQuery} onSearchChange={handleSearchChange} onClearSearch={handleClearSearch}>
                </TableButtonSection>
                <TableContainer>
                    <thead className="bg-gray-50 dark:bg-neutral-800">
                        <tr>
                            <th scope="col" onClick={() => handleSort('cronjobname')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                <div className="flex items-center gap-x-3 cursor-pointer">
                                    <span>Cron Job Name</span>
                                    {sortedColumn === "cronjobname" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                </div>
                            </th>
                            <th scope="col" onClick={() => handleSort('description')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                <div className="flex items-center gap-x-3 cursor-pointer">
                                    <span>Description</span>
                                    {sortedColumn === "description" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                </div>
                            </th>
                            <th scope="col" onClick={() => handleSort('lastrunstatus')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                <div className="flex items-center gap-x-3 cursor-pointer">
                                    <span>Last Run Status</span>
                                    {sortedColumn === "lastrunstatus" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                </div>
                            </th>
                            <th scope="col" onClick={() => handleSort('isActive')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                <div className="flex items-center gap-x-3 cursor-pointer">
                                    <span>Is Active</span>
                                    {sortedColumn === "isActive" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                </div>
                            </th>
                            <th scope="col" onClick={() => handleSort('lastrunat')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                <div className="flex items-center gap-x-3 cursor-pointer">
                                    <span>Last Run On</span>
                                    {sortedColumn === "lastrunat" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                </div>
                            </th>
                            <th scope="col" onClick={() => handleSort('nextrunat')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                <div className="flex items-center gap-x-3 cursor-pointer">
                                    <span>Next Run On</span>
                                    {sortedColumn === "nextrunat" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                </div>
                            </th>

                            <th scope="col" onClick={() => handleSort('created_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                <div className="flex items-center gap-x-3 cursor-pointer">
                                    <span>Created On</span>
                                    {sortedColumn === "created_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                </div>
                            </th>
                            <th scope="col" className="px-4 py-3.5 text-sm text-center rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                        {cronJobList?.map(cronJob => (
                            <tr key={cronJob?.uniqueId}>
                                <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                    <div>
                                        <Link to={`/audit/cron-jobs/${cronJob?.uniqueId}`} state={{ cronJobData: cronJob }} className="font-semibold text-indigo-600 dark:text-indigo-400 ">{cronJob.cronjobname}</Link>
                                    </div>
                                </td>
                                <td className="px-4 py-3 text-sm whitespace-pre-line">
                                    <div className="w-40">
                                        <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden line-clamp-2">{cronJob.description}</h2>
                                    </div>
                                </td>
                                <td className="px-4 py-3 text-sm whitespace-nowrap">
                                    <div className="">
                                        <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{cronJob.lastrunstatus}</h2>
                                    </div>
                                </td>
                                <td className="px-4 py-3 text-sm whitespace-nowrap">
                                    <div>
                                        <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{cronJob.isActive ? "True" :"False"}</h2>
                                    </div>
                                </td>
                                <td className="px-4 py-3 text-sm whitespace-nowrap">
                                    <div>
                                        <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(cronJob.lastrunat)}</h4>
                                    </div>
                                </td>
                                <td className="px-4 py-3 text-sm whitespace-nowrap">
                                    <div>
                                        <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(cronJob.nextrunat)}</h4>
                                    </div>
                                </td>
                                <td className="px-4 py-3 text-sm whitespace-nowrap">
                                    <div>
                                        <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(cronJob.created_at)}</h4>
                                    </div>
                                </td>
                                <td className="px-4 py-3 text-sm whitespace-nowrap pl-2">
                                    <div className="flex item-center justify-center text-gray-700 dark:text-gray-200">
                                        {cronJob?.lastrunstatus === "SUCCESS" ? <Button type="button" className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-gray-500 rounded-lg shrink-0 gap-x-2"
                                            text="Re Run" /> : <Button type="button" className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-green-500 rounded-lg shrink-0 gap-x-2 hover:bg-green-600 dark:hover:bg-green-500 dark:bg-green-600"
                                                text="Re Run" onClick={() => { onClickReRun(cronJob?.cronjobname)}}/>}
                                    </div>
                                </td>
                            </tr>
                        ))}
                        {!cronJobList?.length && !loading && <TableNoRecordFound colSpan={8}></TableNoRecordFound>}
                        {loading && <TableLoadingData colSpan={8}></TableLoadingData>}
                    </tbody>
                </TableContainer>
            </TableWrapper>
            <Pagination totalPages={loading ? 0 : pageInfo?.total_pages} totalRecords={loading ? 0 : pageInfo?.total_records}
                maxPageNumbers={maxPageNumber}
                recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0}
                currentPage={loading ? 0 : pageInfo?.current_page} onPageChange={handlePageChange} onRowChanges={handleRowChanges} ></Pagination>
        </PageWrapper>
    );
};

export default AuditCronJobs;
