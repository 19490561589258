import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {faDownload } from '@fortawesome/free-solid-svg-icons'
import PageWrapper from "../../../layouts/PageWrapper";
import Pagination from "../../../components/table/Pagination";
import TableWrapper from "../../../components/table/TableWrapper";
import TableContainer from "../../../components/table/TableContainer";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound";
import TableLoadingData from "../../../components/table/TableLoadingData";
import TableHeadingSection from "../../../components/table/TableHeadingSection";
import TableButtonSection from "../../../components/table/TableButtonSection";
import { useGetAllPermissionsQuery, useGetAllPermissionsPagesMutation } from "../../../graphql/generated/graphql.ts";
import Button from "../../../components/button/Button";
import { toast } from "react-toastify";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import TableHeaderComponent from "../../../components/form/input/TableHeaderComponent.js";



const AccessControlPermissions = () => {
    const [getPermissionMutation, { data: paginationData, loading, error }] = useGetAllPermissionsPagesMutation();
    const { permissionList, pageInfo } = paginationData?.getAllPermissionsPages || {};
    const [searchQuery, setSearchQuery] = useState('');
    const [sortedColumn, setSortedColumn] = useState('permissionName');
    const [sortOrder, setSortOrder] = useState('ASC');
    const [downloadLoader, setDownloadLoader] = useState(false);
    const maxPageNumber = 5;
    const defaultPageNumber =1; 
    const [recordsPerPageData, setRecordsPerPageData] = useState(pageInfo?.per_page)
    const navigate = useNavigate();

    if (error) {
        navigate('/error', { state: { errorData: JSON.stringify(error) } });
    }

    const { data, } = useGetAllPermissionsQuery({
        fetchPolicy: "network-only",
        skip: !downloadLoader,
    })

    useEffect(() =>{
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} |  Manage Permissions`;
        document.title = pageTitle;
    },[])

    // This will run when the page first loads and whenever the title changes

    const getPermissionData = (page = defaultPageNumber, row = pageInfo?.per_page, search_term = searchQuery) => {
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term,
            search_column: "",
            order_by: sortOrder,
            order_by_column: sortedColumn,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        };

        getPermissionMutation({ variables: { PageInfoInput } })
            .catch(er => {
                toast.error(er.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    };

    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getPermissionData(defaultPageNumber, rowValue);
    };

    useEffect(() => {
        getPermissionData(defaultPageNumber, pageInfo?.per_page);
    }, [sortOrder, sortedColumn]);

    useEffect(() => {
        const delayTime = 1000;
        if (searchQuery) {
            const delayDebounceFn = setTimeout(() => {
                // Make API request with the current value
                getPermissionData(defaultPageNumber, pageInfo?.per_page, searchQuery);
            }, delayTime);
            return () => {
                clearTimeout(delayDebounceFn);
            };
        }
    }, [searchQuery]);

    const handleClearSearch = () => {
        setSearchQuery("");
        getPermissionData(defaultPageNumber, pageInfo?.per_page, "");
    };


    // Handle search input change
    const handleSearchChange = e => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm);
    };

    // Handle column header click for sorting
    const handleSort = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrder);
        setSortedColumn(column);
    };

    // Handle pagination link click
    const handlePageChange = pageNumber => {
        getPermissionData(pageNumber, pageInfo?.per_page);
    };


    const handleDownloadButtonClick = () => {
        setDownloadLoader(true)
    }

    useEffect(() =>{
        if (data?.getAllPermissions){
            let stringToDownload = "Permission Name, Description\n";
            data?.getAllPermissions.forEach(permission => {
                stringToDownload += `"${permission?.permissionName}","${permission?.description}"\n`;
            });
            const blob = new Blob([stringToDownload], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `users_permission_report.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
            setDownloadLoader(false)
        }
    }, [data?.getAllPermissions])


    return (
            <PageWrapper heading={'Access Control Permissions'}>
                <TableWrapper>
                    <TableHeadingSection heading={'Permissions'} description={'All the permissions in the database.'} count={pageInfo?.total_records} />
                <TableButtonSection searchQuery={searchQuery} onSearchChange={handleSearchChange} onClearSearch={handleClearSearch}>
                        <div>
                            <Button
                                type="button"
                                onClick={handleDownloadButtonClick}
                                icon={faDownload}
                                loader={downloadLoader}
                                text="Download Report"
                                className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                            />
                        </div>
                    </TableButtonSection>
                    <TableContainer>
                        <thead className="bg-gray-50 dark:bg-neutral-800">
                            <tr>
                            <TableHeaderComponent columnKey="id" label="ID" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                            <TableHeaderComponent columnKey="permissionName" label="Permission Name" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                            <TableHeaderComponent columnKey="description" label="Description" handleSort={handleSort} sortedColumn={sortedColumn} sortOrder={sortOrder} />
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                        {permissionList?.map(permission=> (
                                <tr key={permission?.id}>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <span className="text-gray-700 dark:text-gray-200 ">{permission.id}</span>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                        <div className="">
                                            <h2 className="text-indigo-600 dark:text-indigo-400">{permission.permissionName}</h2>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-pre-line">
                                        <div className="">
                                            <p className="text-gray-700 dark:text-gray-400 overflow-hidden line-clamp-3">
                                                {permission.description}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        {!permissionList?.length && !loading && <TableNoRecordFound colSpan={3}></TableNoRecordFound>}
                            {loading && <TableLoadingData colSpan={3}></TableLoadingData>}
                        </tbody>
                    </TableContainer>
                </TableWrapper>
            <Pagination totalPages={loading ? 0 : pageInfo?.total_pages} totalRecords={loading ? 0 : pageInfo?.total_records}
                maxPageNumbers={maxPageNumber}
                recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0}
                currentPage={loading ? 0 : pageInfo?.current_page} onPageChange={handlePageChange} onRowChanges={handleRowChanges} ></Pagination>
            </PageWrapper>
    );
};

export default AccessControlPermissions;
