import React from 'react';
import PropTypes from 'prop-types';

const TableContainer = ({ children }) => {

    return (
        <div className="flex flex-col mt-6">
            <div className="w-full overflow-x-auto border border-gray-200 dark:border-neutral-700 md:rounded-lg">
                <div className="inline-block min-w-full align-middle">
                    <div className="overflow-hidden">
                        <table className="w-full divide-y divide-gray-200 dark:divide-neutral-700">
                            {children}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
TableContainer.propTypes = {
    children: PropTypes.any,
}

export default TableContainer;
