import React, { useRef, useState } from 'react';
import { formatSize } from '../../../utils/commonFormatFunctions';
import PropTypes from 'prop-types';
import Button from '../../button/Button';

const FileInputComponent = ({ label, required, name, multiple, maxSize, selectedFiles, handleFileChange, handleRemoveFile, disabled, acceptedData }) => {
    const [error, setError] = useState(null);
    const inputRef = useRef(null);

    const handleFileInputChange = e => {
        const toConvertIntoMb = 1024
        const newFiles = Array.from(e.target.files);
        const validFiles = newFiles.filter(file => {
            if (file.size / toConvertIntoMb  <= maxSize) {
                setError(null);
                return true;
            } else {
                setError(`File ${file.name} exceeds maximum size of ${maxSize / toConvertIntoMb} MB.`);
                return false;
            }
        });
        handleFileChange(validFiles);
    };

    return (
        <>
            {label &&
                <label htmlFor={name} className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200 mb-2">{label}
                    {required && <span className="text-red-600 dark:text-red-400 ml-1">*</span>}
                </label>
            }
            <div className={disabled ? "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-200 bg-gray-200 dark:bg-neutral-700 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 focus:outline-none" : "block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-200 dark:bg-neutral-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 focus:outline-none"}>
                <input
                    type="file"
                    className="block rounded-md border-0 px-3.5 py-2 mb-2 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 focus:outline-none"
                    multiple={multiple}
                    onChange={handleFileInputChange}
                    ref={inputRef}
                    disabled={disabled}
                    required={required}
                    accept={acceptedData}
                />
                {error && <p className="text-red-600 dark:text-red-400 mb-2">{error}</p>}
                <ul className="space-y-2">
                    {selectedFiles?.map(file => (
                        <li
                            key={file.name}
                            className="flex items-center justify-between p-2 bg-gray-100 dark:bg-neutral-700 rounded-md">
                            <span>{file.name} - {formatSize(file.size)}</span>
                            <Button
                                className="text-red-600 hover:text-red-400"
                                onClick={() => {
                                    handleRemoveFile(file)
                                }}
                                text="Remove"
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};


FileInputComponent.propTypes = {
    label: PropTypes.any,
    name: PropTypes.any,
    multiple: PropTypes.any,
    maxSize: PropTypes.any,
    selectedFiles: PropTypes.any,
    handleFileChange: PropTypes.any,
    required: PropTypes.any,
    handleRemoveFile: PropTypes.any,
    disabled: PropTypes.any,
    acceptedData: PropTypes.any,
}

export default FileInputComponent;