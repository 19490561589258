import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useRolePermissionsListUpdateMutation, useGetAllPermissionsQuery } from "../../../graphql/generated/graphql.ts";
import { toast } from "react-toastify";
import InputField from "../../../components/form/input/InputField.js";
import SelectMultipleInput from "../../../components/form/input/SelectMultipleInput.js";
import PageWrapper from "../../../layouts/PageWrapper.js";
import { formRolePermissionsSchema } from "../../../validations/FormValidationSchema.js";
import Button from "../../../components/button/Button.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import TextAreaInput from "../../../components/form/input/TextAreaInput.js";

const EditRoles = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [updateMute, { loading }] = useRolePermissionsListUpdateMutation();
    const { data: permissionData, loading: loader } = useGetAllPermissionsQuery({ variables: {}, });
    const permissionOption = permissionData?.getAllPermissions?.map(permission => ({
        label: permission.permissionName,
        value: permission.id
    }));

    useEffect(() => {
         const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Roles | Edit`;
        document.title = pageTitle;
    }, [])

    const initialRoles = (state?.roles?.permissions.map(option => ({
        value: option.id,
        label: option.permissionName
    })) || []);


    const [initialValues, setInitialValues] = useState({
        roleName: state?.roles?.roleName,
        roleDescription: state?.roles?.roleDescription,
        permissionName: initialRoles,
        roleId: state?.roles?.roleId,
    });

    const { values, errors, touched, setFieldValue, setFieldTouched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues,
        validationSchema: formRolePermissionsSchema,

        onSubmit: async (value, action) => {
            try {
                const rolePermissions = values.permissionName.map(permissionDetails => permissionDetails.value);
                const roleInfo = [{
                    roleId: value.roleId,
                    roleName: value.roleName?.trim(),
                    roleDescription: value.roleDescription?.trim(),
                    permissionIds: rolePermissions,
                }];
                updateMute({ variables: { rolePermissionsUpdate: roleInfo } })
                    .then(() => {
                        toast.success("Role details updated successfully.");
                        navigate("/access-control/roles")
                    })
                    .catch(error => {
                        toast.error(error.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                            autoClose: false,
                        });
                    });
                action.resetForm();
            } catch (err) {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
                // Handle error if needed
            }
        }
    });

    useEffect(() => {
        // Only update initialValues when the values.roleName or values.roleDescription change
        setInitialValues(prevValues => ({
            ...prevValues,
            roleName: values.roleName || [],
            roleDescription: values.roleDescription || []
        }));
    }, [values.roleName, values.roleDescription]);


    return (
        <PageWrapper heading={'Edit roles details'}>
            {loading || loader ? (
                <div className="flex justify-center items-center h-screen">
                    <div>
                        <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                        <span>Loading Data</span>
                    </div>
                </div>
            ) : (
                <div className="py-6">
                    <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                        <div className="col-span-4">
                            <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">General Information</h2>
                            <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Fill in the roles details</p>
                        </div>
                        <form method="POST" onSubmit={handleSubmit} className="col-span-8">
                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">

                                <div className="sm:col-span-2">
                                    <InputField type={'text'} name={'roleName'} label={'Role Name'} value={values.roleName} required onChange={handleChange} onBlur={handleBlur} error={(errors.roleName && touched.roleName) ? errors.roleName : ''} />
                                </div>
                                <div className="sm:col-span-2">
                                    <label htmlFor="roleDescription" className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">
                                        Descriptions
                                    </label>
                                    <TextAreaInput
                                        name="roleDescription"
                                        id="roleDescription"
                                        value={values.roleDescription}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-200 dark:bg-neutral-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 focus:outline-none"
                                        rows={4}
                                    />
                                    {errors.roleDescription && touched.roleDescription && (
                                        <div className="mt-2 text-red-500">{errors.roleDescription}</div>
                                    )}
                                </div>
                                <div className="sm:col-span-2">
                                    {state && (
                                        <SelectMultipleInput
                                            name="permissions"
                                            value={values.permissionName}
                                            options={permissionOption}
                                            required
                                            onChange={selectedRole => {
                                                setFieldValue('permissionName', selectedRole);
                                            }}
                                            onBlur={() => setFieldTouched('permissionName', true)}
                                            label="Select permissions"
                                            error={(errors.permissionName && touched.permissionName) ? errors.permissionName : ''}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="mt-10 flex justify-start">
                                {loading ? <Button type="button"
                                    className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                    text="Update" />
                                    : <Button type="submit"
                                        className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        text="Update" />
                                }
                                <Link to="/access-control/roles"
                                    className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                                {loading && <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </PageWrapper>
    );
};

export default EditRoles;




