import React from 'react';
import { faArrowDownShortWide, faArrowUpWideShort,} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const TableHeaderComponent = ({ columnKey, label, handleSort, sortedColumn, sortOrder }) => (
    <th
        scope="col"
        onClick={() => handleSort(columnKey)}
        className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap"
    >
        <div className="flex items-center gap-x-3 cursor-pointer">
            <span>{label}</span>
            {sortedColumn === columnKey && (
                <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />
            )}
        </div>
    </th>
);

TableHeaderComponent.propTypes = {
    columnKey: PropTypes.any,
    label: PropTypes.any,
    handleSort: PropTypes.func,
    sortedColumn: PropTypes.string,
    sortOrder: PropTypes.string,
};

export default TableHeaderComponent;