import InputField from "../../../components/form/input/InputField";
import SelectMultipleInput from "../../../components/form/input/SelectMultipleInput";
import PageWrapper from "../../../layouts/PageWrapper";
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { formCreateNewUserSchema } from "../../../validations/FormValidationSchema"
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useUserInfoListCreateMutation, useGetAllUserInfoListQuery } from "../../../graphql/generated/graphql.ts";
import { toast } from "react-toastify";
import Button from "../../../components/button/Button.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'


const AddNewUsers = () => {
  const navigate = useNavigate();
  const { data, loading: loader } = useGetAllUserInfoListQuery({ variables: {}, });
  const [createMutation, { loading, data: createdUserData }] = useUserInfoListCreateMutation();
  // This will run when the page first loads and whenever the title changes
  useEffect(() => {
    const envVariable = process.env.REACT_APP_ENV;
    const prefix = 'SIERA';
    const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
    const pageTitle = `${prefix}${suffix} | Manage Users | Create`;
    document.title = pageTitle;
  }, []);
  const defaultRoleId = 2;
  const defaultGroupId = 3;
  // Define default values for roles and groups
  const transformedOptionsRoles = data?.getAllRoles?.map(role => ({
    value: role?.id,
    label: role?.roleName ? role?.roleName : role?.groupName
  }));

  const transformedOptionsGroups = data?.getAllGroups?.map(group => ({
    value: group?.id,
    label: group?.groupName
  }));

  const getMappedData = (inputData, idToFilter, labelKey) => {
    return (inputData || []).filter(item => item.id === idToFilter).map(item => ({
      value: item.id,
      label: item[labelKey]
    })) || [];
  };

  const defaultRoles = getMappedData(data?.getAllRoles, defaultRoleId, 'roleName');
  const defaultGroups = getMappedData(data?.getAllGroups, defaultGroupId, 'groupName');

  // Initialize initial values with defaults
  const initializeValue = (selectedData, defaultValue) => selectedData ? defaultValue : null;

  const initialRoles = initializeValue(data, defaultRoles);
  const initialGroups = initializeValue(data, defaultGroups);
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    lineOfBusiness: "",
    jobTitle: "",
    is_agreed: false,
    roles: initialRoles,
    groups: initialGroups,
  });

  useEffect(() => {
    if (data) {
      setFieldValue('groups', [...initialGroups])
      setFieldValue(
        'roles', [...initialRoles],
      )
    }
  }, [data])



  const { values, errors, touched, setFieldValue, setFieldTouched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: formCreateNewUserSchema,
    onSubmit: value => {
      try {
        const roleIds = value?.roles?.map(role => role.value)
        const groupIds = value?.groups?.map(group => group.value)
        const givenName = value?.first_name.trim()?.charAt(0).toUpperCase() + value?.first_name.trim()?.slice(1);
        const familyName = value?.last_name.trim()?.charAt(0).toUpperCase() + value?.last_name.trim()?.slice(1);
        const userInfo = [{
          givenName, familyName, uniqueName: value?.email,
          groupIds, roleIds, jobTitle: value?.jobTitle, lineOfBusiness: value?.lineOfBusiness
        }]
        // Handle data if needed (e.g., check result.data)
        createMutation({ variables: { userInfoCreate: userInfo } })
          .then(() => {
            toast.success("User added successfully.")
          }).catch(error => {
            toast.error(error.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
              autoClose: false,
            });
          })
      } catch (err) {
        toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
          autoClose: false,
        });
        // Handle error if needed
      }
    }
  })

  useEffect(() => {
    if (createdUserData?.userInfoListCreate) {
      navigate(`/manage/users/${createdUserData?.userInfoListCreate?.[0]?.uniqueId}`)
    }
  }, createdUserData)

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      roles: values?.roles || [],
      groups: values?.groups || []
    });
  }, [values]);

  const getErrorMessage = (error, fieldTouched) => (error && fieldTouched ? error : '');

  return (
    <PageWrapper heading={'Create new user'}>
      {loader ? <div className="flex justify-center items-center h-screen">
        <td colSpan={5} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
          <div>
            <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
            <span>Loading Data</span>
          </div>
        </td>
      </div> :
        <div className="py-6">
          <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
            <div className="col-span-4">
              <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">General Information
              </h2>
              <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Fill in the user details and role based access privileges</p>
            </div>
            <form method="POST" onSubmit={handleSubmit} className="col-span-8">
              <div
                className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                <div>
                  <InputField type={'text'} name={'first_name'} disabled={loading} label={'First Name'} value={values.first_name} required onChange={handleChange} onBlur={handleBlur} error={getErrorMessage(errors.first_name, touched.first_name)} />
                </div>
                <div>
                  <InputField type={'text'} name={'last_name'} disabled={loading} label={'Last Name'} value={values.last_name} required onChange={handleChange} onBlur={handleBlur} error={getErrorMessage(errors.last_name, touched.last_name)} />
                </div>
                <div className="sm:col-span-2">
                  <InputField type={'email'} name={'email'} disabled={loading} label={'Email'} placeholder={"example@shell.com"} required value={values.email} onChange={handleChange} onBlur={handleBlur} error={getErrorMessage(errors.email, touched.email)} />
                </div>
                <div className="sm:col-span-2">
                  <InputField type={'lineOfBusiness'} disabled={loading} name={'lineOfBusiness'} label={'Line of Business'} value={values.lineOfBusiness} onChange={handleChange} onBlur={handleBlur} error={getErrorMessage(errors.lineOfBusiness, touched.lineOfBusiness)} />
                </div>
                <div className="sm:col-span-2">
                  <InputField type={'jobTitle'} disabled={loading} name={'jobTitle'} label={'Job Title'} value={values.jobTitle} onChange={handleChange} onBlur={handleBlur} error={getErrorMessage(errors.jobTitle, touched.jobTitle)} />
                </div>
                <div className="sm:col-span-2">
                  <SelectMultipleInput
                    name="roles"
                    value={values.roles}
                    options={transformedOptionsRoles}
                    required
                    onChange={selectedRole => {
                      setFieldValue('roles', selectedRole)
                    }}
                    onBlur={() => setFieldTouched('roles', true)}
                    label="Select roles"
                    disabled={loading}
                    error={(errors.roles && touched.roles) ? errors.roles : ''}
                  />
                </div>
                <div className="sm:col-span-2">
                  <SelectMultipleInput name='groups' value={values.groups} options={transformedOptionsGroups} required
                    onChange={selectedGroup => setFieldValue('groups', selectedGroup)}
                    onBlur={() => setFieldTouched('groups', true)}
                    disabled={loading}
                    label={'Select groups'} error={(errors.groups && touched.groups) ? errors.groups : ''} />
                </div>
                <div className="sm:col-span-2">
                  <div className="mt-2.5">
                    <fieldset>
                      <div className="">
                        <div className="relative flex gap-x-3">
                          <div className="flex h-6 items-center">
                            <input id="is_agreed" name="is_agreed" type="checkbox" checked={values.is_agreed} onChange={handleChange} onBlur={handleBlur} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                          </div>
                          <div className="text-sm leading-6">
                            <label htmlFor="is_agreed" className="font-medium text-gray-900 dark:text-gray-200">I agree to user terms & conditions</label>
                            <p className="text-gray-600 dark:text-gray-400">The user is authorized to have given access privileges.</p>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                    {errors.is_agreed && touched.is_agreed && <span className="text-sm text-red-600 dark:text-red-400  font-semibold">{errors.is_agreed}</span>}
                  </div>
                </div>

              </div>
              <div className="mt-10 flex justify-start">
                {loading ? <Button type="button"
                  className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  text="Create" />
                  : <Button type="submit"
                    className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    text="Create" />
                }
                <Link to='/manage/users'
                  className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                {loading && <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
              </div>
            </form>
          </div>
        </div>}
    </PageWrapper>
  );
};

export default AddNewUsers;
