import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import PageWrapper from "../layouts/PageWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { requestAccessTokenSimmas } from '../utils/AuthClient';


const Welcome = () => {
  const navigate = useNavigate();

  useEffect(() => {
    verifyAuthenticatedUser();
  });


  const verifyAuthenticatedUser = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    if (code) {
      await requestAccessTokenSimmas(code);
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser !== null) {
        const redirectUrl = sessionStorage.getItem('redirectPath');
        if (redirectUrl) {
          navigate(redirectUrl)
          sessionStorage.removeItem('redirectPath');
        } else {
          navigate('/home');
        }
      } else {
        navigate('/login');
      }
    }
    else {
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser !== null) {
        navigate("/home");
      } else {
        navigate("/login");
      }

    }
  };

  return (
      <PageWrapper heading={'Verifying'}>
        <div className="flex flex-row items-center p-4">
          <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
          <span>Redirecting...</span>
        </div>
      </PageWrapper>
  );
};

export default Welcome;
