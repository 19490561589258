import PageWrapper from "../../../layouts/PageWrapper";
import SelectSingleInput from "../../../components/form/input/SelectSingleInput";
import { useFormik } from "formik";
import { formCreateEditDocumentSchema } from "../../../validations/FormValidationSchema"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUpdateMutationForFileMetadataMutation, useGetFileUploadSettingsQuery, useGetDocsMetadataForAdminQuery } from '../../../graphql/generated/graphql.ts'
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import FileInputComponent from "../../../components/form/input/FileInputComponent";
import React, { useEffect, useState } from "react";
import Button from "../../../components/button/Button";
import { ReplaceFile } from '../../../graphql/graphql.queries'
import * as Auth from "../../../utils/AuthClient"; {/* NOSONAR */ }
import axios from "axios";
import CheckboxButton from "../../../components/form/input/CheckboxButton.js";
import InputField from "../../../components/form/input/InputField.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'

const EditDocuments = () => {
    const navigate = useNavigate();
    const state = useLocation()?.state || {};
    const [selectedFiles, setSelectedFiles] = useState([]);
    const { documentData, } = state;
    const [replaceLoader, setReplaceLoader] = useState(false)
    const [editDoc, { loading }] = useUpdateMutationForFileMetadataMutation();
    const { data: maxUploadSizeData } = useGetFileUploadSettingsQuery();
    const { data: metaDataQuery, loading: loader } = useGetDocsMetadataForAdminQuery();
    const { getAllDocumentsFolders, getAllSenestivityLabel } = metaDataQuery || {};
    const { KB } = maxUploadSizeData?.getFileUploadSettings?.maxUploadSize || {};
    // Helper function to create folder select options
    const createFolderSelectOptions = (folders = []) =>
        folders.map(option => ({
            value: option.id,
            label: option.display_name,
        }));

    // Use helper functions to create options and set initial values
    const folderSelectOptions = createFolderSelectOptions(getAllDocumentsFolders);
    const folderName = folderSelectOptions.find(folderDetails => folderDetails.value === documentData?.folder_id) || null;
    // Helper function to create sensitivity label options
    const createDocSensitivityOptions = (sensitivityLabels = []) =>
        sensitivityLabels.map(option => ({
            value: option?.key,
            label: option?.value,
        }));

    const docSensitivityOptions = createDocSensitivityOptions(getAllSenestivityLabel);
    const sensitivityDoc = docSensitivityOptions.find(
        element => element.label.toLowerCase() === documentData?.sensitivity_label?.toLowerCase()
    ) || null;

    // Set initial form values
    const getInitialFormValues = (selectedDocumentData, sensitivityDocument, selectedFolderName) => ({
        document_name: selectedDocumentData?.fileName || '',
        sensitivityLabel: sensitivityDocument || [],
        folder: selectedFolderName || null,
        is_support: selectedDocumentData?.is_support || false,
        is_notify: false,
    });
    const initialValues = getInitialFormValues(documentData, sensitivityDoc, folderName);

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Documents | Edit | ${values?.document_name}`;
        document.title = pageTitle;
    }, [])

    const { values, errors, touched, setFieldValue, setFieldTouched, handleSubmit, handleChange, handleBlur } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: formCreateEditDocumentSchema,
        onSubmit: value => {
            const filedata = {
                fileName: value?.document_name?.trim(),
                folder_id: value?.folder?.value,
                roleIds: [1],
                id: documentData?.id || documentData?.folder_id,
                is_support: value?.is_support,
                file_bytes: documentData?.file_bytes,
                file_url: documentData?.fileURL,
                notify_user: value?.is_notify || false,
                sensitivity_label: value?.sensitivityLabel?.label.toLowerCase()
            };
            if (selectedFiles.length>0) {
                setReplaceLoader(true)
                handleReplaceDocument();
            }
            editDoc({ variables: { fileMetadataListForUpdate: filedata } })
                .then(() => {
                    toast.success('Document updated successfully.')
                    !replaceLoader && navigate('/manage/documents')
                })
                .catch(error => {
                    toast.error(error.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                        autoClose: false,
                    });
                })
        }
    });

    const handleFileChange = newFiles => {
        setSelectedFiles([...selectedFiles, ...newFiles]);
    };
    const handleRemoveFile = file => {
        setSelectedFiles(selectedFiles.filter(selectedFile => selectedFile !== file));
    };

    const handleReplaceDocument = async () => {
        try {
            // Creating variables object
            const variables = {
                "req": {
                    "fileUrl": documentData?.fileURL,
                    "file": selectedFiles[0],
                    "notifyUser": values?.is_notify,
                    "sensitivityLabel": documentData?.sensitivity_label,
                    "autoDownload": false,
                    fileName: values?.document_name
                }
            }
            // Creating FormData for the request
            const data = new FormData();
            data.append("operations", JSON.stringify({ query: ReplaceFile, variables }));
            data.append('map', JSON.stringify({ "0": ["variables.req.file"] }))
            data.append('0', selectedFiles[0])
            // Retrieving token data from localStorage
            const tokenData = JSON.parse(localStorage.getItem('user'))
            const token = tokenData?.access_token
            // Checking token expiration
            const tokenExpiration = tokenData?.expiry_time.exp
            const dateNow = new Date();
            const timeSecond = 1000
            if (tokenExpiration < dateNow.getTime() / timeSecond) {
                await Auth.requestRefreshTokenSimmas(tokenData.refresh_token);
            }
            // Making the GraphQL request
            const response = await axios({
                method: "post",
                url: process.env.REACT_APP_GRAPHQLHOSTUPLOAD,
                data,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`
                },
            });
            // Handling response
            const responseData = response?.data?.errors
            if (!responseData) {
                toast.success(`Document Replaced successfully.`)
                setReplaceLoader(false)
                navigate('/manage/documents')
                setSelectedFiles([])
            } else {
                toast.error(responseData[0]?.message, {
                    autoClose: false,
                });
            }
        } catch (err) {
            // Handling errors
            toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                autoClose: false,
            });
        }
    }

    return (
        <PageWrapper heading={'Edit document'}>
            {loader ? <div className="flex justify-center items-center h-screen">
                <td colSpan={5} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
                    <div>
                        <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                        <span>Loading Data</span>
                    </div>
                </td>
            </div> :
                <div className="py-6">
                    <div className="grid grid-cols-1 xl:grid-cols-12 gap-4 mb-6">
                        <div className="col-span-4">
                            <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">General Information
                            </h2>
                            <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Update in the document details</p>
                        </div>
                        <form method="POST" onSubmit={handleSubmit} className="col-span-8">
                            <div
                                className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                                <div className="sm:col-span-2">
                                    <InputField type={'text'} disabled={loading} name={'document_name'} label={'Document Name'} value={values.document_name} onChange={handleChange} onBlur={handleBlur} required error={(errors.document_name && touched.document_name) ? errors.document_name : ''} />
                                </div>
                                <div className="sm:col-span-2">
                                    <SelectSingleInput name={'folder'} value={values.folder} options={folderSelectOptions} label={'Select folder'}
                                        onChange={selectedOption => setFieldValue('folder', selectedOption)} disabled
                                        onBlur={() => setFieldTouched('folder', true)} 
                                        required error={(errors.folder && touched.folder) ? errors.folder : ''} />
                                </div>
                                <div className="sm:col-span-2  capitalize">
                                    <SelectSingleInput name={'sensitivityLabel'} value={values.sensitivityLabel} options={docSensitivityOptions} label={'sensitivityLabel'}
                                        onChange={selectedOption => setFieldValue('sensitivityLabel', selectedOption)}
                                        onBlur={() => setFieldTouched('sensitivityLabel', true)} disabled={loading}
                                        required error={(errors.sensitivityLabel && touched.sensitivityLabel) ? errors.sensitivityLabel : ''} />
                                </div>
                                <div className="sm:col-span-2  capitalize">
                                    <FileInputComponent
                                        label={'Replace file'}
                                        name={'uploaded_doc'}
                                        multiple={false}
                                        maxSize={KB}
                                        required={false}
                                        selectedFiles={selectedFiles}
                                        acceptedData=".txt, .doc, .docx, .pdf, .ppt, .pptx, .csv, .xls, .xlsx, .odt, .odg, .ods, .odp, image"
                                        handleFileChange={handleFileChange}
                                        handleRemoveFile={handleRemoveFile}
                                        dissabled={false}
                                    />
                                </div>
                                <div className="sm:col-span-2">
                                    <div className="mt-2.5">
                                        <fieldset>
                                            <div className="space-y-4">
                                                <div className="relative flex gap-x-3">
                                                    <CheckboxButton
                                                        name="is_support"
                                                        label="Mark as Support Document"
                                                        value={values?.is_support}
                                                        checked={values?.is_support}
                                                        onChange={e => setFieldValue("is_support", e.target.checked)}
                                                        description="The support documents will be visible in support page of the mobile application."
                                                        disabled={loading}
                                                    />
                                                </div>
                                            </div>
                                            <div className="sm:col-span-2 mt-5">
                                                <CheckboxButton
                                                    name="is_notify"
                                                    label="Notify to users"
                                                    value={values?.is_notify}
                                                    checked={values?.is_notify}
                                                    onChange={e => setFieldValue("is_notify", e.target.checked)}
                                                    description="This will enable in-app notification to end users."
                                                    disabled={loading}
                                                />
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-10 flex justify-start">
                                {loading || replaceLoader ? <Button type="button"
                                    className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                    text="Update" />
                                    : <Button type="submit"
                                        className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        text="Update" />
                                }
                                <Link to="/manage/documents"
                                    className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                                {loading || replaceLoader && <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                            </div>
                        </form>
                    </div>
                    <hr />
                    {/* <div className="py-6">
                        <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                            <div className="col-span-4">
                                <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">File Information
                                </h2>
                                <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Replace the uploaded document</p>
                            </div>
                            <div className="sm:col-span-8">
                                <FileInputComponent
                                    name={'uploaded_doc'}
                                    multiple={false}
                                    maxSize={KB}
                                    selectedFiles={selectedFiles}
                                    acceptedData=".txt, .doc, .docx, .pdf, .ppt, .pptx, .csv, .xls, .xlsx, .odt, .odg, .ods, .odp, image"
                                    handleFileChange={handleFileChange}
                                    handleRemoveFile={handleRemoveFile}
                                    dissabled={false}
                                />
                                <div className="mt-10 flex justify-start">
                                    {replaceLoader ? <Button type="button"
                                        className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                        text="Replace" />
                                        : <Button type="submit"
                                            className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            text="Replace"
                                            onClick={() => { handleReplaceDocument() }} />
                                    }
                                    <Link to="/manage/documents"
                                        className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                                    {replaceLoader && <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                                </div>
                            </div>

                        </div>
                    </div> */}
                </div>}
        </PageWrapper>
    );
};

export default EditDocuments;
