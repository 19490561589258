import React, { useContext, useEffect, useState } from 'react';
import logo from './../../assets/logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import UseContext from '../../utils/UseContext';
import Button from '../button/Button';


const NavBar = ({ toggleSidebar, handleSignout }) => {
  const navigate = useNavigate();
  const [openAccountDrawer, setOpenAccountDrawer] = React.useState(false);
  const [darkMode, setDarkMode] = useState(JSON.parse(localStorage.getItem('darkMode')));
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const { isAuthenticated, user,
  } = useContext(UseContext);
  const firstName = storedUser?.id_claims?.givenName?.charAt(0) || '';
  const lastName = storedUser?.id_claims?.sn?.charAt(0) || '';

  const accountDrawerClick = () => {
    setOpenAccountDrawer(!openAccountDrawer);
  };

  useEffect(() => {
    const body = document.body;
    if (darkMode) {
      body.classList.add('dark');
    } else {
      body.classList.remove('dark');
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem("darkMode", newDarkMode);
    document.body.classList.toggle('dark');
  };


  return (
    <nav className="bg-neutral-950 border-b-2 dark:border-b border-[#FFCD00] fixed z-50 z-top-0 w-full shadow-lg">
      <div className="px-4">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button type="button" onClick={toggleSidebar}
              className="mr-3 md:inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
              <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <button type="button" onClick={toggleSidebar}
              className="mr-3 md:inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white hidden xs:inline-flex"
              aria-controls="mobile-menu" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
              <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="flex flex-shrink-0 items-center px-4"> {/* Added px-4 for padding */}
              <img className="block h-8 w-auto lg:hidden rounded-md" src={logo} alt="Your Company" />
              <img className="hidden h-8 w-auto lg:block rounded-md" src={logo} alt="Your Company" />
              <span className="text-lg font-bold text-white ml-4 hidden lg:block">Shell IT Enterprise Recovery Application</span>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <button type="button" onClick={toggleDarkMode}
              className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
              <span className="sr-only">View notifications</span>
              <div className='w-6 h-6'><FontAwesomeIcon icon={darkMode ? faSun : faMoon} /></div>
            </button>

            {isAuthenticated && user !== null &&
              <div className="relative ml-3">
                <div>
                  <button type="button"
                    className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    id="user-menu-button" aria-expanded="false" aria-haspopup="true" onClick={accountDrawerClick}>
                    <span className="sr-only">Open user menu</span>
                    <div className="h-10 w-10 rounded-full ">
                      <p className=" justify-center mt-2 font-bold text-gray-400 hover:text-white">{firstName}{lastName}</p>
                    </div>
                  </button>
                </div>
                {openAccountDrawer &&
                  <div
                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-neutral-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabIndex="-1">
                    <button className="w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-neutral-700 font-semibold" role="menuitem" tabIndex="-1"
                      id="user-menu-item-0" onClick={() => {
                        accountDrawerClick();
                        navigate(`/manage/users/${localStorage.getItem('uniqueId')}`)
                      }}>My Profile</button>
                    <button className="w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-neutral-700 font-semibold" role="menuitem" tabIndex="-1"
                      id="user-menu-item-1" onClick={() => {
                        accountDrawerClick()
                        navigate('/setting/systemSetting')
                      }}>Settings</button>
                    <Button onClick={handleSignout} className="w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-neutral-700 font-semibold" role="menuitem" tabIndex="-1"
                      id="user-menu-item-2" text="Log out" />
                  </div>
                }
              </div>
            }
            {!isAuthenticated && user == null && <Link to={'/login'}
              className="ml-3 text-sm font-semibold rounded-full bg-indigo-600 px-4 py-1 text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
              Login
            </Link>}
          </div>
        </div>
      </div>
    </nav>
  );
};

NavBar.propTypes = {
  toggleSidebar: PropTypes.any,
  handleSignout: PropTypes.any
}


export default NavBar;
