import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownShortWide, faArrowUpWideShort, faClockRotateLeft, } from '@fortawesome/free-solid-svg-icons'
import PageWrapper from "../../../layouts/PageWrapper";
import Pagination from "../../../components/table/Pagination";
import TableWrapper from "../../../components/table/TableWrapper";
import TableContainer from "../../../components/table/TableContainer";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound";
import TableLoadingData from "../../../components/table/TableLoadingData";
import TableHeadingSection from "../../../components/table/TableHeadingSection";
import TableButtonSection from "../../../components/table/TableButtonSection";
import { useRecoverArchivedUsersMutationMutation, useGetCurrentUserPermissionsQuery, useGetAllArchivedUserPagesMutation } from "../../../graphql/generated/graphql.ts";
import DialogModal from "../../../components/modal/DialogModal";
import { toast } from "react-toastify";
import Button from "../../../components/button/Button";
import { formatTimestamp } from "../../../utils/commonFormatFunctions.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'



const AuditArchivedUsers = () => {
    const navigate = useNavigate();
    const [archivedUserInfoMutation, { data, error, loading, }] = useGetAllArchivedUserPagesMutation();
    const { archivedUsersList, pageInfo } = data?.getAllArchivedUserPages || {};
    const [recoverMutation, { loading: loader }] = useRecoverArchivedUsersMutationMutation();
    const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
    const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
    const permissionAPIList = permissionData?.getAllAPIPermissions
    const updateUserInfo = permissionAPIList?.filter(item => item.accessType === "UpdateUserInfo")
    const editUserPermission = updateUserInfo?.length && updateUserInfo[0].assignedPermissions.some(val => addPermission.includes(val));
    const [searchQuery, setSearchQuery] = useState('');
    const [sortedColumn, setSortedColumn] = useState('givenName');
    const [sortOrder, setSortOrder] = useState('ASC');
    const [isOpen, setIsOpen] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [deletedItem, setDeletedItem] = useState([]);
    const [recordsPerPageData, setRecordsPerPageData] = useState(pageInfo?.per_page)
    const maxPageNumber = 5;
    const defaultPageNumber = 1;

    if (error) {
        navigate('/error', { state: { errorData: JSON.stringify(error) } });
    }

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Archived Users`;
        document.title = pageTitle;
    }, [data]);

    useEffect(() => {
        getArchivedUserData(defaultPageNumber, pageInfo?.per_page);
    }, [sortOrder, sortedColumn]);

    useEffect(() => {
        const delayTime = 1000;
        if (searchQuery) {
            const delayDebounceFn = setTimeout(() => {
                // Make API request with the current value
                getArchivedUserData(defaultPageNumber, pageInfo?.per_page, searchQuery);
            }, delayTime);
            return () => {
                clearTimeout(delayDebounceFn);
            };
        }
    }, [searchQuery]);

    const handleClearSearch = () => {
        setSearchQuery("");
        getArchivedUserData(defaultPageNumber, pageInfo?.per_page, "");
    };

    const getArchivedUserData = (page = defaultPageNumber, row = pageInfo?.per_page, search_term = searchQuery) => {
        setDeletedItem([])
        setSelectedItems([]); setSelectAll(false)
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term,
            search_column: "",
            order_by: sortOrder,
            order_by_column: sortedColumn,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        };

        archivedUserInfoMutation({ variables: { PageInfoInput } })
            .catch(e => {
                toast.error(e.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    };

    // Handle search input change
    const handleSearchChange = e => {
        const searchTerm = e.target.value;
        setSearchQuery(searchTerm);
    };

    // Handle column header click for sorting
    const handleSort = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrder);
        setSortedColumn(column);
    };

    // Handle pagination link click
    const handlePageChange = pageNumber => {
        getArchivedUserData(pageNumber, pageInfo?.per_page);
    };

    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getArchivedUserData(defaultPageNumber, rowValue);
    };

    const openModal = () => {
        if (selectedItems.length) {
            setIsOpen(true)
        }
    };

    const closeModal = () => {
        setIsOpen(false)
        setDeletedItem([])
        setSelectedItems([])
    };

    const handleCheckboxChange = itemId => {
        if (selectedItems.includes(itemId)) {
            setSelectedItems(selectedItems?.filter(uniqueId => uniqueId !== itemId));
        } else {
            setSelectedItems([...selectedItems, itemId]);
        }
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(archivedUsersList?.map(item => item.uniqueId));
        }
        setSelectAll(!selectAll);
    };

    const handleArchiveUser = user => {
        const uniqueId = [user?.uniqueId];
        setDeletedItem(uniqueId);
        setIsOpen(true);
    }

    const onClickDelete = selectedItem => {
        recoverMutation({ variables: { uniqueId: selectedItem } })
            .then(() => {
                toast.success("User recovered successfully.")
                closeModal();
                getArchivedUserData();
                setSelectedItems([]);
            }).catch(err => {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            })
    }

    return (
        <>
            <PageWrapper heading={'Audit Archived Users'}>
                <TableWrapper>
                    <TableHeadingSection heading={'Users'} description={'All the deleted users in the database.'} count={pageInfo?.total_records} />
                    <TableButtonSection searchQuery={searchQuery} onSearchChange={handleSearchChange} onClearSearch={handleClearSearch}>
                        {editUserPermission && selectedItems?.length > 0 && <div>
                            <Button
                                type="button"
                                onClick={openModal}
                                className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-indigo-500 rounded-lg shrink-0 gap-x-2 hover:bg-indigo-600 dark:hover:bg-indigo-500 dark:bg-indigo-600"
                                icon={faClockRotateLeft}
                                text="Recover"
                            />
                        </div>}
                    </TableButtonSection>
                    <TableContainer>
                        <thead className="bg-gray-50 dark:bg-neutral-800">
                            <tr>
                                <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold">
                                    <div className="flex h-6 items-center">
                                        <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('givenName')} className="py-3.5 px-4 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>First Name</span>
                                        {sortedColumn === "givenName" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('familyName')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Last Name</span>
                                        {sortedColumn === "familyName" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('uniqueName')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Email</span>
                                        {sortedColumn === "uniqueName" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('created_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Created On</span>
                                        {sortedColumn === "created_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" onClick={() => handleSort('deleted_at')} className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                        <span>Deleted On</span>
                                        {sortedColumn === "deleted_at" && <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />}
                                    </div>
                                </th>
                                <th scope="col" className="px-4 py-3.5 text-sm text-center rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                            {archivedUsersList?.map(user => (
                                <tr key={user?.uniqueId}>
                                    <td className="px-4 py-3 text-sm font-medium">
                                        <div className="flex h-6 items-center">
                                            <input type="checkbox" checked={selectedItems.includes(user?.uniqueId)} onChange={() => handleCheckboxChange(user.uniqueId)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                        <div>
                                            <h2 className="font-semibold text-indigo-600 dark:text-indigo-400 ">{user.givenName}</h2>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="w-40">
                                            <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{user.familyName}</h2>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="">
                                            <p className="text-gray-700 dark:text-gray-200">{user.uniqueName}</p>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(user.created_at)}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div>
                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(user.deleted_at)}</h4>
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                        <div className="flex item-center justify-center text-gray-700 dark:text-gray-200">
                                            {editUserPermission ? <button className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" title="Reset">
                                                <FontAwesomeIcon className="stroke-2" icon={faClockRotateLeft} onClick={() => { handleArchiveUser(user) }} />
                                            </button> : <button className="w-4 mr-2 transform text-hray-500 " title="Reset">
                                                <FontAwesomeIcon className="stroke-2" icon={faClockRotateLeft} />
                                            </button>}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {!archivedUsersList?.length && !loading && <TableNoRecordFound colSpan={8}></TableNoRecordFound>}
                            {loading && <TableLoadingData colSpan={8}></TableLoadingData>}
                        </tbody>
                    </TableContainer>
                </TableWrapper>
                <Pagination totalPages={loading ? 0 : pageInfo?.total_pages} totalRecords={loading ? 0 : pageInfo?.total_records}
                    maxPageNumbers={maxPageNumber}
                    recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0}
                    currentPage={loading ? 0 : pageInfo?.current_page} onPageChange={handlePageChange} onRowChanges={handleRowChanges} ></Pagination>
            </PageWrapper>
            <DialogModal
                isOpen={isOpen}
                onClose={closeModal}
                title="Recover User"
                closeOnClickOutside={false} // Set this prop as needed
                pTagData={`Do you confirm to Recover ${deletedItem?.length || selectedItems?.length} users?`}
                btnOne="Confirm Recover"
                btnTwo="Cancel"
                loader={loader}
                onClickDeleteModal={() => { onClickDelete(deletedItem.length > 0 ? deletedItem : selectedItems) }}>
            </DialogModal>
        </>
    );
};

export default AuditArchivedUsers;