import { Link, useNavigate, useParams } from "react-router-dom";
import {
    useGetDocumentsFolderByIdMutation, useFileMetadataForAdminQuery,
    useDeleteDocumentsFolderMutation, useDeleteBulkFileMetadataByUrlMutation, useGetDocsMetadataForAdminQuery,
    useGetCurrentUserPermissionsQuery, useGetDocumentsByFolderIdPagesMutation
} from "../../../graphql/generated/graphql.ts";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowDownShortWide,
    faArrowUpWideShort,
    faPlus,
    faEdit,
    faDownload,
    faTrashCan,
    faSpinner
} from '@fortawesome/free-solid-svg-icons'
import PageWrapper from "../../../layouts/PageWrapper";
import Pagination from "../../../components/table/Pagination";
import TableWrapper from "../../../components/table/TableWrapper";
import TableContainer from "../../../components/table/TableContainer";
import TableNoRecordFound from "../../../components/table/TableNoRecordFound";
import TableLoadingData from "../../../components/table/TableLoadingData";
import { ArrowDownTrayIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { toast } from "react-toastify";
import TableButtonSection from "../../../components/table/TableButtonSection.js";
import Button from "../../../components/button/Button.js";
import DialogModal from "../../../components/modal/DialogModal.js";
import { formatSize, formatTimestamp, getFileExtension } from "../../../utils/commonFormatFunctions.js";
import axios from "axios";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import PropTypes from 'prop-types';

const SortableHeader = ({ label, sortKey, sortedColumn, sortOrder, onSort }) => (
    <th
        scope="col"
        onClick={() => onSort(sortKey)}
        className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap cursor-pointer"
    >
        <div className="flex items-center gap-x-3">
            <span>{label}</span>
            {sortedColumn === sortKey && (
                <FontAwesomeIcon className="h-3" icon={sortOrder === "ASC" ? faArrowDownShortWide : faArrowUpWideShort} />
            )}
        </div>
    </th>
);

SortableHeader.propTypes = {
    label: PropTypes.string.isRequired,
    sortKey: PropTypes.string.isRequired,
    sortedColumn: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
    onSort: PropTypes.func.isRequired,
};

const FolderDetails = () => {
    const navigate = useNavigate();
    const perPageRecord = 10;
    const { uniqueId } = useParams();
    const [sectionDoc, setSectionDoc] = useState()
    const [sortedColumn, setSortedColumn] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [deletedItem, setDeletedItem] = useState([]);
    const [recordsPerPageData, setRecordsPerPageData] = useState(perPageRecord);
    const maxPageNumber = 5;
    const { loading: loader, error: errorData, data: docData } = useFileMetadataForAdminQuery()
    const [getUserDetailsByIdMutation, { data, loading, error }] = useGetDocumentsFolderByIdMutation({
        variables: {
            uniqueId
        },
    });
    const { data: fileMetadataForAdmin, loading: load } = useGetDocsMetadataForAdminQuery({ variables: {}, });
    const [getdocbyFolderId, { data: docPaginationData, error: paginationError, loading:paginationLoader }] = useGetDocumentsByFolderIdPagesMutation()
    const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
    const { getAllDocumentsFolders, getAllSenestivityLabel } = fileMetadataForAdmin || {};
    const [deleteFolder, { loading: deleteFolderLoader }] = useDeleteDocumentsFolderMutation();
    const [deleteDocInBulkByUrl, { loading: deleteDocLoader }] = useDeleteBulkFileMetadataByUrlMutation();
    const { fileMetadataList, pageInfo } = docPaginationData?.getDocumentsByFolderIdPages ||{};
    const folderData = data?.getDocumentsFolderById;
    const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
    const permissionAPIList = permissionData?.getAllAPIPermissions
    const createDocuments = permissionAPIList?.filter(item => item.accessType === "CreateDocuments")
    const deleteDocuments = permissionAPIList?.filter(item => item.accessType === "DeleteDocuments")
    const updateDocuments = permissionAPIList?.filter(item => item.accessType === "UpdateDocuments")
    // Helper function to check permissions
    const hasPermission = (documents, permissionToCheck) =>
        documents?.length && documents[0].assignedPermissions.some(val => permissionToCheck.includes(val));
    const editUserPermission = hasPermission(updateDocuments, addPermission);
    const addUserPermission = hasPermission(createDocuments, addPermission);
    const deleteUserPermission = hasPermission(deleteDocuments, addPermission);
    // Filter sections based on accessType
    const deleteSections = permissionAPIList?.filter(item => item.accessType === "DeleteSections");
    const updateSections = permissionAPIList?.filter(item => item.accessType === "UpdateSections");
    // Folder permission checks
    const editfolderPermission = hasPermission(updateSections, addPermission);
    const deletefolderPermission = hasPermission(deleteSections, addPermission);
    const defaultPageNumber = 1

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Folders | Details | ${folderData?.display_name}`;
        document.title = pageTitle;
    }, [data])

    useEffect(() => {
        getUserDetailsByIdMutation();
    }, [getUserDetailsByIdMutation]);

    // This will run when the page first loads and whenever the title changes
    useEffect(() => {
        getDocDataById();
    }, []);
    useEffect(() =>{
        fileMetadataList?.forEach(item =>{
            item.folder_id = uniqueId;
        })
    },[fileMetadataList])

    const getDocDataById = (page = defaultPageNumber, row = pageInfo?.per_page, search_term = searchQuery) => {
        setDeletedItem([])
        setSelectedItems([]); setSelectAll(false)
        const PageInfoInput = {
            current_page: page,
            per_page: row,
            search_term,
            search_column: "",
            order_by: sortOrder,
            order_by_column: sortedColumn,
            startTime: 0,
            endTime: 0,
            time_window_column: ""
        };

        getdocbyFolderId({ variables: { PageInfoInput, uniqueId  } })
            .catch(e => {
                toast.error(e.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            });
    };

    function navigationError () {
        if (error || errorData) { //NOSONAR
            const errorValue = error || errorData
            navigate('/error', { state: { errorData: JSON.stringify(errorValue) } });
        }
        if (paginationError) {
            navigate('/error', { state: { errorData: JSON.stringify(paginationError) } });
        }

    }

    navigationError();

    const formatBytes = (bytes, decimals = 0) => {
        if (!+bytes) {
            return '0 Bytes'
        }
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    useEffect(() => {
        if (folderData && docData) {
            const filteredData = docData?.fileMetadataForAdmin?.filter(item => item.filePath === folderData.storage_path);
            const documentData = filteredData.map(item => ({
                ...item,
                created_at: item.created_at,
                updated_at: item.updated_at,
                size: formatBytes(item.file_bytes),
                is_support: item.is_support,
                created_at_intial: item.created_at,
                updated_at_intial: item.updated_at,
                size_byte: item.file_bytes,
                sensitivity_label: item.sensitivity_label.toLowerCase().replace(/\b[a-z]/g, letter => letter.toUpperCase()),
            }));
            setSectionDoc(documentData);
        }
    }, [docData, folderData]);

    useEffect(() => {
        getDocDataById(defaultPageNumber, pageInfo?.per_page);
    }, [sortOrder, sortedColumn]);

    useEffect(() => {
        const delayTime = 1000;
        if (!searchQuery) {
            getDocDataById(defaultPageNumber, pageInfo?.per_page, "");
           return () => { };// No need to proceed if searchQuery is empty
        }
        const delayDebounceFn = setTimeout(() => {
            // Make API request with the current value
            getDocDataById(defaultPageNumber, pageInfo?.per_page, searchQuery);
        }, delayTime);
        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [searchQuery]);

    const handleSort = column => {
        const newSortOrder = column === sortedColumn && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newSortOrder);
        setSortedColumn(column);
    };

    const handlePageChange = pageNumber => {
        getDocDataById(pageNumber, pageInfo?.per_page);
    };
    const handleRowChanges = rowValue => {
        setRecordsPerPageData(rowValue)
        getDocDataById(defaultPageNumber, rowValue);
    };
    // Handle search input change
    const handleSearchChange = e => {
        setSearchQuery(e.target.value);
    };

    const handleClearSearch = () => {
        setSearchQuery("");
        getDocDataById(defaultPageNumber, pageInfo?.per_page, "");
    };

    //function to download document in csv formate. 
    const handleDownloadButtonClick = () => {
        let stringToDownload = "File Name, Support Document, Folder, Size, Created On, Updated On \n";
        sectionDoc.forEach(value => {
            stringToDownload = `${stringToDownload}${value.fileName},${(value.is_support ? 'Yes' : 'No')},${value.section},${value.size},${formatTimestamp(value.created_at)},${formatTimestamp(value.updated_at)} \n`
        });
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:attachment/text,' + encodeURI(stringToDownload);
        hiddenElement.target = '_blank';
        const date = new Date();
        hiddenElement.download = `documents_report ${date.toString()} .csv`
        hiddenElement.click();
    }

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(fileMetadataList?.map(item => item.fileURL));
        }
        setSelectAll(!selectAll);
    };
    const openModal = () => {
        if (selectedItems.length) {
            setIsOpen(true)
        }
    };

    const closeModal = () => {
        setIsOpen(false)
        setDeletedItem([])
        setSelectedItems([])
    };

    const handleCheckboxChange = itemId => {
        if (selectedItems.includes(itemId)) {
            setSelectedItems(selectedItems.filter(id => id !== itemId));
        } else {
            setSelectedItems([...selectedItems, itemId]);
        }
    };

    const onClickDelete = selectedItem => {
        const fileUrls = selectedItem;
        deleteDocInBulkByUrl({ variables: { fileUrls } })
            .then(() => {
                toast.success('Selected documents deleted successfully.');
                closeModal();
                getDocDataById();
            }).catch(err => {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
                closeModal();
            })

    }

    const handleDelete = folderValue => {
        if (folderValue?.fileURL) {
            const id = [folderValue?.fileURL]
            setDeletedItem(id)
        }

        setIsOpen(true)
    }


    const handleDeleteModal = () => {
        const itemsToDelete = selectedItems.length > 0 ? selectedItems : deletedItem;
        onClickDelete(itemsToDelete);
    };

    const handleDeleteDocModal = () => {
        deleteFolder({ variables: { id: uniqueId } })
            .then(() => {//NOSONAR
                toast.success(`Selected folder deleted successfully.`)
                closeModal()
                navigate('/manage/folders')
            }).catch(err => {
                toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
            })
    }
    //handle download doc.
    const handleDownloadDocument = async r => {
        try {
            const response = await axios.get(r.downloadURL, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = r.fileName;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (errData) {
            toast.error(`Error downloading file: ${errData}`, {
                autoClose: false,
            });
        }
    }

    const linkClassName = loading || !addUserPermission
        ? "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2"
        : "flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-indigo-500 rounded-lg shrink-0 gap-x-2 hover:bg-indigo-600 dark:bg-indigo-600";

    const getDeleteModalHandler = () => (selectedItems?.length || deletedItem?.length) ? handleDeleteModal : handleDeleteDocModal;
    const itemCount = selectedItems?.length || deletedItem?.length || 1;
    const itemType = selectedItems?.length || deletedItem?.length ? "documents" : "folders";

    return (
        <div className="mt-16 container justify-center mx-auto px-2 sm:px-20 md:px-32 lg:px-40 xl:40">
            <div>
                <div className="py-6">
                    <h2 className="flex flex-row items-center text-2xl font-bold dark:text-gray-100 mb-4">Folder Details</h2>
                    <hr />
                </div>
            </div>
            {folderData ? 
                <div>
                    <div className="pb-6">
                        <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                            <div className="col-span-4 mt-10">
                                <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">General Information</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Personal details and application.</p>
                            </div>
                            <div className="col-span-8">
                                <div className="flex gap-2 mb-3 justify-end">
                                    {!editfolderPermission || loading ? <div>
                                        <Button className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2"
                                            icon={faEdit}
                                            text="Edit"
                                        />
                                    </div> : <div>
                                        <Link to='/manage/folders/edit' state={{ folder: folderData }} className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-indigo-500 rounded-lg shrink-0 gap-x-2 hover:bg-indigo-600 dark:bg-indigo-600">
                                            <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faEdit} />
                                            <span>Edit</span>
                                        </Link>
                                    </div>}
                                    {!deletefolderPermission || loading ? <Button className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-gray-500 bg-gray-300 rounded-lg shrink-0 gap-x-2"
                                        icon={faTrashCan}
                                        text="Delete"
                                        type="button"
                                    /> :
                                        <div>
                                            <Button
                                                type="button"
                                                className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-red-500 rounded-lg shrink-0 gap-x-2 hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600"
                                                onClick={handleDelete}
                                                icon={"faTrashCan"}
                                                text={"Delete"}
                                            />
                                        </div>}
                                </div>
                                <dl className="md:px-8 divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                                    <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Folder name</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{folderData.display_name}</dd>
                                    </div>
                                    <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">File Path</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0">{folderData.storage_path}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="py-6">
                        <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                            <div className="col-span-4">
                                <h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">Activity Timestamp</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-gray-400">Created and Updated Timestamp</p>
                            </div>
                            <div className="col-span-8">
                                <dl className="md:px-8 divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-neutral-900 px-4 rounded-md shadow">
                                    <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Created By</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{folderData.created_by}</span><span className="text-xs font-semibold">{formatTimestamp(folderData.created_at)}</span></dd>
                                    </div>
                                    <div className="p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">Updated By</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-gray-200 sm:col-span-2 sm:mt-0 flex flex-col"><span>{folderData.updated_by}</span><span className="text-xs font-semibold">{formatTimestamp(folderData.updated_at)}</span></dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex flex-col xl:flex-row gap-4">
                        <PageWrapper className={" mt-16 mb-6 w-full"}>
                            <TableWrapper>
                                <div className="flex-1">
                                    <div>
                                        <h2 className="flex flex-row items-center text-2xl font-bold dark:text-gray-100 mb-4">Related Documents</h2>
                                    </div>
                                    <hr />
                                    <TableButtonSection searchQuery={searchQuery} onSearchChange={handleSearchChange} onClearSearch={handleClearSearch}>
                                        {!load && (
                                            <Link
                                                to={`/manage/documents/new/${uniqueId}`}
                                                state={{ folderData, sensitivityData: getAllSenestivityLabel }}
                                                className={linkClassName}
                                            >
                                                <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faPlus} />
                                                <span>Add</span>
                                            </Link>)}
                                        {!loader && (
                                            <Button
                                                type="button"
                                                onClick={handleDownloadButtonClick}
                                                icon={faDownload}
                                                text="Download Report"
                                                className="flex items-center justify-center px-5 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 dark:hover:bg-neutral-800 dark:bg-neutral-900 hover:bg-neutral-100 dark:text-gray-200 dark:border-neutral-700"
                                            />
                                        )}
                                        {selectedItems.length > 0 && (
                                            <button
                                                type="button"
                                                onClick={openModal}
                                                className="flex items-center justify-center px-5 py-2 text-sm font-medium tracking-wide text-white transition-colors duration-200 bg-red-500 rounded-lg shrink-0 gap-x-2 hover:bg-red-600 dark:hover:bg-red-500 dark:bg-red-600"
                                            >
                                                <FontAwesomeIcon className="w-5 h-5 stroke-2" icon={faTrashCan} />
                                                <span>Delete</span>
                                            </button>
                                        )}
                                    </TableButtonSection>
                                    <TableContainer>
                                        <thead className="bg-gray-50 dark:bg-neutral-800">
                                            <tr>
                                                <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold">
                                                    <div className="flex h-6 items-center">
                                                        <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                    </div>
                                                </th>
                                                <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap"></th>
                                            <SortableHeader label="File Name" sortKey="display_name" sortedColumn={sortedColumn} sortOrder={sortOrder} onSort={handleSort} />
                                            <SortableHeader label="Support" sortKey="is_support" sortedColumn={sortedColumn} sortOrder={sortOrder} onSort={handleSort} />
                                            <SortableHeader label="Size" sortKey="size" sortedColumn={sortedColumn} sortOrder={sortOrder} onSort={handleSort} />
                                            <SortableHeader label="Sensitivity Label" sortKey="sensitivity_label" sortedColumn={sortedColumn} sortOrder={sortOrder} onSort={handleSort} />
                                            <SortableHeader label="Created On" sortKey="created_at" sortedColumn={sortedColumn} sortOrder={sortOrder} onSort={handleSort} />
                                            <SortableHeader label="Updated On" sortKey="updated_at" sortedColumn={sortedColumn} sortOrder={sortOrder} onSort={handleSort} />
                                                <th scope="col" className="px-4 py-3.5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 font-semibold whitespace-nowrap">
                                                    <div className="flex items-center gap-x-3 cursor-pointer">
                                                        <span>File Actions</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
                                            {fileMetadataList?.map(documentData => (
                                                <tr key={documentData?.id}>
                                                    <td className="px-4 py-3 text-sm font-medium">
                                                        <div className="flex h-6 items-center">
                                                            <input type="checkbox" checked={selectedItems?.includes(documentData.fileURL)} onChange={() => handleCheckboxChange(documentData.fileURL)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-3 text-sm">
                                                        <div className="text-ellipsis overflow-hidden">
                                                            <div className="text-2xl font-semibold text-gray-600 dark:text-gray-200">
                                                                <FontAwesomeIcon icon={getFileExtension(documentData.fileURL)} />
                                                                </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-3 text-sm font-medium whitespace-nowrap">
                                                        <div className="w-45">
                                                            <h2 className="font-semibold text-indigo-600 dark:text-indigo-400 ">{documentData.fileName}</h2>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                        <div>
                                                            <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{documentData?.is_support ? "Yes" : "No"}</h2>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                        <div>
                                                            <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{formatSize(documentData.file_bytes)}</h2>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                        <div>
                                                            <h2 className="text-gray-700 dark:text-gray-200 text-ellipsis overflow-hidden">{documentData.sensitivity_label}</h2>
                                                        </div>
                                                    </td>

                                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                        <div>
                                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(documentData?.created_at)}</h4>

                                                        </div>
                                                    </td>

                                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                        <div>
                                                            <h4 className="text-gray-700 dark:text-gray-200">{formatTimestamp(documentData?.updated_at)}</h4>
                                                        </div>
                                                    </td>
                                                    <td className="px-4 py-3 text-sm whitespace-nowrap">
                                                        <div className="flex item-center justify-center text-gray-700 dark:text-gray-200">
                                                            <div className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                                                                <ArrowDownTrayIcon className="stroke-2" onClick={() => { handleDownloadDocument(documentData) }} />
                                                            </div>
                                                            {editUserPermission ? <div className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                                                                <Link to='/manage/documents/edit' state={{ documentData, uniqueId, folderData: getAllDocumentsFolders, sensitivityData: getAllSenestivityLabel }}>
                                                                    <PencilIcon className="stroke-2" />
                                                                </Link>
                                                            </div> : <div className="w-4 mr-2 transform text-gray-500">
                                                                <PencilIcon className="stroke-2" />
                                                            </div>}
                                                            {deleteUserPermission ? <div className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                                                                <TrashIcon className="stroke-2" onClick={() => { handleDelete(documentData) }} />
                                                            </div> : <div className="w-4 mr-2 transform text-gray-500">
                                                                <PencilIcon className="stroke-2" />
                                                            </div>}
                                                        </div>
                                                    </td>
                                                </tr>

                                            ))}
                                            {!fileMetadataList?.length && !paginationLoader && <TableNoRecordFound colSpan={9}></TableNoRecordFound>}
                                            {paginationLoader && <TableLoadingData colSpan={9}></TableLoadingData>}

                                        </tbody>
                                    </TableContainer>
                                </div>
                            </TableWrapper>
                            <Pagination totalPages={pageInfo?.total_pages || 0} totalRecords={pageInfo?.total_records}
                                maxPageNumbers={maxPageNumber}
                                recordsPerPage={pageInfo?.per_page || recordsPerPageData || 0}
                                currentPage={pageInfo?.current_page || 0} onPageChange={handlePageChange} onRowChanges={handleRowChanges} ></Pagination>
                        </PageWrapper>
                        <DialogModal
                            isOpen={isOpen}
                            onClose={closeModal}
                            title="Confirm Deletion"
                            closeOnClickOutside={false} // Set this prop as needed
                            pTagData= {`Do you confirm to delete ${itemCount} ${itemType}?`}
                            btnOne="Confirm Delete"
                            btnTwo="Cancel"
                            loader={deleteDocLoader || deleteFolderLoader}
                            onClickDeleteModal={getDeleteModalHandler()}>
                        </DialogModal>
                    </div>
                </div>
                :
                <div className="flex justify-center items-center h-screen">
                    <td colSpan={5} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
                        <div>
                            <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                            <span>Loading Data</span>
                        </div>
                    </td>
                </div>
            }
        </div>
    );
};

export default FolderDetails;