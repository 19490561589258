import React, { useEffect } from 'react';
import PageWrapper from "../../../layouts/PageWrapper.js";
import ContentEditor from "../../../components/form/editor/ContentEditor.js";
import {
    EditorState, convertToRaw, ContentState
} from 'draft-js';
import Button from '../../../components/button/Button.js';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import slugify from 'slugify';
import htmlToDraft from 'html-to-draftjs';
import '../../../styles/Draft.css';
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import { useUpdateStaticPageMutation, useGetStaticPageByIdMutation } from '../../../graphql/generated/graphql.ts'
import { useFormik } from "formik";
import InputField from '../../../components/form/input/InputField.js';
import { formCreateStaticScreenSchema } from '../../../validations/FormValidationSchema.js';

const EditStaticPage = () => {
    const navigate = useNavigate();
    const { pageId } = useParams();
    const state = useLocation()?.state?.staticPage || "";
    const [updateStaticPageMutation, { loading, error }] = useUpdateStaticPageMutation();
    const [getsStaticPageByIdMutation, { data, error: err, loading: load }] = useGetStaticPageByIdMutation({
        variables: {
            slug: state?.slug
        },
    });

    function handleNavigationError() {
        if (error || err) {
            navigate('/error', { state: { errorData: JSON.stringify(error || err) } });
        }
    }
    // Call the function to handle navigation error
    handleNavigationError();


    const initialValues = {
        message: EditorState.createEmpty(),
        message_html: "",
        slug: "",
        title: data?.getStaticPageById?.title || "",
    };
    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Edit Static Page | Edit`;
        document.title = pageTitle;

    }, [])


    useEffect(() => {
        getsStaticPageByIdMutation();
    }, [])
    useEffect(() => {
        if (data?.getStaticPageById?.body) {
            convertHtmlToDraft(data?.getStaticPageById?.body);
        }
    }, [data])

    function convertHtmlToDraft(html) {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorData = EditorState.createWithContent(contentState);
        handleEditorStateChange(editorData)
    }

    const { values, errors, touched, setFieldValue, handleSubmit, handleChange, handleBlur } = useFormik({
        initialValues,
        validationSchema: formCreateStaticScreenSchema,
        onSubmit: value => {
            updateStaticPageMutation({ variables: { title: value?.title, body: value?.message_html, id: pageId, slug:value?.slug } })
                .then(() => {
                    toast.success('Static page content updated successfully.')
                    navigate('/manage/static-pages')
                })
                .catch(e => {
                    toast.error(e.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                        autoClose: false,
                    });
                });
        }
    });

    const handleEditorStateChange = editorState => {
        const contentState = editorState.getCurrentContent();
        const rawContent = convertToRaw(contentState);
        const contentHtml = draftToHtml(rawContent);
        setFieldValue('message', editorState);
        setFieldValue('message_html', contentHtml);
        setFieldValue('title', data?.getStaticPageById?.title)
        setFieldValue('slug', data?.getStaticPageById?.slug)
    };

    const handleFolderNameChange = e => {
        const titleValue = e.target.value;
        const slug = slugify(titleValue, { lower: true, strict: true });
        setFieldValue('slug', slug);
    };


    return (
        <PageWrapper heading={'Edit Page'}>
            {load ? <div className="flex justify-center items-center h-screen">
                <td colSpan={3} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
                    <div>
                        <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                        <span>Loading Data</span>
                    </div>
                </td>
            </div> : <div className="py-6">
                <div className="flex flex-col">
                    <div className="mb-4">
                        <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">Edit Static Page Content
                        </h2>
                        <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">The content updated here will be visible as a static page.</p>
                    </div>
                    <div className="py-6">
                        <div className="flex flex-col">
                            <form method="POST" onSubmit={handleSubmit} className="">
                                <div
                                    className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                                    <div className="sm:col-span-2">
                                            <InputField type={'text'} name={'title'} label={'Title'} value={values.title} required onChange={handleChange} onBlur={handleBlur} onInput={handleFolderNameChange} error={(errors.title && touched.title) ? errors.title : ''} />
                                    </div>
                                        <div className="sm:col-span-2">
                                            <InputField type={'text'} name={'slug'} label={'Slug'} value={values.slug} required onChange={handleChange} onBlur={handleBlur} error={(errors.slug && touched.slug) ? errors.slug : ''} />
                                        </div>
                                    <div className="sm:col-span-2">
                                        <ContentEditor name={'content'} editorState={values.message} required={true} onEditorStateChange={handleEditorStateChange} error={(errors.message_html) ? errors.message_html : ''} />
                                    </div>

                                </div>
                                <div className="mt-10 flex justify-start">
                                    {loading ? <Button type="button"
                                        className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                        text="Save" />
                                        : <Button type="submit"
                                            className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            text="Save" />
                                    }
                                    <Button type="button"
                                        className="block rounded-md bg-gray-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                        text={"Cancel"}
                                            onClick={() => { navigate("/manage/static-pages") }} />

                                    {loading && <FontAwesomeIcon className="w-5 h-5 mr-6 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>}
        </PageWrapper>
    )

};

export default EditStaticPage;