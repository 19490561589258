import PageWrapper from "../../../layouts/PageWrapper";
import SelectSingleInput from "../../../components/form/input/SelectSingleInput";
import InputField from "../../../components/form/input/InputField";
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import { formCreateNewVideoSchema } from "../../../validations/FormValidationSchema"
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/button/Button";
import { useUpdateMutationForFileMetadataMutation, useGetFileUploadSettingsQuery, useUpdateThumbnailAndDurationMutation } from '../../../graphql/generated/graphql.ts'
import FileInputComponent from "../../../components/form/input/FileInputComponent";
import React, { useEffect, useState } from "react";
import { ReplaceFile } from '../../../graphql/graphql.queries'
import axios from "axios";
import * as Auth from "../../../utils/AuthClient"; {/* NOSONAR */ }
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import CheckboxButton from "../../../components/form/input/CheckboxButton.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'


const EditVideos = () => {
    const state = useLocation()?.state || {};
    const timeDuration = 1000;
    const navigate = useNavigate();
    const { videoDetails, folderData } = state;
    const { data: maxUploadSizeData } = useGetFileUploadSettingsQuery();
    const [addThumbnailAndDuration,] = useUpdateThumbnailAndDurationMutation();
    const { KB } = maxUploadSizeData?.getFileUploadSettings?.maxUploadSize || {};
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [replaceLoader, setReplaceLoader] = useState(false);
    const [thumbnailLoader, setThumbnailLoader] = useState(false);
    const navigationPath = '/manage/videos';
    const folderOptionData = folderData?.filter(item => item.id === videoDetails?.folder_id)
    const folderOptions = (folderOptionData || []).map(option => ({
        value: option.id,
        label: option.storage_path
    }))
    const [editDoc, { loading }] = useUpdateMutationForFileMetadataMutation();
    const initialValues = {
        video_name: videoDetails?.fileName,
        folder: {
            value: videoDetails?.filePath, label: videoDetails?.filePath
        },
        video_url: videoDetails?.fileURL,
        is_critical: videoDetails?.auto_download,
        is_notify: false,
    }

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} |Manage Videos | Edit | ${values?.video_name}`;
        document.title = pageTitle;
    }, [])

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        validationSchema: formCreateNewVideoSchema,
        onSubmit: value => {
            const filedata = {
                fileName: value?.video_name?.trim(),
                folder_id: videoDetails?.folder_id,
                roleIds: [1],
                id: videoDetails?.id,
                file_url: value?.video_url,
                is_support: videoDetails?.is_support,
                file_bytes: videoDetails?.file_bytes,
                is_video: true,
                auto_download: value?.is_critical,
                notify_user: value?.is_notify || false,
            };
            if (selectedFiles?.length>0) {
                setReplaceLoader(true)
                handleReplaceVideo();
            }
            editDoc({ variables: { fileMetadataListForUpdate: filedata } })
                .then(() => {
                    toast.success('Video updated successfully.')
                    if (!selectedFiles?.length && !replaceLoader ){
                        navigate(navigationPath)
                    }
                }).catch(error => {
                    toast.error(error.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                        autoClose: false,
                    });
                })
        }
    })

    const handleFileChange = newFiles => {
        setSelectedFiles([...selectedFiles, ...newFiles]);
    };

    const handleRemoveFile = file => {
        const updatedFiles = selectedFiles.filter(selectedFile => selectedFile !== file);
        setSelectedFiles(updatedFiles);

        // Clear the file input value
        const inputRef = document.getElementById('uploaded_doc'); // Use the correct ID
        if (inputRef) {
            inputRef.value = null;
        }
    };

    const generateThumbnail = async (url, file_url) => {
        const video = document.createElement('video');
        video.src = url;
        video.crossOrigin = 'anonymous';

        const loadedMetadataPromise = new Promise(resolve => {
            video.addEventListener('loadedmetadata', () => resolve());
        });

        await Promise.all([loadedMetadataPromise, video.play()]);

        const canvas = document.createElement('canvas');
        canvas.width = 256;
        canvas.height = 144;

        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const thumbnail = canvas.toDataURL('image/jpeg');
        const duration = Math.round(video.duration * timeDuration);

        video.pause();
        video.removeAttribute('src');
        video.load();
        return {
            file_url: file_url || null,
            duration,
            thumbnail,
        };
    };

    const handleReplaceVideo = async () => {
        try {
            // Creating variables object
            const variables = {
                "req": {
                    "fileUrl": videoDetails?.fileURL,
                    "file": selectedFiles[0],
                    "notifyUser": values?.is_notify,
                    "sensitivityLabel": videoDetails?.sensitivity_label.toLowerCase() || "internal",
                    "autoDownload": values?.is_critical,
                    fileName: values?.video_name
                }
            }
            // Creating FormData for the request
            const data = new FormData();
            data.append("operations", JSON.stringify({ query: ReplaceFile, variables }));
            data.append('map', JSON.stringify({ "0": ["variables.req.file"] }))
            data.append('0', selectedFiles[0])
            // Retrieving token data from localStorage
            const tokenData = JSON.parse(localStorage.getItem('user'))
            const token = tokenData?.access_token
            // Checking token expiration
            const tokenExpiration = tokenData?.expiry_time.exp
            const dateNow = new Date();
            if (tokenExpiration < dateNow.getTime() / timeDuration) {
                await Auth.requestRefreshTokenSimmas(tokenData.refresh_token);
            }
            // Making the GraphQL request
            const response = await axios({
                method: "post",
                url: process.env.REACT_APP_GRAPHQLHOSTUPLOAD,
                data,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`
                },
            });
            // Handling response
            const responseData = response?.data?.errors;
            const downloadedURL = response?.data?.data?.replaceSingleFile?.downloadURL;
            const file_url = response?.data?.data?.replaceSingleFile?.fileUrl;
            if (!responseData) {
                toast.success(`Video Replaced successfully.`)
                setThumbnailLoader(true);
                setReplaceLoader(false)
                navigate(navigationPath);
                const thumbnailData = await generateThumbnail(downloadedURL, file_url);
                addThumbnailAndDuration({ variables: { thumbnailAndDurationUpdateList: [thumbnailData] } })
                    .then(() => {
                        toast.success('Thumbnail updated successfully.');
                        setThumbnailLoader(false);
                        navigate(navigationPath);
                        setSelectedFiles([])
                    })
                    .catch(error => {
                        toast.error(error.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                            autoClose: false,
                        });
                        setThumbnailLoader(false);
                        setReplaceLoader(false)
                    });
            } else {
                toast.error(responseData[0]?.message, {
                    autoClose: false,
                });
                setThumbnailLoader(false);
                setReplaceLoader(false)
            }
        } catch (err) {
            // Handling errors
            toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                autoClose: false,
            });
            setThumbnailLoader(false);
            setReplaceLoader(false)
        }
    }

    return (
        <PageWrapper heading={'Edit Video'}>
            <div className="py-6">
                <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                    <div className="col-span-4">
                        <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">General Information
                        </h2>
                        <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Fill in the video details</p>
                    </div>
                    <form method="POST" onSubmit={handleSubmit} className="col-span-8">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                            <div className="sm:col-span-2">
                                <InputField type={'text'} name={'video_name'} label={'Video Name'} disabled={loading} value={values.video_name} onChange={handleChange} onBlur={handleBlur} required error={(errors.video_name && touched.video_name) ? errors.video_name : ''} />
                            </div>
                            <div className="sm:col-span-2">
                                <SelectSingleInput name={'folder'} value={values.folder} options={folderOptions} label={'Select folder'} disabled={true} required error={(errors.folder && touched.folder) ? errors.folder : ''} />
                            </div>
                            <div className="sm:col-span-2">
                                <InputField type={'text'} name={'video_url'} label={'Video URL'} value={values.video_url} required disabled={true} placeholder={'/blob-storage-video-url'} error={(errors.video_url && touched.video_url) ? errors.video_url : ''} />
                            </div>
                            <div className="sm:col-span-2">
                                <FileInputComponent
                                    name={'uploaded_doc'}
                                    label={'Replace file'}
                                    multiple={false}
                                    maxSize={KB}
                                    selectedFiles={selectedFiles}
                                    acceptedData=".mov, .mp4"
                                    required={false}
                                    handleFileChange={handleFileChange}
                                    handleRemoveFile={handleRemoveFile}
                                    dissabled={false} />
                                {thumbnailLoader && <div className=" pt-2 justify-start flex">
                                    <p className=" text-gray-400 dark:text-gray-600">Thumbnail is getting generated</p>
                                    <FontAwesomeIcon className="w-5 h-5 ml-2 mt-1 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                                </div>}
                            </div>
                            <div className="sm:col-span-2">
                                <div className="mt-2.5">
                                    <fieldset>
                                        <div className="space-y-4">
                                            <CheckboxButton
                                                name="is_critical"
                                                label="Mark as Critical Video"
                                                value={values?.is_critical}
                                                checked={values?.is_critical}
                                                onChange={e => setFieldValue("is_critical", e.target.checked)}
                                                description="The critical video will be auto downloaded to the mobile application. "
                                                disabled={loading}
                                            />
                                        </div>
                                        <div className="sm:col-span-2 mt-5">
                                            <CheckboxButton
                                                name="is_notify"
                                                label="Notify to users"
                                                value={values?.is_notify}
                                                checked={values?.is_notify}
                                                onChange={e => setFieldValue("is_notify", e.target.checked)}
                                                description="This will enable in-app notification to end users. "
                                                disabled={loading}
                                            />
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                        <div className="mt-10 flex justify-start">
                            {loading || replaceLoader ? <Button type="button"
                                className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                text="Update" />
                                : <Button type="submit"
                                    className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    text="Update" />
                            }
                            <Link to="/manage/videos"
                                className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                            {loading || replaceLoader && <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                        </div>
                    </form>
                </div>
            </div>
        </PageWrapper>
    );
};
export default EditVideos;
