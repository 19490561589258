import InputField from "../../../components/form/input/InputField";
import PageWrapper from "../../../layouts/PageWrapper";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { formCreateNewGroupSchema } from "../../../validations/FormValidationSchema"
import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useGroupSectionsDocsMappingListCreateMutation } from "../../../graphql/generated/graphql.ts";
import { toast } from "react-toastify";
import SelectMultipleInput from "../../../components/form/input/SelectMultipleInput";
import Button from "../../../components/button/Button.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import TextAreaInput from "../../../components/form/input/TextAreaInput.js";


const AddNewGroups = () => {
    const state = useLocation()?.state || {}
    const navigate = useNavigate()
    const { fileMetadataForAdmin, getAllDocumentsFolders } = state?.folderAndDocData || {};
    const documentData = (fileMetadataForAdmin || []).map(option => ({
        value: option.id,
        label: option.fileName,
        folder_id: option.folder_id
    }));
    const folderSelectOptions = (getAllDocumentsFolders || []).map(option => ({
        value: option.id,
        label: option.display_name
    }));
    // Initialize initial values with defaults
    const initialValues = {
        folder_name: null,
        group_name: "",
        descriptions: "",
        document_name: null,
        documentList: documentData
    }
    const [createMutation, { loading, data: createdGroup }] = useGroupSectionsDocsMappingListCreateMutation();

    useEffect(() => {
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Groups | Create`;
        document.title = pageTitle;
    }, [])

    const { values, errors, touched, setFieldValue, setFieldTouched, handleBlur, handleChange, handleSubmit, setErrors } = useFormik({
        initialValues,
        validationSchema: formCreateNewGroupSchema,
        onSubmit: async value => {
            if (!value.folder_name && !value.document_name) {
                setErrors('folder_name')
                return;
            }
            try {
                const groupSectionsDoc = [{
                    groupName: value?.group_name?.trim(),
                    groupDescription: value?.descriptions?.trim(),
                    sectionIds: value?.folder_name?.map(item => item?.value),
                    documentIds: value?.document_name?.map(val => val?.value)
                }]
                createMutation({ variables: { groupSectionsDocsCreate: groupSectionsDoc } })
                    .then(() => {
                        toast.success('Group created successfully.')
                    }).catch(err => {
                        toast.error(err.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                            autoClose: false,
                        });
                    })
            } catch (err) {
                toast.error(err?.message?.replace(GRAPHQL_ERROR_PREFIX, ""), {
                    autoClose: false,
                });
                // Handle error if needed
            }
        }
    })

    useEffect(() => {
        if (createdGroup?.groupSectionsDocsMappingListCreate) {
            navigate(`/access-control/groups/${createdGroup?.groupSectionsDocsMappingListCreate?.[0]?.groupId}`)
        }
    }, [createdGroup])

    useEffect(() => {
        // Update documentData based on selected folder_name
        const selectedFolderIds = values.folder_name?.map(item => item?.value) || [];
        const updatedDocumentData = fileMetadataForAdmin
            .filter(option => !selectedFolderIds.includes(option.folder_id))
            .map(option => ({
                value: option.id,
                label: option.fileName,
                folder_id: option.folder_id
            }));
        setFieldValue('documentList', null); // Reset document_name when folder_name changes
        setFieldValue('documentList', updatedDocumentData);
    }, [values.folder_name, fileMetadataForAdmin, setFieldValue]);


    return (
        <PageWrapper heading={'Create New Groups'}>
            <div className="py-6">
                <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                    <div className="col-span-4">
                        <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">General Information
                        </h2>
                        <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm">Fill in the Group details and documents details</p>
                    </div>
                    <form method="POST" onSubmit={handleSubmit} className="col-span-8">
                        <div
                            className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                            <div className="sm:col-span-2">
                                <InputField type={'text'} name={'group_name'} label={'Group Name'} value={values.group_name} required onChange={handleChange} onBlur={handleBlur} error={(errors.group_name && touched.group_name) ? errors.group_name : ''} />
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="descriptions" className="block text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">
                                    Descriptions
                                </label>
                                <TextAreaInput
                                    name="descriptions"
                                    id="descriptions"
                                    value={values.descriptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 dark:text-gray-200 dark:bg-neutral-800 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-neutral-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 focus:outline-none"
                                    rows={4} // adjust the number of rows as needed
                                />
                                {errors.descriptions && touched.descriptions && (
                                    <div className="mt-2 text-red-500">{errors.descriptions}</div>
                                )}
                            </div>

                            <div className="sm:col-span-2">
                                <SelectMultipleInput
                                    name="folder_name"
                                    value={values.folder_name}
                                    options={folderSelectOptions}
                                    onChange={selectedFolder => {
                                        setFieldValue('folder_name', selectedFolder)
                                    }}
                                    onBlur={() => setFieldTouched('folder_name', true)}
                                    label="Select Folders"
                                    error={(errors === "folder_name" && touched.folder_name) ? "Please selcte at least one folder or document" : ''}
                                />
                            </div>
                            <div className="sm:col-span-2">
                                <SelectMultipleInput
                                    name="document_name"
                                    value={values.document_name}
                                    options={values.documentList}
                                    onChange={selectedDocument => {
                                        setFieldValue('document_name', selectedDocument)
                                    }}
                                    onBlur={() => setFieldTouched('document_name', true)}
                                    label="Select Documents"
                                />
                            </div>
                        </div>
                        <div className="mt-10 flex justify-start">
                            {loading ? <Button type="button"
                                className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                text="Create" />
                                : <Button type="submit"
                                    className="mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    text="Create" />
                            }
                            <Link to='/access-control/groups'
                                className="block rounded-md bg-gray-600 px-3.5 py-2.5 mr-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                            {loading && <FontAwesomeIcon className="w-5 h-5 mr-4 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
                        </div>
                    </form>
                </div>
            </div>
        </PageWrapper>
    );
};

export default AddNewGroups;
