import InputField from "../../../components/form/input/InputField";
import PageWrapper from "../../../layouts/PageWrapper";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useFormik } from "formik";
import { formCreateDuoCircleSchema, formCreateNewAppSettingSchema } from "./../../../validations/FormValidationSchema"
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
    useIsPushNotificationEnabledQuery, useCronJobListToggleMutation, useGetFileUploadSettingsQuery,
    useModifyFileUploadSettingsMutation, useGetCurrentUserPermissionsQuery, useGetEmailSettingQuery,
    useGetAllValidUsersUniqueNameQuery, useGetEmailNotificationDeatilsQuery,
    useUpdateEmailNotificationMutation, useEmailEnableDisableMutation
} from '../../../graphql/generated/graphql.ts'
import { toast } from "react-toastify";
import ConfigSection from "../../../components/form/input/ConfigSection.js";
import { GRAPHQL_ERROR_PREFIX } from '../../../utils/constant.js'
import SelectMultipleInput from "../../../components/form/input/SelectMultipleInput.js";



const ConfigurationApplicationConfig = () => {
    const navigate = useNavigate();
    const RecipientEmailAddress = 'Recipient Email Address';
    const toastSuccessMessage = 'Application config information updated successfully.';
    const toConvertIntoMb = 1024;
    const { data: pushNotificationData, loading, error, refetch } = useIsPushNotificationEnabledQuery();
    const { data: emailSetting, loading: emailLoader, error: emailError } = useGetEmailSettingQuery();
    const { data: maxUploadSizeData, loading: loadData, error: errorData, } = useGetFileUploadSettingsQuery();
    const { data: emailNotificationDeatils } = useGetEmailNotificationDeatilsQuery();
    const { data: permissionData } = useGetCurrentUserPermissionsQuery({ variables: {}, });
    const { data: userList } = useGetAllValidUsersUniqueNameQuery();
    const addPermission = permissionData?.getCurrentUserPermissions?.assignedPermissions
    const permissionAPIList = permissionData?.getAllAPIPermissions
    const updateUserInfo = permissionAPIList?.filter(item => item.accessType === "UpdateNotification")
    const sendEmailInfo = permissionAPIList?.filter(item => item.accessType === "SendEmail")
    const editUserPermission = updateUserInfo?.length && updateUserInfo[0].assignedPermissions.some(val => addPermission.includes(val));
    const sendEmailPermission = sendEmailInfo?.length && sendEmailInfo[0].assignedPermissions.some(val => addPermission.includes(val));
    const { isPushNotificationEnabled } = pushNotificationData || "";
    const { response } = emailSetting?.getEmailSetting || "";
    const { maxUploadSize } = maxUploadSizeData?.getFileUploadSettings || {}
    const [isEnabled, setIsEnabled] = useState(isPushNotificationEnabled || false);
    const [isEnabledEmail, setIsEnabledEmail] = useState(response === "true");
    const [maxFileUpload, setMaxFileUpload] = useState(maxUploadSize?.KB / toConvertIntoMb || 0);
    const [settingConfigUpdate, { loading: load }] = useCronJobListToggleMutation();
    const [maxUploadSizeMutation, { loading: loder }] = useModifyFileUploadSettingsMutation();
    const [emailNotificationSettingMutation, {loading: emailNotificationSettingLoader }] = useUpdateEmailNotificationMutation();
    const [emailEnableDisablerMutation, { loading: emailEnableDisablerLoader }] = useEmailEnableDisableMutation();
    const buttonClassName =  "mr-2 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600";
    function handleNavigationError() {
        const err = error || errorData || emailError;
        if (err) {
            navigate('/error', { state: { errorData: JSON.stringify(err) } });
        }
    }

    const userEmailarrayTo = userList?.getAllValidUsersUniqueName?.map((item, index) => ({
        label: item,
        value: index
    }));


    useEffect(() => {
        // Call the function to handle navigation error
        handleNavigationError();
        const envVariable = process.env.REACT_APP_ENV;
        const prefix = 'SIERA';
        const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
        const pageTitle = `${prefix}${suffix} | Manage Application Settings`;
        document.title = pageTitle;
    }, [])

    useEffect(() => {
        if (isPushNotificationEnabled){
            setIsEnabled(isPushNotificationEnabled)
        }
    }, [isPushNotificationEnabled])

    useEffect(() => {
        if(response){
            setIsEnabledEmail(response === "true")
        }
    }, [response])


    useEffect(() => {
        if (maxUploadSize?.KB) {
            setMaxFileUpload(maxUploadSize?.KB / toConvertIntoMb || 0);
        }
    }, [maxUploadSize?.KB]);

    const fileUploadInitilValue = {
        fileSize: maxFileUpload,
    }

    const [emailNotificationInitialValue, setEmailNotificationInitialValue] = useState({
        sender: "",
        sender_first_name: "",
        sender_last_name: "",
        recipient_cc: "",
        recipient_bcc: "",
        recipient_to: "",
        subject: "",
        notificationName: "",
        recipient_cc_email: userEmailarrayTo,
        recipient_to_email: userEmailarrayTo,
        recipient_bcc_email: userEmailarrayTo,
        recipient_cc_email_input:"",
        recipient_bcc_email_input :"",
        recipient_to_email_input :""
    })
    useEffect(() => {
        if (emailNotificationDeatils?.getEmailNotificationDeatils) {
            const { getEmailNotificationDeatils } = emailNotificationDeatils;
            setEmailNotificationInitialValue({
                ...emailNotificationInitialValue,
                sender: getEmailNotificationDeatils?.sender,
                sender_first_name: getEmailNotificationDeatils?.sender_name,
                recipient_cc: getEmailNotificationDeatils?.recipient_cc?.map((item, index) => ({
                    label: item,
                    value: index
                })),
                recipient_bcc: getEmailNotificationDeatils?.recipient_bcc?.map((item, index) => ({
                    label: item,
                    value: index
                })),
                recipient_to: getEmailNotificationDeatils?.recipient_to?.map((item, index) => ({
                    label: item,
                    value: index
                })),
                subject: getEmailNotificationDeatils?.subject,
                notificationName: getEmailNotificationDeatils?.notification_name,
                recipient_cc_email: userEmailarrayTo,
                recipient_to_email: userEmailarrayTo,
                recipient_bcc_email: userEmailarrayTo
            })
        }
    }, [emailNotificationDeatils?.getEmailNotificationDeatils])

    const maxFileSizeFormik = useFormik({
        initialValues: fileUploadInitilValue,
        enableReinitialize: true,
        validationSchema: formCreateNewAppSettingSchema,
        onSubmit: value => {
            // Handle submission for maxFile form
            const MaxUploadSizeInputObj = {
                "KB": value?.fileSize * toConvertIntoMb
            }
            if (MaxUploadSizeInputObj?.KB !== maxFileUpload) {
                maxUploadSizeMutation({ variables: { MaxUploadSizeInputObj } }).then(() => {
                    toast.success(toastSuccessMessage)
                })
                    .catch(e => {
                        toast.error(e.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                            autoClose: false,
                        });
                    });
            }
        },
    });

    const initialValues ={
        is_enable : false
    }

    const notificationFormik = useFormik({
        initialValues,
        onSubmit: () => {
            // Handle submission for notification form
            const enablePushNotificationData = {
                cronjobname: "ProcessPushNotifications",
                isActive: isEnabled,
            };
            if (isEnabled !== isPushNotificationEnabled) {
                settingConfigUpdate({ variables: { cronJobToggle: [enablePushNotificationData] } })
                    .then(() => {
                        refetch()
                        toast.success(toastSuccessMessage)
                    })
                    .catch(er => {
                        toast.error(er.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                            autoClose: false,
                        });
                    });

            }
        },
    });


    const emailNotificationFormik = useFormik({
        initialValues: emailNotificationInitialValue,
        enableReinitialize: true,
        validationSchema: formCreateDuoCircleSchema, // Your validation schema for emailNotification form,
        onSubmit: value => {
            const { getEmailNotificationDeatils } = emailNotificationDeatils;
            // Handle submission for emailNotification form
            const recipientCcName = value?.recipient_cc?.map(cc => cc.label)
            const recipientBccName = value?.recipient_bcc?.map(bcc => bcc.label)
            const recipientToName = value?.recipient_to?.map(to => to.label)
            const recipientEmails = value.recipient_to_email_input.split(';').map(email => email.trim());
            const recipientCcEmails = value.recipient_cc_email_input.split(';').map(email => email.trim());
            const recipientBccEmails = value.recipient_bcc_email_input.split(';').map(email => email.trim());
            const recipientToNameData = recipientToName.concat(recipientEmails);
            const recipientCcNameData = recipientCcName.concat(recipientCcEmails);
            const recipientBccNameData = recipientBccName.concat(recipientBccEmails);
            const finalData = {
                uniqueId: getEmailNotificationDeatils?.uniqueId,
                recipient_to: recipientToNameData?.filter(email => email.trim() !== ""),
                recipient_cc: recipientCcNameData?.filter(email => email.trim() !== ""),
                recipient_bcc: recipientBccNameData?.filter(email => email.trim() !== ""),
                sender: getEmailNotificationDeatils?.sender,
                sender_name: value.sender_first_name,
                subject: getEmailNotificationDeatils?.subject
            }

            if (isEnabledEmail !== response) {
                emailEnableDisablerMutation({ variables: { enabled: isEnabledEmail } }).then(() => {
                    toast.success(toastSuccessMessage);
                }).catch(e => {
                    toast.error(e.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                        autoClose: false,
                    });
                });
            }
            if (isEnabledEmail) {
                emailNotificationSettingMutation({ variables: finalData }).then(() => {
                    // toast.success(toastSuccessMessage);
                }).catch(e => {
                    toast.error(e.message.replace(GRAPHQL_ERROR_PREFIX, ""), {
                        autoClose: false,
                    });
                });
            }
        }
    });

    const toggleSwitch = () => {
        setIsEnabled(previousState => !previousState);
    };
    const emailToggleSwitch = () => {
        setIsEnabledEmail(previousState => !previousState);
    };

    const loadingSection = () => {
        return (
            <div className="flex justify-center items-center h-screen">
                <td colSpan={5} className="p-4 dark:bg-neutral-900 text-gray-900 dark:text-gray-400 text-sm text-center">
                    <div>
                        <FontAwesomeIcon className="w-5 h-5 mr-4 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />
                        <span>Loading Data</span>
                    </div>
                </td>
            </div>
        )
    }

    const actionItemContent = () => {
        return (
            <div className="mt-10 flex justify-start">
                {editUserPermission || loder ?
                    <Button type="submit"
                        className={loder ? "block rounded-md bg-gray-600 px-3.5 py-2.5 mr-3 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" : buttonClassName} text="Update" />
                    : <Button type="button"
                        className={"block rounded-md bg-gray-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"} text="Update" />
                }
                <Link to='/home'
                    className="block rounded-md bg-gray-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                {loder && <FontAwesomeIcon className="w-5 h-5 mr-4 ml-5 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
            </div>
        )
    }
    const actionNotificationContent = () => {
        return (
            <div className="mt-10 flex justify-start">
                {editUserPermission || load ?
                    <Button type="submit"
                        className={load ? "block rounded-md bg-gray-600 px-3.5 py-2.5 mr-3 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" : buttonClassName} text="Update" />
                    : <Button type="button"
                        className={"block rounded-md bg-gray-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"} text="Update" />
                }
                <Link to='/home'
                    className="block rounded-md bg-gray-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                {load && <FontAwesomeIcon className="w-5 h-5 mr-4 ml-5 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} />}
            </div>
        )
    }

    const notificationSection = () => {
        return (
            <form method="POST" className="col-span-8" onSubmit={notificationFormik.handleSubmit}>
                <div
                    className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-3 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                    <h2 className=" text-m font-semibold tracking-tight text-gray-900 dark:text-gray-200">Push Notification</h2>
                    <div className={` w-16 h-8 rounded-2xl ${isEnabled ? "bg-green-600" : ' bg-gray-400'} flex justify-between cursor-pointer p-1`}
                        onClick={() => toggleSwitch()}>
                        <div className={` w-6 h-6 rounded-xl bg-white ${isEnabled ? ' translate-x-8 transition-transform duration-200' : 'translate-x-0 transition-transform duration-200'}`}
                        />
                    </div>
                </div>
                {actionNotificationContent()}
            </form>
        )
    }

    const emailNotificationSection = () => {
        return (
            <form method="POST" className="col-span-8" onSubmit={emailNotificationFormik.handleSubmit}>
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-3 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                    <h2 className="text-m font-semibold tracking-tight text-gray-900 dark:text-gray-200">Email Notification</h2>
                    <div className={`w-16 h-8 rounded-2xl ${isEnabledEmail ? "bg-green-600" : 'bg-gray-400'} flex justify-between cursor-pointer p-1`}
                        onClick={() => emailToggleSwitch()}>
                        <div className={`w-6 h-6 rounded-xl bg-white ${isEnabledEmail ? 'translate-x-8 transition-transform duration-200' : 'translate-x-0 transition-transform duration-200'}`}
                        />
                    </div>
                    <div className="col-span-3 mt-4">
                        <div className="mb-3">
                            <InputField type={'text'} name={'notification_name'} disabled={true} label={'Notification Name'} value={emailNotificationDeatils?.getEmailNotificationDeatils?.notification_name} />
                        </div>
                        <div className="mb-3">
                            <InputField type={'text'} name={'subject'} disabled={true} label={'Subject'} required value={emailNotificationFormik.values.subject} />
                        </div>
                        <h2 className="text-m font-semibold tracking-tight text-gray-900 dark:text-gray-200">From</h2>
                        <div className="sm:col-span-2 mb-2">
                            <InputField type={'email'} name={'sender'} disabled={true} label={'Email ID'} required value={emailNotificationFormik.values.sender} />
                        </div>
                        <div className="sm:col-span-2 ">
                            <InputField type={'text'} name={'sender_first_name'} disabled={true} label={'Name'} required value={emailNotificationFormik.values.sender_first_name} />
                        </div>
                        <div className="sm:col-span-2 mt-4">
                            <h2 className="text-m font-semibold tracking-tight text-gray-900 dark:text-gray-200">To</h2>
                            <SelectMultipleInput
                                name="recipient_to"
                                value={emailNotificationFormik.values.recipient_to}
                                options={emailNotificationFormik.values.recipient_to_email}
                                disabled={!isEnabledEmail}
                                onChange={selectedFolder => {
                                    const selectedFolderIds = selectedFolder?.map(item => item?.label)
                                    const updatedDocumentData = emailNotificationFormik.values.recipient_to_email
                                        .filter(option => !selectedFolderIds?.includes(option.label))
                                        .map(data => ({
                                            value: data.label,
                                            label: data.label,
                                        }));
                                    emailNotificationFormik.setFieldValue('recipient_to', selectedFolder)
                                    emailNotificationFormik.setFieldValue('recipient_cc_email', updatedDocumentData)
                                    emailNotificationFormik.setFieldValue('recipient_bcc_email', updatedDocumentData)
                                }}
                                onBlur={() => emailNotificationFormik.setFieldTouched('recipient_to', true)}
                                label="Recipient Email"
                            />
                            <InputField type={'text'} name={'recipient_to_email_input'} disabled={!isEnabledEmail} label={RecipientEmailAddress} value={emailNotificationFormik.values.recipient_to_email_input}
                                onChange={emailNotificationFormik.handleChange}
                                onBlur={emailNotificationFormik.handleBlur}
                                error={(emailNotificationFormik.errors.recipient_to_email_input && emailNotificationFormik.touched.recipient_to_email_input) ? emailNotificationFormik.errors.recipient_to_email_input : ''} />
                        </div>
                        {/* Cc Section */}
                        <div className="sm:col-span-2 mt-4">
                            <h2 className="text-m font-semibold tracking-tight text-gray-900 dark:text-gray-200">Cc</h2>
                            <SelectMultipleInput
                                name="recipient_cc"
                                value={emailNotificationFormik.values.recipient_cc}
                                options={emailNotificationFormik.values.recipient_cc_email}
                                onChange={selectedDocument => {
                                    const selectedFolderIds = selectedDocument?.map(item => item?.label)
                                    const updatedDocumentData = emailNotificationFormik.values.recipient_cc_email
                                        .filter(option => !selectedFolderIds?.includes(option.label))
                                        .map(data => ({
                                            value: data.label,
                                            label: data.label,
                                        }));
                                    emailNotificationFormik.setFieldValue('recipient_cc', selectedDocument)
                                    emailNotificationFormik.setFieldValue('recipient_to_email', updatedDocumentData)
                                    emailNotificationFormik.setFieldValue('recipient_bcc_email', updatedDocumentData)
                                }}
                                disabled={!isEnabledEmail}
                                onBlur={() => emailNotificationFormik.setFieldTouched('recipient_cc', true)}
                                label="Recipient Email"
                            />
                            <InputField type={'text'} name={'recipient_cc_email_input'} disabled={!isEnabledEmail} label={RecipientEmailAddress} value={emailNotificationFormik.values.recipient_cc_email_input}
                                onChange={emailNotificationFormik.handleChange}
                                onBlur={emailNotificationFormik.handleBlur}
                                error={(emailNotificationFormik.errors.recipient_cc_email_input && emailNotificationFormik.touched.recipient_cc_email_input) ? emailNotificationFormik.errors.recipient_cc_email_input : ''} />
                        </div>
                        {/* Bcc Section */}
                        <div className="sm:col-span-2 mt-4">
                            <h2 className="text-m font-semibold tracking-tight text-gray-900 dark:text-gray-200">Bcc</h2>
                            <SelectMultipleInput
                                name="recipient_bcc"
                                value={emailNotificationFormik.values.recipient_bcc}
                                options={emailNotificationFormik.values.recipient_bcc_email}
                                disabled={!isEnabledEmail}
                                onChange={selectedDocument => {
                                    const selectedFolderIds = selectedDocument?.map(item => item?.label)
                                    const updatedDocumentData = emailNotificationFormik.values.recipient_cc_email
                                        .filter(option => !selectedFolderIds?.includes(option.label))
                                        .map(data => ({
                                            value: data.label,
                                            label: data.label,
                                        }));
                                    emailNotificationFormik.setFieldValue('recipient_bcc', selectedDocument)
                                    emailNotificationFormik.setFieldValue('recipient_cc_email', updatedDocumentData)
                                    emailNotificationFormik.setFieldValue('recipient_to_email', updatedDocumentData)
                                }}
                                onBlur={() => emailNotificationFormik.setFieldTouched('recipient_bcc', true)}
                                label="Recipient Email"
                            />
                            <InputField type={'text'} name={'recipient_bcc_email_input'} disabled={!isEnabledEmail} label={RecipientEmailAddress} value={emailNotificationFormik.values.recipient_bcc_email_input}
                                onChange={emailNotificationFormik.handleChange}
                                onBlur={emailNotificationFormik.handleBlur}
                                error={(emailNotificationFormik.errors.recipient_bcc_email_input && emailNotificationFormik.touched.recipient_bcc_email_input) ? emailNotificationFormik.errors.recipient_bcc_email_input : ''} />
                        </div>
                    </div>
                </div>
                <div className="mt-10 flex justify-start">
                    {sendEmailPermission || emailNotificationSettingLoader || emailEnableDisablerLoader ?
                        <Button type="submit"
                            className={emailNotificationSettingLoader || emailEnableDisablerLoader ? "block rounded-md bg-gray-600 px-3.5 py-2.5 mr-3 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600" : buttonClassName} text="Update" />
                        : <Button type="button"
                            className={"block rounded-md bg-gray-600 px-3.5 mr-3 py-2.5 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"} text="Update" />
                    }
                    <Link to='/home'
                        className="block rounded-md bg-gray-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"><span>Cancel</span></Link>
                    {emailNotificationSettingLoader || emailEnableDisablerLoader ? <FontAwesomeIcon className="w-5 h-5 mr-4 ml-5 mt-2 animate-spin text-gray-400 dark:text-gray-600" icon={faSpinner} /> : null}
                </div>
            </form>
        )
    }
    const isLoading = loading || load || loadData || emailLoader;

    return (
        <PageWrapper heading={'Configure Application Settings'}>
            {isLoading ? loadingSection()
                : <div>
                    <ConfigSection
                        title={'Push Notification'}
                        description={`Notification: ${isEnabled ? "Enable" : "Disable"}`}>
                        {notificationSection()}
                    </ConfigSection>
                    <div className="py-6">
                        <div className="grid grid-cols-1 xl:grid-cols-12 gap-4">
                            <div className="col-span-4">
                                <h2 className="text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-200">Max File Support (MB)
                                </h2>
                                <p className="leading-8 text-gray-600 dark:text-gray-400 text-sm capitalize">Max file upload for video and documents.</p>
                            </div>
                            <form method="POST" className="col-span-8" onSubmit={maxFileSizeFormik.handleSubmit}>
                                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2 bg-white dark:bg-neutral-900 shadow py-8 px-4 sm:px-8">
                                    <div className="">
                                        <InputField type={'number'} name={'fileSize'} label={'Max file Size'} value={maxFileSizeFormik.values.fileSize} required onChange={maxFileSizeFormik.handleChange} onBlur={maxFileSizeFormik.handleBlur}
                                            error={(maxFileSizeFormik.errors.fileSize && maxFileSizeFormik.touched.fileSize) ? maxFileSizeFormik.errors.fileSize : ''} />
                                    </div>
                                </div>
                                {actionItemContent()}
                            </form>
                        </div>
                    </div>
                    <hr />
                    <ConfigSection
                        title={'Email Notification'}
                        description={`Notification: ${isEnabledEmail ? "Enable" : "Disable"}`}>
                        {emailNotificationSection()}
                    </ConfigSection>
                </div>}
        </PageWrapper>
    );
};

export default ConfigurationApplicationConfig;
