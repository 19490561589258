import logo from './../assets/logo.png'
import * as Auth from "../utils/AuthClient"; //NOSONAR
import React, { useEffect } from 'react';

const Login = () => {
  const handleLogin = async () => {
    try {
      // Step 1: Authorize the user
      const authURL = await Auth.requestAuthorisationURLSimmas();
      window.location.replace(authURL);
    }
    catch (error) {
      // Handle error during login process
    }
  };

  useEffect(() =>{
    const envVariable = process.env.REACT_APP_ENV;
    const prefix = 'SIERA';
    const suffix = envVariable !== 'prod' ? ` - ${envVariable.toUpperCase()}` : '';
    const pageTitle = `${prefix}${suffix} | Manage Login`;
    document.title = pageTitle;
  })

  return (
    <div className="mt-16 w-full max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 mb-6">
      <div className="min-h-40 mt-10 flex justify-center items-center">
        <div className="flex flex-col w-full max-w-xl bg-white dark:bg-neutral-900 rounded-md shadow px-6 py-4">
          <div className="py-4">
            <h3 className="text-2xl font-bold text-center text-gray-900 dark:text-gray-100">Welcome to SIERA Application</h3>
          </div>
          <div className="flex justify-center"><span className="w-16 p-1 bg-red-600 mb-2"></span></div>
          <div>
          </div>
          <div className="flex justify-center">
            <img className="h-40 w-auto rounded-md" src={logo} alt="Your Company" />
          </div>
          <div className="my-10">
            <button type="button" onClick={handleLogin}
              className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Admin Portal Login</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
